import QueryString from 'qs';
import api from '../api';


export const hives = api.injectEndpoints({
  endpoints: (builder) => ({
    getHives: builder.query({
      query: () => ({ url: '/user/hive', method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['HIVES'],
    }),
    getAuditsByHive: builder.query({
      query: (hiveId) => ({ url: `/audit/hive/${hiveId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['HIVE'],
    }),
    getAuditsByStudy: builder.query({
      query: (studyId) => ({ url: `/audit/study/${studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['HIVE'],
    }),
    getHiveStatsById: builder.query({
      query: (hiveId) => ({ url: `/user/hive/statsbyid/${hiveId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items[0],
      providesTags: ['HIVE'],
    }),

    getAudits: builder.query({
      query: () => ({ url: '/audit', method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['AUDITS'],
    }),
    createAudit: builder.mutation({
      query: (payload) => ({ url: '/audit', method: 'POST', body: payload }),
      invalidatesTags: ['AUDITS', 'AUDIT', 'HIVE', 'HIVES'],
    }),

    getAuditStatsById: builder.query({
      query: (auditId) => ({ url: `/audit/statsbyid/${auditId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items[0],
      providesTags: ['AUDIT'],
    }),
    getFindingByAudit: builder.query({
      query: (auditId) => ({ url: `/audit/finding/audit/${auditId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items[0],
      providesTags: ['AUDIT'],
    }),
    updateAudit: builder.mutation({
      query: (payload) => ({ url: `/audit/${payload.auditId}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['AUDIT', 'AUDITS'],
    }),

    createFinding: builder.mutation({
      query: (payload) => ({ url: '/audit/finding', method: 'POST', body: payload }),
      invalidatesTags: ['AUDIT', 'AUDITIMG'],
    }),
    getSimilarImgs: builder.mutation({
      query: (payload) => ({ url: `/audit/${payload.auditId}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['AUDIT'],
    }),
    updateImageReview: builder.mutation({
      query: (payload) => ({ url: `/audit/image/review/${payload.auditId}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['AUDIT'],
    }),

    getFindingByImage: builder.query({
      query: (seriesId) => ({ url: `/audit/finding/image/${seriesId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['AUDITIMG'],
    }),
    getColdAuditFileArray: builder.query({
      query: ({ id }) => ({ url: `/files/cold/files/audit/${id}`, method: 'GET' }),
      providesTags: ['AUDITIMG'],
    }),

  }),
});

export const {
  usePrefetch,
  useGetHivesQuery,
  useGetHiveStatsByIdQuery,
  useGetAuditStatsByIdQuery,
  useGetAuditsQuery,
  useGetColdAuditFileArrayQuery,
  useCreateAuditMutation,
  useGetAuditsByHiveQuery,
  useGetAuditsByStudyQuery,
  useGetFindingByImageQuery,
  useCreateFindingMutation,
  useUpdateImageReviewMutation,
  useGetSimilarImgsMutation,
  useUpdateAuditMutation,
  useGetFindingByAuditQuery,
} = hives;
