import React from 'react';
// import classnames from 'classnames';
import './log-item.scss';
import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import { Tile, Spacer, Label, Button, Modal, Badge, Checkbox } from '../../atoms';


export interface LogItemProps {
  user: string,
  item: string,
  action: string,
  date: number,
}

const LogItem = (props: LogItemProps): JSX.Element => {
  const { user, item, action, date } = props;
  return (
    <div className="log-item">
      <Tile>
        <div className='log-item__body'>
          <Grid container spacing={2}>
            <Grid xs={3} >
              <Label size="h5" center>{user}</Label>
            </Grid>
            <Grid xs={3} >
              <Label size="h5" center> {moment(date).format('L')} </Label>
            </Grid>
            <Grid xs={3} >
              <Label size="h5" center>{item}</Label>
            </Grid>
            <Grid xs={3} >
              <Label size="h5" center>{action}</Label>
            </Grid>
          </Grid>
        </div>
      </Tile>
    </div>
  );
};

LogItem.defaultProps = {
};

LogItem.displayName = 'LogItem';
export default LogItem;
