/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BsPeople, BsFillCalendarDateFill } from 'react-icons/bs';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FaClipboardList } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { IoStatsChart } from 'react-icons/io5';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { IconButton, Label, Spacer, Spinner, Tile } from '../../../components/atoms';
import { useGetPatRegistriesStatsQuery, useGetPatientRegistryRequirementsQuery, registriesApi, useGetVisitsFromRegistryByDatesQuery } from '../../../api/builders/registries.api';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { ClinicVisits, BlackholeListItem } from '../../../components/molecules';
import { useGetBlackholePatientsQuery } from '../../../api/builders/conference.api';


interface LocationState {
  registry: any;
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const getCurrentWeekRange = () => {
  const today = new Date();
  const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  const startOfWeek = new Date(today); // Copy today's date
  startOfWeek.setDate(today.getDate() - currentDay); // Move to the first day (Sunday) of the current week

  const endOfWeek = new Date(today); // Copy today's date
  endOfWeek.setDate(today.getDate() + (6 - currentDay)); // Move to the last day (Saturday) of the current week

  return { startOfWeek, endOfWeek };
};



const Registry = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const { registry } = location?.state as LocationState;
  const { data: blackHole, isLoading: isLoadingHold, isFetching: isFetchingHole } = useGetBlackholePatientsQuery(registry ? registry.id : skipToken);
  const [payload, setPayload] = React.useState<any>(null);
  const { data: visits, isLoading: isLoadingVisits, isFetching: isFetchingVisits } = useGetVisitsFromRegistryByDatesQuery(payload ?? skipToken);


  React.useEffect(() => {
    const { startOfWeek, endOfWeek } = getCurrentWeekRange();
    const pl = {
      patientregistryUID: registry?.id ?? skipToken,
      startDate: startOfWeek?.toISOString() ?? '',
      endDate: endOfWeek?.toISOString() ?? '',
    };
    setPayload(pl);
  }, [registry]);


  const onOpen = (patient: any) => {
    navigate('/private/registries/registry/patient', { state: { patient } });
  };


  React.useEffect(() => {
    const newBreadcrumb = {
      label: registry?.name ?? 'Registry',
      path: location.pathname,
      state: { registry },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    console.log('newBreadcrumb', newBreadcrumb);
    if (alreadyExists === false) {
      setBreadcrumbs([newBreadcrumb]);
    }
  }, [location.pathname]);


  const handleChangeTab = (newTab: string) => {
    navigate(`/private/registries/registry/${newTab}`, { state: { registry } });
  };


  if (!location?.state) {
    return (
      <div className='d-flex-row-center'>
        <Label size="h4" weight={600} color="primary">Error Loading Registry Details</Label>
      </div>
    );
  }
  return (
    <div className="registry">

      <div className="registry__header">
        <button type='button' className='registry__tab-active' >
          <AiOutlineDashboard className='registry__icon-active' />
          <Label size='h5' uppercase>Overview</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('settings')}>
          <FiSettings className='registry__icon' />
          <Label size='h5' uppercase>Settings</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('Patients')}>
          <BsPeople className='registry__icon' />
          <Label size='h5' uppercase>All Patients</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('Tracker')}>
          <IoStatsChart className='registry__icon' />
          <Label size='h5' uppercase>Patient Tracker</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('Conferences')}>
          <FaClipboardList className='registry__icon' />
          <Label size='h5' uppercase>Conferences</Label>
        </button>
      </div>



      <div className='registry__body'>
        <div className='registry__panel1'>
          <div className='registry__patients'>
            <ClinicVisits data={visits ?? []} loading={false} openHandler={onOpen} clinic={registry.id} />
          </div>
        </div>
        <div className='registry__panel2'>
          <div className='registry__urgent'>
            <div className='registry__urgent-tile'>
              <div className='registry__body-heading'>
                <Label size="h4" uppercase weight={600} center >Neglected Patients</Label>
                <Spacer value={5} />
              </div>
              <Spacer value={10} />
              <div className='registry__urgent-tile-body'>
                {blackHole?.length > 0 && Array.isArray(blackHole[0].patients) ? (
                  <>
                    {blackHole[0].patients.map((item: any) => (
                      <BlackholeListItem
                        item={item}
                        onOpen={onOpen}
                        key={item.id}
                      />
                    ))}
                  </>
                )
                  :
                  <div className='registry__urgent-tile-body-empty'>
                    <Label size="h6"  >Nothing to show</Label>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default withAuthenticator(Registry);



