/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, ReactElement, useState, useEffect } from 'react';
import FileTreeItem from '../FileTreeItem/FileTreeItem';
// import classnames from 'classnames';
import './file-tree.scss';

export interface FileTreeProps  {
  tree: Array<any>,
}

export interface TreeProps  {
  tree: Array<any>,
  level: number,
  history: Array<any>,
}

const FileTree = (props: FileTreeProps) => {
  const { tree } = props;
  const [filetree, setFileTree] = useState<TreeProps>({ tree,  level:0, history: [] });
  useEffect(() => {
    setFileTree({ tree, level:0, history: [] });
  }, [tree]);
  
  const onNavigate = ( rootindex: any ) => {
    if (rootindex >= 0 ) {
      setFileTree(prev=>({ tree: filetree.tree[rootindex].children, history: [...prev.history, prev.tree], level: prev.level + 1 }));
    } else {
      const history = [...filetree.history];
      const parent = history.pop();
      setFileTree(prev=>({ ...prev, tree: parent, history, level: prev.level - 1 }));
    }
  };

  
  const Tree = ( treeprops: { data: any; } ): ReactElement<any, string> => {
    const { data } = treeprops;
    return <div>
      {filetree.level > 0 && <FileTreeItem id='ft_back' data={ { name:'..' }} onNavigate={()=>onNavigate(-999)}/>}
      {data.map((it: any, index: any)=><FileTreeItem id={`ft_${index}`} data={it} onNavigate={()=>onNavigate(index)}/>)}
      </div>;
  };
  return (
    <div className="file-tree" >
      <Tree data={filetree.tree} />
    </div>
  ); 
};

FileTree.defaultProps = {
};

FileTree.displayName = 'FileTree';


export default FileTree;
