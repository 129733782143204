import React from 'react';
import classnames from 'classnames';
import Spinner from '../Spinner/Spinner';
import './button.scss';

type Props = {
  children?: any;
  kind?: 'primary' | 'secondary' | 'ghost' | 'danger' | 'warning' | 'success' | 'info' | 'ghost-white';
  disabled?: boolean;
  size?: 'large' | 'small' | 'wide' | 'default';
  className?: string;
  outline?: boolean;
  loading?: boolean;
  onClick?: () => void;
  icon?: React.ComponentType<any>;
  iconSize?: number;
};

const Button = ({
  kind,
  disabled,
  children,
  outline,
  loading,
  className,
  onClick,
  size,
  icon: Icon,
  iconSize,
}: Props) => {
  
  let classes = {
    button: true,
    [`button--${kind}`]: true,
    'button--with-icon': Icon,
    'button--disabled': disabled,
    'button--with-text': children,
    [`button--${size}`]: size,
    [`${className}`]: true,
  };

  if (kind !== 'ghost') {
    classes = {
      'button--outline': outline,
      ...classes,
    };
  }

  return (
    <div className="button-wrapper">
      {loading ? (
      // eslint-disable-next-line react/button-has-type
        <span className="button__content">
          <Spinner size={22} />
        </span>
      ) : (
        // eslint-disable-next-line react/button-has-type
        <button className={classnames(classes)} onClick={onClick}  disabled={disabled}>
          <span className="button__content">
            {Icon && <Icon className="button__icon" size={iconSize} />}
           {children &&  <span className="button__text">{children}</span>}
          </span>
        </button>
      )}
    </div>
  );
};

Button.defaultProps = {
  kind: 'primary',
  icon: null,
  size: 'default',
  className: 'button',
  disabled: false,
  outline: false,
  iconSize: 20,
  onClick: () => null,
  loading: false,
  children: '',
};
export default Button;
