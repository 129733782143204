import React, { useState } from 'react';
import './delete-verification.scss';
import { Text, Spacer, Button, Spinner } from '../../atoms';
import TextInput from '../TextInput/TextInput';

export interface DeleteVerificationProps {
  item: any,
  loading?: boolean,
  name: string,
  onCancel: () => void,
  input?: string,
  onDelete: (item: any) => void,
}

const DeleteVerification = (props: DeleteVerificationProps) => {
  const { item, loading, onDelete, input, onCancel, name } = props;

  const [verifyInput, setVerifyInput] = useState('');
  const [incorrect, setIncorrect] = useState(false);

  const handleChangeVerifyInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyInput(event.target.value);
  };

  const verify = async () => {
    try {
      const inputTmp = verifyInput.trim().toUpperCase();
      if (input && inputTmp === input.trim().toUpperCase()) {
        if (item) {
          await onDelete(item);
        }
        setVerifyInput('');
        setIncorrect(false);
      } else if (!input && inputTmp === 'DELETE') {
        if (item) {
          await onDelete(item);
        }
        setVerifyInput('');
        setIncorrect(false);
      } else {
        setIncorrect(true);
      }
    } catch (e) {
      console.error(e);
    }
  };



  return (
    <div className="card">
      <Text>
        Are you sure you want to delete {name} and all its contents? This can&apos;t be undone. Type
        &apos;DELETE&apos; to confirm
      </Text>
      <Spacer value={10} />
      <TextInput
        type="input"
        id="input"
        kind={incorrect ? 'invalid' : 'ghost'}
        value={verifyInput}
        onChange={handleChangeVerifyInput}
      />
      <Spacer value={30} />
      {loading ? (
        <div className="_center">
          <Spinner />
        </div>
      ) : (
        <div className="_center">
          <Button outline onClick={() => verify()}>
            Confirm
          </Button>
          <Button outline kind="danger" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

DeleteVerification.defaultProps = {
  loading: false,
  input: '',
};

DeleteVerification.displayName = 'DeleteVerification';


export default DeleteVerification;
