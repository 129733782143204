import React from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { LuRefreshCcw, LuPlusCircle } from 'react-icons/lu';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, Button, Spacer, Dropdown, DeIdentifiedPatient, LogItem, Modal, Checkbox, CohortUserItem } from '../../../components';
import { IconButton } from '../../../components/atoms';
import { useGetCohortInfoQuery, useAddPatientsMutation, cohortApi, useSendEmailMutation } from './api';
import Logs from './logs';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';

export interface Item {
  id: string,
  acl: string | null,
  description: string | null,
  shared: boolean,
  numberOfPatients: number,
  cohortLabel: string,
  createdAt: number,
  type: string
}

interface LocationState {
  cohort: Item;
}



const SharedCohort = () => {
  const location = useLocation();
  const { cohort } = location.state as LocationState;
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetCohortInfoQuery({ id: cohort?.id ? cohort.id : skipToken, type: cohort?.type ? cohort.type : skipToken });
  const { refetch: refetchPats } =
    cohortApi.endpoints.getCohortInfo.useQuerySubscription({ id: cohort?.id ? cohort.id : skipToken, type: cohort?.type ? cohort.type : skipToken });
  const [view, setView] = React.useState('participants');


  return (
    <div className="cohort" >
      <div className='cohort__header'>
        <Label size='h2' weight={200}>{cohort?.cohortLabel ?? 'Cohort'}</Label>
      </div>
      < div className="cohort__body" >
        <aside className='cohort__side-panel'>
          <div className='cohort__btn-wrapper'>
            <button className={view === 'participants' ? 'cohort__button-active' : 'cohort__button'} type='button' onClick={() => setView('participants')}> Participants</button>
            <button className={view === 'access' ? 'cohort__button-active' : 'cohort__button'} type='button' onClick={() => setView('access')}> Access</button>
            <button className={view === 'history' ? 'cohort__button-active' : 'cohort__button'} type='button' onClick={() => setView('history')}> Logs</button>
          </div>
        </aside>
        <div className='cohort__right-panel'>
          {view === 'participants' &&
            <div>
              <div className="study__heading d-flex-row _apart">
                <Label size="h4" weight={600} >Participants</Label>
                <IconButton icon="refresh" onClick={refetchPats} />
              </div>
              <Grid container spacing={2}>
                <Grid xs={4}>
                  <Label center size='subtext'>Identifier</Label>
                </Grid>
                <Grid xs={4}>
                  <Label center size='subtext'> Age </Label>
                </Grid>
                <Grid xs={3}>
                  <Label center size='subtext'>Gender </Label>
                </Grid>
              </Grid>
              {Array.isArray(data) && data[0]?.patients && data[0].patients.map((item: any) => (
                <DeIdentifiedPatient
                  key={item.id}
                  id={item.id}
                  patientId={item.secondaryId}
                  gender={item.PatientSex}
                  checked={false}
                  handleCheck={() => console.info('hi')}
                  dob={Number(item.PatientBirthDate)}
                  patient={item}
                  cohortId={cohort.id}
                  cohort={cohort.cohortLabel}
                  type='shared'
                />
              ))}
            </div>
          }
          {view === 'access' &&
            <div>
              <div className='study__heading d-flex-row _apart'>
                <Label size="h4" weight={600} >User Access</Label>
                <div>
                  <IconButton icon="refresh" onClick={refetchPats} />
                </div>
              </div>
              <Grid container spacing={2}>
                <Grid xs={4}>
                  <Label center size='subtext'>Email</Label>
                </Grid>
                <Grid xs={4}>
                  <Label center size='subtext'> Date Added </Label>
                </Grid>
              </Grid>
              {Array.isArray(data) && data[0]?.sharedWith && data[0].sharedWith.map((item: any) => (
                <CohortUserItem
                  email={item.email}
                  added={item.createdAt}
                  readonly
                />
              ))}
            </div>
          }
          {view === 'history' &&
            <Logs cohort={cohort} />
          }
          {view === 'settings' &&
            <div>
              <Label size="h4" weight={600} >Types of Data Shared</Label>
              <Spacer value={15} />
              <div>
                <Grid container spacing={2}>
                  <Grid xs={3}>
                    <Label size='h5'>Radiology</Label>
                    <Checkbox checked label='All Radiology' onChange={() => console.info('click')} ><Label size='h5'>All Radiology</Label></Checkbox>
                    <div className='shared-cohort__tabed'>
                      <Checkbox checked label='MRI' onChange={() => console.info('click')} ><Label size='h5'>MRI</Label></Checkbox>
                      <Checkbox checked label='CT' onChange={() => console.info('click')} ><Label size='h5'>CT</Label></Checkbox>
                    </div>
                  </Grid>
                  <Grid xs={2}>
                    <Label size='h5'>Reports</Label>
                    <Checkbox checked label='Labs' onChange={() => console.info('click')} ><Label size='h5'>Labs</Label></Checkbox>
                  </Grid>
                  <Grid xs={7}>
                    <div />
                  </Grid>
                  <Grid xs={5}>
                    <div className='btn-row'>
                      <Button disabled>Save Changes</Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          }
        </div>
      </div >
    </div>
  );
};

export default withAuthenticator(SharedCohort);
