/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React from 'react';
// import classnames from 'classnames';
import './snapshot-browser.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FaUndo } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';

import { AiFillDelete } from 'react-icons/ai';

import { RootState } from '../../../store/store';
import { deleteSnapshot, resetSnapshots } from '../../../store/snapshots';

export interface SnapshotBrowserProps {
  id: string;
  className?: string;
}

const SnapshotBrowser = (props: SnapshotBrowserProps): JSX.Element => {
  const { id, className } = props;
  const dispatch = useDispatch<any>();
  const snapshots = useSelector((state: RootState) => state.snapshot.snapshots);
  const sliderSettings = {
    dots: true, // Show navigation dots
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 3, // Number of visible slides at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    initialSlide: snapshots.length - 1, // Set the initial slide to the last snapshot (most recent)
    // responsive: [
    //   {
    //     breakpoint: 768, // Adjust the settings for smaller screens if needed
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  };
  const sortedSnapshots = snapshots.slice().reverse(); // Sort the snapshots in reverse order
  const handleDeleteSnapshot = (snapshotId: string) => {
    dispatch(deleteSnapshot(snapshotId));
  };

  const handleResetSnapshots = () => {
    dispatch(resetSnapshots());
  };
  const handleOpenSnapshotWindow = (snapshotId: string) => {};

  if (sortedSnapshots && sortedSnapshots.length > 0) {
    return (
      <div className="snapshot-browser">
        <div className="snapshot-browser__thumbnails">
          {sortedSnapshots.map((snapshot) => (
            <div className="thumbnail" key={snapshot.id}>
              <img
                src={snapshot.fileUrl}
                alt="Snapshot Thumbnail"
                onClick={() => handleOpenSnapshotWindow(snapshot.id)}
              />
              <div className="thumbnail__overlay" onClick={() => handleDeleteSnapshot(snapshot.id)}>
                <RiCloseLine />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return <div />;
};

SnapshotBrowser.defaultProps = {
  className: '',
};

SnapshotBrowser.displayName = 'SnapshotBrowser';
export default SnapshotBrowser;

/* <span>ID: {snapshot.id}</span>
    <span>User ID: {snapshot.userId}</span>
    <span>Meta: {JSON.stringify(snapshot.meta)}</span> */
