import React from 'react';
// import classnames from 'classnames';
import './loading-wrapper.scss';
import CustomProgress from '../../atoms/CustomProgress/CustomProgress';
import Label from '../../atoms/Label/Label';

export interface LoadingWrapperProps  {
  id: string,
  className?: string,
  isLoading: boolean,
  error?: string |  undefined,
  children: JSX.Element | JSX.Element[],
}

const LoadingWrapper = (props: LoadingWrapperProps): JSX.Element => {
  const { id, className, isLoading, children, error } = props;
  
  if (error !== undefined) {
    return (
      <div key={`LoadingWrapper_${id}`} className={`loading-wrapper ${className}`}>
        <Label size='h6' color='danger'>{error}</Label>
      </div>
    );   
  }
  if (isLoading === true ) {
    return (
      <div key={`LoadingWrapper_${id}`} className={`loading-wrapper ${className}`}>
        <CustomProgress />
        {error}
      </div>
    );   
  }
  
  return (
        <div key={`LoadingWrapper_${id}`} className={`loading-wrapper ${className}`}>
        {children}
        </div>
  ); 
};

LoadingWrapper.defaultProps = {
  className: '',
  error: undefined,
};

LoadingWrapper.displayName = 'LoadingWrapper';
export default LoadingWrapper;
