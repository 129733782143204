import api from '../api';

export const logsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFiles: builder.query({
      query: (payload) => ({ url: `/files/images/patient/${payload.patientId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['PATIENTFILES'],
    }),
    downloadPatSeries: builder.mutation({
      query: (payload) =>  ({ url: '/files/s3zip/cohort/series', method: 'POST', body: payload }),
    }),
    sendImg: builder.mutation({
      query: (payload) =>  ({ url: '/files/optellum', method: 'POST', body: payload }),
    }),
    downLoadFile: builder.mutation({ query: (s3_path) => ({ url: '/files/drive/getobject/', method: 'POST', body: s3_path }) }),
  }),
});

export const {  
  usePrefetch,
  useGetFilesQuery, 
  useDownloadPatSeriesMutation, 
  useDownLoadFileMutation,
  useSendImgMutation,
} = logsApi;
