/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React from 'react';
// import classnames from 'classnames';
// import { BsCheckCircle } from 'react-icons/bs';
import { BiCircle } from 'react-icons/bi';
import './table-header.scss';


export type HeaderProp = {
  label: string,
  key: string,
  title: string,
  customCol: any,
  accessor: any,
};  

type Props = {
  noSelectors?: boolean,
  small: boolean | false,
  selected: Array<any> | [],
  sliced: Array<any> | [],
  onSelectAll: () => any,
  headers: Array<HeaderProp>
};

const TableHeader = (props: Props) => {
  const { noSelectors, onSelectAll, selected, sliced, headers } = props;
  return (
    <tr className="table-header">
    {!noSelectors && (
      <th
        colSpan={1}
        className='table-header__circle-button'
        onClick={onSelectAll}
      >
        <BiCircle className='' size={16} />
        {/* {selected.some((s) => sliced.indexOf(s) >= 0) ? (
          <BsCheckCircle className='' size={16} />
        ) : (
          <BiCircle className='' size={16} />
        )} */}
      </th>
    )}
   {headers.map(
     (header, index) => {
       if (header.title.toLowerCase() === 'actions') {
         return (
              <th className='__actions-header' key={index}>
                {header.title}
              </th>
         );
       } 
       return <th key={index}>{header.title}</th>;          
     },
   )}
  </tr>
  ); 
};
TableHeader.defaultProps = {
  noSelectors: false,

};
export default TableHeader;
