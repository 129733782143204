import React from 'react';
import classnames from 'classnames';
import './text.scss';

type Props = {
  children: any;
  size?: 'sm' | 'md' | 'lg';
  color?: 'default' | 'white' | 'ghost' | 'primary' | 'secondary' | 'tertiary' | 'danger' | 'warning' | 'success';
  uppercase?: boolean;
  className?: string | void;
};

const Text = (props: Props) => {
  const { children, size, color, uppercase, className } = props;

  const classes = {
    'text': true,
    [`text--${size}`]: true,
    [`text--${color}`]: true,
    'text--uppercase': uppercase,
    [`${className}`]: true,
  };

  return (
    <span className={classnames(classes)}>
        {children}
    </span>
  );
};

Text.defaultProps = {
  size: 'md',
  color: 'default',
  uppercase: false,
  className: null,
};

export default Text;
