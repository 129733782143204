import React from 'react';
// import classnames from 'classnames';
import './protocol-time-point.scss';
import { IProtocolItemType } from '../ProtocolItem/ProtocolItem';

export interface ProtocolTimePointProps  {
  id: string,
  className?: string,
}

export interface IProtocolTimePoint {
  iscurrent: boolean;
  date: string | undefined,
  id: string | undefined,
  label: string | undefined,
  order: number | undefined,
  studyDay: string | undefined,
  updatedAt: number | undefined,
  visit: string | undefined,
  visitType: string | undefined,
  userProtocolItems: IProtocolItemType[] | [],
}

const ProtocolTimePoint = (props: ProtocolTimePointProps): JSX.Element => {
  const { id, className } = props;
  return (
        <div key={`ProtocolTimePoint_${id}`} className="card">
        ProtocolTimePoint
        </div>
  ); 
};

ProtocolTimePoint.defaultProps = {
  className: '',
};

ProtocolTimePoint.displayName = 'ProtocolTimePoint';
export default ProtocolTimePoint;
