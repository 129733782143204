import React from 'react';
// import classnames from 'classnames';
import './emr-search-box.scss';

export interface EMRSearchBoxProps {
  value?: string;
  onChange?: (value: string) => void;
  onChangeField?: (value: string) => void;
  onSubmit?: (value: string) => void;
  placeholder?: string;
  ops: any,
}

const EMRSearchBox = (props: EMRSearchBoxProps): JSX.Element => {
  const { value, onChange, onSubmit, placeholder, onChangeField, ops } = props;

  const [state, setState] = React.useState({
    value: value || '',
  });
  const [field, setField] = React.useState('mrn');
  const [blur, setBlur] = React.useState(false);

  React.useEffect(() => {
    if (typeof value === 'string') {
      setState({ value });
    }
  }, [value]);

  const handleChangeField = (val: any) => {
    setField(val.target.value);
    if (onChangeField) {
      onChangeField(val.target.value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { value } = e.target;
    setState({ value });
    if (onChange) onChange(value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      if (onSubmit) {
        onSubmit(state.value);
      }      // callMyFunction();
    }
  };

  return (
    <div className='textfield'>
      <div
        className={`textfield__input ${blur && 'textfield__input--focused'}`}
      >
        <div className='textfield__input-adornment'>
          <select onChange={handleChangeField} value={field} className='textfield__select' name="cars" id="cars">
            {ops.map((item: any) => (
              <option value={item.value}>{item.name}</option>
            ))}
          </select>
        </div>
        <input
          placeholder={placeholder}
          value={state.value}
          onChange={handleChange}
          className='textfield__input-field'
          onFocus={() => setBlur(!blur)}
          onBlur={() => setBlur(false)}
          onKeyDown={handleKeyPress}
        />
      </div>
    </div>
  );
};

EMRSearchBox.defaultProps = {
  value: '',
  onChange: () => null,
  onSubmit: () => null,
  onChangeField: () => null,
  placeholder: 'Enter text to search',
};

EMRSearchBox.displayName = 'EMRSearchBox';
export default EMRSearchBox;
