import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Tile, Label, DatePicker } from '../../../atoms';
import '../form-item.scss';

export interface DateProps {
  answer: any,
  onAnswer: (vals: any) => void,
  editable?: boolean,
  label: string,
  qNumber: number,
  noNumbers: boolean,
  className: string,
}

const Date = (props: DateProps): JSX.Element => {
  const { answer, onAnswer, editable, label, qNumber, noNumbers, className } = props;
  const [value, setValue] = useState('');

  const [invalid, setInvalid] = useState(false);


  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {
      setValue(answer[0].value);
    }
  }, [answer]);

  const onChange = (val: any) => {
    if (editable) {
      setValue(val);
      setInvalid(false);
      onAnswer([{ 'value': val, 'label': val }]);
    }
  };


  return (
    <div className={className}>
      {noNumbers ?
        <Label size="h6" weight={200}  >{label}</Label>
        :
        <Label size="h6" weight={200}  >{qNumber}: {label}</Label>
      }
      <DatePicker
        label=""
        value={value}
        onChange={onChange}
      />
      {/* {editable ?
        <DatePicker
          label=""
          value={value}
          onChange={onChange}
        />
        :
        <Label size="h6" >
          {value && moment(value).format('YYYY/MM/DD')}
        </Label>
      } */}
    </div>
  );
};

Date.defaultProps = {
  editable: true,
};

Date.displayName = 'Date';
export default Date;
