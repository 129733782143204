import React from 'react';
// import classnames from 'classnames';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import FlatList from 'flatlist-react';
import Grid from '@mui/material/Unstable_Grid2';
import { BsFolderFill } from 'react-icons/bs';
import './study-drive-by-site.scss';
import { useGetAllPatsQuery } from '../../../pages/private/StudyDrive/api';
import { Label, Tile } from '../../atoms';
import ProtocolFileLoader from '../ProtocolFileLoader/ProtocolFileLoader';

export interface StudyDriveBySiteProps {
  sites: SiteItem[] | [],
}
type SiteItem = {
  id: string | undefined;
  name: string | undefined;
  region: string | undefined;
  siteId: string | undefined;
  siteLabel: string | undefined;
  status: string | undefined;
  prefix: string | undefined;
};

type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  type: string | undefined;
  id: string;
  consentStatus: string;
  group?: string,
  userProtocol: any,
};



const StudyDriveBySite = (props: StudyDriveBySiteProps) => {
  const { sites } = props;
  const [payload, setPayload] = React.useState<any>(null);
  const [selected, setSelected] = React.useState<ParticipantItem | null>(null);
  const [currentSite, setCurrentSite] = React.useState<SiteItem | null>(null);
  const [allData, setAllData] = React.useState<ParticipantItem[] | []>([]);
  const [{ pageSize, current: pageCurrent }, setPagination] = React.useState({ pageSize: 5, current: 1 });
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetAllPatsQuery(payload !== null ? payload : skipToken);

  React.useEffect(() => {
    if (Array.isArray(sites) && sites.length > 0) {
      const siteId = sites[0].id;
      setPayload({
        siteId,
        pageSize,
        pageCurrent,
        orderBy: 'recent',
      });
    }
  }, [sites]);

  React.useEffect(() => {
    if (Array.isArray(data?.items) && data.items.length > 0) {
      const curr = allData.concat(data.items);
      setAllData(curr);
    }
  }, [data]);


  const handleChangeSite = (newSite: SiteItem) => {
    setCurrentSite(newSite);
    setPayload({
      siteId: newSite.id,
      pageSize: 5,
      pageCurrent: 1,
      orderBy: 'recent',
    });
    setPagination({
      pageSize: 5, current: 1,
    });
    setAllData([]);
    setSelected(null);
  };

  const handleSelectParticipant = (newPat: ParticipantItem) => {
    setSelected(newPat);
  };


  const renderItem = (item: ParticipantItem) => (
    <div role='button' tabIndex={-1} aria-label="select participant" className='study-drive-by-site__indent1' onClick={() => handleSelectParticipant(item)}>
      <Tile>
        <div>
          <Label size='h4' color={selected === item ? 'primary' : 'default'}> <BsFolderFill /> {item.participantId} </Label>
        </div>
      </Tile>
    </div>
  );

  const fetchMorePats = () => {
    setPayload({
      ...payload,
      pageSize: 5,
      pageCurrent: data.pageCurrent + 1,
      orderBy: 'recent',
    });
  };

  return (
    <div className="card">
      <Grid container spacing={2}>
        <Grid xs={3} style={{ paddingBottom: 0 }}>
          {Array.isArray(sites) && sites.length > 0 && sites.map((item: SiteItem) => (
            <div>
              <Tile className='study-drive-by-site__item'>
                <div role='button' tabIndex={-1} onClick={() => handleChangeSite(item)}>
                  <div>
                    <Label size='h4' color={currentSite === item ? 'primary' : 'default'}><BsFolderFill /> {item.siteLabel} </Label>
                    <div className='study-drive-by-site__indent1' ><Label size='subtext'>Region: {item.region} </Label></div>
                  </div>
                </div>
              </Tile>
              {currentSite === item &&
                <div>
                  <FlatList list={allData} renderItem={renderItem} renderWhenEmpty={() => <div />}
                    pagination={{
                      hasMore: data?.pageCurrent <= data?.pageTotal,
                      loadMore: fetchMorePats,
                      loadingIndicator: <div style={{ background: '#090' }}>Getting more items...</div>,
                    }}
                    wrapperHtmlTag='div'
                    style={{ overflow: 'auto', maxHeight: '270px' }}
                  />
                  
                </div>}
            </div>
          ))}
        </Grid>
        <Grid xs={9} style={{ paddingBottom: 0 }}>
          {selected &&
            <ProtocolFileLoader participant={selected} />
          }
        </Grid>
      </Grid>
    </div>
  );
};

StudyDriveBySite.defaultProps = {
};

StudyDriveBySite.displayName = 'StudyDriveBySite';


export default StudyDriveBySite;
