/* eslint-disable react/button-has-type */
import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import './multi-panels.scss';
import { FiChevronLeft } from 'react-icons/fi';

export interface MultiPanelsProps {
  id: string;
  className?: string;
  children: [JSX.Element, JSX.Element];
}

const MultiPanels = (props: MultiPanelsProps): JSX.Element => {
  const { id, className, children } = props;
  const [leftChild, rightChild] = children;
  const [showRightPanel, setShowRightPanel] = useState(false);

  const handleToggleRightPanel = () => {
    setShowRightPanel(!showRightPanel);
  };

  return (
    <div key={`MultiPanels_${id}`} className={`multi-panels ${className}`}>
      <div className={`panel ${leftChild?.props.className} left-panel` }>{leftChild}</div>
      <div className={`panel ${rightChild?.props.className} right-panel ${showRightPanel ? 'active' : ''}`}>
        <button className={`multi-panels__toggle-button ${showRightPanel ? 'active' : ''}`} onClick={handleToggleRightPanel}>
          <FiChevronLeft />
        </button>
        <div className={`right-panel-content ${showRightPanel ? 'active' : 'hidden'}`}>{rightChild}</div>
      </div>
    </div>
  );
};

MultiPanels.defaultProps = {
  className: '',
};

MultiPanels.displayName = 'MultiPanels';

export default MultiPanels;
