import React from 'react';
// import classnames from 'classnames';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import moment from 'moment';
import { Tile, Spacer, Label, Button, Modal, Badge } from '../../atoms';
import './news-item.scss';

export interface NewsItemProps {
  news: RecentItemType,
  onOpenSpecific: (item: Activity, news: RecentItemType) => void,
  acl: string,
}

type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  type: string | undefined;
  id: string;
  consentStatus: string;
};

type RecentItemType = {
  id: string,
  participantId: string,
  secondaryId: string,
  type: string,
  siteId: number,
  backgroundUrl?: string,
  activities: Activity,
  drive_s3_path: string,
  updatedAt: number,
};

type Activity = {
  file: string | undefined,
  id: string | undefined,
  item: string | undefined,
  label: string | undefined,
  messages: string | undefined,
  order: number,
  createdAt: number | undefined,
  updatedAt: number | undefined,
  role: string | undefined,
  status: string,
  surveyId: string | undefined,
  type: string | undefined,
  userId: string | undefined,
  timePointId: string,
  visit: string,
};

const NewsItem = (props: NewsItemProps) => {
  const { news, onOpenSpecific, acl } = props;
  const { id, participantId, activities, secondaryId } = news;

  return (
    <div className="news-item">
      <Tile className="news-item__tile">
        <Grid container spacing={2}>
          <Grid xs={2}>
            <Label size="h6" >{activities?.updatedAt ? moment(activities.updatedAt).format('LL') : 'N/A'} </Label>
          </Grid>
          <Grid xs={2} className='news-item__no-overflow'>
            <Label size="h6">{ acl === 'normal' ? participantId : secondaryId}</Label>
          </Grid>
          <Grid xs={2}>
            <Label size="h6" >{activities?.visit} </Label>
          </Grid>
          <Grid xs={3}>
            <Label size="h6" >{activities?.item} </Label>
          </Grid>
          <Grid xs={2}>
            <Label size="h6" >{activities?.status} </Label>
          </Grid>
          <Grid xs={1}>
            <div role='button' tabIndex={-1} onClick={() => onOpenSpecific(activities, news)} >
              <BsFillArrowRightCircleFill className='news-item__icon' />
            </div>
          </Grid>
        </Grid>
      </Tile>
    </div>
  );
};

NewsItem.defaultProps = {
};

NewsItem.displayName = 'NewsItem';


export default NewsItem;
