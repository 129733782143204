import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Label } from '../../../components/atoms';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';



const placeHolder = {
  description: 'Survey Title',
};

const PatientHome = () => {
  const somevar = 'PatientHome';
  const navigate = useNavigate();
  const location = useLocation();

  const handleStartSurvey = async (survey: any) => {
    navigate('/private/mysurveys/survey', { state: { survey } });
  };


  return (
    <div className="patient-home">
      <div className='linked-patient-home__empty'>
        <Label size='h3'>You have no surveys left to complete!</Label>
      </div>
    </div>
  );
};

export default withAuthenticator(PatientHome);
