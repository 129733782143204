/* eslint-disable */
import React, { useState } from 'react';
// import classnames from 'classnames';
import './simple-emr-search.scss';
import { Dropdown, Label, Spacer, Button, Modal } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import SearchBox from '../SearchBox/SearchBox';
import useDebounce from '../../../hooks/useDebounce';
import EMRSearchBox from '../EMRSearchBox/EMRSearchBox';

export interface SimpleEMRSearchProps {
  onSubmitSearch: (pl: any) => void,
  onFieldSearch: (pl: any) => void,
}


const ops = [
  {
    name: 'MRN',
    value: 'mrn',
  },
  {
    name: 'Name',
    value: 'name',
  },
  {
    name: 'Medical ID',
    value: 'MCL_ID',
  },
  {
    name: 'Patient Birth Date',
    value: 'PatientBirthDate',
  },
];

const SimpleEMRSearch = (props: SimpleEMRSearchProps): JSX.Element => {
  const { onSubmitSearch, onFieldSearch } = props;
  const [debouncedOutput, setDebouncedOutput] = useState('');
  const [immediateOutput, setImmediateOutput] = useState('');
  const [debouncedCalls, setDebouncedCalls] = useState(0);
  const [searchField, setSearchField] = useState('mrn');
  const [immediateCalls, setImmediateCalls] = useState(0);
  const [value, setValue] = React.useState<number[]>([10, 90]);
  const [searchQueryString, setSearchQueryString] = React.useState('');
  const [searchState, setSearchState] = React.useState({
    PatientSex: 'any',
    pky: 0,
    hx_cancer: 'any',
    density: 'any',
    spiculation: 'any',
    FDG: 'any',
  });


  React.useEffect(() => {
    onSubmitSearch(searchQueryString);
  }, [searchQueryString]);

  const resetSearch = () => {
    setSearchState({
      PatientSex: 'any',
      pky: 0,
      hx_cancer: 'any',
      density: 'any',
      spiculation: 'any',
      FDG: 'any',
    });
    setSearchQueryString('');
    // setSearchField('mrn');
    setDebouncedOutput('');
    setImmediateOutput('');
  };

  function joinWithAmpersand(strings: string[]): string {
    return strings.join('&');
  }

  function findNonAnyProperties(obj: any): string[] {
    const nonAnyProperties: string[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== 'any' && obj[key] !== 0) {
        // mrn=1&
        nonAnyProperties.push(`${key}=${obj[key]}`);
      }
    }
    return nonAnyProperties;
  }

  React.useEffect(() => {
    const test2 = findNonAnyProperties(searchState);
    let query = ''
    if (Array.isArray(test2) && test2.length > 0) {
      query = joinWithAmpersand(test2);
    }
    let final = '';
    if (debouncedOutput !== '') {
      final = `${searchField}=${debouncedOutput}&${query}`;
    } else {
      final = query;
    }
    setSearchQueryString(final);
  }, [searchState, debouncedOutput, searchField]);



  const onChangeDebouncedT = async (text: string) => {
    setDebouncedOutput(text);
    setDebouncedCalls(debouncedCalls + 1);
    // onSubmitSearch(text);
  };

  const onChangeImmediate = async (text: string) => {
    setImmediateOutput(text);
    setImmediateCalls(immediateCalls + 1);
  };

  const onChangeDebounced = useDebounce(onChangeDebouncedT);

  const handleSearchChange = (val: string) => {
    onChangeDebounced(val);
    onChangeImmediate(val);
  };

  const onSearch = () => {
    console.info('onKeydown?');
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleFieldChange = (val: any) => {
    // onFieldSearch(val);
    setSearchField(val);
  };

  const handleFilterDropdown = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchState({ ...searchState, [event.target.name]: event.target.value });
  };

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchState({ ...searchState, [event.target.name]: event.target.value });
  };


  return (
    <div className="patients__header">
      <div className='d-flex-row'>
        <Label size='h2' weight={300}>All Patients</Label>
      </div>
      <Spacer value={20} />
      <div className="patients__header-search">
        <EMRSearchBox
          value={immediateOutput}
          onChange={handleSearchChange}
          onSubmit={onSearch}
          placeholder='Enter Text to search'
          onChangeField={handleFieldChange}
          ops={ops}
        />
        <Spacer value={20} />
      </div>
      <Spacer value={20} />
    </div>
  );
};

SimpleEMRSearch.defaultProps = {
};

SimpleEMRSearch.displayName = 'SimpleEMRSearch';
export default SimpleEMRSearch;
