/* eslint-disable react/require-default-props */
import React from 'react';
// import classnames from 'classnames';

import { FaSearch } from 'react-icons/fa';
import './filter-box.scss';
import { FiFilter } from 'react-icons/fi';
import { Spacer } from '../../atoms';

type Props = {
  state: string;
  onChange?: (value: string ) => void;
  className?: string;
};

const FilterBox = ({ state, onChange, className }: Props) => {
  // const [state, setState] = React.useState({
  //   filter: 'Clear',
  // });

  const handleFilter = (e : string | undefined) => {
    if (onChange && e) {
      // setState({ filter:e });
      onChange(e);
    }
  };


  const localclassname = `filter-box ${className || ''}`;
  return (
    <div className={localclassname}>
      <div className='filter__wrapper'>
        <FiFilter size={12} />
        <Spacer value={10} />
        <button type='button' onClick={() => handleFilter('PN')} className={state === 'PN' ? 'filter__active' : 'filter__btn'}>Person Names</button>
        <span className='filter__spacer'>{' | '}</span>
        <button type='button' onClick={() => handleFilter('UI')} className={state === 'UI' ? 'filter__active' : 'filter__btn'}>IDs </button>
        <span className='filter__spacer'>{' | '}</span>
        <button type='button' onClick={() => handleFilter('AS')} className={state === 'AS' ? 'filter__active' : 'filter__btn'}>Ages </button>
        <span className='filter__spacer'>{' | '}</span>
        <button type='button' onClick={() => handleFilter('DA')} className={state === 'DA' ? 'filter__active' : 'filter__btn'}>Dates</button>
        <span className='filter__spacer'>{' | '}</span>
        <button type='button' onClick={() => handleFilter('TM')} className={state === 'TM' ? 'filter__active' : 'filter__btn'}>Times</button>
        <span className='filter__spacer'>{' | '}</span>
        <button type='button' onClick={() => handleFilter('Clear')} className={state === 'All' ? 'filter__active' : 'filter__btn'}> Clear </button>
      </div>
    </div>
  );
};
FilterBox.defaultProps = {
  onChange: () => null,
};

export default FilterBox;
