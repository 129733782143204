import React, { useState, useEffect } from 'react';
import { Tile, Label, Button, Spacer } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import './question-types.scss';

export interface TextProps {
  answer: any,
  onAnswer: (vals: any) => void,
}

const Text = (props: TextProps): JSX.Element => {
  const { answer, onAnswer } = props;
  const [value, setValue] = useState('');
  const [state, setState] = React.useState({
    wordCount: 0,
    charCount: 0,
  });
  const [invalid, setInvalid] = useState(false);

  const countWords = (count: string) => {
    if (count.length === 0) {
      return 0;
    }
    let countN = '';
    countN = count.replace(/(^\s*)|(\s*$)/gi, '');
    countN = count.replace(/[ ]{2,}/gi, ' ');
    countN = count.replace(/\n /, '\n');
    return countN.split(' ').length;
  };

  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {
      setValue(answer[0].value);

      if (state.wordCount === 0 && answer[0].value) {
        const count = answer[0].value;
        setState({
          wordCount: countWords(count),
          charCount: count.length,
        });

        if (countWords(count) > 300) {
          setInvalid(true);
        }
      }
    }
  }, [answer, state.wordCount]);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const txt = event.target.value;

    if (countWords(txt) < 300) {
      setState({
        wordCount: countWords(txt),
        charCount: txt.length,
      });

      setValue(event.target.value);
      setInvalid(false);
      onAnswer([{ 'value': event.target.value, 'label': event.target.value }]);
    } else {
      setInvalid(true);
    }
  };



  return (
    <div className='question-types__text'>

      <Spacer value={20} />

      <TextAreaInput
        value={value}
        onChange={onChange}
        rows={1}
      />

      <div className='d-flex-row space-btw '>
        <Label color={invalid ? 'danger' : 'default'} size='subtext'>Word Count: {state.wordCount} / 300</Label>
      </div>

      <Spacer value={10} />

    </div>
  );
};

Text.defaultProps = {
};

Text.displayName = 'Text';
export default Text;
