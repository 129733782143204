import api from '../api';


export const imageAnalysisApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAnalysisDeets: builder.query({
      query: (payload) => ({ url: `/analysis/statsbyid/${payload}`, method: 'GET' }),
      providesTags: ['IMG_ANALYSIS'],
      transformResponse: (response: any) => response.items,
    }),
    editAnalysis: builder.mutation({
      query: (payload) => ({ url: `/analysis/userappinput/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['IMG_ANALYSIS'],
    }),
    createImgJob: builder.mutation({
      query: (payload) => ({ url: '/analysis/job/', method: 'POST', body: payload }),
      invalidatesTags: ['IMG_ANALYSIS'],
    }),
    getAnalysisFiles: builder.query({
      query: (id) => ({ url: `/analysis/files/${id}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
    }),
    getVolAnalysis: builder.query({
      query: (id) => ({ url: `/analysis/volumetricanalysis/${id}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
    }),
  }),
});

export const {  
  usePrefetch,
  useGetAnalysisDeetsQuery, 
  useEditAnalysisMutation, 
  useCreateImgJobMutation, 
  useGetAnalysisFilesQuery, 
  useGetVolAnalysisQuery,
} = imageAnalysisApi;
