import React from 'react';
import './modal.scss';
import Dialog from '@mui/material/Dialog';
import { IoClose } from 'react-icons/io5';
import Label from '../Label/Label';
import Button from '../Button/Button';
import Spacer from '../Spacer/Spacer';

type Props = {
  size?: 'lg' | 'sm' | 'md',
  open: boolean,
  onClose: () => void,
  title?: string,
  children: JSX.Element,
  fullScreen?: boolean,
};

const Modal = (props: Props) => {
  const { size, open, title, onClose, children, fullScreen } = props;
  return (
    <Dialog className={`modal-${size}`} open={open} onClose={onClose} fullScreen={fullScreen}>
      <div className={title ? 'modal__header' : 'modal__header2'}>
        {title && <Label  size="h4" weight={600} uppercase color="primary">{title}</Label>}
        <div className='modal__close-btn'>
          <Button kind='ghost' onClick={onClose} ><IoClose /></Button>
        </div>
      </div>
      {title && <Spacer value={15} />}
      {/* <div className='modal__body'>
        {children}
      </div> */}
              {children}

    </Dialog>
  );
};

Modal.defaultProps = {
  size: 'md',
  title: '',
  fullScreen: false,
};

export default Modal;
