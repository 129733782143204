import React from 'react';
import Lottie from 'lottie-react';
import classNames from 'classnames';
import groovyAnimation from './spinner.json';
import './custom-progress.scss';
import Spinner from '../Spinner/Spinner';

export interface CustomProgressProps  {
  className?: string;
}

const CustomProgress = (props: CustomProgressProps) => {
  const { className } = props;
  
  const classes = {
    'custom-progress': true,
    [`${className}`]: true,
  };

  switch (process.env.REACT_APP_FEATURE) {
    case 'INS':
      return (
        <div key='custom_spin' className={classNames(classes)}>
          <div className='anim'>
            <Lottie animationData={groovyAnimation} loop className='anim3'/>
          </div>
        </div>
      ); 
    case 'MASTER2':
      return (
        <div key='custom_spin' className={classNames(classes)}>
          <div className='anim'>
            <Lottie animationData={groovyAnimation} loop className='anim3'/>
          </div>
        </div>
      ); 
    default:
      return (
        <div key='custom_spin' className={classNames(classes)}>
          <div className='defaultanim'>
            <Spinner />
          </div>
        </div>
      ); 
  }
  
};

CustomProgress.defaultProps = {
  className: '',
};

CustomProgress.displayName = 'CustomProgress';


export default CustomProgress;
