import React from 'react';
// import classnames from 'classnames';
import { AiOutlineSend } from 'react-icons/ai';
import './new-message.scss';
import TextInput from '../TextInput/TextInput';
import { Button } from '../../atoms';

export interface NewMessageProps {
  id: string,
  className?: string,
  handleNewMsg: (text: string) => void,
}

const NewMessage = (props: NewMessageProps): JSX.Element => {
  const { id, handleNewMsg } = props;
  const [newChat, setNewChat] = React.useState('');

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewChat(event.target.value);
  };

  const sendMessage = () => {
    handleNewMsg(newChat);
    setNewChat('');
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      sendMessage();
      // callMyFunction();
    }
  };

  return (
    <div key={`NewMessage_${id}`} className="new-message" >
      <TextInput
        name='NewMessage'
        label=''
        value={newChat}
        onChange={handleChangeInput}
        className='new-message-input'
        onKeyDown={handleKeyPress}
      />
      <Button kind='ghost' onClick={sendMessage}><AiOutlineSend size={20} /></Button>
    </div>
  );
};

NewMessage.defaultProps = {
  className: '',
};

NewMessage.displayName = 'NewMessage';
export default NewMessage;
