import React from 'react';
import { AiOutlineCopyrightCircle } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import './page-footer.scss';
import version from '../../../version.json';

const PageFooter = ( props: any ) => {
  const { className : parentClassName } = props; 
  const footerClassName = `page-footer ${parentClassName || ''}`;

  return (
  <footer className={footerClassName}>
    <div className="page-footer__container">
      <div className="page-footer__text">
      V {version.version}
      </div>
      <div className="page-footer__text">
        {/* POWERED BY UPSTREAM VISION */}
        Copyright <AiOutlineCopyrightCircle className="page-footer__icon" /> 2023 Upstream Vision - All rights reserved
      </div>
      <div className="page-footer__nav">
          <NavLink to='/legal/privacy'>Privacy</NavLink>
          <NavLink to='/legal/Terms'>Terms</NavLink>
          <NavLink to='/compliance'>Compliance</NavLink>
          <NavLink to='/support/faq'>Support</NavLink>
      </div>
    </div>
  </footer>);
};

export default PageFooter;
