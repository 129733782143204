import csTools from 'cornerstone-tools';

const BaseTool = csTools.importInternal('base/BaseTool');

export default class HelloWorldTool extends BaseTool {
  constructor(name = 'IdentifyMET') {
    super({
      name,
      supportedInteractionTypes: ['Mouse'],
      mixins: ['activeOrDisabledBinaryTool'],
    });
  }

}