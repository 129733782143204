/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classnames from 'classnames';
import './file-tree-item.scss';
import { BsFileEarmarkRichtextFill, BsFolderFill } from 'react-icons/bs';
import { Text } from '../../atoms';
import { useDownLoadFileMutation } from '../../../pages/private/StudyDrive/api';


export interface FileTreeItemProps {
  id?: string,
  data: any,
  onNavigate?: () => unknown,
  className?: string,
}

const FileTreeItem = (props: FileTreeItemProps) => {
  const { id, data, onNavigate, className } = props;
  const [download] = useDownLoadFileMutation();

  const getLabel = (longName: string) => {
    const split = longName.split('/');
    const shortened = split[split.length - 1];
    return shortened;
  };


  const classes = {
    'file-tree-item': true,
    // 'file-tree-item-wrapper': true,
    'file-tree-item-folder': data.children && data.children.length > 0,
    'file-tree-item-file': !data.children || data.children.length === 0,
    [`${className}`]: true,
  };

  const handleFileClick = async (clickedFile: any) => {
    if (data.name !== '..') {
      const newPath = `s3://${clickedFile.bucket}/${clickedFile.coldKey}`;
      const payload = { files: [{ s3_path: newPath }] };
      const yourbrandnewfile = await download(payload).unwrap();
      const url = window.URL.createObjectURL(new Blob([yourbrandnewfile[0].signedUrl]));
      const link = document.createElement('a');
      link.href = url;
      if (data.filename) {
        link.setAttribute('download', `${data.filename}`);
      } else {
        link.setAttribute('download', `${getLabel(clickedFile.originalPath)}`);
      }
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div key={`fti_${id}`} className={classnames(classes)} onClick={onNavigate} >
      {data.children && data.children.length > 0 ?
        <div className="file-tree-item--header">
          <BsFolderFill />
          <Text className="file-tree-item--childrencount">
            {data.name}
          </Text>
        </div>
        :
        <div className="file-tree-item--header" tabIndex={-1} onClick={() => handleFileClick(data)}>
          <BsFileEarmarkRichtextFill />
          <Text>
            {data.name}
          </Text>
        </div>
      }

      {data.children && data.children.length > 0 &&
        <Text className="file-tree-item--childrencount">
          {data.children.length} items
        </Text>
      }
    </div>
  );
};

FileTreeItem.defaultProps = {
  id: '_key',
  onNavigate: () => console.info('click'),
};

FileTreeItem.displayName = 'FileTreeItem';


export default FileTreeItem;
