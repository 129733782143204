import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';
import { Label, Button, Alert, Spinner, Modal, Spacer, PatientsTable } from '../../../components';
import { SimpleEMRSearch, AdvancedEMRSearch } from '../../../components/molecules';
import { useAdvancedSearchQuery } from '../../../api/builders/patient.api';
import { useAddPatientsToRegistryMutation, useGetPatRegistriesStatsQuery } from '../../../api/builders/registries.api';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';


type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const RegistryPatients = () => {
  const { linkedRegistry } = useParams();
  const [payload, setPayload] = React.useState({ pageSize: 10, type: 'mrn', orderBy: 'recent', pageCurrent: 1 });
  const { data: allPats, isLoading: isLoadingData, isFetching: isFetchingData } = useAdvancedSearchQuery(payload);

  // have to fetch registy data for pL
  const { data } = useGetPatRegistriesStatsQuery(linkedRegistry);

  const [linkPats, { isLoading: isAdding, isError: isErrorAdding, error: Adderror, isSuccess: isSuccessAdding }] = useAddPatientsToRegistryMutation();
  const [checked, setChecked] = React.useState<any[]>([]);
  const [searching, setSearching] = React.useState(false);
  const [patients, setPatients] = React.useState<any | null>(null);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = Adderror as ApiError;
      setAlert({
        open: true,
        message: `Error Adding Patients to Registry: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding Patients to Registry',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  React.useEffect(() => {
    if (allPats && Array.isArray(allPats.items)) {
      if (searching) {
        setPatients(allPats.items);
      } else if (allPats.pageCurrent > 1) {
        const pats = Array.isArray(patients) ? patients : [];
        const curr = pats.concat(allPats.items);
        setPatients(curr);
      } else {
        setPatients(allPats.items);
      }
    }
  }, [allPats]);


  const onFieldSearch = (newQuery: string) => {
    setPayload(prev => ({ ...prev, type: newQuery }));
    setSearching(true);
    if (newQuery === '') {
      setPatients(null);
      setSearching(false);
    }
  };


  const onSubmitSearch = (newQuery: string) => {
    setPayload(prev => ({ ...prev, query: newQuery }));
    setSearching(true);
    if (newQuery === '') {
      setPatients(null);
      setSearching(false);
    }
  };

  const loadMore = () => {
    setPayload({
      ...payload,
      pageSize: 10,
      pageCurrent: allPats?.pageCurrent ? (allPats.pageCurrent + 1) : 0,
      orderBy: 'recent',
    });
  };

  const linkCohorts = async () => {
    if (linkedRegistry && Array.isArray(data)) {
      try {
        const pl = {
          patientIds: checked,
          patientRegistryId: linkedRegistry,
          carepathwayId: (Array.isArray(data[0].carepathways) && data[0].carepathways.length > 0) ? data[0].carepathways[0].id : '',
          status: (Array.isArray(data[0].carepathways) && data[0].carepathways.length > 0) ? 'Registered' : '',
        };
        await linkPats(pl);
        setChecked([]);
      } catch (e) {
        console.error('Error', e);
      }
    }
  };



  return (
    <div className="registry-patients">
      <SimpleEMRSearch onSubmitSearch={onSubmitSearch} onFieldSearch={onFieldSearch} />

      {(isLoadingData || isFetchingData) ?
        <div className="registry-patients__body super-centered">
          <Spacer value={30} />
          <Spacer value={30} />
          <Spacer value={30} />
          <Spinner />
        </div>
        :
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {Array.isArray(patients) && patients.length > 0 &&
            <div className="registry-patients__body">
              <Spacer value={10} />
              <div className='d-flex'>
                <Button loading={isAdding} onClick={linkCohorts} disabled={checked.length <= 0}>Add to Registry</Button>
              </div>
              <PatientsTable
                patients={patients}
                loading={false}
                id={linkedRegistry}
                isAdding={isAdding}
                onAdd={linkCohorts}
                checked={checked}
                setChecked={setChecked}
                // deIdentified
              />
              <Spacer value={20} />
              <Button loading={isLoadingData || isFetchingData} disabled={allPats.pageCurrent >= allPats.pageTotal} onClick={loadMore}>Load More</Button>
            </div>
          }
        </>
      }

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />

    </div>
  );
};

export default withAuthenticator(RegistryPatients);
