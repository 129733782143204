import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from 'react-responsive';
import { useGetparticipantInfoQuery, useEditProtocolMutation, useAddChatRoomMutation } from './api';
import { Label, Tile, Spacer, ProtocolItemFactory, ProtocolVisitItem, ProtocolVisitCriteriaItem, BreadcrumbWithMenu, Alert } from '../../../components';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { IProtocolItemType } from '../../../components/molecules/ProtocolItem/ProtocolItem';


type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  phantom: boolean | undefined;
  id: string | undefined;
  label: string | undefined;
  consentStatus: string;
};

type ProtocolTimePoint = {
  date: string | undefined,
  id: string | undefined,
  label: string | undefined,
  order: number | undefined,
  studyDay: string | undefined,
  updatedAt: number | undefined,
  visit: string | undefined,
  visitType: string | undefined,
  userProtocolItems: IProtocolItemType[],
};

// type ProtocolItemType = {
//   file: string | undefined,
//   id: string | undefined,
//   item: string | undefined,
//   label: string | undefined,
//   messages: string | undefined,
//   order: number,
//   createdAt: number | undefined,
//   updatedAt: number | undefined,
//   role: string | undefined,
//   status: string,
//   surveyId: string | undefined,
//   type: string | undefined,
//   userId: string | undefined,
//   deviation: boolean | null;
// };


interface LocationState {
  participant: ParticipantItem,
  sitePath: string,
  activity?: Activity,
  acl: string,
  siteId: string,
}

type StateType = {
  visitIndex: number,
  itemIndex: number,
  minifiedPath: string,
  fullPath: string,
  item: IProtocolItemType | undefined,
  criteria: IProtocolItemType[] | [],
};


type Activity = {
  timePointId: string,
  protocolItemId: string,
};

const initialState = {
  visitIndex: 0,
  itemIndex: 0,
  minifiedPath: '',
  fullPath: '',
  item: undefined,
  criteria: [],
};

const sort = (items: IProtocolItemType[]) => {
  try {
    const test = JSON.parse(JSON.stringify(items));
    test.sort((a: IProtocolItemType, b: IProtocolItemType) => a.order - b.order);
    return test;
  } catch {
    console.error('err sorting');
    return items;
  }
};

const validSeverities = ['error', 'warning', 'info', 'success'];


const ReviewerParticipantTask = () => {
  const location = useLocation();
  const { participant, sitePath, activity, acl } = location.state as LocationState;
  const { data } = useGetparticipantInfoQuery({ participantId: participant?.id ? participant.id : skipToken });
  const [state, setState] = useState<StateType>(initialState);
  const [reviewMode, setReviewMode] = React.useState(false);
  const [participantProtocol, setParticipantProtocol] = useState<ProtocolTimePoint[] | []>([]);
  const [readOnly, setReadOnly] = React.useState(true);
  const [chatError, setChatError] = React.useState(false);

  const collapse = () => {
    // setSmall(true);
  };
  const expand = () => {
    // setSmall(false);
  };



  const createChatRoom = () => {
    setChatError(true);
  };

  const toggleReviewMode = () => {
    setReviewMode(!reviewMode);
  };


  React.useEffect(() => {
    if (acl && acl === 'admin') {
      setReadOnly(false);
    } else if (acl && acl === 'SITE COORDINATOR') {
      setReadOnly(false);
    }
  });


  useEffect(() => {
    if (Array.isArray(data) && data[0].userProtocol && data[0].userProtocol.timePoints) {
      setParticipantProtocol(data[0].userProtocol.timePoints);

      if (activity) {
        const findVistIndex = data[0].userProtocol.timePoints.findIndex((it: any) => it.id === activity.timePointId);
        if (findVistIndex !== -1) {
          const findVist = data[0].userProtocol.timePoints[findVistIndex];
          const findItemIndex = sort(findVist.userProtocolItems).findIndex((it: any) => it.id === activity.protocolItemId);
          const findItem = sort(findVist.userProtocolItems)[findItemIndex];

          if (findVist && findItem) {
            const tmpPath = `${sitePath}participants/${data[0].id}/protocol/${data[0].userProtocol.timePoints[findVistIndex].visit}/${data[0].userProtocol.timePoints[findVistIndex].userProtocolItems[findItemIndex].item}`;
            setState({
              ...state,
              visitIndex: findVistIndex,
              itemIndex: findItemIndex,
              fullPath: tmpPath,
              item: findItem,
              criteria: sort(findVist.userProtocolItems),
            });
          }
        }
      } else {
        const tmpPath = `${sitePath}participants/${data[0].id}/protocol/${data[0].userProtocol.timePoints[state.visitIndex].visit}/${data[0].userProtocol.timePoints[state.visitIndex].userProtocolItems[state.itemIndex].item}`;
        setState({
          ...state,
          fullPath: tmpPath,
          item: sort(data[0].userProtocol.timePoints[state.visitIndex].userProtocolItems)[state.itemIndex],
          criteria: sort(data[0].userProtocol.timePoints[state.visitIndex].userProtocolItems),
        });
      }
    }
  }, [data]);

  return (
    <div className="reviewer-participant-task">
      {state.item && <ProtocolItemFactory secondaryId='' createChatRoom={createChatRoom} reviewMode={reviewMode} toggleReviewMode={toggleReviewMode} acl={acl} item={state.item} path={state.fullPath} participant={participant.id} collapse={collapse} expand={expand} readOnly={false} visitId={participantProtocol[state.visitIndex].id ? participantProtocol[state.visitIndex].id : 'n/a'} baselineMRI={data[0].baeslineMri ?? 'undefined'} />}
      {chatError && <Alert severity='error' open={chatError} onClose={() => setChatError(false)}  message='Unauthorized to perform this action' />}

    </div>
  );
};

export default withAuthenticator(ReviewerParticipantTask);
