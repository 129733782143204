export const STOP_PROP_TAG = 'allowRowEvents';

export enum Direction {
  LTR = 'ltr',
  RTL = 'rtl',
  AUTO = 'auto',
}

export enum Alignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum Media {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}