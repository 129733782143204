import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Tile, Label, Button, Spacer } from '../../atoms';
import './question-types.scss';

const defaultScale = [
  {
    value: '0',
    color: '#25852e',
  },
  {
    value: '1',
    color: '#38a740',
  },
  {
    value: '2',
    color: '#8abb37',
  },
  {
    value: '3',
    color: '#9bbf32',
  },
  {
    value: '4',
    color: '#f5d705',
  },
  {
    value: '5',
    color: '#f5d61d',
  },
  {
    value: '6',
    color: '#f6d800',
  },
  {
    value: '7',
    color: '#e9761c',
  },
  {
    value: '8',
    color: '#d83821',
  },
  {
    value: '9',
    color: '#b5181b',
  },
  {
    value: '10',
  },
];

export interface ListProps {
  answer: any,
  onAnswer: (vals: any) => void,
  mode?: string,
  settings?: any,
}

const Scale = (props: ListProps): JSX.Element => {
  const { answer, onAnswer, mode, settings } = props;
  const [selected, setSelected] = useState('');

  const mainClasses = {
    'question__scale': true,
  };
  const classes = {
    'question__scale-container': true,
    [`question__scale-container--${mode}`]: true,
  };
  const itemClasses = {
    'question__scale-item': true,
    [`question__scale-item--${mode}`]: true,
  };
  const labelClasses = {
    'question__scale-label-container': true,
    [`question__scale-label-container--${mode}`]: true,
  };

  useEffect(() => {
    const someVarSoLinterSHUTSUP = 0;
    if (answer?.length > 0 && answer[0].value) {
      setSelected(answer[0].value);
    }
  }, [answer]);

  const onSelect = (val: any) => {
    setSelected(val);
    onAnswer([{ 'value': val, 'label': val }]);
  };


  return (
    <div className={classnames(mainClasses)} >
      <div className={classnames(classes)}>
        {defaultScale.map((item, index) => (
          <div key={`scale-item-${item.value}`} className={classnames(itemClasses)}>
            <div role='button' tabIndex={-1} onClick={() => onSelect(item.value)} className={`question__scale-value ${selected === item.value ? 'question__scale-value-selected' : ''}`}>{item.value}</div>
            <div className="question__scale-dash" style={{ backgroundColor: item.color }} />
          </div>
        ))}
      </div>

      <div className={classnames(labelClasses)} >
        {/* <Label size="text">{settings.min}</Label>
        <Label size="text">{settings.max}</Label> */}
      </div>
{/* 
      {selected ? (
        <div className="question__scale-selected-container">
          <Label size="h2" uppercase >{selected}</Label>
          <Label size="h4">Selected</Label>
        </div>
      ) : (
        <Label size="h4">Select a number to begin</Label>
      )} */}
    </div>
  );
};

Scale.defaultProps = {
  mode: 'horizontal',
  settings: { goto: 'next', min: 'false', max: 'true' },
};

Scale.displayName = 'Scale';
export default Scale;
