import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BsPeople } from 'react-icons/bs';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FaClipboardList } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { IoStatsChart } from 'react-icons/io5';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { useUpdateRegistryMutation, useGetCarePathwaysQuery, useGetCarepathwayStatsQuery } from '../../../api/builders/registries.api';
import { Label, Alert, Spacer, Button, Dropdown, TextInput } from '../../../components';
import { TextAreaInput } from '../../../components/molecules';



interface LocationState {
  registry: any;
}

export interface PageProps {
  registry: any;
}


const initialVals = {
  name: '',
  description: '',
  carepathwayId: '',
};

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const RegistrySettings = () => {
  const location = useLocation();
  const { registry } = location.state as LocationState;
  const navigate = useNavigate();
  // const { registry } = props;

  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const { data: pathways } = useGetCarePathwaysQuery('');
  const [values, setValues] = React.useState(initialVals);
  const [activeTab, setActiveTab] = React.useState('details');
  const [selectedPath, setSelectedPath] = React.useState('');
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });
  const [edit, { isLoading: isEdititng, isSuccess, isError, error }] = useUpdateRegistryMutation();

  const { data } = useGetCarepathwayStatsQuery(registry?.carepathways?.length > 0 ? registry.carepathways[0].id : skipToken);



  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Registry: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Editing Registry',
        kind: 'success',
      });
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  React.useEffect(() => {
    const newBreadcrumb = [
      {
        label: registry?.name ?? 'Registry',
        path: '/private/registries/registry/overview',
        state: { registry },
      },
      {
        label: 'Settings',
        path: location.pathname,
        state: { registry },
      },
    ];
    setBreadcrumbs(newBreadcrumb);
  }, [location.pathname]);


  React.useEffect(() => {
    if (registry) {
      setValues({
        name: registry?.name ?? '',
        description: registry?.description ?? '',
        carepathwayId: registry?.carepathwayId ?? '',
      });
    }
  }, [registry]);

  const handleChangeSummary = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleChangeVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleChageTab = (newT: string) => {
    setActiveTab(newT);
  };

  const submitChanges = () => {
    try {
      const payload = {
        id: registry.id,
        body: {
          ...values,
        },
      };
      edit(payload);
    } catch (e) {
      console.error('err edit', e);
    }
  };

  const handleAddPathway = () => {
    try {
      const payload = {
        id: registry.id,
        body: {
          carepathwayId: selectedPath,
        },
      };
      edit(payload);
    } catch (e) {
      console.error('err edit', e);
    }
  };

  const handleChangeTab = (newTab: string) => {
    navigate(`/private/registries/registry/${newTab}`, { state: { registry } });
  };

  if (!location?.state) {
    return (
      <div className='d-flex-row-center'>
        <Label size="h4" weight={600} color="primary">Error Loading Registry Details</Label>
      </div>
    );
  }
  return (
    <div className="registry">
      <div className="registry__header">
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('overview')}>
          <AiOutlineDashboard className='registry__icon' />
          <Label size='h5' uppercase>Overview</Label>
        </button>
        <button type='button' className='registry__tab-active' onClick={() => handleChangeTab('settings')}>
          <FiSettings className='registry__icon-active' />
          <Label size='h5' uppercase>Settings</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('patients')}>
          <BsPeople className='registry__icon' />
          <Label size='h5' uppercase>All Patients</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('tracker')}>
          <IoStatsChart className='registry__icon' />
          <Label size='h5' uppercase>Patient Tracker</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('conferences')}>
          <FaClipboardList className='registry__icon' />
          <Label size='h5' uppercase>Conferences</Label>
        </button>
      </div>
      <div className="registry-settings">
        <div className='registry-settings__header'>
          <Label size="h3" weight={300} >{registry?.name} Settings</Label>
        </div>
        <div className='registry-settings__body'>
          <Grid container spacing={2}>
            <Grid xs={3}>
              <div role='button' onClick={() => handleChageTab('details')} tabIndex={-1} className={activeTab === 'details' ? 'registry-settings__active-tab' : 'registry-settings__tab'}>
                <Label size="h4" weight={400} uppercase color={activeTab === 'details' ? 'primary' : 'default'}>Details</Label>
              </div>
              <div role='button' onClick={() => handleChageTab('care')} tabIndex={-1} className={activeTab === 'care' ? 'registry-settings__active-tab' : 'registry-settings__tab'}>
                <Label size="h4" weight={400} uppercase color={activeTab === 'care' ? 'primary' : 'default'} >Manage Care Pathway</Label>
              </div>
            </Grid>
            {activeTab === 'details' &&
              <Grid xs={9}>
                <TextInput
                  name='title'
                  label='Title'
                  value={values.name}
                  onChange={handleChangeVals}
                />
                <TextAreaInput
                  name='summary'
                  label='Summary'
                  value={values.description}
                  onChange={handleChangeSummary}
                />

                <Spacer value={20} />

                <div className='btn-row'>
                  <Button loading={isEdititng} onClick={submitChanges}>Save Changes</Button>
                </div>
              </Grid>
            }
            {activeTab === 'care' &&
              <Grid xs={9}>
                <Spacer value={10} />
                {/* REGISTRYTODO::: edit the map to display multiple possible pathways */}
                <div >
                  {registry?.carepathways.length > 0 ?
                    <>
                      {registry.carepathways.map((item: any) => (
                        <div>
                          <Label size="h4" weight={600}  >{item.name}</Label>
                          <Spacer value={5} />
                          <Label size="h6" weight={600}  >{item.description}</Label>
                          <Spacer value={20} />
                          {data && data[0].steps && data[0].steps.map((step: any) => (
                            <>
                              <Label size="h6" weight={400}  >{`${step.order}: ${step.description}`}</Label>
                              <Spacer value={5} />
                            </>
                          ))}
                        </div>
                      ))}
                    </>
                    :
                    <>
                      <Dropdown
                        label="Select Care Pathway"
                        id="path"
                        name="path"
                        onChange={(e) => setSelectedPath(e.target.value)}
                        value={selectedPath}
                      >
                        {Array.isArray(pathways) && pathways.length > 0 ? pathways.map((choice: any) => (
                          <MenuItem value={choice.id}>{choice.name}</MenuItem>
                        )) :
                          <MenuItem value="">No Options</MenuItem>
                        }
                      </Dropdown>
                      <Spacer value={20} />
                      {selectedPath !== '' &&
                        <Button loading={isEdititng} onClick={handleAddPathway}>Save</Button>
                      }
                    </>
                  }
                </div >
              </Grid>
            }
          </Grid>
        </div>
        <Alert
          severity={alert.kind}
          open={alert.open}
          onClose={resetAlert}
          message={alert.message}
        />
      </div>
    </div>
  );
};

export default withAuthenticator(RegistrySettings);
