import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { Link, useLocation } from 'react-router-dom';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { logout } from '../../../store/authentication/slice';
import api from '../../../api/api';

import './private-layout.scss';
import { SnapshotBrowser } from '../../molecules';

type Props = {
  children: JSX.Element,
};

const PrivateLayout = (props: Props) => {
  const { children } = props;
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [status, setStatus] = useState<string>('Active');
  const [count, setCount] = useState<number>(0);
  const [remaining, setRemaining] = useState<number>(0);
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const onIdle = () => {
    setStatus('Idle');
    dispatch(logout());
    dispatch(api.util.resetApiState());
  };

  const onActive = () => {
    setStatus('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 900000,
    throttle: 500,
  });

  /* eslint-disable @typescript-eslint/no-shadow  */
  useEffect(() => {
    const newUrl = window.location.pathname;
    const index = breadcrumbs.findIndex((b) => b.path === newUrl);
    if (index !== -1) {
      setBreadcrumbs((breadcrumbs) => {
        const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
        return newBreadcrumbs;
      });
    }
  }, [location]);


  /* eslint-disable @typescript-eslint/no-shadow  */
  const handleCrumbClick = (index: number) => {
    setBreadcrumbs((breadcrumbs) => {
      const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
      return newBreadcrumbs;
    });
  };


  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const uniqueBreadcrumbs = [...new Map(breadcrumbs.map((item) => [item.path, item])).values()];


  /* eslint-disable  react/no-array-index-key */
  /* eslint-disable  prefer-destructuring */
  /* eslint-disable prefer-destructuring */
  return (
    <div className="private-layout">
      <div className='private-layout__crumb-wrapper'>
        {uniqueBreadcrumbs.map(({ label, path, state }, index) => {
          const isLast = index === uniqueBreadcrumbs.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return (
            <span key={label} role='button' tabIndex={-1} >
              {isLast ? (
                <span className='private-layout__link-active '> {label} </span>
              ) : (
                // <Link to={{ pathname: path, state  }}>{label}</Link>
                <Link onClick={() => handleCrumbClick(index)} className='private-layout__link' to={path} state={{ ...state }} >{label}</Link>
              )}
              {!isLast && <span className='private-layout__separator'>&gt;</span>}
            </span>
          );
        })}
      </div>
      <div className="private-layout__body">
      {children}
      </div>
      <div className="private-layout__footer">
        <SnapshotBrowser className='snapshot_browser' id="snapbar"/>
      </div>
    </div>
  );
};

export default PrivateLayout;