import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import './registry-item.scss';
import { FcPlus, FcApproval, FcOpenedFolder, FcClock, FcSettings, FcEmptyFilter, FcClearFilters, FcDataEncryption, FcDataProtection, FcSerialTasks, FcDeleteRow } from 'react-icons/fc';
import { AiFillClockCircle } from 'react-icons/ai';
import moment from 'moment';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tile, Text, Label, Spacer, Button } from '../../atoms';
import { usePrefetch as usePrefetchStudy } from '../../../api/builders/study.api';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';

export interface RegistryItemProps {
  studyId?: string | undefined;
  summary?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  name?: string | undefined;
  acl?: string | undefined;
  updatedAt: number,
}

type Props = {
  item: RegistryItemProps
};


const RegistryItem = ({ item }: Props) => {
  const { id, description, status, name, updatedAt, acl } = item;
  const navigate = useNavigate();
  const prefetchstudy = usePrefetchStudy('getStudyInfo');
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);

  const addTrialCrumb = () => {
    const newBreadcrumb = {
      label: 'Registries',
      path: '/private/registries',
    };
    setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
  };

  const addStudyCrumb = () => {
    addTrialCrumb();
    const newBreadcrumb = {
      label: name ?? 'Registry',
      path: '/private/registries/registry/overview',
      state: { study: item },
    };
    setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
  };

  const onOpen = (_item: RegistryItemProps) => {
    // if (acl === 'CLR1' || acl === 'CLR2' || acl === 'CLTR') {
    //   navigate('/private/studies/study/activity', { state: { study: _item, acl } });
    // } else {
    //   navigate('/private/registries/registry', { state: { registry: _item } });
    //   addTrialCrumb();
    // }
    navigate('/private/registries/registry/overview', { state: { registry: _item } });
    // navigate('/private/study/view', { state: { study: _item } });
  };

  const onOpenDrive = (_item: RegistryItemProps) => {
    addStudyCrumb();
    navigate('/private/studies/study/drive', { state: { study: _item } });
  };
  const onOpenSettings = (_item: RegistryItemProps) => {
    addStudyCrumb();
    navigate('/private/studies/study/settings', { state: { study: _item } });
  };

  const onOpenRecent = (_item: RegistryItemProps) => {
    addStudyCrumb();
    navigate('/private/studies/study/recent', { state: { study: _item, acl } });
  };



  const onHover = (hoveritem: any) => {
    // prefetchstudy({ studyId: hoveritem?.id ? hoveritem.id : skipToken });
  };

  const renderActions = () => {
    switch (acl) {
      case 'admin':
        return (<div className='study-item__actions'>
          {/* <div><BsFillPeopleFill onClick={() => onConfig(item)} /></div> */}
          {/* <div><FcApproval onClick={() => onReview(item)} /></div> */}
          <div><FcDataProtection onClick={() => onOpenDrive(item)} /></div>
          <div><FcClock onClick={() => onOpenRecent(item)} /></div>
          <div><FcSettings onClick={() => onOpenSettings(item)} /></div>
          {/* <div><AiOutlineAudit onClick={() => onAudit(item)} /></div> */}
        </div>);
      case 'SPONSOR':
        return (<div className='study-item__actions'>
          <div><FcSettings onClick={() => onOpenSettings(item)} /></div>
          {/* <div><FcApproval onClick={() => onReview(item)} /></div> */}
          <div><FcDataProtection onClick={() => onOpenDrive(item)} /></div>
          <div><FcClock onClick={() => onOpenRecent(item)} /></div>
        </div>);
      case '(BLINDED)':
        return (<div className='study-item__actions'>
          {/* <div><FcSettings onClick={() => onConfig(item)} /></div> */}
          {/* <div><FcApproval onClick={() => onReview(item)} /></div> */}
          <div><FcDataProtection onClick={() => onOpenDrive(item)} /></div>
          <div><FcClock onClick={() => onOpenRecent(item)} /></div>
        </div>);
      case 'SPONSOR (BLINDED)':
        return (<div className='study-item__actions'>
          {/* <div><FcSettings onClick={() => onConfig(item)} /></div> */}
          {/* <div><FcApproval onClick={() => onReview(item)} /></div> */}
          <div><FcDataProtection onClick={() => onOpenDrive(item)} /></div>
          <div><FcClock onClick={() => onOpenRecent(item)} /></div>
        </div>);
      case 'SITE COORDINATOR':
        return (<div className='study-item__actions'>
          {/* <div><FcClock onClick={() => onOpenRecent(item)} /></div> */}
        </div>);
      case 'STUDY COORDINATOR':
        return (<div className='study-item__actions'>
          <div><FcClock onClick={() => onOpenRecent(item)} /></div>
        </div>);
      case 'CORELAB':
        return (<div className='study-item__actions'>
          <div><FcClock onClick={() => onOpenRecent(item)} /></div>
        </div>);
      default:
        return (<div className='study-item__actions' />);
    }
  };
  const memoizedActions = useMemo(() => renderActions, [acl]);
  // eslint-disable-next-line react/jsx-curly-brace-presence
  return (
    <Tile key={`study-item-key-${id}`} onHover={() => onHover(item)} className='study-item__root'>
      <div className='study-item__wrapper' >
        <div className='study-item__body' role="button" tabIndex={0} onClick={() => onOpen(item)}>
          <div>
            <Label size="h4" weight={600} >{name}</Label>
            <Spacer value={10} />
            <Text size='sm'>{description}</Text>
            <Spacer value={20} />
          </div>
          <div className='d-flex'>
            <Label size='subtext'>Status: <span className='bold'>{status ?? 'N/A'}</span> </Label>
            <span className='h-space-20' />
            <AiFillClockCircle size={10} className='col-1' />
            <Spacer value={5} />
            <Label size="subtext" center>Last Updated On <span className='bold'>{moment(updatedAt).format('L')}</span></Label>
        </div>
      </div>
      {memoizedActions()}
    </div>
    </Tile >
  );
};

RegistryItem.defaultProps = {
};

RegistryItem.displayName = 'RegistryItem';
export default RegistryItem;
