import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BsPeople } from 'react-icons/bs';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FaClipboardList } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { IoStatsChart } from 'react-icons/io5';
import Pagination from '@mui/material/Pagination';
import { IconButton, Label, Spacer, Alert } from '../../../components/atoms';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { AddPatientModal, SearchBox } from '../../../components/molecules';
import { useGetPatientRegistryRequirementsQuery, useAddPatientsToRegistryMutation, registriesApi, usePatientRegistrySearchQuery } from '../../../api/builders/registries.api';
import useDebounce from '../../../hooks/useDebounce';

export interface PageProps {
  registry: any;
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

interface LocationState {
  registry: any;
}


const ClinicPatients = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { registry } = location.state as LocationState;
  // const { registry } = props;

  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [payload, setPayload] = React.useState<any>(null);
  const { data } = useGetPatientRegistryRequirementsQuery(payload !== null ? payload : skipToken);
  const { refetch } =
    registriesApi.endpoints.getPatientRegistryRequirements.useQuerySubscription(registry?.id ? registry.id : skipToken);
  const [patients, setPatients] = React.useState<any>([]);
  const [addPatModal, setAddPatModal] = React.useState(false);
  const [linkPats, { isLoading: isAdding, isError: isErrorAdding, error: Adderror, isSuccess: isSuccessAdding }] = useAddPatientsToRegistryMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [searchPl, setSearchPl] = React.useState<any>(null);
  const [debouncedOutput, setDebouncedOutput] = React.useState('');
  const { data: searchResults, isLoading: isLoadingData, isFetching: isFetchingData } = usePatientRegistrySearchQuery(searchPl ?? skipToken);

  console.log('searchResults', searchResults);
  console.log('searchPl', searchPl);


  React.useEffect(() => {
    // const newBreadcrumb = {
    //   label: 'All Patients',
    //   path: location.pathname,
    //   state: { registry },
    // };
    // const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    // if (alreadyExists === false) {
    //   setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    // }

    const newBreadcrumb = [
      {
        label: registry?.name ?? 'Registry',
        path: '/private/registries/registry/overview',
        state: { registry },
      },
      {
        label: 'All Patients',
        path: location.pathname,
        state: { registry },
      },
    ];
    setBreadcrumbs(newBreadcrumb);
  }, [location.pathname]);

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = Adderror as ApiError;
      setAlert({
        open: true,
        message: `Error Adding Patients to Registry: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding Patients to Registry',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  // search funcs
  React.useEffect(() => {
    let pl = null;

    if (searchTerm !== '') {
      pl = {
        query: `mrn=${searchTerm}`,
        pageSize: 10,
        orderBy: 'recent',
        pageCurrent: 1,
        id: registry?.id,
      };
    }

    setSearchPl(pl);
  }, [searchTerm, debouncedOutput]);

  const onChangeDebouncedT = async (text: string) => {
    setDebouncedOutput(text);
    // onSubmitSearch(text);
  };

  const onChangeDebounced = useDebounce(onChangeDebouncedT);

  const handleChangeSearch = (value: string) => {
    setSearchTerm(value);
    onChangeDebounced(value);
  };


  // const filteredPats = patients?.filter((pat: any) => (pat.mrn?.toLowerCase().includes(searchTerm.toLowerCase()) || (pat.firstname?.toLowerCase().includes(searchTerm.toLowerCase())) || (pat.lastname?.toLowerCase().includes(searchTerm.toLowerCase()))),
  // );

  React.useEffect(() => {
    if (registry) {
      const registryID = registry.id;
      setPayload({
        registryID,
        pageSize: 10,
        orderBy: 'recent',
      });
    }
  }, [registry]);

  const handleAddPat = (pat: any) => {
    try {
      const pl = {
        patientIds: [pat.id],
        patientRegistryId: registry.id,
        carepathwayId: (Array.isArray(registry.carepathways) && registry.carepathways.length > 0) ? registry.carepathways[0].id : '',
        status: 'Registered',
      };
      console.log('pl', pl);
      linkPats(pl);
    } catch (e) {
      console.error('Error', e);
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === data.pageCurrent) {
      return;
    }
    setPayload({
      ...payload,
      pageSize: 10,
      pageCurrent: newPage,
      orderBy: 'recent',
    });
  };
  const handleChangeSearchPage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === searchResults?.pageCurrent) {
      return;
    }
    setSearchPl({
      ...payload,
      pageSize: 10,
      pageCurrent: newPage,
      orderBy: 'recent',
    });
  };

  React.useEffect(() => {
    if (data && Array.isArray(data.items) && data.items.length > 0) {
      setPatients(data.items);
    }
  }, [data]);

  const onOpen = (patient: any) => {
    navigate('/private/registries/registry/patient', { state: { patient } });
  };

  function calculateAge(patientBirthDate: any) {
    // Parse the date string into year, month, and day components
    const year = parseInt(patientBirthDate.slice(0, 4), 10);
    const month = parseInt(patientBirthDate.slice(4, 6), 10);
    const day = parseInt(patientBirthDate.slice(6, 8), 10);

    // Create a Date object for the patient's birthdate
    const birthDate = new Date(year, month - 1, day); // Note: Month is zero-based in Date constructor

    // Get the current date
    const currentDate = new Date();

    // Calculate the age
    let age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust the age if the birth date has not occurred yet this year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())
    ) {
      // eslint-disable-next-line no-plusplus
      age--;
    }

    return age;
  }

  const handleChangeTab = (newTab: string) => {
    navigate(`/private/registries/registry/${newTab}`, { state: { registry } });
  };


  return (
    <div className="registry">
      <div className="registry__header">
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('overview')}>
          <AiOutlineDashboard className='registry__icon' />
          <Label size='h5' uppercase>Overview</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('settings')}>
          <FiSettings className='registry__icon' />
          <Label size='h5' uppercase>Settings</Label>
        </button>
        <button type='button' className='registry__tab-active' onClick={() => handleChangeTab('patients')}>
          <BsPeople className='registry__icon-active' />
          <Label size='h5' uppercase>All Patients</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('tracker')}>
          <IoStatsChart className='registry__icon' />
          <Label size='h5' uppercase>Patient Tracker</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('conferences')}>
          <FaClipboardList className='registry__icon' />
          <Label size='h5' uppercase>Conferences</Label>
        </button>
      </div>
      <div className="clinic-patients">
        <div className="clinic-patients__header">
          <span className='d-flex'>
            <Label size="h3" weight={300}>
              Registry Patients
            </Label>
            <IconButton icon='refresh' onClick={refetch} />
          </span>
          <span className='d-flex'>
            <SearchBox value={searchTerm} onChange={handleChangeSearch} />
            <IconButton icon='add' onClick={() => setAddPatModal(true)} />
          </span>
        </div>
        <Spacer value={20} />

        {(searchPl && searchResults) ?
          <>
            <div className='clinic-patients__table-container'>

              <table className="clinic-patients__table">
                <thead>
                  <tr>
                    <th>
                      <Label size='h6' uppercase>MRN</Label>
                    </th>
                    <th>
                      <Label size='h6' uppercase>First Name</Label>
                    </th>
                    <th>
                      <Label size='h6' uppercase>Last Name</Label>
                    </th>
                    <th>
                      <Label size='h6' uppercase>Age</Label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(Array.isArray(searchResults.items) && searchResults.items.length > 0) ?
                    searchResults.items.map((item: any) => (
                      <tr
                        key={item.id}
                        role='button'
                        tabIndex={-1}
                        className='clinic-patients__table__row'
                        onClick={() => onOpen(item)}
                      >
                        <td>
                          <Label size="h5" weight={200}>{item.mrn}</Label>
                        </td>
                        <td>
                          <Label size="h5" weight={200} >{item.firstname}</Label>

                        </td>
                        <td>
                          <Label size="h5" weight={200} >{item.lastname}</Label>
                        </td>
                        <td>
                          <Label size="h5" weight={200} >{calculateAge(item.PatientBirthDate)}</Label>
                        </td>
                      </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={3} >
                        <span className='clinic-patients__empty'>
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Label size='h6'> Nothing to show</Label>
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />

                        </span>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <Spacer value={20} />
            {data &&
              <div className='w-100 btn-row'>
                <Pagination count={searchResults.pageTotal} page={searchResults.pageCurrent} onChange={handleChangeSearchPage} />
              </div>
            }
          </>
          :
          <>
            <div className='clinic-patients__table-container'>

              <table className="clinic-patients__table">
                <thead>
                  <tr>
                    <th>
                      <Label size='h6' uppercase>MRN</Label>
                    </th>
                    <th>
                      <Label size='h6' uppercase>First Name</Label>
                    </th>
                    <th>
                      <Label size='h6' uppercase>Last Name</Label>
                    </th>
                    <th>
                      <Label size='h6' uppercase>Age</Label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(patients && Array.isArray(patients)) ?
                    patients.map((item: any) => (
                      <tr
                        key={item.id}
                        role='button'
                        tabIndex={-1}
                        className='clinic-patients__table__row'
                        onClick={() => onOpen(item)}
                      >
                        <td>
                          <Label size="h5" weight={200}>{item.mrn}</Label>
                        </td>
                        <td>
                          <Label size="h5" weight={200} >{item.firstname}</Label>

                        </td>
                        <td>
                          <Label size="h5" weight={200} >{item.lastname}</Label>
                        </td>
                        <td>
                          <Label size="h5" weight={200} >{calculateAge(item.PatientBirthDate)}</Label>
                        </td>
                      </tr>
                    ))
                    :
                    <tr>
                      <td colSpan={3} >
                        <span className='clinic-patients__empty'>
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Label size='h6'> Nothing to show</Label>
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />
                          <Spacer value={30} />

                        </span>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <Spacer value={20} />
            {data &&
              <div className='w-100 btn-row'>
                <Pagination count={data.pageTotal} page={data.pageCurrent} onChange={handleChange} />
              </div>
            }
          </>}

        <AddPatientModal handleAdd={handleAddPat} open={addPatModal} onClose={() => setAddPatModal(false)} registryId={registry.id} />

        <Alert
          severity={alert.kind}
          open={alert.open}
          onClose={resetAlert}
          message={alert.message}
        />
      </div>
    </div>
  );
};

export default withAuthenticator(ClinicPatients);
