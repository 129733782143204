import React, { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import { Spacer, Label, Button, Modal } from '../../atoms';
import './close-audit.scss';
import { useUpdateAuditMutation, useGetFindingByAuditQuery } from '../../../api/builders/hives.api';


export interface CloseAuditProps {
  className?: string,
  audit: any,
}


type AnswerOption = {
  label: string;
  value: string;
};


const answerOptions: AnswerOption[] = [
  { label: 'None', value: 'none' },
  { label: 'Minor, Not Pausing Sharing', value: 'minor' },
  { label: 'Major, Pause sharing only series with findings', value: 'major-series' },
  // { label: 'Major, Pause Sharing All Similar Series', value: 'major-similar-series' },
  { label: 'Global, Pause Sharing All', value: 'global' },
];


const getRecText = (selectedAnswer: string) => {
  switch (selectedAnswer) {
    case 'none':
      return 'None';
    case 'minor':
      return 'Minor, Not Pausing Sharing';
    case 'major-series':
      return 'Major, Pause Sharing Only this Series';
    case 'major-similar-series':
      return 'Major, Pause Sharing All Similar Series';
    case 'global':
      return 'Global, Pause Sharing All';
    default:
      return 'N/A'; // Invalid selected answer
  }
};


const CloseAudit = (props: CloseAuditProps): JSX.Element => {
  const { audit } = props;
  const { data } = useGetFindingByAuditQuery(audit?.id ?? skipToken);
  const [saveAudit] = useUpdateAuditMutation();
  const [modal, setModal] = useState(false);
  const [signerName, setSignerName] = React.useState('');
  const [rec, setRec] = useState('');

  const sigCanvas = React.useRef<SignatureCanvas>(null);


  const handleSaveAudit = () => {
    try {
      const payload = {
        auditId: audit.id,
        body: {
          status: 'cleared',
          description: rec,
        },
      };
      saveAudit(payload);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpen = () => {
    setModal(true);
  };
  const handleClose = () => {
    setModal(false);
  };

  const handleSelectAnswer = (value: string) => {
    setRec(value);
  };

  const getInfo = (payload: any) => {
    const { hives, studies } = payload;
    let result = '';

    if (hives.length > 0 && hives[0].id) {
      result = `${hives[0].hiveLabel} / ${hives[0].description}`;
    }

    if (studies.length > 0 && studies[0].id) {
      result = `${studies[0].title} / ${studies[0].description} `;
    }

    return result;
  };


  const renderOptions = (selectedAnswer: string) => {
    const filler = 'somebsvar';
    return answerOptions.map((option) => {
      const Icon = selectedAnswer === option.value ? BsCheckCircle : BsCircle;
      const iconColor = selectedAnswer === option.value ? 'blue' : 'gray';
      return (
        <span key={option.value} role='button' tabIndex={-1} onClick={() => handleSelectAnswer(option.value)} >
          <Label size='h5' key={option.value} className="radio-item">
            <input
              type="radio"
              name="answer"
              value={option.value}
              checked={selectedAnswer === option.value}
              onChange={() => handleSelectAnswer(option.value)}
              style={{ display: 'none' }}
            />
            <Icon size={16} color={iconColor} className='radio-icon' />
            {' '}    {' '}
            {option.label}
          </Label>
        </span>
      );
    });
  };

  return (
    <div className="card">
      <Button onClick={handleOpen}>Close Audit</Button>
      <Modal size='lg' title={`Close Audit ${audit?.description ?? ''}`} open={modal} onClose={handleClose}>
        <div>
          <div className='close-audit__section-header'>
            <Label uppercase size="h3" center>
              Audit {audit?.description}
            </Label>
          </div>
          <Spacer value={10} />
          <Label center size="h4" weight={600} >
            {getInfo(audit)}
          </Label>

          <Spacer value={10} />
          <div className='close-audit__section-header'>
            <Label uppercase size="h3" center >
              Images Audited
            </Label>
          </div>
          <Spacer value={10} />
          {Array.isArray(audit.scans) && audit.scans.map((scan: any) => (
            <div className='close-audit__finding' key={scan.id}>
              <Label uppercase size="h4" weight={600} >
                {scan.SeriesDescription}
              </Label>
              <Spacer value={5} />
              {scan.auditStatus === 'incomplete' ?
                <>
                  <Label uppercase size="h5" color='danger' weight={600} >
                    AUDIT incomplete
                  </Label>
                  <Spacer value={20} />
                </>

                :
                <>
                  <Label size="h6" weight={400} >
                    STATUS: {scan.auditStatus}
                  </Label>
                  <Spacer value={5} />
                  <Label size="h6" weight={400} >
                    RISK LEVEL: {scan.riskLevel}
                  </Label>
                  <Spacer value={5} />
                  <Label size="h6" weight={400} >
                    RECCOMENDATION: {getRecText(scan.recommendation)}
                    {/* RECCOMENDATION: {scan.recommendation === 'null' ? 'N/A' : scan.recommendation} */}
                  </Label>
                  <Spacer value={20} />
                </>
              }
            </div>
          ))}
          <Spacer value={10} />

          <div className='close-audit__section-header'>
            <Label uppercase size="h3" center >
              RECCOMENDATION
            </Label>
          </div>
          <div className="close-audit__radio-list">
            <Spacer value={5} />
            {renderOptions(rec)}
          </div>

          <Spacer value={20} />

          <div className='close-audit__signature-wrapper'>
            <Label size='h6'>Completed on: {moment().format('LLL')}</Label>
            <Spacer value={5} />
            <Label size='h6'>Name:</Label>
            <input className='w-100' type='text' value={signerName} onChange={(e) => setSignerName(e.target.value)} />
            <Spacer value={5} />

            <Label size='h6'>Signature:</Label>
            <SignatureCanvas
              penColor='black'
              ref={sigCanvas}
              onEnd={() => console.info('document signed')}
              maxWidth={1}
              canvasProps={{ width: 500, height: 150, className: 'close-audit__signature' }}
            />
          </div>

          <Spacer value={20} />
          <Spacer value={20} />

          <Button onClick={handleSaveAudit}>Finish Audit</Button>
        </div>
      </Modal>

    </div>
  );
};

CloseAudit.defaultProps = {
  className: '',
};

CloseAudit.displayName = 'CloseAudit';
export default CloseAudit;
