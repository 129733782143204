import React, { useState } from 'react';
/* eslint-disable */
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import './advanced-emr-search.scss';
import { Dropdown, Label, Spacer, Button, Modal } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import SearchBox from './LargeSearchBox';
import useDebounce from '../../../hooks/useDebounce';

export interface AdvancedEMRSearchProps {
  onSubmitSearch: (pl: any) => void,
  onFieldSearch: (pl: any) => void,
}

function valuetext(value: number) {
  return `${value}°C`;
}

const PrettoSlider = styled(Slider)({
  color: '#FFE5FF',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#FFE5FF',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#FFE5FF',
    color: '#000000',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const AdvancedEMRSearch = (props: AdvancedEMRSearchProps): JSX.Element => {
  const { onSubmitSearch, onFieldSearch } = props;
  const [debouncedOutput, setDebouncedOutput] = useState('');
  const [immediateOutput, setImmediateOutput] = useState('');
  const [debouncedCalls, setDebouncedCalls] = useState(0);
  const [searchField, setSearchField] = useState('mrn');
  const [immediateCalls, setImmediateCalls] = useState(0);
  const [value, setValue] = React.useState<number[]>([10, 90]);
  const [searchQueryString, setSearchQueryString] = React.useState('');
  const [searchState, setSearchState] = React.useState({
    PatientSex: 'any',
    pky: 0,
    hx_cancer: 'any',
    density: 'any',
    spiculation: 'any',
    FDG: 'any',
  });


  React.useEffect(() => {
    onSubmitSearch(searchQueryString);
  }, [searchQueryString]);

  const resetSearch = () => {
    setSearchState({
      PatientSex: 'any',
      pky: 0,
      hx_cancer: 'any',
      density: 'any',
      spiculation: 'any',
      FDG: 'any',
    });
    setSearchQueryString('');
    // setSearchField('mrn');
    setDebouncedOutput('');
    setImmediateOutput('');
  };

  function joinWithAmpersand(strings: string[]): string {
    if (strings.length === 1) {
      return strings[0] + '&';
    }
    return strings.join('&');
  }

  function findNonAnyProperties(obj: any): string[] {
    const nonAnyProperties: string[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== 'any' && obj[key] !== 0) {
        // mrn=1&
        nonAnyProperties.push(`${key}=${obj[key]}`);
      }
    }
    return nonAnyProperties;
  }

  React.useEffect(() => {
    const test2 = findNonAnyProperties(searchState);
    let query = ''
    if (Array.isArray(test2) && test2.length > 0) {
      query = joinWithAmpersand(test2);
    }
    let final = '';
    if (debouncedOutput !== '') {
      final = `${searchField}=${debouncedOutput}&${query}`;
    } else {
      final = query;
    }
    setSearchQueryString(final);
  }, [searchState, debouncedOutput, searchField]);

  // React.useEffect(() => {
  //   if (debouncedOutput !== '') {
  //     let final = '';
  //     if (searchQueryString !== ''){
  //       final = `${searchQueryString}&${searchField}=${debouncedOutput}`;
  //     } else {
  //       final = `${searchField}=${debouncedOutput}`;
  //     }
  //     setSearchQueryString(final);
  //   }
  // }, [debouncedOutput, searchField]);



  const onChangeDebouncedT = async (text: string) => {
    setDebouncedOutput(text);
    setDebouncedCalls(debouncedCalls + 1);
    // onSubmitSearch(text);
  };

  const onChangeImmediate = async (text: string) => {
    setImmediateOutput(text);
    setImmediateCalls(immediateCalls + 1);
  };

  const onChangeDebounced = useDebounce(onChangeDebouncedT);

  const handleSearchChange = (val: string) => {
    onChangeDebounced(val);
    onChangeImmediate(val);
  };

  const onSearch = () => {
    console.info('onKeydown?');
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleFieldChange = (val: any) => {
    // onFieldSearch(val);
    setSearchField(val);
  };

  const handleFilterDropdown = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchState({ ...searchState, [event.target.name]: event.target.value });
  };

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchState({ ...searchState, [event.target.name]: event.target.value });
  };

  return (
    <div className="patients__header">
      <div className='d-flex-row'>
        <Label size='h3' weight={600} color='primary' >Search All Patients</Label>
      </div>
      <Spacer value={20} />
      <div className="patients__header-search">
        <SearchBox
          value={immediateOutput}
          onChange={handleSearchChange}
          onSubmit={onSearch}
          placeholder='Enter Text to search'
          onChangeField={handleFieldChange}
        />
        <Spacer value={20} />
      </div>
      <Spacer value={10} />
      <Grid container spacing={2}>
        <Grid xs={2}>
          <Label className="input__label" size="h5" weight={600} color="primary" >Gender</Label>
          <Dropdown
            name='PatientSex'
            label=''
            value={searchState.PatientSex}
            onChange={handleFilterDropdown}
          >
            <MenuItem value="any">Any</MenuItem>
            <MenuItem value="M">Male</MenuItem>
            <MenuItem value="F">Female</MenuItem>
          </Dropdown>
        </Grid>
        {/* <Grid xs={3} >
          <Label className="input__label" size="h5" weight={600} color="primary" > Age</Label>
          <Spacer value={5} />
          <PrettoSlider
            getAriaLabel={() => 'Age'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
          />
        </Grid> */}
        {/* <Grid xs={6}>
          <div />
        </Grid> */}
        <Grid >
          <Label className="input__label" size="h5" weight={600} color="primary" >PKY</Label>
          <input
            value={searchState.pky}
            onChange={handleChangeText}
            name='pky'
            className="aemrs__input"
          />
        </Grid>
        <Grid xs={2}>
          <Label className="input__label" size="h5" weight={600} color="primary" >Cancer</Label>
          <Dropdown
            name='hx_cancer'
            label=''
            value={searchState.hx_cancer}
            onChange={handleFilterDropdown}
          >
            <MenuItem value="any">Any</MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Dropdown>
        </Grid>
        <Grid xs={2}>
          <Label className="input__label" size="h5" weight={600} color="primary" >Density</Label>
          <Dropdown
            name='density'
            label=''
            value={searchState.density}
            onChange={handleFilterDropdown}
          >
            <MenuItem value="any">Any</MenuItem>
            <MenuItem value="Solid">Solid</MenuItem>
            <MenuItem value="Part-Solid">Part-Solid</MenuItem>
            <MenuItem value="GGO">GGO</MenuItem>
          </Dropdown>
        </Grid>
        <Grid xs={2}>
          <Label className="input__label" size="h5" weight={600} color="primary" >Spiculation</Label>
          <Dropdown
            name='spiculation'
            label=''
            value={searchState.spiculation}
            onChange={handleFilterDropdown}
          >
            <MenuItem value="any">Any</MenuItem>
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Dropdown>
        </Grid>

      </Grid>
      <div className='d-flex'>
        <Button kind='ghost' className='aemrs__reset' onClick={resetSearch}>Reset Search</Button>
      </div>
    </div>
  );
};

AdvancedEMRSearch.defaultProps = {
};

AdvancedEMRSearch.displayName = 'AdvancedEMRSearch';
export default AdvancedEMRSearch;
