import React from 'react';
import './share-cohort.scss';
import { Label, Modal, Button, Spacer } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import TextAreaInput from '../TextAreaInput/TextAreaInput';

export interface ShareCohortProps {
  addCohort: (payload: any) => void,
  onOpen: boolean,
  onClose: () => void,
  cohort: any,
}

const initialForm = {
  email: '',
  label: '',
  description: '',
};


const ShareCohort = (props: ShareCohortProps): JSX.Element => {
  const { addCohort, onOpen, onClose, cohort } = props;
  const [modalView, setModalView] = React.useState('choice');
  const [newVals, setNewVals] = React.useState(initialForm);
  const [error, setError] = React.useState({ open: false, message: '' });

  const handleChangeVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewVals({ ...newVals, [event.target.name]: event.target.value });
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewVals({ ...newVals, [event.target.name]: event.target.value });
  };

  const validateEmail = (username: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(username).toLowerCase());
  };

  const closeError = () => {
    setError({ open: false, message: '' });
  };

  const onCloseModal = () => {
    onClose();
    setNewVals(initialForm);
    setModalView('choice');
    closeError();
  };

  const copyMode = () => {
    setModalView('shared');
    const newLabel = `${cohort?.cohortLabel ?? 'Cohort'} Copy`;
    setNewVals({ ...newVals, label: newLabel, description: cohort?.description ? cohort.description : '' });
  };

  const shareMode = () => {
    setModalView('master');
    const newLabel = `${cohort?.cohortLabel ?? 'Cohort'} Copy`;
    setNewVals({ ...newVals, label: newLabel, description: cohort?.description ? cohort.description : '' });
  };

  const cancelChoice = () => {
    setModalView('choice');
    setNewVals(initialForm);
    closeError();
  };

  const onSubmitShare = () => {
    try {
      const verifiedEmail = validateEmail(newVals.email);

      if (!verifiedEmail) {
        setError({
          open: true,
          message: 'Please enter a valid email address',
        });
        return;
      }
      const payload = {
        cohortId: cohort.id,
        email: newVals.email,
        cohortName: newVals.label,
        cohortDescription: newVals.description,
        cohortType: modalView,
      };
      addCohort(payload);
      onCloseModal();
    } catch (e) {
      console.error('ERROR SENDING', e);
    }
  };

  return (
    <Modal
      open={onOpen}
      onClose={onCloseModal}
      title="Access Control"
      size="md"
    >
      <div>
        {modalView === 'choice' && (
          <div >
            <Spacer value={15} />
            <div role="button" tabIndex={0} onClick={copyMode} className="add-participant__tile">
              <Label size="h5" weight={600} color="primary" >Create Share Copy</Label>
            </div>
            <div role="button" tabIndex={-1} onClick={shareMode} className="add-participant__tile">
              <Label size="h5" weight={600} color="primary"  >Add User </Label>
            </div>
          </div>
        )}
        {modalView === 'shared' && (
          <div>
            <TextInput
              name='label'
              label='Label'
              onChange={handleChangeVals}
              value={newVals.label}
            />
            <TextAreaInput
              name='description'
              label='Description'
              onChange={handleChangeDescription}
              value={newVals.description}
            />
            <TextInput
              name='email'
              label='Add email to share'
              onChange={handleChangeVals}
              value={newVals.email}
            />
            <Spacer value={10} />
            <div className='btn-row'>
              <Button onClick={cancelChoice} kind='danger'>Cancel</Button>
              <Button onClick={onSubmitShare}>Submit</Button>
            </div>
            <Spacer value={10} />
            {error.open &&
              <Label size='h4' color='danger'>{error.message}</Label>
            }
          </div>
        )}
        {modalView === 'master' && (
          <div>
            <TextInput
              name='email'
              label='Email'
              onChange={handleChangeVals}
              value={newVals.email}
            />            
            <Label size='h5'>** Master Cohorts can only be shared with existing users</Label>
            <Spacer value={30} />
            <div className='btn-row'>
              <Button onClick={cancelChoice} kind='danger'>Cancel</Button>
              <Button onClick={onSubmitShare}>Submit</Button>
            </div>
            <Spacer value={10} />
            {error.open &&
              <Label size='h4' color='danger'>{error.message}</Label>
            }
          </div>
        )}
      </div>
    </Modal>
  );
};

ShareCohort.defaultProps = {
};

ShareCohort.displayName = 'ShareCohort';
export default ShareCohort;
