/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { BsFileEarmarkRichtextFill, BsFolderFill } from 'react-icons/bs';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tile, Label, Text, Button, Spacer } from '../../../atoms';
import { useGetAnalysisFilesQuery } from '../../../../api/builders/imageAnalysis.api';
import { useDownLoadFileMutation } from '../../../../pages/private/StudyDrive/api';
import DownloadableFile from '../../DownloadableFile/DownloadableFile';
import FileTree from '../../FileTree/FileTree';

export interface InputProps {
  careCase: any,
}

interface FileData {
  name: string;
  type: 'folder' | 'file';
  children?: FileData[];
  coldkey: any;
  bucket: any;
}


interface FileTreeComponentProps {
  files: FileData[];
}

// const FileTreeComponent: React.FC<FileTreeComponentProps> = ({ files }) => {
//   const [expandedFolders, setExpandedFolders] = useState<string[]>([]);

//   const toggleFolder = (folderName: string) => {
//     setExpandedFolders((prevExpandedFolders) => {
//       if (prevExpandedFolders.includes(folderName)) {
//         return prevExpandedFolders.filter((folder) => folder !== folderName);
//       }
//       return [...prevExpandedFolders, folderName];

//     });
//   };

//   const renderNode = (node: FileData) => {
//     const isFolder = node.type === 'folder';
//     const isExpanded = isFolder && expandedFolders.includes(node.name);

//     const handleToggle = () => {
//       if (isFolder) {
//         toggleFolder(node.name);
//       }
//     };

//     return (
//       <div key={node.name}>
//         <div tabIndex={-1} onClick={handleToggle}>
//           {node.name}
//         </div>
//         {isExpanded && isFolder && node.children && (
//           <div style={{ marginLeft: '20px' }}>
//             {node.children.map((child) => renderNode(child))}
//           </div>
//         )}
//       </div>
//     );
//   };

//   return <div>{files.map((node) => renderNode(node))}</div>;
// };


const FileTreeComponent: React.FC<FileTreeComponentProps> = ({ files }) => {
  const [expandedFolders, setExpandedFolders] = useState<string[]>([]);
  const [download] = useDownLoadFileMutation();

  const toggleFolder = (folderName: string) => {
    setExpandedFolders((prevExpandedFolders) => {
      if (prevExpandedFolders.includes(folderName)) {
        return prevExpandedFolders.filter((folder) => folder !== folderName);
      }
      return [...prevExpandedFolders, folderName];
    });
  };

  const handleClickFile = async (clickedFile: any) => {
    const newPath = `s3://${clickedFile.bucket}/${clickedFile.coldkey}`;
    const payload = { files: [{ s3_path: newPath }] };
    const yourbrandnewfile = await download(payload).unwrap();

    const response = await axios({
      method: 'GET',
      url: yourbrandnewfile[0].signedUrl,
      responseType: 'blob',
    });

    // const url = window.URL.createObjectURL(new Blob([yourbrandnewfile[0].signedUrl]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', `${clickedFile.name}`);
    // document.body.appendChild(link);
    // link.click();

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${clickedFile.name}`); // Specify the desired file name
    document.body.appendChild(link);
    link.click();
    link.remove();

    // Clean up the temporary URL object
    window.URL.revokeObjectURL(url);
  };

  const renderNode = (node: FileData) => {
    const isFolder = node.type === 'folder';
    const isExpanded = isFolder && expandedFolders.includes(node.name);

    const handleToggle = () => {
      if (isFolder) {
        toggleFolder(node.name);
      }
    };

    const handleClick = () => {
      if (!isFolder) {
        handleClickFile(node!);
      }
    };

    return (
      <div key={node.name}>
        <div className={isFolder ? 'files__folder' : 'files__file'} tabIndex={-1} onClick={handleToggle} onClickCapture={handleClick}>
          {isFolder ?
            <BsFolderFill />
            :
            <BsFileEarmarkRichtextFill />
          }
          <Text>{node.name}</Text>
        </div>
        {isExpanded && isFolder && node.children && (
          <div style={{ marginLeft: '20px' }}>
            {node.children.map((child) => renderNode(child))}
          </div>
        )}
      </div>
    );
  };

  return <div>{files.map((node) => renderNode(node))}</div>;
};



const Files = (props: InputProps): JSX.Element => {
  const { careCase } = props;
  const { data, status } = useGetAnalysisFilesQuery(careCase?.jobId ?? skipToken);
  const [fileTree, setFileTree] = useState<FileData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const optimizeFileTree = (node: FileData) => {
    if (!node.children || node.children.length === 0) {
      return;
    }

    if (node.children.length === 1 && node.children[0].type === 'folder') {
      node.name += `/${node.children[0].name}`;
      node.children = node.children[0].children;
      optimizeFileTree(node);
    } else {
      node.children.forEach((child) => optimizeFileTree(child));
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     const root: FileData = {
  //       name: '',
  //       type: 'folder',
  //       children: [],
  //     };

  //     const logAnonymizedPaths = (dootood: any) => {
  //       const anonymizedPaths = dootood.map((item: any) => item.anonymizedPath);
  //       return anonymizedPaths;
  //     };

  //     logAnonymizedPaths(data).forEach((path: any) => {
  //       const pathParts = path.split('/');
  //       let currentLevel = root;

  //       pathParts.forEach((part: any) => {
  //         const existingNode = currentLevel.children?.find((node) => node.name === part);

  //         if (existingNode) {
  //           currentLevel = existingNode;
  //         } else {
  //           const newNode: FileData = {
  //             name: part,
  //             type: part.includes('.') ? 'file' : 'folder',
  //             children: [],
  //           };

  //           currentLevel.children!.push(newNode);
  //           currentLevel = newNode;
  //         }
  //       });
  //     });

  //     optimizeFileTree(root);
  //     setFileTree(root.children || []);
  //     setIsLoading(false);
  //   }

  // }, [data]);

  useEffect(() => {
    if (data) {
      const root: FileData = {
        name: '',
        type: 'folder',
        children: [],
        coldkey: '',
        bucket: '',
      };

      const logAnonymizedPaths = (dootood: any) => {
        const anonymizedPaths = dootood.map((item: any) => item.anonymizedPath);
        return anonymizedPaths;
      };

      logAnonymizedPaths(data).forEach((path: any) => {
        const pathParts = path.split('/');
        let currentLevel = root;

        pathParts.forEach((part: any) => {
          const existingNode = currentLevel.children?.find((node) => node.name === part);

          if (existingNode) {
            currentLevel = existingNode;
          } else {
            const isFile = part.includes('.');
            const newNode: FileData = {
              name: part,
              type: isFile ? 'file' : 'folder',
              children: [],
              coldkey: isFile ? data.find((item: any) => item.anonymizedPath === path)?.coldKey : undefined,
              bucket: isFile ? data.find((item: any) => item.anonymizedPath === path)?.bucket : undefined,
            };

            currentLevel.children!.push(newNode);
            currentLevel = newNode;
          }
        });
      });

      optimizeFileTree(root);
      setFileTree(root.children || []);
      setIsLoading(false);
    }
  }, [data]);


  const toggleFolder = (folder: FileData) => {
    if (folder.type === 'folder') {
      folder.children = folder.children || [];
      setFileTree([...fileTree]);
    }
  };

  const renderNode = (node: FileData) => {
    const isFolder = node.type === 'folder';

    return (
      <div key={node.name}>
        <div onClick={() => isFolder && toggleFolder(node)}>{node.name}</div>
        {isFolder && node.children && (
          <div style={{ marginLeft: '20px' }}>
            {node.children.map((child) => renderNode(child))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {careCase.status === 'pending' &&
        <div className='text-center'>
          <Spacer value={30} />
          <Label size='h6' center> Please Select your Scans and begin Processing
          </Label>
        </div>
      }
      {careCase.status === 'processing started' &&
        <div className='text-center'>
          <Spacer value={30} />
          <Label size='h6'>
            Your processing has started, this may take several minutes.
          </Label>
          <Label size='h6'>
            Please check back later. You may have to refresh the page to see results
          </Label>
        </div>
      }
      {careCase.status === 'processing complete' &&
        <div>
          {fileTree ?
            <div className='files__wrapper'>
              <FileTreeComponent files={fileTree} />
            </div>
            :
            <div className='text-center'>
              <Spacer value={30} />
              <Label size='h5' color='ghost'>No Files</Label>
            </div>
          }
        </div>
      }
    </div>
  );
};

Files.defaultProps = {
};

Files.displayName = 'Files';
export default Files;


