/* eslint-disable  */
import React, { useCallback, useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import * as dicomParser from 'dicom-parser';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { Spinner, Label, CustomAnimation } from '../../atoms';
import { useGetColdFileArrayQuery } from '../../../api/builders/participantFiles.api';
import CustomProgress from '../../atoms/CustomProgress/CustomProgress';
import { LinearProgress } from '@mui/material';
import ViewerToolBar from '../ViewerToolBar/ViewerToolBar';
import MemoizedViewerToolBar from '../ViewerToolBar/ViewerToolBar';
import { red } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Snapshot, listSnapshots, registerSnapshot, saveSnapshot } from '../../../store/snapshots';

import './dicom-viewer2.scss';

interface IStack {
  currentImageIdIndex: number;
  currentImageIndex: number;
  imageIds: string[];
}

export interface DicomViewer2Props {
  id: string;
  className?: string;
  viewerId: string;
  stack: IStack;
  prefix?: string;
  series: any;
  onCopyMeasurement?: (arg: any) => void,
}



interface Provider {
  name: string;
  test: () => void;
  getMetadata: (type: string, data: any) => any;
  priority: number;
}

// const initStack: IStack = {
//   currentImageIndex: 0,
//   imageIds: [],
// };
// configure cornerstone tools
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

const LoadingProgress = React.memo((props: { progress: number }) => {
  const { progress } = props;
  const linearProgressStyles = {
    width: '100%',
    backgroundColor: 'red'
  };
  return (
    <div className="loading_progress">
      <Box sx={{ width: '100%', display: 'flex', marginBottom: '2px' }}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress className={'loading_progress2'} variant="determinate" value={progress} />
        </Box>
      </Box>
    </div>
  );
});

const extractPixelSpacing = (pixelSpacing: string): { xSpacing: number; ySpacing: number } | null => {
  const pattern = /(\d+\.\d+)/g;
  
  if (pixelSpacing) {
    const matches = pixelSpacing.match(pattern);
    if (matches && matches.length >= 2) {
      const xSpacing = parseFloat(matches[0]);
      const ySpacing = parseFloat(matches[1]);
      const spacing = { xSpacing, ySpacing }
      return spacing;
    }
  }
  return null;
};


const initStack: IStack = {
  currentImageIdIndex: 0,
  currentImageIndex: 0,
  imageIds: []
};

const DicomViewer2 = (props: DicomViewer2Props): JSX.Element => {
  const { id, className, stack = null, viewerId, prefix, series, onCopyMeasurement } = props;

  const [currentStack, setCurrentStack] = useState(stack || initStack);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    cornerstoneTools.init({
      moduleName: 'globalConfiguration',
      configuration: {
        mouseEnabled: true,
        touchEnabled: true,
        showSVGCursors: true,
        globalToolSyncEnabled: true
      }
    });
    const element = document.getElementById(viewerId!);
    cornerstone.enable(element!);
    // initTools(element!);
  }, []);

  useEffect(() => {
    // Do something when the currentImageIndex changes
    const element = document.getElementById(viewerId!);
    setError(false);
    if (element && stack && stack.imageIds.length > 0 && stack.currentImageIndex < stack.imageIds.length) {
      cornerstone.enable(element);
      try {
        cornerstone.loadAndCacheImage(stack.imageIds[stack.currentImageIndex]).then((image: cornerstone.Image) => {
          cornerstone.displayImage(element, image);
        });

        cornerstoneTools.addToolState(element, 'stack', stack);
        setCurrentStack(stack);
      } catch (e) {
        console.error('ERROR DISPLAYING IMG', e);
        setError(true);
      }
    }
  }, [stack]);

  useEffect(() => {
    // Do something when the currentImageIndex changes
    const element = document.getElementById(viewerId!);
    setError(false);
    if (element && currentStack.imageIds.length > 0 && currentStack.currentImageIndex < currentStack.imageIds.length) {
      cornerstone.enable(element);
      try {
        cornerstone
          .loadAndCacheImage(currentStack.imageIds[currentStack.currentImageIndex])
          .then((image: cornerstone.Image) => {
            cornerstone.displayImage(element, image);
          });
      } catch (e) {
        console.error('ERROR DISPLAYING IMG', e);
        setError(true);
      }
    }
  }, [currentStack]);

  const handleChangeImage = useCallback((index: number) => {
    setCurrentStack((prevStack) => {
      return { ...prevStack, currentImageIndex: index };
    });
  }, []);

  const captureMeasurement = (arg: any) => {
    const measurements_3d = arg?.map( (measurement: { longestDiameter: any; shortestDiameter: any; start: { x: any; y: any; }; end: { x: any; y: any; }; perpendicularStart: { x: any; y: any; }; perpendicularEnd: { x: any; y: any; }; intersect: any; }) => {
      return {
        series: series?.SeriesDescription,
        longestDiameter: measurement.longestDiameter,
        shortestDiameter: measurement.shortestDiameter,
        start: { x: measurement.start.x, y: measurement.start.y, z: currentStack.currentImageIdIndex },
        end: { x: measurement.end.x, y: measurement.end.y, z: currentStack.currentImageIdIndex },
        perpendicularStart: {
          x: measurement.perpendicularStart.x,
          y: measurement.perpendicularStart.y, 
          z: currentStack.currentImageIdIndex,
        },
        perpendicularEnd: { 
          x: measurement.perpendicularEnd.x, 
          y: measurement.perpendicularEnd.y, 
          z: currentStack.currentImageIdIndex 
        },
        intersect : {...measurement.intersect, z: currentStack.currentImageIdIndex},
      };
    })
    // console.log('DICOM2 measurements_3d', measurements_3d);
    if (onCopyMeasurement){
      onCopyMeasurement(measurements_3d);
      captureSnapshot(`measurement-${series?.SeriesDescription}-${currentStack.currentImageIdIndex}`);
    }
  }

  const captureSnapshot = (snapshotName: string) => {
    const element = document.getElementById(viewerId); // Replace with your container ID
    const canvas = cornerstone.getEnabledElement(element!).canvas;
    const image = cornerstone.getEnabledElement(element!).image;
    if (image) {
      // Create a temporary canvas to draw the snapshot
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = canvas?.width!;
      tempCanvas.height = canvas?.height!;
      const tempContext = tempCanvas.getContext('2d');
      if (tempContext) {
        tempContext.drawImage(canvas!, 0, 0);
        // Draw overlay content on the temporary canvas
        // const overlayContent = `#${currentStack?.currentImageIndex}`;
        const overlayContent = `Slice ${stack?.currentImageIndex}\n${
          series.SeriesDescription
        }\nVox ${extractPixelSpacing(series.PixelSpacing)?.xSpacing.toFixed(2)} x ${extractPixelSpacing(
          series.PixelSpacing
        )?.ySpacing.toFixed(2)} x ${series.SliceThickness} mm`;
        tempContext.fillStyle = 'white';
        tempContext.font = '10px Arial';
        tempContext.fillText(overlayContent, 10, 20);
        // Draw the copyright banner
        const bannerHeight = 50; // Height of the banner in pixels
        tempContext.fillStyle = '#000'; // Color of the banner background
        tempContext.fillRect(0, tempCanvas.height - bannerHeight, tempCanvas.width, bannerHeight);

        const text = '2023 © Insightec';
        tempContext.font = '10px Arial'; // Font style for the text
        tempContext.fillStyle = '#fff'; // Color of the text
        tempContext.textBaseline = 'middle';
        tempContext.textAlign = 'center';
        tempContext.fillText(text, tempCanvas.width / 2, tempCanvas.height - bannerHeight / 2);
      }
      // Convert the snapshot to an image URL
      const imageURL = tempCanvas.toDataURL();

      // Create an anchor element to trigger the download
      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = `${snapshotName}.png`; // Specify the desired file name and format

      // Trigger the download programmatically
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Dispatch the saveSnapshot action
      // const newSnapshot: Snapshot = {
      //   id: `${snapshotName}.png`, // Replace with a unique identifier
      //   userId: 'userId', // Replace with the actual user ID
      //   meta: {
      //     sliceId: stack && stack?.currentImageIndex
      //     // 'seriesDesc': series.SeriesDescription,
      //   }, // Add your desired meta information
      //   fileUrl: imageURL // Pass the file URL to the action
      // };

      // dispatch(registerSnapshot(newSnapshot));
    }
  };

  const Overlay = ({sliceIndex, serieDesc, voxSize, aligned }:any): JSX.Element => {
    const a = 1;
    return (
      <div className="overlay-wrapper">
        <div className="overlay overlay-top-left">
          Slice {sliceIndex}
        </div>
        <div className="overlay overlay-top-right">
          {serieDesc}
        </div>
        <div className="overlay overlay-bottom-left">
          Vox {voxSize} mm
        </div>
        <div className="overlay overlay-bottom-right">
          {aligned && 'Aligned'}
        </div>
      </div>
    );
  };

  return (
    <div key={`DicomViewer2_${id}`} className="dicom-viewer--wrapper">
      <div className="dicom-viewer">
        <LoadingProgress progress={loadingProgress || 0} />
        <div className="dicom-viewer__body">
        <Overlay sliceIndex={currentStack?.currentImageIndex} serieDesc={series?.SeriesDescription} voxSize={`${extractPixelSpacing(series?.PixelSpacing)?.xSpacing.toFixed(2)}x${extractPixelSpacing(series?.PixelSpacing)?.ySpacing.toFixed(2)}`} aligned={false}/>
          <div id={viewerId} style={{ height: '100%' }} className="dicom-viewer__body__viewer">
            {error && (
              <div className="loading-wrapper">
                <Label size="h3" color="danger">
                  Unable to load image
                </Label>{' '}
              </div>
            )}
          </div>
          
        </div>
        <div className="dicom-viewer__toolbar">
          {/* <MemoizedViewerToolBar elementId={viewerId!} onCaptureSnapshot={()=>captureSnapshot(`${prefix}.${stack?.currentImageIndex}`)} /> */}
          <MemoizedViewerToolBar
            stack={currentStack}
            elementId={viewerId!}
            onCaptureSnapshot={() =>
              captureSnapshot(`${prefix}.${series.SeriesDescription}.${stack?.currentImageIndex}`)
            }
            onCopyMeasurement={captureMeasurement}
          />
        </div>
        <div className="dicom-viewer__slider_wrapper">
          <input
            className="slider"
            style={{ width: '90%' }}
            type="range"
            min={0}
            max={(currentStack && currentStack?.imageIds.length - 1) || 0}
            value={currentStack?.currentImageIndex}
            onChange={(event) => handleChangeImage(parseInt(event.target.value))}
          />
          <span className="current-image-index">{currentStack?.currentImageIndex}</span>
        </div>
      </div>
    </div>
  );
};

DicomViewer2.defaultProps = {
  className: ''
};

DicomViewer2.displayName = 'DicomViewer2';

const MemoizedDicomViewer2 = React.memo(DicomViewer2);

export default MemoizedDicomViewer2;
