import QueryString from 'qs';
import api from '../api';


export const registriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPatRegistries: builder.query({
      query: ({ order_by, pageCurrent, pageSize }) => `/patientregistry?${QueryString.stringify({ order_by, pageCurrent, pageSize })}`,
      providesTags: ['REGISTRIES'],
    }),
    getCarePathways: builder.query({
      query: () => '/carepathway',
      transformResponse: (response: any) => response.items,
      providesTags: ['CARETEMPLATES'],
    }),
    createRegistry: builder.mutation({
      query: (payload) => ({ url: '/patientregistry', method: 'POST', body: payload }),
      invalidatesTags: ['REGISTRIES'],
    }),
    updateRegistry: builder.mutation({
      query: (payload) => ({ url: `/patientregistry/${payload.id}`, method: 'PUT', body: payload }),
      invalidatesTags: ['REGISTRIES', 'REGISTRY'],
    }),
    getPatRegistriesStats: builder.query({
      query: (id) => `/patientregistry/statsbyid/${id}`,
      transformResponse: (response: any) => response.items,
      providesTags: ['REGISTRY'],
    }),
    getPatientRegistryRequirements: builder.query({
      query: ({ order_by, pageCurrent, pageSize, registryID }) => `/patientregistry/requirementsbyid/${registryID}?${QueryString.stringify({ order_by, pageCurrent, pageSize })}`,
      // transformResponse: (response: any) => response.items,
      providesTags: ['REGISTRY'],
    }),
    addPatientsToRegistry: builder.mutation({
      query: (payload) => ({ url: '/patient/patientregistry', method: 'POST', body: payload }),
      invalidatesTags: ['REGISTRY'],
    }),
    getPatientPathways: builder.query({
      query: (patientId) => `/patientcarepathway/${patientId}`,
      transformResponse: (response: any) => response.items,
      providesTags: ['OPENPATIENT'],
    }),
    getCarepathwayStats: builder.query({
      query: (carepathwayId) => `carepathway/statsbyid/${carepathwayId}`,
      transformResponse: (response: any) => response.items,
    }),
    getPatientcarepathwayStats: builder.query({
      query: (patientId) => `/patientcarepathway/statsbyid/${patientId}`,
      transformResponse: (response: any) => response.items,
      providesTags: ['OPENPATIENT'],
    }),
    advancePatientStep: builder.mutation({
      query: (payload) => ({ url: `/patientcarepathway/step/${payload.patientcarepathwayId}`, method: 'PATCH', body: payload.body }),
      invalidatesTags: ['OPENPATIENT', 'REGISTRY', 'CONFERENCE'],
    }),
    getVisitsFromRegistryByDates: builder.query({
      query: (payload) => `/emr/visit/patientregistry/${payload.patientregistryUID}/${payload.startDate}/${payload.endDate}`,
      transformResponse: (response: any) => response.items,
      providesTags: ['REGISTRY'],
    }),
    getPatientsByPCPStatus: builder.query({
      query: (pl) => `/patient/patientcarepathway/status/${pl.patientRegistryUID}/${pl.status}`,
      transformResponse: (response: any) => response.items,
      providesTags: ['CONFERENCEPATS'],
    }),
    getPatientEMR: builder.query({
      query: (patientId) => `/emr/visit/patient/${patientId}`,
      transformResponse: (response: any) => response.items,
      providesTags: ['OPENPATIENT'],
    }),
    updateReadyForConf: builder.mutation({
      query: (payload) => ({ url: `/patientcarepathway/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['OPENPATIENT', 'REGISTRY'],
    }),
    createSignedURL: builder.mutation({
      query: (payload) => ({ url: '/files/patientcarepathway/generic/', method: 'POST', body: payload }),
      invalidatesTags: ['OPENPATIENT'],
    }),
    getSavedCRFS: builder.query({
      query: (pl) => `/files/patientcarepathway/generic/${pl.patientcarepathwayId}/${pl.stepId}`,
      transformResponse: (response: any) => response.items,
      providesTags: ['OPENPATIENT'],
    }),
    patientSearch: builder.query({
      query: ({ query, pageCurrent, pageSize, id }) => `/patient/registry/search/${id}?${query}&${QueryString.stringify({ pageCurrent, pageSize })}`,
    }),
    patientRegistrySearch: builder.query({
      query: ({ query, pageCurrent, pageSize, id }) => `/patient/in/registry/search/${id}?${query}&${QueryString.stringify({ pageCurrent, pageSize })}`,
    }),
  }),
});

export const {
  usePrefetch,
  useGetPatRegistriesQuery,
  useCreateRegistryMutation,
  useGetPatRegistriesStatsQuery,
  useAddPatientsToRegistryMutation,
  useUpdateRegistryMutation,
  useGetCarePathwaysQuery,
  useGetPatientcarepathwayStatsQuery,
  useGetPatientPathwaysQuery,
  useAdvancePatientStepMutation,
  useGetCarepathwayStatsQuery,
  useGetPatientRegistryRequirementsQuery,
  useGetVisitsFromRegistryByDatesQuery,
  useUpdateReadyForConfMutation,
  useGetPatientsByPCPStatusQuery,
  useGetPatientEMRQuery,
  useCreateSignedURLMutation,
  useGetSavedCRFSQuery,
  usePatientSearchQuery,
  usePatientRegistrySearchQuery,
} = registriesApi;
