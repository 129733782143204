import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tile, Label, Text, Button, Spacer } from '../../../atoms';
import { useGetVolAnalysisQuery } from '../../../../api/builders/imageAnalysis.api';
import { BrainSegVolumeReport, PETSUVReport } from '../../AnalysisReports';

export interface InputProps {
  careCase: any,
}

const Analysis = (props: InputProps): JSX.Element => {
  const { careCase } = props;
  const { data, isLoading } = useGetVolAnalysisQuery(careCase?.jobId ? careCase.jobId : skipToken);


  const renderReportTabs = () => {
    if (careCase?.title && Array.isArray(data)) {
      switch (careCase.title) {
        case 'Whole Brain High Resolution Segmentation':
          return (<BrainSegVolumeReport volumetricData={data} isLoading={isLoading} />);
        case 'AD-PET analysis':
          return (<PETSUVReport volumetricData={data} isLoading={isLoading} />);
        default: return (
          <div className='text-center'>
            <Spacer value={30} />
            {isLoading ? <Label size='h6' center>Loading ...</Label> : <Label size='h6' center>Data not available</Label>}
          </div>
        );
      }
    }
    return (
      <div className='text-center'>
        <Spacer value={30} />
        {isLoading ? <Label size='h6' center>Loading ...</Label> : <Label size='h6' center>Data not available</Label>}
      </div>
    );
  };


  return (
    <div className="card">
      {careCase.status === 'pending' &&
        <div className='text-center'>
          <Spacer value={30} />
          <Label size='h6' center> Please Select your Scans and begin Processing
          </Label>
        </div>
      }
      {careCase.status === 'processing started' &&
        <div className='text-center'>
          <Spacer value={30} />
          <Label size='h6'>
            Your processing has started, this may take several minutes.
          </Label>
          <Label size='h6'>
            Please check back later. You may have to refresh the page to see results
          </Label>
        </div>
      }
      {careCase.status === 'processing complete' &&
        <div>
          {renderReportTabs()}
        </div>
      }
    </div >
  );
};

Analysis.defaultProps = {
};

Analysis.displayName = 'Analysis';
export default Analysis;
