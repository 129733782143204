import React from 'react';
import classnames from 'classnames';
import './icon-button.scss';
import { IconType } from 'react-icons';
import { AiFillPlusCircle } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
import { BiRefresh } from 'react-icons/bi';
import { FcSettings } from 'react-icons/fc';

export interface IconButtonProps {
  icon?: string | null;
  customIcon?: React.ReactNode | null;
  filled?: boolean;
  onClick?: (() => void) | undefined;
  className?: string;
  color?: string;
  size?: number;
}

const getIconByName = (name: string): IconType | undefined => {
  const iconMap: { [key: string]: IconType } = {
    add: AiFillPlusCircle,
    refresh: BiRefresh,
    settings: FcSettings,
    close: IoClose,
  };

  return iconMap[name];
};

const IconButton = (props: IconButtonProps) => {
  const { icon, customIcon, filled, onClick, className, color, size } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const IconComponent = icon ? getIconByName(icon) : null;

  const classes = {
    'icon-btn': true,
    [`icon-btn__${filled}`]: true,
    [`icon-btn__${color}`]: true,
    [`${className}`]: true,
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={classnames(classes)}
    >
      {IconComponent ? <IconComponent size={size} /> : customIcon}
    </button>
  );
};

IconButton.defaultProps = {
  onClick: () => console.info('click'),
  icon: 'null',
  customIcon: 'null',
  className: 'my-icon-btn',
  color: 'ghost',
  filled: false,
  size: 16,
};

IconButton.displayName = 'IconButton';

export default IconButton;
