import React from 'react';

const FAQbody = [
  // {
  //   title: 'What if I forgot my password?',
  //   body: [
  //     'If you forgot your password or would like to change your password, follow the below steps:',
  //     '1. Go to “Sign In”.',
  //     '2. Select “Forgot Password”.',
  //     '3. Enter your email address that you used at the time of registration to receive a password reset code.',
  //     '4. Select “Submit”.',
  //     '5. Enter the code that was sent to your email that you provided and your new password.',
  //     '6.Select “Reset”.',
  //     'Remember that your user ID is the email address or cell phone number you used to register.',
  //   ],
  // },
  // {
  //   title: 'How can I change my account information?',
  //   body: [
  //     'If you would like to update your Profile, you can do so by selecting the circle with your initials at the top menu bar. Then follow the below steps:',
  //     '1. Edit the field you would like to change.',
  //     '2. Scroll to the bottom of the page and press the "Save Changes" button.',
  //   ],
  // },
  {
    title: 'Who do I contact to get support?',
    body: [
      'If you need immediate assistance, please contact the Upstream Vision team that works closely with Insightec team.',
      'You can connect with Pierre D\'Haese, PhD (pierre@upstream.vision), Lisa Dye (LisaD@insightec.com) or Gabriella Toltsis (GabriellaT@insightec.com),  or directly through whatsapp at https://chat.whatsapp.com/G4icNcFWaN15Aip565P2Sy',
    ],
  },
  {
    title: 'How do I print out the Terms and Conditions of Use/User Agreement, so I have a copy for my records?',
    body: [
      '1. Select “Terms of Use” on the bottom footer bar of any screen.',
      '2.Windows/PC Users: To print, while viewing the document, press Control key [Ctrl], and [P], on your keyboard.',
      '3. Mac Users: To print, while viewing the document, press Command key [CMD], and [P], on your keyboard.',
    ],
  },
  {
    title: 'What are the minimum system requirements for the web based version or the mobile app?',
    body: [
      'You must use a recent version of a modern web browser, such a Chrome or Safari (Mac). When using the mobile app, you must use a modern iPhone or Android device running a recent version. Unsupported versions may not be fully functional.',
    ],
  },
  {
    title: 'How do I print out the FAQ sheet?',
    body: [
      'Windows/PC Users: To print an FAQ sheet, while viewing the document, press Control key [Ctrl], and [P], on your keyboard.',
      'Mac Users: To print an FAQ sheet, while viewing the document, press Command key [CMD], and [P], on your keyboard.',
    ],
  },
];

export default FAQbody;