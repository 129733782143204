/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CopiedItem {
  userId: string;
  meta: object,
  fileUrl: string; // Add a property for the file URL
}

interface ClipboardState {
  copiedItem: CopiedItem | null;
}

const initialState: ClipboardState = {
  copiedItem: null,
};

const clipboardSlice = createSlice({
  name: 'clipboard',
  initialState,
  reducers: {
    copyItem: (state, action: PayloadAction<{ meta: any; userId: string; fileUrl: string }>) => {
      const { meta, userId, fileUrl } = action.payload;
      state.copiedItem = { meta, userId, fileUrl };
      console.info('Copied item:', meta, userId, fileUrl);
    },
    pasteItem: (state) => {
      // Logic to handle pasting the item
      // Access the copied item using state.copiedItem
      if (state.copiedItem) {
        const { meta, userId, fileUrl } = state.copiedItem;
        // Perform actions with the copied item, such as dispatching an action or updating the state
        console.info('Pasting item:', meta, userId, fileUrl);
      }
    },
    clearClipboard: (state) => {
      state.copiedItem = null;
    },
  },
});

export const { copyItem, pasteItem, clearClipboard } = clipboardSlice.actions;

export default clipboardSlice.reducer;
