import api from '../api';


export const surveysApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsersurveyStatsById: builder.query({
      query: (id) => ({ url: `/usersurvey/statsbyid/${id}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['SURVEY'],
    }),
    editSurvey: builder.mutation({
      query: (payload) => ({ url: `/usersurvey/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['SURVEY'],
    }),

  }),
});

export const { usePrefetch, useGetUsersurveyStatsByIdQuery, useEditSurveyMutation } = surveysApi;
