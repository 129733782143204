import { createContext } from 'react';

export interface Breadcrumb {
  label: string;
  path: string;
  state?: {
    [key: string]: any;
  };
}

interface BreadcrumbsContextType {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
}

export const BreadcrumbsContext = createContext<BreadcrumbsContextType>({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
});

// import React, { createContext, useContext, useState } from 'react';
// import { useLocation } from 'react-router-dom';

// interface BreadcrumbItem {
//   label: string;
//   path: string;
//   state?: {
//     [key: string]: any;
//   };
// }

// interface BreadcrumbContextValue {
//   breadcrumbs: BreadcrumbItem[];
//   addBreadcrumb: (breadcrumb: BreadcrumbItem) => void;
// }

// const BreadcrumbContext = createContext<BreadcrumbContextValue>({
//   breadcrumbs: [],
//   addBreadcrumb: () => {},
// });

// export const useBreadcrumbContext = () => useContext(BreadcrumbContext);

// export const BreadcrumbProvider: React.FC<{ children: React.ReactNode }> = ({
//   children,
// }) => {
//   const location = useLocation();
//   const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

//   const addBreadcrumb = (breadcrumb: BreadcrumbItem) => {
//     const index = breadcrumbs.findIndex((b) => b.path === breadcrumb.path);
//     if (index === -1) {
//       setBreadcrumbs((prevBreadcrumbs) => [
//         ...prevBreadcrumbs,
//         {
//           ...breadcrumb,
//           path: breadcrumb.path,
//           state: breadcrumb.state,
//         },
//       ]);
//     } else {
//       setBreadcrumbs((prevBreadcrumbs) => [
//         ...prevBreadcrumbs.slice(0, index),
//         {
//           ...breadcrumb,
//           path: breadcrumb.path,
//           state: breadcrumb.state,
//         },
//       ]);
//     }
//   };

//   return (
//     <BreadcrumbContext.Provider value={{ breadcrumbs, addBreadcrumb }}>
//       {children}
//     </BreadcrumbContext.Provider>
//   );
// };