/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect } from 'react';

type Timer = ReturnType<typeof setTimeout>;
type SomeFunction = (...args: any[]) => void;
/**
 *
 * @param func The original, non debounced function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */

const useDebounce: any = (
  callback: SomeFunction,
  delay = 1000,
) => {
  const timer = useRef<Timer>();

  useEffect(() => () => {
    if (!timer.current) return;
    clearTimeout(timer.current);
  }, []);

  const debouncedFunction = ((...args: any) => {
    const newTimer = setTimeout(() => {
      callback(...args);
    }, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  });

  return debouncedFunction;
};

export default useDebounce;