import QueryString from 'qs';
import api from '../api';


export const metApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getparticipantTumors: builder.query({
      query: (payload) => ({ url: `/tumor/statsbyid/${payload.participantId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['TUMORS'],
    }),
    editTumor: builder.mutation({
      query: (payload) => ({ url: `/tumor/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['TUMORS'],
    }),
    addMET: builder.mutation({
      query: (payload) => ({ url: '/tumor', method: 'POST', body: payload }),
      invalidatesTags: ['TUMORS'],
    }),
    addTumorAs: builder.mutation({
      query: (payload) => ({ url: '/tumorassessment', method: 'POST', body: payload }),
      invalidatesTags: ['TUMORS'],
    }),
    editTumorAs: builder.mutation({
      query: (payload) => ({ url: `/tumorassessment/${payload.tumorassessmentId}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['TUMORS'],
    }),
    removeTumor: builder.mutation({
      query: (id) => ({ url: `/tumor/${id}`, method: 'DELETE' }),
      invalidatesTags: ['TUMORS'],
    }),
  }),
});

export const {  
  usePrefetch,
  useGetparticipantTumorsQuery, 
  useAddMETMutation, 
  useAddTumorAsMutation, 
  useEditTumorAsMutation, 
  useEditTumorMutation,
  useRemoveTumorMutation, 
} = metApi;
