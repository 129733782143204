import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BsDownload } from 'react-icons/bs';
import { BiRefresh } from 'react-icons/bi';
import { useGetDocumentsQuery, participantFilesApi, useDownLoadFileMutation } from '../../../api/builders/participantFiles.api';
import { useCreateLogMutation } from '../../../api/builders/logs.api';
import { Label, Tile, Alert, Text, Modal, Spacer, Button } from '../../atoms';
import './documents-drive.scss';

export interface DocumentsDriveProps {
  protocolId: string;
}

const DocumentsDrive = (props: DocumentsDriveProps) => {
  const { protocolId } = props;
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetDocumentsQuery(protocolId !== null ? protocolId : skipToken);
  const { refetch } =
    participantFilesApi.endpoints.getDocuments.useQuerySubscription(protocolId !== null ? protocolId : skipToken);
  const [snack, setSnack] = React.useState({ open: false, message: '' });
  const [downloadlink, setLink] = React.useState('');
  const [previewWindow, setPreviewWindow] = React.useState(false);
  const [download] = useDownLoadFileMutation();
  const [addLog] = useCreateLogMutation();


  React.useEffect(() => {
    refetch();
  }, [protocolId]);

  const closePreview = () => {
    setLink('');
    setPreviewWindow(false);
  };

  const openPreview = (link: string, event: any) => {
    event.preventDefault();
    setLink(link);
    setPreviewWindow(true);
  };


  const getFileName = (fileName: string) => {
    try {
      const path = fileName.split('/');
      // if (path[path.length - 1].length > 50) {
      //   return `${path[path.length - 1].substring(0, 100)} ...`;
      // }
      return path[path.length - 1];
    } catch (e) {
      console.error(e);
      return 'File';
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({ open: false, message: '' });
  };

  const logDownload = (item: any) => {
    try {
      const logPl = {
        itemId: protocolId,
        itemLabel: item.id,
        actionType: 'Downloaded File',
      };
      addLog(logPl);
    } catch (e) {
      console.error('CREATE LOG ER', e);
    }
  };


  const onDownload = async (item: any) => {
    // setSnack({ open: true, message: 'Preparing your file. Please wait.' });
    // const downloadPayload = { files: [{ s3_path: item.coldKey }] };
    // const yourbrandnewfile = await download(downloadPayload).unwrap();
    // setLink(item.backgroundUrl);
    // const link = document.createElement('a');
    // link.href = item.backgroundUrl;
    // link.rel = 'noreferrer';
    // document.body.appendChild(link);
    // link.click();
    // logDownload(item);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', item.backgroundUrl, true);
    xhr.responseType = 'blob';

    xhr.onload = () => {
      // Create a temporary anchor link to download the file
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(xhr.response);
      link.download = getFileName(item.hotKey);
      document.body.appendChild(link);
      link.click();

      // Clean up the temporary link and object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    };

    xhr.send();
    logDownload(item);
  };

  return (
    <div className="card">
      <Spacer value={15} />
      {Array.isArray(data) && data.length > 0 &&
        <div>
          {/* <div className='document-drive__header'>
            <Label uppercase size="h6">
              Previous Uploads
            </Label>
            <Button onClick={refetch}>refresh</Button>
          </div> */}
          {data?.map((file: any) => (
            <div className='file__item' key={file.id}>
              <div className='file__btn' role='button' tabIndex={-1}>
                {/* <div className='file__btn' role='button' tabIndex={-1} onClick={(e) => openPreview(file.backgroundUrl, e)}> */}
                <Label uppercase size="h6">
                  📂 {getFileName(file.hotKey)}
                </Label>
              </div>
              <button type='button' aria-label='btn' onClick={() => onDownload(file)} className='icon-btn'><BsDownload size={12} /></button>
            </div>
          ))}
        </div>
      }
      <Alert onClose={handleClose} severity="info" open={snack.open} message=' The file is getting prepared and will start downloading shortly.' />

      {/* <Modal title='Preview File' open={previewWindow} onClose={closePreview}>
        <div>
          <iframe width="100%" height="600" title='file-preview' src={downloadlink} />

        </div>
      </Modal> */}
    </div>
  );
};

DocumentsDrive.defaultProps = {

};

DocumentsDrive.displayName = 'DocumentsDrive';
export default DocumentsDrive;
