/* eslint-disable react/no-array-index-key */
import React from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Label, Spacer } from '../../components';
import FAQbody from './FAQbody';

const FAQ = () => {
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='faq'>
      <Label size='h2'>Frequently asked questions</Label>
      <Spacer value={20}/>
      {FAQbody.map((el, index) => (
        <Accordion key={`faq-${index}`} expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionSummary
            expandIcon={<MdOutlineExpandMore />}
          >
            <Label size="h4">
              {el.title}
            </Label>
          </AccordionSummary>
          {el.body.map((el2, index2) => (
            <AccordionDetails key={`faq-detail-${index2}`}>
              <div className="help__faq-text">
                <Label>
                  {el2}
                </Label>
              </div>
            </AccordionDetails>
          ))}
        </Accordion>),
      )
      }
    </div>
  );
};

export default FAQ;
