import React from 'react';
import classnames from 'classnames';
import './badge.scss';
import Label from '../Label/Label';

export interface BadgeProps {
  label?: string,
  color: string,
}

const Badge = (props: BadgeProps) => {
  const { label, color } = props;

  const classes = {
    'badge': true,
    [`badge-${color}`]: true,
  };

  return (
    <div className={classnames(classes)}>
      <Label size="h6" weight={700} color='white' uppercase >{label}</Label>
    </div>
  );
};

Badge.defaultProps = {
  label: 'unknown',
};

Badge.displayName = 'Badge';


export default Badge;
