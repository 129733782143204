import React from 'react';
import classnames from 'classnames';
import './tab.scss';

export interface TabProps {
  children: JSX.Element | Array<JSX.Element>,
  title: string,
}

const Tab = (props: TabProps): JSX.Element => {
  // const { id, index, active, disabled, label, handleTabClick, handleViewChange } = props;
  const { children, title } = props;

  // const handleClick = () => {
  //   if (disabled) return;
  //   handleTabClick(index);
  //   handleViewChange(label);
  // };

  // const tabClasses = classnames('tab', {
  //   'tab--active': active,
  //   'tab--disabled': disabled,
  // });

  return (
    <div className='tab'>{children}</div>
    // <li className="tab__wrapper" id={id}>
    //   <button type='button' role="tab" className={tabClasses} onClick={handleClick}>
    //     <span className="tab__text">{label}</span>
    //   </button>
    // </li>
  );
};

Tab.defaultProps = {
};

Tab.displayName = 'Tab';
export default Tab;
