import React from 'react';
// import classnames from 'classnames';
import './cohort-item.scss';
import { useSelector } from 'react-redux';
import { BsCircleFill, BsTrash } from 'react-icons/bs';
import { AiFillClockCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaStar } from 'react-icons/fa';
import { selectAuth } from '../../../store/authentication/slice';
import DeleteVerification from '../DeleteVerification/DeleteVerification';
import { Tile, Spacer, Label, Button, Modal, Badge, Text } from '../../atoms';
import { useDeleteCohortMutation } from '../../../api/builders/cohorts.api';

export interface CohortItemProps {
  item: Item,
}

export interface Item {
  id: string,
  acl: string | null,
  description: string | null,
  shared: boolean,
  numberOfPatients: number,
  cohortLabel: string,
  updatedAt: number
  type: string
}


const CohortItem = (props: CohortItemProps): JSX.Element => {
  const { item } = props;
  const { id, cohortLabel, shared, numberOfPatients, description, updatedAt, type } = item;
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteCohort, { isLoading }] = useDeleteCohortMutation();
  const { groups } = useSelector(selectAuth);


  const closeDeleteMetModal = () => {
    setOpenDelete(false);
  };
  const openDeleteMetModal = () => {
    setOpenDelete(true);
  };


  const onOpen = (_item: any) => {
    if (type === 'master') {
      navigate('/private/cohorts/item', { state: { cohort: item } });
    } else {
      navigate('/private/cohorts/shared', { state: { cohort: item } });
    }
  };

  const handleDelete = () => {
    try {
      const payload = {
        id,
        body: {
          cohortLabel,
        },
      };
      deleteCohort(payload);
    } catch (e) {
      console.error('err deleting cohort', e);
    }
  };

  return (
    <div className='cohort-item__wrapper' >
      <Tile>
        <div className='cohort-item__body'>
          <div className='cohort-item__outer-wrapper'>
            <div role='button' className='cohort-item__body' tabIndex={-1} onClick={onOpen}>
              <Label size="h4" weight={600} uppercase >{cohortLabel}</Label>
              <Spacer value={10} />
              <Text size='sm'>{description}</Text>
              <Spacer value={20} />
              <div className='cohort-item__footer'>

                <BsCircleFill size={10} className={type === 'master' ? 'col-3' : 'col-2'} />
                <Spacer value={5} />
                <Label size="subtext" weight={600} center>{type}</Label>

                <Spacer value={10} />

                <AiFillClockCircle size={10} className='col-1' />
                <Spacer value={5} />
                <Label size="subtext" center>Last Updated On {moment(updatedAt).format('L')}</Label>
                
              </div>
            </div>
            <span className='cohort-item__trash-wrapper'>
              {groups.includes('ADMIN') &&
                <button type='button' className='icon-btn' onClick={openDeleteMetModal} aria-label='refetch'><BsTrash /></button>
              }
            </span>
          </div>

        </div>
      </Tile>


      <Modal size="sm" open={openDelete} onClose={closeDeleteMetModal} title="Delete Cohort">
        <DeleteVerification
          loading={isLoading}
          item={item}
          name='Cohort'
          onCancel={closeDeleteMetModal}
          onDelete={handleDelete}
        />
      </Modal>
    </div>
  );
};

CohortItem.defaultProps = {
};

CohortItem.displayName = 'CohortItem';
export default CohortItem;
