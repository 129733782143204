import React from 'react';
// import classnames from 'classnames';
import './documents-navigator.scss';

export interface DocumentsNavigatorProps  {
  id: string;
}

const DocumentsNavigator = (props: DocumentsNavigatorProps) => {
  const { id } = props;
  return (
        <div key={`document_nav_${id}`} className="card">
        DocumentsNavigator
        </div>
  ); 
};

DocumentsNavigator.defaultProps = {
};

DocumentsNavigator.displayName = 'DocumentsNavigator';


export default DocumentsNavigator;
