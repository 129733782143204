import React, { useState, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import './tabs.scss';

type TitleProps = {
  title: string,
  active: boolean,
  index: number,
  setSelectedTab: (index: number) => void
};

const TabTitle = (props: TitleProps): JSX.Element => {
  const { title, setSelectedTab, index, active } = props;

  const classes = classnames('tab-title', {
    'tab-title__selected': active,
  });

  return (
    <li className='tab-titles'>
      <button className={classes} type='button' onClick={() => setSelectedTab(index)}>{title}</button>
    </li>
  );
};


type Props = {
  children: any
};

const Tabs: React.FC<Props> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      <ul className='tabs__headers'>
        {children.map((item: any, index: number) => (
          <TabTitle
            key={item.props.title}
            title={item.props.title}
            active={selectedTab === index}
            index={index}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </ul>
      {children[selectedTab]}
    </div>
  );
};

export default Tabs;
