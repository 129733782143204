import React from 'react';
import { BsCheckSquareFill, BsSquare } from 'react-icons/bs';

import './checkbox.scss';
import { FormControlLabel, Checkbox } from '@mui/material';

export interface CheckboxProps {
  checked: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  label?: string,
  children?: JSX.Element;
}

const CustomCheckbox = (props: CheckboxProps) => {
  const { checked, onChange, label, children } = props;

  
  return (
    <form className="checkbox">
      <FormControlLabel
        control={
          <Checkbox
          icon={<BsSquare className='checkbox__icon' size={20} />} checkedIcon={<BsCheckSquareFill className='checkbox__icon' size={20} />}
            className="checkbox"
            color="secondary"
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            style={{
              transform: 'scale(.75)',
            }}
          />
        }
        label={children}
      />
    </form>
  );
};

CustomCheckbox.defaultProps = {
  children: <div />,
  label: '',
};

CustomCheckbox.displayName = 'Checkbox';


export default CustomCheckbox;


// import React from "react"
// import PropTypes from 'prop-types'
// import { FormControlLabel, Checkbox } from '@mui/material';
// import './checkbox.scss'

// interface CheckboxProps {
//         checked: boolean,
//         onChange: () => void,
//         label: string,
//         children?: JSX.Element;
// }

// export default function CustomCheckbox({ checked, onChange, label, children  }: CheckboxProps) {
//         return (
//                 <form className="checkbox">
//                         <FormControlLabel
//                                 control={
//                                         <Checkbox
//                                                 className="checkbox"
//                                                 color="default"
//                                                 checked={checked}
//                                                 onChange={onChange}
//                                                 inputProps={{ 'aria-label': 'decorative checkbox' }}
//                                                 style={{
//                                                         transform: "scale(.75)",
//                                                 }}
//                                         />
//                                 }
//                                 label={label}
//                         />
//                         {children}
//                 </form>
//         )
// }

// CustomCheckbox.propTypes = {
//         children: PropTypes.element,
//         checked: PropTypes.bool,
//         onChange: PropTypes.func,
//         label: PropTypes.string
// }

// CustomCheckbox.defaultProps = {

// }