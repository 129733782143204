import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import { FaCheck, FaEllipsisH } from 'react-icons/fa';
import { TfiTime } from 'react-icons/tfi';
import { Tile, Spacer, Label, Modal, Checkbox } from '../../atoms';
import './registry-patient.scss';

export interface RegistryPatientProps {
  className?: string,
  patient: any
}

interface Step {
  completionDate: string;
  description: string;
  id: string;
  isFirst: boolean;
  isLast: boolean;
  label: string;
  nextSteps: { toStep: string; condition: string }[];
  order: number;
  patientStatus: string;
  relativeStartDay: string;
  relativeStartDayVariance: string;
  scheduledDate: string;
  stepLabel: string;
  type: string;
  minDate: number;
  maxDate: number;
  targetDate: number;
}

interface PatientStatusTrackerProps {
  steps: Step[];
}
interface LabelRenderProps {
  _steps: Step[];
}

function findActiveStepIndex(steps: Step[]): number {
  const pendingSteps = steps.filter(step => step.patientStatus === 'pending');
  const startedSteps = steps.filter(step => step.patientStatus === 'started');
  const completedSteps = steps.filter(step => step.patientStatus === 'COMPLETED');

  if (pendingSteps.length > 0 || startedSteps.length > 0) {
    const activeStep = pendingSteps[0] || startedSteps[0];
    return steps.findIndex(step => step.id === activeStep.id);
  }

  if (completedSteps.length > 0) {
    const sortedCompletedSteps = completedSteps.sort((a, b) =>
      a.completionDate.localeCompare(b.completionDate),
    );
    const lastCompletedStep = sortedCompletedSteps[sortedCompletedSteps.length - 1];
    const index = steps.findIndex(step => step.id === lastCompletedStep.id);
    return index + 1;
  }

  const orderOneStep = steps.find(step => step.order === 1);
  return orderOneStep ? steps.findIndex(step => step.id === orderOneStep.id) : 0;
}



const PatientStatusTracker: React.FC<PatientStatusTrackerProps> = ({ steps }) => {
  const completedSteps = steps.filter((step) => step.patientStatus === 'COMPLETED');

  const lastActiveIndex = findActiveStepIndex(steps);
  // If the lastActiveIndex is within the last 10 items in the steps array, use it as the activeStepIndex
  const activeStepIndex = lastActiveIndex >= steps.length - 10 ? lastActiveIndex : completedSteps.length;

  // Determine the starting index for the clip
  const clipStartIndex = Math.max(0, activeStepIndex - 9);
  // Determine the ending index for the clip
  const clipEndIndex = Math.min(steps.length - 1, activeStepIndex + 9);

  // Clip the steps array based on the calculated start and end indices
  const clippedSteps = steps.slice(clipStartIndex, clipEndIndex + 1);

  const renderStatus = (itemStatus: string, isLastItem: boolean) => {
    // REGISTRYTODO::: add the custom chair time icon where relevant in place of FaCheck, FaEllipsisH, or TfiTime
    switch (itemStatus) {
      case 'COMPLETED':
        return (
          <>
            <span className='status-tracker-success__wrapper'>
              <FaCheck size={16} className='status-tracker-success__icon' />
            </span>
            {!isLastItem && <div className='status-tracker-divider-success' />}
          </>
        );
      case 'in progress':
        return (
          <>
            <span className='status-tracker-inprogress__wrapper'>
              <FaEllipsisH size={16} className='status-tracker-inprogress__icon' />
            </span>
            {!isLastItem && <div className='status-tracker-divider' />}
          </>
        );
      default:
        return (
          <span className='status-tracker-item '>
            <span className='status-tracker-pending__wrapper'>
              <TfiTime size={35} className='status-tracker-pending__icon' />
            </span>
            {!isLastItem && <div className='status-tracker-divider' />}
          </span>
        );
    }
  };

  return (
    <td >
      <span className='status-tracker-item '>
        {/* {steps.map((step, index) => (
          <span className='status-tracker-item '
            key={step.id}
          >
            {renderStatus(
              index < activeStepIndex ? 'COMPLETED' : index === activeStepIndex ? 'in progress' : '',
              index === steps.length - 1,
            )}
          </span>
        ))} */}
        {clippedSteps.map((step, index) => (
          <span className='status-tracker-item' key={step.id}>
            {renderStatus(
              // eslint-disable-next-line no-nested-ternary
              index < activeStepIndex ? 'COMPLETED' : index === activeStepIndex ? 'in progress' : '',
              index === clippedSteps.length - 1,
            )}
          </span>
        ))}
      </span>
    </td>
  );
};



const RegistryPatient = (props: RegistryPatientProps): JSX.Element => {
  const navigate = useNavigate();
  const { patient } = props;
  const { mrn } = patient;

  const onOpen = () => {
    navigate('/private/registries/registry/patient', { state: { patient } });
  };


  // eslint-disable-next-line react/no-unstable-nested-components
  const FindActiveStepInfo: React.FC<LabelRenderProps> = ({ _steps }) => {
    const completedStepss = _steps.filter((step: Step) => step.patientStatus === 'COMPLETED');

    if (completedStepss.length === 0) {
      // If no steps are completed, the first step is considered active
      return (
        <td className='registry-patient__tracker-label'>

          <Label size='h5' center weight={200}> N/A</Label>
          {/* <Label size='h5' center>  {_steps[0].description}</Label> */}
          {/* <Label size='subtext' color='danger' center><span className='bold'>Due By:</span> {moment(_steps[0].maxDate).format('L')}</Label> */}
        </td>
      );
    }

    const lastCompletedStep = completedStepss[completedStepss.length - 1];
    const activeStepIndexx = lastCompletedStep.order + 1;

    if (activeStepIndexx >= _steps.length) {
      // If the active step index is out of range, there is no active step
      return (<td><Label size='h5' center>N/A</Label></td>);
    }

    return (
      <td >
        <span className='d-flex'>
          {_steps[activeStepIndexx - 1].completionDate && <Label size='h5' weight={200} center>{moment(_steps[activeStepIndexx].maxDate).format('L')}</Label>}
        </span>
      </td>
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
    <tr role='button' tabIndex={-1} onClick={onOpen} className='care-pathway-tracker__table__row'    >
      <td >
        <Label size="h5" weight={200} center>{mrn}</Label>
      </td>
      <td >
        <Label size="h5" weight={200} center> {(Array.isArray(patient.patientcarepathway) && patient.patientcarepathway.length > 0) ? patient.patientcarepathway[0].carepathway.name : 'None'}</Label>
      </td>
      {(Array.isArray(patient.patientcarepathway) && patient.patientcarepathway.length > 0) ? <FindActiveStepInfo _steps={patient.patientcarepathway[0].carepathway.steps} /> : <td>  <Label size="h5" weight={200} center> None </Label> </td>}
      {(Array.isArray(patient.patientcarepathway) && patient.patientcarepathway.length > 0) ? <PatientStatusTracker steps={patient.patientcarepathway[0].carepathway.steps} /> : <td>  <Label size="h5" weight={200} center> None </Label> </td>}
      {/* <FindActiveStepInfo _steps={patient.patientcarepathway[0].carepathway.steps} />
      <PatientStatusTracker steps={patient.patientcarepathway[0].carepathway.steps} /> */}
    </tr>
  );
};

RegistryPatient.defaultProps = {
  className: '',
};

RegistryPatient.displayName = 'RegistryPatient';
export default RegistryPatient;



