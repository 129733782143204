/* eslint-disable */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import api from '../api/api';
import authenticationReducer from './authentication/slice';
// import uploaderReducer from './uploader/index';
import intlReducer from './intl/index';
import snapshotReducer from './snapshots/index';
import clipboardReducer from './clipboard/index';


const appReducer = combineReducers({
  authentication: authenticationReducer,
  intl: intlReducer,
  // uploader: uploaderReducer,
  snapshot: snapshotReducer,
  clipboard: clipboardReducer,
  [api.reducerPath]: api.reducer,
});


const rootReducer = (state: any, action: any) => {
  if (action.type === 'authentication/logout/pending' || action.type === 'authentication/logout/fulfilled') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(api.middleware),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
