import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import './met-baseline-mri.scss';
import { Label, Button, Spacer, Dropdown, Text, Spinner } from '../../atoms';
import { useGetAllVisitImagesQuery } from '../../../api/builders/participantFiles.api';
import { useEditProtocolMutation } from '../../../api/builders/participantProtocol.api';


export interface MetBaselineMRIProps {
  visitId: string | undefined,
  className?: string,
  participant: string | undefined,
  baselineMRI: any,
  role: string,
}
interface SerieInterface {
  id: string;
  SeriesInstanceUID: string;
}
interface StudyInterface {
  id: string;
  series: Array<SerieInterface> | null;
}

interface StudyFilesReducerStateInterface {
  tree: Array<StudyInterface> | [] | null;
  loading: boolean;
  loaded: boolean;
}



const MetBaselineMRI = (props: MetBaselineMRIProps): JSX.Element => {
  const { visitId, participant, baselineMRI, role } = props;
  const [updateImage, { isLoading: isEdititng }] = useEditProtocolMutation();
  const { data, isLoading: isLoadingData } = useGetAllVisitImagesQuery(visitId);
  const [selected, setSelected] = React.useState<any | null>(null);

  const submitItem = () => {
    const payload = {
      id: participant,
      body: {
        baeslineMriId: selected.ScanSeries[0].id,
      },
    };
    updateImage(payload);
  };


  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelected(event.target.value);
  };

  return (
    <div className="card">
      <div className='border-heading'>
        
        <Label size='h4' weight={600}>Baseline MRI:</Label>
      </div>
      <Spacer value={5} />
      {(isEdititng || isLoadingData) ?
        <div className='w-100 h-100 super-centered'>
          <Spinner />
        </div> :
        <div>
          {baselineMRI !== 'null' && baselineMRI !== 'undefined' ?
            <div>
              <Spacer value={10} />
              <Text>{baselineMRI.Modality} {' '}{baselineMRI.SeriesDescription}</Text>
            </div>
            :
            <div>
              {data && data?.length > 0  && role === 'SITE COORDINATOR' ?
                <div>
                  <Dropdown
                    name='Select Baseline'
                    label='Select Baseline'
                    value={selected}
                    onChange={handleChange}
                  >
                    {data.map((it: any) => <MenuItem value={it} >{`${it.ScanSeries[0].Modality} ${it.ScanSeries[0].SeriesDescription} `} </MenuItem>)}
                  </Dropdown>
                  <Spacer value={10} />
                  <div className='btn-row'>
                    <Button onClick={submitItem}>Save</Button>
                  </div>
                </div>
                :
                <div className='w-100 h-100 super-centered'><Text>No Options </Text></div>
              }
            </div>
          }
        </div>
      }


    </div>
  );
};

MetBaselineMRI.defaultProps = {
  className: '',
};

MetBaselineMRI.displayName = 'MetBaselineMRI';
export default MetBaselineMRI;
