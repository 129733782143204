import React from 'react';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';
// import classnames from 'classnames';
import './spinner.scss';

type Props = {
  size?: number;
  value?: number;
};

const Spinner = ({ size, value }: Props) => {
  if (value) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: '#1a72f4',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={size}
        thickness={4}
      />
    </Box>
  );
};
Spinner.defaultProps = {
  value: 0,
  size: 24,
};
export default Spinner;
