/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import classnames from 'classnames';
import './participant-list-item.scss';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { RiErrorWarningLine } from 'react-icons/ri';
import { BsClipboardCheck } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Tile, Spacer, Label, Button, Modal, Badge, Dropdown } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import ConsentManager from '../ConsentManager/ConsentManager';
import ParticipantFactory from './ParticipantFactory';

export interface ParticipantListItemProps {
  participant: ParticipantItem,
  prefix: string,
  sitePath: string,
  binderPath: string,
  convertCandidate: (payload: { id: string, participantId: string }) => void,
  onUpdateConsent: (payload: { id: string, status: string }) => void,
  onUpdateTx: (payload: { id: string, treatment: string }) => void,
  acl: string,
  siteId: string,
  treatments: any[],
}

type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  type: string | undefined;
  id: string;
  consentStatus: string;
  group?: string,
};

type StudyItemProps = {
  studyId?: string | undefined;
  summary?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  acl?: string | undefined;
};



const ParticipantListItem = (props: ParticipantListItemProps) => {
  const { participant, convertCandidate, prefix, sitePath, onUpdateConsent, binderPath, onUpdateTx, treatments, siteId } = props;
  const { id, type, participantId, screeningId, consentStatus, group } = participant;
  const [consentModal, setConsentModal] = React.useState(false);
  const [txModal, setTxModal] = React.useState(false);
  const [newTx, setNewTx] = React.useState(group && group !== 'null' ? group : '');



  const handleOpenTx = () => {
    setTxModal(true);
  };

  const handleSubmitTx = async () => {
    const payload = {
      id: participant.id,
      treatment: newTx,
    };
    await onUpdateTx(payload);
    setTxModal(false);
  };

  const handleChangeTx = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewTx(event.target.value);
  };

  const onCloseConsent = () => {
    setConsentModal(false);
  };


  return (
    <div
      key={id}
      className="participant-list-item">

      {/* {switchType(type)} */}
      <ParticipantFactory {...props} siteId={siteId} consentModal={consentModal} txModal={txModal} setTxModal={setTxModal} setConsentModal={setConsentModal} />
      <ConsentManager
        participant={participant}
        binderPath={binderPath}
        onOpen={consentModal}
        onClose={onCloseConsent}
        onUpdateConsent={onUpdateConsent}
      />
      <Modal open={txModal} size='sm' onClose={() => setTxModal(false)} title='Edit Treatment'>
        <div>
          <Dropdown
            name='treatment'
            label='Select New Treatment'
            value={newTx}
            onChange={handleChangeTx}
          >
            {Array.isArray(treatments) && treatments.length > 0 ?
              treatments.map((item: any) => (
                <MenuItem value={item.label}>{item.label}</MenuItem>
              ))
              :
              <MenuItem value="">No options</MenuItem>
            }
          </Dropdown>
          <Spacer value={15} />
          <div className='btn-row'>
            <Button onClick={() => setTxModal(false)}>
              Cancel
            </Button>
            <Button disabled={!treatments || treatments.length < 1} onClick={handleSubmitTx}>
              Save Changes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ParticipantListItem.defaultProps = {
  // key: '_key',
};

ParticipantListItem.displayName = 'ParticipantListItem';


export default ParticipantListItem;
