/* eslint-disable  */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import { CornerstoneEvent } from 'cornerstone-tools';

import Hammer from 'hammerjs';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import * as dicomParser from 'dicom-parser';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

import { Spinner, Label, CustomAnimation } from '../../atoms';
import './dicom-viewer.scss';
import { useGetColdFileArrayQuery } from '../../../api/builders/participantFiles.api';
import CustomProgress from '../../atoms/CustomProgress/CustomProgress';
import { LinearProgress } from '@mui/material';
import ViewerToolBar from '../ViewerToolBar/ViewerToolBar';
import MemoizedViewerToolBar from '../ViewerToolBar/ViewerToolBar';
import { red } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Snapshot, listSnapshots, registerSnapshot, saveSnapshot } from '../../../store/snapshots';

export interface DicomViewerProps {
  viewerId: string;
  className: string | '';
  series: any;
  prefix?: string;
}

// configure cornerstone tools
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;

// cornerstoneWADOImageLoader.webWorkerManager.initialize({
//   maxWebWorkers: navigator.hardwareConcurrency || 1,
//   startWebWorkersOnDemand: true,
// });

cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

// interface ImageMetadata {
//   instanceNumber: number;
//   imageId: string;
// }

interface Provider {
  name: string;
  test: () => void;
  getMetadata: (type: string, data: any) => any;
  priority: number;
}

export interface IStack {
  currentImageIndex: number;
  currentImageIdIndex: number;
  imageIds: string[];
}

const initTools = (element: HTMLElement) => {
  cornerstoneTools.addStackStateManager(element, ['stack']);
  cornerstoneTools.addTool(cornerstoneTools.WwwcTool);
  cornerstoneTools.addTool(cornerstoneTools.LengthTool);
  cornerstoneTools.addTool(cornerstoneTools.PanTool);
  cornerstoneTools.addTool(cornerstoneTools.MagnifyTool);
  cornerstoneTools.addTool(cornerstoneTools.WwwcRegionTool);
  cornerstoneTools.addTool(cornerstoneTools.ScaleOverlayTool);
  cornerstoneTools.addTool(cornerstoneTools.BidirectionalTool);
  cornerstoneTools.addTool(cornerstoneTools.ArrowAnnotateTool);
  cornerstoneTools.addTool(cornerstoneTools.AngleTool);
  cornerstoneTools.addTool(cornerstoneTools.RectangleRoiTool);
  cornerstoneTools.addTool(cornerstoneTools.FreehandScissorsTool);
  cornerstoneTools.addTool(cornerstoneTools.TextMarkerTool, {
    configuration: {
      markers: ['MET-1', 'MET-2', 'MET-3', 'MET-4', 'MET-5'],
      current: 'MET-1',
      ascending: true,
      loop: false
    }
  });
  cornerstoneTools.addTool(cornerstoneTools.ZoomTool, {
    configuration: {
      invert: false,
      preventZoomOutsideImage: false,
      minScale: 0.1,
      maxScale: 20.0
    }
  });
};
const initStack: IStack = {
  currentImageIndex: 0,
  currentImageIdIndex: 0,
  imageIds: []
};

const LoadingProgress = React.memo((props: { progress: number }) => {
  const { progress } = props;
  const linearProgressStyles = {
    width: '100%',
    backgroundColor: 'red'
  };
  return (
    <div className="loading_progress">
      <Box sx={{ width: '100%', display: 'flex', marginBottom: '2px' }}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress className={'loading_progress2'} variant="determinate" value={progress} />
        </Box>
      </Box>
    </div>
  );
});

const extractPixelSpacing = (pixelSpacing: string): { xSpacing: number; ySpacing: number } | null => {
  const pattern = /(\d+\.\d+)/g;
  const matches = pixelSpacing.match(pattern);

  if (matches && matches.length >= 2) {
    const xSpacing = parseFloat(matches[0]);
    const ySpacing = parseFloat(matches[1]);

    return { xSpacing, ySpacing };
  }

  return null;
};

const DicomViewer = React.memo<DicomViewerProps>((props: DicomViewerProps) => {
  const viewerRef = useRef<HTMLDivElement>(null);
  const { series, viewerId, prefix, className } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [currentStack, setCurrentStack] = useState(initStack);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const dispatch = useDispatch<any>();

  const getPayload = (st: any) => {
    const variable = 'filler';
    return st.replace(/\./g, '_');
  };

  // const {
  //   data,
  //   isLoading: isLoadingData,
  //   isFetching: isFetchingData
  // } = useGetColdFileArrayQuery({ id: series ? getPayload(series.id) : skipToken });

  
  const {
    data,
    isLoading: isLoadingData,
    isFetching: isFetchingData
  } = useGetColdFileArrayQuery( series ? {id: getPayload(series.id), job:series?.registrationJobId, aligned:series?.alignment} : skipToken );
  
  
  useEffect(() => {
    cornerstoneTools.init({
      moduleName: 'globalConfiguration',
      configuration: {
        mouseEnabled: true,
        touchEnabled: true,
        showSVGCursors: true,
        globalToolSyncEnabled: true
      }
    });

    const element = document.getElementById(viewerId!);
    cornerstone.enable(element!);
    //// initTools(element!);
  }, []);

  useEffect(() => {
    if (viewerRef.current) {
      cornerstone.enable(viewerRef.current);

      const element = viewerRef.current;
      const targetElement = cornerstone.getEnabledElement(element);

      const mouseMoveHandler = (event: any) => {
        const coords = cornerstone.pageToPixel(targetElement as unknown as HTMLElement, event.detail.currentPoints.page[0], event.detail.currentPoints.page[1]);
        // console.log('Pointer coordinates:', coords);
      };

      // cornerstoneTools.mouseInput.enable(element);
      // cornerstoneTools.mouseInput.mouseMoveCallbacks.add(mouseMoveHandler);

      // return () => {
      //   cornerstoneTools.mouseInput.mouseMoveCallbacks.remove(mouseMoveHandler);
      // };
    }
  }, []);
  const handleMouseMove = (event: { currentTarget: any; }) => {
    const eventData = cornerstoneTools.getToolState(event.currentTarget, 'mouse');
    
    if (!eventData || eventData.length === 0) {
      return;
    }
    
    const { currentPoints } = eventData[0];
  
    // Access the mouse coordinates
    const { imageX, imageY } = currentPoints;
  
    // console.log('Mouse coordinates:', imageX, imageY);
  }
  useEffect(() => {
    if (data && data.Keys) {
      //cornerstone.imageCache.purgeCache();
      setLoading(true);
      setCurrentStack(initStack);
      let imageIds = data.Keys.map((file: any) => `wadouri:${file}`);
      let midFrame = Math.floor(imageIds.length / 2);
      const element = document.getElementById(viewerId!);
      if (element) {
        try {
          const defaultImg: any = imageIds[midFrame];
          cornerstone.loadAndCacheImage(defaultImg).then((image: cornerstone.Image) => {
            cornerstone.displayImage(element, image);
          });
        } catch (e) {
          console.error('ERROR DISPLAYING IMG', e);
        }
      }
      setLoading(false);
      const promises = imageIds.map((id: string) => cornerstone.loadAndCacheImage(id));
      Promise.all(
        promises.map((p: Promise<any>, i: number) =>
          p.then(() => setLoadingProgress(((i + 1) / promises.length) * 100))
        )
      )
        .then((images) => {
          setLoadingProgress(100);
        })
        .catch((error) => {
          console.error('Failed to load images', error);
        });
      
      var stack: IStack = {
        currentImageIdIndex: midFrame,
        currentImageIndex: midFrame,
        imageIds: imageIds
      };
      // console.log('STACK 2', stack);
      cornerstoneTools.addStackStateManager(element, ['stack'])
      cornerstoneTools.addToolState(element, 'stack', stack);
      element!.addEventListener('cornerstonetoolsmousedown', handleMouseMove);

      

      // const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool

      // cornerstoneTools.addTool(StackScrollMouseWheelTool)
      // cornerstoneTools.setToolActive('StackScrollMouseWheel', { })

      setCurrentStack(stack);
    }
  }, [data]);

  useEffect(() => {
    // Do something when the currentImageIndex changes
    const element = document.getElementById(viewerId!);
    setError(false);
    if (element && currentStack.imageIds.length > 0 && currentStack.currentImageIndex < currentStack.imageIds.length) {
      cornerstone.enable(element);
      try {
        cornerstone
          .loadAndCacheImage(currentStack.imageIds[currentStack.currentImageIndex])
          .then((image: cornerstone.Image) => {
            cornerstone.displayImage(element, image);
            cornerstoneTools.addStackStateManager(element, ['stack']);
            cornerstoneTools.addToolState(element, 'stack', currentStack);
          
          });
      } catch (e) {
        console.error('ERROR DISPLAYING IMG', e);
        setError(true);
      }
    }
  }, [currentStack]);

  useEffect(() => {
    const mouseMoveHandler = (event: any) => {
      const element = document.getElementById(viewerId!);
      if (element){
        const coords = cornerstone.pageToPixel(element, event.detail.currentPoints.page.x, event.detail.currentPoints.page.y);
          // console.log('Pointer coordinates:', coords);
        };
        element!.addEventListener('cornerstonetoolsmousemove', mouseMoveHandler);
      }

    

    return () => {
      const element = document.getElementById(viewerId!);
      if (element){
        element!.removeEventListener('cornerstonetoolsmousemove', mouseMoveHandler);
      }
    };


  }, []);
  
  useEffect(() => {
    // Fetch the snapshots from storage (e.g., S3) and dispatch the listSnapshots action
  }, [dispatch]);

      
  const handleChangeImage = useCallback((index: number) => {
    setCurrentStack((prevStack) => {
      return { ...prevStack, currentImageIndex: index };
    });
  }, []);

  const captureSnapshot = (snapshotName: string) => {
    const element = document.getElementById(viewerId); // Replace with your container ID
    const canvas = cornerstone.getEnabledElement(element!).canvas;
    const image = cornerstone.getEnabledElement(element!).image;
    if (image) {
      // Create a temporary canvas to draw the snapshot
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = canvas?.width!;
      tempCanvas.height = canvas?.height!;
      const tempContext = tempCanvas.getContext('2d');
      if (tempContext) {
        tempContext.drawImage(canvas!, 0, 0);

        // Draw overlay content on the temporary canvas
        // const overlayContent = `#${currentStack?.currentImageIndex}`;
        const overlayContent = `Slice ${currentStack?.currentImageIndex}\n${
          series.SeriesDescription
        }\nVox ${extractPixelSpacing(series.PixelSpacing)?.xSpacing.toFixed(2)} x ${extractPixelSpacing(
          series.PixelSpacing
        )?.ySpacing.toFixed(2)} x ${series.SliceThickness} mm`;

        tempContext.fillStyle = 'white';
        tempContext.font = '10px Arial';
        tempContext.fillText(overlayContent, 10, 20);

        // Draw the copyright banner
        const bannerHeight = 50; // Height of the banner in pixels
        tempContext.fillStyle = '#000'; // Color of the banner background
        tempContext.fillRect(0, tempCanvas.height - bannerHeight, tempCanvas.width, bannerHeight);

        const text = '2023 © Insightec';
        tempContext.font = '10px Arial'; // Font style for the text
        tempContext.fillStyle = '#fff'; // Color of the text
        tempContext.textBaseline = 'middle';
        tempContext.textAlign = 'center';
        tempContext.fillText(text, tempCanvas.width / 2, tempCanvas.height - bannerHeight / 2);
      }
      // Convert the snapshot to an image URL
      const imageURL = tempCanvas.toDataURL();

      // Create an anchor element to trigger the download
      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = `${snapshotName}.png`; // Specify the desired file name and format

      // Trigger the download programmatically
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Dispatch the saveSnapshot action
      const newSnapshot: Snapshot = {
        id: `${snapshotName}.png`, // Replace with a unique identifier
        userId: 'userId', // Replace with the actual user ID
        meta: {
          sliceId: currentStack?.currentImageIndex,
          seriesDesc: series.SeriesDescription
        }, // Add your desired meta information
        fileUrl: imageURL // Pass the file URL to the action
      };

      dispatch(registerSnapshot(newSnapshot));
    }
  };
  const captureDualSnapshot = (snapshotName: string) => {
    const element = document.getElementById(viewerId); // Replace with your container ID
    const canvas = cornerstone.getEnabledElement(element!).canvas;
    const image = cornerstone.getEnabledElement(element!).image;

    if (image) {
      // Create a temporary canvas to draw the snapshot
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = image.width! * 2; // Double the width to accommodate both panels side by side
      tempCanvas.height = image.height!;
      const tempContext = tempCanvas.getContext('2d');

      if (tempContext) {
        // Set the background color to black
        tempContext.fillStyle = '#000';
        tempContext.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
        // Draw the left panel without overlay
        tempContext.drawImage(canvas!, 0, 0);

        // Draw the right panel with overlay

        // const overlayContent = `#${currentStack?.currentImageIndex}`;
        const overlayContent = `#${currentStack?.currentImageIndex}\n${
          series.SeriesDescription
        }\nVox ${extractPixelSpacing(series.PixelSpacing)?.xSpacing.toFixed(2)} x ${extractPixelSpacing(
          series.PixelSpacing
        )?.ySpacing.toFixed(2)} x ${series.SliceThickness} mm`;

        const overlayFont = '12px Arial';
        const overlayX = tempCanvas.width / 2;
        const overlayY = 20;
        // Draw the canvas content on the right panel
        tempContext.drawImage(canvas!, image.width!, 0);

        tempContext.fillStyle = 'white';
        tempContext.font = overlayFont;
        tempContext.fillText(overlayContent, overlayX, overlayY);

        // Draw the copyright banner
        const bannerHeight = 50; // Height of the banner in pixels
        const bannerColor = '#000'; // Color of the banner background
        const textColor = '#fff'; // Color of the text
        const bannerText = '2023 © INSIGHTEC, INC';
        const textFont = '10px Arial';

        // Draw the banner on the bottom of the image
        tempContext.fillStyle = bannerColor;
        tempContext.fillRect(0, tempCanvas.height - bannerHeight, tempCanvas.width, bannerHeight);
        tempContext.font = textFont;
        tempContext.fillStyle = textColor;
        tempContext.textBaseline = 'middle';
        tempContext.textAlign = 'center';
        tempContext.fillText(bannerText, tempCanvas.width / 2, tempCanvas.height - bannerHeight / 2);

        // Convert the snapshot to an image URL
        const imageURL = tempCanvas.toDataURL();

        // Create an anchor element to trigger the download
        const anchor = document.createElement('a');
        anchor.href = imageURL;
        anchor.download = `${snapshotName}.png`; // Specify the desired file name and format

        // Trigger the download programmatically
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    }
  };

  return (
    <div className={`dicom-viewer--wrapper ${className}`}>
      <div className="dicom-viewer">
         <LoadingProgress progress={loadingProgress || 0} />
        {/* {(loading || isLoadingData || isFetchingData) && (
          <div className="dicom-viewer__loading-overlay">
            <CustomProgress />
          </div>
        )} */}
        <div className="dicom-viewer__body">
          <div className="dicom-viewer__body__overlay dicom-viewer__body__overlay-top-left">
              Slice {currentStack?.currentImageIndex}
            </div>
            <div className="dicom-viewer__body__overlay dicom-viewer__body__overlay-top-right">
              {series.SeriesDescription}
            </div>
            <div className="dicom-viewer__body__overlay dicom-viewer__body__overlay-bottom-left">
              Vox {extractPixelSpacing(series.PixelSpacing)?.xSpacing.toFixed(2)} x{' '}
              {extractPixelSpacing(series.PixelSpacing)?.ySpacing.toFixed(2)} x{' '}
              {parseFloat(series.SliceThickness).toFixed(2)} mm
            </div>
            <div className="dicom-viewer__body__overlay dicom-viewer__body__overlay-bottom-right">
            {series?.alignment ? 'Aligned' : ''}
          </div>
          <div  ref={viewerRef}  id={viewerId} style={{  }} className="dicom-viewer__body__viewer">
            {error && (
              <div className="loading-wrapper">
                <Label size="h3" color="danger">
                  Unable to load image
                </Label>{' '}
              </div>
            )}
          </div>
          </div>
          
          <div className="dicom-viewer__toolbar">
                {/* <MemoizedViewerToolBar */}
                <ViewerToolBar
                stack={currentStack}
                elementId={viewerId!}
                onCaptureSnapshot={() =>
                  captureSnapshot(`${prefix}.${series.SeriesDescription}.${currentStack?.currentImageIndex}`)
                }
              /> 
          </div>
          <div className="dicom-viewer__slider_wrapper">
              <input
                className="slider"
                style={{ width: '90%' }}
                type="range"
                min={0}
                max={currentStack?.imageIds.length - 1}
                value={currentStack?.currentImageIndex}
                onChange={(event) => handleChangeImage(parseInt(event.target.value))}
              />
              <span className="current-image-index">{currentStack?.currentImageIndex}</span>
        
          </div>
        </div>
    </div>
  );
});

DicomViewer.displayName = 'DicomViewer';
export default DicomViewer;
