import React from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { useNavigate, useLocation } from 'react-router-dom';
import './label.scss';

type Props = {
  children: any;
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'h8' | 'text' | 'subtext';
  color?: 'default' | 'white' | 'ghost' | 'primary' | 'secondary' | 'tertiary' | 'danger' | 'warning' | 'success';
  uppercase?: boolean;
  className?: string | void;
  weight?: number;
  italic?: boolean;
  justify?: boolean;
  center?: boolean;
  underline?: boolean;
  left?: boolean;
  right?: boolean;
  bold?: boolean;
};

const Label = ({ size, weight, center, bold, left, right, justify, children, color, className, uppercase, italic, underline }: Props) => {
  const classes = {
    'custom-label': true,
    [`custom-label--${size}`]: true,
    [`custom-label--${color}`]: true,
    [`custom-label--${weight}`]: true,
    'custom-label--uppercase': uppercase,
    'custom-label--italic': italic,
    'custom-label--justify': justify,
    'custom-label--center': center,
    'custom-label--right': right,
    'custom-label--left': left,
    'custom-label--underline': underline,
    'custom-label--bold': bold,
    [`${className}`]: true,
  };
  return (
        <div className={classnames(classes)}>
        {children}
        </div>
  ); 
};

Label.defaultProps = {
  size: 'test',
  color: 'default',
  uppercase: false,
  className: null,
  weight: 1,
  italic: false,
  underline: false,
  center: false,
  justify: false,
  left:false,
  right: false,
  bold:false,
};

export default Label;
