import React, { useState, useEffect } from 'react';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, Text, Spacer, Tile, Button, SheildIcon, Spinner } from '../../atoms';
import { useGetFindingByImageQuery, useUpdateImageReviewMutation } from '../../../api/builders/hives.api';
import './dicom-audit-action-bar.scss';

export interface DicomAuditActionBarProps {
  series: any,
  audit: any,
  readOnly: boolean,
  check: () => void,
}


const readableFields: { [key: string]: string } = {
  date: 'Date',
  imagePHI: 'PHI info imprinted in image',
  manufacturer: 'Manufacturer',
  mrn: 'MRN',
  name: 'NON-PHI Name',
  nonPHIimageText: 'Non-PHI info imprinted in IMAGE',
  other: 'Other non-PHI ID',
  phi: 'PHI',
  physician: 'Physician ID',
  site: 'Site ID',
  time: 'Time',
};

type AnswerOption = {
  label: string;
  value: string;
};

const answerOptions: AnswerOption[] = [
  { label: 'None', value: 'none' },
  { label: 'Minor, Not Pausing Sharing', value: 'minor' },
  { label: 'Major, Pause Sharing Only this Series', value: 'major-series' },
  { label: 'Major, Pause Sharing All Similar Series', value: 'major-similar-series' },
  { label: 'Global, Pause Sharing All', value: 'global' },
];

const getRiskLevel = (selectedAnswer: string) => {
  switch (selectedAnswer) {
    case 'none':
      return 1;
    case 'minor':
      return 1;
    case 'major-series':
      return 2;
    case 'major-similar-series':
      return 3;
    case 'global':
      return 4;
    default:
      return 0; // Invalid selected answer
  }
};

const getPayload = (st: any) => {
  const variable = 'filler';
  return st.replace(/\./g, '_');
};

const DicomAuditActionBar = (props: DicomAuditActionBarProps): JSX.Element => {
  const { series, audit, readOnly, check } = props;
  const { data: findings, isLoading: isLoadingData, isFetching: isFetchingData } = useGetFindingByImageQuery(series ? getPayload(series.id) : skipToken);
  const [submitReview, { isLoading: isEditing, isError: isErrorEditing, isSuccess: isSuccessEditing, error: editError }] = useUpdateImageReviewMutation();

  const [isRequestDeidentificationChecked, setIsRequestDeidentificationChecked] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState('');

  const handleSelectAnswer = (value: string) => {
    if (series.auditStatus === 'incomplete' && !readOnly) {
      setSelectedAnswer(value);
    }
  };

  useEffect(() => {
    if (series?.recommendation !== 'null') {
      setSelectedAnswer(series?.recommendation);
    } else {
      setSelectedAnswer('');
    }
  }, [series]);


  const handleRequestDeidentification = () => {
    setIsRequestDeidentificationChecked(!isRequestDeidentificationChecked);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderOptions = (selectedAnswer: string) => {
    const filler = 'somebsvar';
    return answerOptions.map((option) => {
      const Icon = selectedAnswer === option.value ? BsCheckCircle : BsCircle;
      const iconColor = selectedAnswer === option.value ? 'blue' : 'gray';
      return (
        <span key={option.value} role='button' tabIndex={-1} onClick={() => handleSelectAnswer(option.value)} className={selectedAnswer === option.value ? 'audit-action-bar__ops-item-selected' : 'audit-action-bar__ops-item'}>
          {/* <input
              type="radio"
              name="answer"
              value={option.value}
              checked={selectedAnswer === option.value}
              onChange={() => handleSelectAnswer(option.value)}
              style={{ display: 'none' }}
            />
            <Icon size={16} color={iconColor} className='radio-icon' /> */}
          {option.label}
        </span>
      );
    });
  };

  const submitImg = () => {
    try {
      check();
      const payload = {
        auditId: audit,
        body: {
          status: 'audited',
          riskLevel: getRiskLevel(selectedAnswer),
          recommendation: selectedAnswer,
          seriesId: series.id,
          deidentification: isRequestDeidentificationChecked,
        },
      };
      submitReview(payload);
    } catch (e) {
      console.error(e);
    }
  };

  const renderReadableFields = (data: any) => {
    const trueFields = Object.keys(readableFields).filter((field) => {
      const fieldData = data.find((item: any) => item[field]);
      return fieldData && fieldData[field] === true;
    });

    return trueFields.map((field) => (
      <Text key={field}>{readableFields[field]}</Text>
    ));
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Badge = (BadgeProps: any): JSX.Element => {
    const { risk } = BadgeProps;

    const getColor = (number: any) => {
      switch (true) {
        case number >= 1 && number <= 2:
          return 'finding-badge__green';
        case number >= 3 && number <= 7:
          return 'finding-badge__yellow';
        case number >= 8 && number <= 10:
          return 'finding-badge__red';
        default:
          return 'finding-badge__gray';
      }
    };


    const color = getColor(risk);

    return (
      <div className={`finding-badge  ${color}`}>
        <span className="finding-badge__badge-number">{risk}</span>
      </div>
    );
  };


  return (
    <div className='audit-action-bar'>

      <div className='audit-action-bar__panel'>
        <div className='audit-action-bar__header'>
          <span className='audit-dicom__text' > Findings:</span>
          {/* <Label size="h4" weight={200} color="default" > Findings:</Label> */}
        </div>
        <div className='audit-action-bar__panel-body'>
          {findings && Array.isArray(findings) && findings.map((item: any) => (
            <Tile key={item.id}>
              <div className='finding__wrapper'>
                <span>
                  <Label size='h4'>Tag: {item.dicomGroup}, {item.dicomElement} </Label>
                  <Text>Issues: </Text>
                  {renderReadableFields([item])}
                  <Spacer value={5} />
                  <Text>Notes: </Text>
                  <Text>{item.notes}</Text>
                  <Spacer value={5} />
                </span>
                <span className='finding__badge'>
                  <Label size='subtext'>Risk:</Label>
                  <Spacer value={5} />
                  <Badge risk={item.riskLevel} />
                </span>
              </div>
            </Tile>
          ))}
          <Spacer value={15} />
          {(isLoadingData || isFetchingData) && <div className='btn-row'><Spinner /> </div>}
        </div>
      </div>

      <div className='audit-action-bar__panel'>
        <div className='audit-action-bar__header'>
          {/* <Label size="h4" weight={200} color="default" > Reccomendations:</Label> */}
          <span className='audit-dicom__text' > RECOMMENDATIONS:</span>
        </div>
        <Spacer value={10} />
        <div className='audit-action-bar__panel-body'>
          <div className='audit-action-bar__ops-wrapper'>
            {renderOptions(selectedAnswer)}
            <Spacer value={30} />
            <Spacer value={30} />
          </div>
        </div>
      </div>

      <div className='audit-action-bar__panel'>
        <div className='audit-action-bar__header'>
          {/* <Label size="h4" weight={200} color="default" > Image Audit:</Label> */}
          <span className='audit-dicom__text' > Image Audit:</span>
        </div>
        <div className='audit-action-bar__panel-body-audit'>
          {series.auditStatus === 'incomplete' && !readOnly ?
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isEditing ? <Spinner />
                :
                <>
                  <span role='button' tabIndex={-1} onClick={handleRequestDeidentification} >
                    <Label size='h5' className="radio-item">
                      <input
                        type="radio"
                        name="answer"
                        value='Request re-deidentification'
                        checked={isRequestDeidentificationChecked}
                        onChange={handleRequestDeidentification}
                        style={{ display: 'none' }}
                      />
                      {isRequestDeidentificationChecked ?
                        <BsCheckCircle size={16} color='blue' className='radio-icon' />
                        :
                        <BsCircle size={16} color='gray' className='radio-icon' />

                      }
                      {' '} {' '}
                      Request re-deidentification
                    </Label>
                  </span>
                  <Button className='audit-dicom__action-row-submitbtn' size='large' onClick={submitImg}>Submit Image Audit</Button>
                  <Spacer value={20} />
                </>
              }
            </>
            :
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {series?.riskLevel !== 'null' ?
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {series.riskLevel === 1 ? <SheildIcon size={80} type='good' /> : <SheildIcon size={80} type='bad' />}
                </>
                :
                <span className='audit-dicom__text' > No audit Submitted</span>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
};

DicomAuditActionBar.defaultProps = {
};

DicomAuditActionBar.displayName = 'DicomAuditActionBar';
export default DicomAuditActionBar;
