/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Grid from '@mui/material/Unstable_Grid2';
import { AiFillEdit } from 'react-icons/ai';
import Avatar from '@mui/material/Avatar';
import { useGetStudyInfoQuery, useEditUserMutation } from '../../../api/builders/studyConfig.api';
import { Label, Modal, Button, Spacer, Tile, Dropdown } from '../../atoms';
import './user-item.scss';

export interface UserItemProps {
  id: string,
  className?: string,
  users: any,
  study: any,
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const UserItem = (props: UserItemProps): JSX.Element => {
  const { id, users, study } = props;
  const [edit, setEdit] = React.useState(false);
  const [newRole, setNewRole] = React.useState('');
  const [selectedSite, setSelectedSite] = React.useState('');
  const [editUser, setEditUser] = React.useState<any | null>(null);
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetStudyInfoQuery({ studyId: study?.id ? study.id : skipToken });
  const [onEdit] = useEditUserMutation();

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value);
  };

  const handleEdit = (usr: any) => {
    setEditUser(usr);
    setEdit(true);
  };

  const handleCloseEdit = () => {
    setEditUser(null);
    setEdit(false);
  };

  const handleChangeSite = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSite(event.target.value);
  };

  const confirmEdit = () => {
    try {
      if (newRole === 'SITE COORDINATOR') {
        const payload = {
          id: editUser.id,
          body: {
            role: newRole,
            studyId: study.id,
            siteId: selectedSite,
          },
        };
        onEdit(payload);
      } else {
        const payload = {
          id: editUser.id,
          body: {
            role: newRole,
            studyId: study.id,
          },
        };
        onEdit(payload);
      }
      handleCloseEdit();
    } catch (e) {
      const err = e as TypeError;
      console.error('err editing', err);
    }
  };


  return (
    <div>
      {Array.isArray(users) && users.length > 0 && users.map((item: any) => (
        <Tile key={item.id} className='user-item'>
          <Grid container spacing={1} >
            <Grid xs={3} className='user-item__grid'>
              <Avatar {...stringAvatar(`${item.firstname} ${item.lastname}`)} />
              <Spacer value={5} />
              <Label size='h6'>{`${item.firstname} ${item.lastname}`}</Label>
            </Grid>
            <Grid xs={6} className='user-item__grid'>
              <Label size='h6'>{item.email} </Label>
            </Grid>
            <Grid xs={2} className='user-item__grid'>
              <Label size='h6'>{item.role}</Label>
            </Grid>
            <Grid xs={1} className='user-item__grid'>
              <Button onClick={() => handleEdit(item)} kind='ghost'><AiFillEdit /></Button>
            </Grid>
          </Grid>
        </Tile >
      ))}
      <Modal title={`Change ${editUser?.firstname} ${editUser?.lastname}'s role`} open={edit} onClose={() => setEdit(false)} size='sm'>
        <div>
          <Dropdown
            label="New Role"
            id="role"
            name="role"
            onChange={handleChangeRole}
            value={newRole}
          >
            {/* training, pending start , recruiting , closed */}
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="SPONSOR">Sponsor</MenuItem>
            <MenuItem value="SPONSOR (BLINDED)">Sponsor Blinded</MenuItem>
            <MenuItem value="SITE COORDINATOR">Site Coordinator</MenuItem>
            <MenuItem value="CORELAB">Corelab</MenuItem>
            <MenuItem value="CLR1">Reviewer A</MenuItem>
            <MenuItem value="CLR2">Reviewer B</MenuItem>
            <MenuItem value="CLTR">Tie Breaker </MenuItem>
          </Dropdown>
          {(newRole === 'SITE COORDINATOR') &&
            <Dropdown
              label="Site"
              id="site"
              name="Site"
              onChange={handleChangeSite}
              value={selectedSite}
            >
              {data && data[0].sites?.length > 0 && data[0].sites.map((it: any) => <MenuItem value={it.id} >{it.name}</MenuItem>)}
            </Dropdown>
          }
          <Spacer value={15} />
          <div className='btn-row'>
            <Button kind='danger' onClick={() => setEdit(false)}> Cancel</Button>
            <Button onClick={confirmEdit}> Confirm</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

UserItem.defaultProps = {
  className: '',
};

UserItem.displayName = 'UserItem';
export default UserItem;
