/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AiFillPauseCircle, AiOutlineArrowRight, AiOutlinePlus } from 'react-icons/ai';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { Label, Spacer, Modal, Alert, Tile } from '../../../components';
import { AuditListItem, TextAreaInput } from '../../../components/molecules';
import { useGetHiveStatsByIdQuery, useGetAuditsByHiveQuery, useCreateAuditMutation } from '../../../api/builders/hives.api';
import { Badge } from '../../../components/atoms';
import StudyItem from './StudyItem';


type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean;
  message: string;
  kind: AlertType;
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

interface LocationState {
  hive: any;
}

const fakeyData = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group D', value: 200 },
];

const COLORS = ['#574AE2', '#5CC0EB', '#F37F30'];

interface RenderCustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const RenderCustomizedLabel: React.FC<RenderCustomizedLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Hive = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { hive } = location?.state as LocationState;
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetHiveStatsByIdQuery(hive?.id ?? skipToken);
  const { data: audits, isLoading: isLoadingAudits, isFetching: isFetchingAudits } = useGetAuditsByHiveQuery(hive?.id ?? skipToken);
  const [newAuditSummary, setNewAuditSummary] = useState<string>('');
  const [addModal, setAddModal] = useState(false);
  const [createAudit, { isLoading: isAdding, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useCreateAuditMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  const handleClick = (_item: any) => {
    navigate('/private/hives/hive/cohort/audit', { state: { audit: _item } });
  };


  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  const openAdd = () => {
    setAddModal(true);
  };

  const closeAdd = () => {
    setAddModal(false);
    setNewAuditSummary('');
  };


  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Adding New Audit: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
      closeAdd();
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding New Audit',
        kind: 'success',
      });
      closeAdd();
    }
  }, [isSuccessAdding]);

  const transformData = (items: { status: string }[]): { name: string; value: number }[] => {
    const counts: { [key: string]: number } = {};
  
    // Count the number of items for each status
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
      const { status } = item;
      counts[status] = (counts[status] || 0) + 1;
    }
  
    // Transform the counts object into the desired format
    const transformedData: { name: string; value: number }[] = Object.entries(counts).map(([name, value]) => ({
      name,
      value,
    }));
  
    return transformedData;
  };

  useEffect(() => {
    const newBreadcrumb = {
      label: hive?.label ?? 'Hive',
      path: location.pathname,
      state: { hive },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);

  const onOpen = (item: any) => {
    navigate('/private/hives/hive/cohort', { state: { item } });
  };

  if (!location?.state) {
    return (
      <div className='d-flex-row-center'>
        <Label size="h4" weight={600} color="primary">Error Loading Hive Details</Label>
      </div>
    );
  }
  return (
    <div className="hive">
      <Tile className="hive__title">
        <>
          <Label size="h2" weight={300} >{hive?.hiveLabel} {' - '} {hive?.description}  </Label>
          <div className='hive__title-actions'>
            <div className='hive__title-item'>
              <Label size="h6" weight={700} uppercase >Last Audit: {data && moment(data.lastAudit.updatedAt).format('L')} </Label>
            </div>
            <div className='hive__title-item'>
              <span>
                <Badge label='status' color='gray' />
              </span>
            </div>
            <div className='hive__title-item'>
              <AiFillPauseCircle size={30} className='pause-btn' />

            </div>
          </div>
        </>
      </Tile>
      <div className='hive__body'>
        <div className='hive__body-right'>
          <div className='hive__body-tile'>
            <>
              <Label className='hive__body-tile-title' size="h6" weight={700} uppercase >Audits:</Label>
              {audits?.length > 0 ?
                <>
                  {audits?.map((item: any) => (
                    <AuditListItem onClick={handleClick} item={item} />
                  ))}
                </>
                :
                <Tile className='hive__body-empty'>
                  <div className='hive__body-empty'>
                    <Label color='ghost' size="h6" weight={700} uppercase >Nothing to show</Label>
                  </div>
                </Tile>
              }

            </>
          </div>
          <Spacer value={15} />
          <div className='hive__body-tile'>
            <div>
              <Label className='hive__body-tile-title' size="h6" weight={700} uppercase >Items:</Label>
              {data && Array.isArray(data.studies) && data.studies.length > 0 ? data.studies.map((item: any, idx: number) => (
                <StudyItem key={item.id} study={item} />
              ))
                :
                <Tile className='hive__body-tile'>
                  <div className='super-centered'>
                    <Label color='ghost' size="h6" weight={700} uppercase >Nothing to show:</Label>
                  </div>
                </Tile>
              }
            </div>
          </div>
        </div>
        <div className='hive__body-left'>
          <div className='hive__body-minis'>
            <Tile className='hive__body-mini box-orange ' >
              <div>
                <Label size='h1' center color='primary'>{data?.numberofpatients ?? '?'}</Label>
                <Spacer value={10} />
                <Label size='h4' center uppercase color='primary'>patients</Label>
              </div>
            </Tile>

            <Tile className='hive__body-mini box-green' >
              <div>
                <Label size='h1' center className='green'>44</Label>
                <Spacer value={10} />
                <Label size='h4' center uppercase className='green'>Audited Images</Label>
              </div>
            </Tile>

            <Tile className='hive__body-mini box-blue'>
              <div>
                <Label size='h1' center className='blue'>{data?.numberOfFindings}</Label>
                <Spacer value={10} />
                <Label size='h4' center uppercase className='blue'>Issues Identified</Label>
              </div>
            </Tile>

            <Tile className='hive__body-mini box-red'>
              <div>
                <Label size='h1' center className='orange'>22%</Label>
                <Spacer value={10} />
                <Label size='h4' center uppercase className='orange'>Audit Coverage</Label>
              </div>
            </Tile>

          </div>
          <Tile className='hive__body-tile'>
            <div className='hive__body-graph-wrapper'>
              <ResponsiveContainer width="70%" height="100%">
                <PieChart width={1000} height={1000}>
                  <Pie
                    data={audits && transformData(audits)}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={RenderCustomizedLabel}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {audits?.map((entry: any, index: number) => (
                      <Cell key={`cell-${entry.status}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              <div>
                <Label size='h4' uppercase color='primary'>Completed</Label>
                <Spacer value={10} />
                <Label size='h4' uppercase className='orange'>In Progress</Label>
                <Spacer value={10} />
                <Label size='h4' uppercase className='blue'>Not Started</Label>
              </div>
            </div>
          </Tile>
        </div>
      </div>
    </div >
  );

};

export default withAuthenticator(Hive);