import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Button, Spacer, SearchBox, Dropdown, MultipleSelectDropdown, Modal, TextInput, CohortItem, TextAreaInput } from '../../../components';
import { IconButton } from '../../../components/atoms';
import { useAddCohortMutation, useGetCohortsQuery, cohortsApis } from '../../../api/builders/cohorts.api';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';



const Cohorts = () => {
  const [addCohort] = useAddCohortMutation();
  const { t } = useTranslation();
  const { data, isError, isFetching } = useGetCohortsQuery('');
  const { refetch: refetchCohorts } =
    cohortsApis.endpoints.getCohorts.useQuerySubscription('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [modal, setModal] = React.useState(false);
  const [filter, setFilter] = React.useState('all');
  const [filteredData, setFilteredData] = React.useState<any | any[]>([]);
  const [newVals, setNewVals] = React.useState({
    cohortLabel: '',
    description: '',
  });

  const handleChangeSearch = (value: string) => {
    setSearchTerm(value);
  };


  React.useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      if (filter === 'all') {
        setFilteredData(data);
      } else {
        const filtered = data.filter((i: any) => i.type === filter);
        setFilteredData(filtered);
      }
    }
  }, [data, filter]);

  const filteredCohorts = filteredData?.filter((study: any) => (study.cohortLabel?.toLowerCase().includes(searchTerm.toLowerCase()) || study.description?.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  const handleChangeVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewVals({ ...newVals, [event.target.name]: event.target.value });
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewVals({ ...newVals, [event.target.name]: event.target.value });
  };

  const cancelNewCohort = () => {
    setNewVals({
      cohortLabel: '',
      description: '',
    });
    setModal(false);
  };

  const onSubmitNewCohort = () => {
    try {
      addCohort(newVals);
      cancelNewCohort();
    } catch (e) {
      console.error('error adding new cohort::', e);
    }
  };



  return (
    <div className="cohorts">
      <div className='cohorts__header'>
        <span className='cohorts__header-title'>
          <Label size="h2" weight={300}>
            {t('Cohorts')}
          </Label>
          <IconButton icon="refresh" onClick={refetchCohorts} />
        </span>

        <span className='d-flex'>
          <SearchBox value={searchTerm}
            onChange={handleChangeSearch}
            placeholder={t('search') as string} />
          <IconButton icon="add" onClick={() => setModal(true)} />
        </span>

      </div>

      {/* <div className='cohorts__btn-wrapper'>
        <button className={filter === 'master' ? 'cohorts__button-active' : 'cohorts__button'} type='button' onClick={() => setFilter('master')}>My Cohorts</button>
        <button className={filter === 'shared' ? 'cohorts__button-active' : 'cohorts__button'} type='button' onClick={() => setFilter('shared')}>Shared Cohorts</button>
      </div> */}

      <div className='cohorts__body'>
        {filteredCohorts.length > 0 ? filteredCohorts?.map((item: any) => (
          <CohortItem
            key={item.id}
            item={item}
          />
        ))
          :
          <div className='cohorts__er'>
            <Label color="ghost" size="h3"> Nothing to show</Label>
          </div>
        }
      </div>


      <Modal size='md' open={modal} onClose={cancelNewCohort} title='add new Cohort'>
        <div>
          <TextInput
            name='cohortLabel'
            label='Label'
            onChange={handleChangeVals}
            value={newVals.cohortLabel}
          />
          <TextAreaInput
            name='description'
            label='Description'
            onChange={handleChangeDescription}
            value={newVals.description}
          />
          <Spacer value={10} />
          <div className='btn-row'>
            <Button onClick={cancelNewCohort} kind='danger'>Cancel</Button>
            <Button onClick={onSubmitNewCohort}>Submit</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withAuthenticator(Cohorts);
