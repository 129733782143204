/* eslint-disable react/jsx-no-undef */
import React from 'react';
// import classnames from 'classnames';
import './met-item-details.scss';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { FaPaste } from 'react-icons/fa';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import FlaggableDropdown from '../FlaggableDropdown/FlaggableDropdown';
import FlaggableInput from '../FlaggableInput/FlaggableInput';
import { Spacer, Label, Checkbox, Button, Dropdown } from '../../atoms';
import { useCreateLogMutation } from '../../../api/builders/logs.api';
import { RootState } from '../../../store/store';
import { useGetAllVisitImagesQuery } from '../../../api/builders/participantFiles.api';

export interface Met {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  isTreated: boolean,
  isTreatable: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  visit?: string,
  tumorassessments: MetHx[] | [],
}

export interface MetHx {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  tumorAssessmentId: string,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  protocolItemId: string,
  isTreated: boolean,
  isTreatable: boolean,
}

export interface MetAs {
  tumorId: string,
  protocolItemId: string | undefined,
  isTumor: boolean,
  type: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  volume: number,
  location: string,
  RLCoord?: number,
  APCoord?: number,
  SICoord?: number,
  notes: string,
  status: string,
  steroid?: string,
  isTreated: boolean,
  isTreatable: boolean,
}


export interface MetItemDetailsProps {
  met: Met,
  protocolItemId: string | undefined,
  addTumorAssessmnt: (payload: MetAs) => void,
  loading: boolean,
  error: { open: boolean, message: string },
  acl: string,
  role: string,
  visitId: string | undefined,

  // updateDetails: (payload: Met) => void,
  // addFlag: (payload: FlagItem) => void,
}

export interface FlagItem {
  user: string,
  userEmail: string,
  message: string,
  time: string,
  item: string,
}

const MetItem = {
  tumorLabel: '',
  tumorId: '',
  flags: [],
  RLCoord: 0,
  APCoord: 0,
  SICoord: 0,
  image: '',
  lobe: '',
  laterality: '',
  diameterAxial: 0,
  diameterCoronal: 0,
  diameterSagittal: 0,
  volume: 0,
  longDiameter: 0,
  sumDiameter: 0,
  status: '',
  steroid: '',
  isTumor: true,
  isTreated: false,
  isTreatable: false,
  notes: '',
  id: '',
};


const MetItemDetails = (props: MetItemDetailsProps) => {
  const { met, protocolItemId, addTumorAssessmnt, loading, error, visitId, role } = props;
  const [values, setValues] = React.useState<typeof MetItem>(MetItem);
  const [err, setError] = React.useState({ open: false, message: '' });
  const [addLog] = useCreateLogMutation();
  const copiedItem = useSelector((state: RootState) => state.clipboard.copiedItem);
  const [selectedImage, setSelectedImage] = React.useState<any | null>(null);
  const { data, isLoading: isLoadingData } = useGetAllVisitImagesQuery(visitId);


  const closeError = () => {
    setError({ open: false, message: '' });
  };

  const handleChangeImage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedImage(event.target.value);
  };

  React.useEffect(() => {
    if (met.tumorassessments.length > 0) {
      const findMatch = met.tumorassessments.filter(it => it.protocolItemId === protocolItemId);
      if (findMatch && findMatch.length > 0) {
        setValues({
          ...values,
          tumorLabel: met.tumorLabel,
          flags: [],
          RLCoord: met.RLCoord,
          APCoord: met.APCoord,
          SICoord: met.SICoord,
          lobe: met.lobe,
          laterality: met.laterality,
          diameterAxial: findMatch[0].diameterAxial ? findMatch[0].diameterAxial : 0,
          diameterCoronal: findMatch[0].diameterCoronal ? findMatch[0].diameterCoronal : 0,
          diameterSagittal: findMatch[0].diameterSagittal ? findMatch[0].diameterSagittal : 0,
          volume: findMatch[0].longDiameter,
          longDiameter: 0,
          sumDiameter: 0,
          status: findMatch[0].status,
          steroid: findMatch[0].steroid ? findMatch[0].steroid : '',
          isTumor: findMatch[0].isTumor,
          notes: findMatch[0].notes,
          isTreated: met.isTreated ? met.isTreated : false,
          isTreatable: met.isTreatable ? met.isTreatable : false,
        });
      }
    } else {
      setValues({
        ...values,
        tumorLabel: met.tumorLabel,
        flags: [],
        RLCoord: met.RLCoord,
        APCoord: met.APCoord,
        SICoord: met.SICoord,
        image: '',
        lobe: met.lobe,
        laterality: met.laterality,
        diameterAxial: 0,
        diameterCoronal: 0,
        diameterSagittal: 0,
        volume: 0,
        longDiameter: 0,
        sumDiameter: 0,
        status: '',
        steroid: '',
        isTumor: true,
        notes: '',
        isTreated: met.isTreated ? met.isTreated : false,
        isTreatable: met.isTreatable ? met.isTreatable : false,
      });
    }
  }, [met]);

  // const compareObjects = () => {
  //   const findMatch = met.tumorassessments.filter(it => it.protocolItemId === protocolItemId)[0];
  //   if (findMatch) {
  //     const result: any = {};
  //     for (const key of Object.keys(findMatch)) {
  //       if (findMatch[key] !== values[key]) {
  //         result[key] = values[key];
  //       }
  //     }

  //   }
  // };

  function compareObjects(obj1: any, obj2: any): string {
    let result = '';

    if (obj1.diameterAxial !== obj2.diameterAxial) {
      result += `diameterAxial changed from '${obj1.diameterAxial}' to '${obj2.diameterAxial}'\n`;
    }

    if (obj1.diameterCoronal !== obj2.diameterCoronal) {
      result += `diameterCoronal changed from '${obj1.diameterCoronal}' to '${obj2.diameterCoronal}'\n`;
    }

    if (obj1.diameterSagittal !== obj2.diameterSagittal) {
      result += `diameterSagittal changed from '${obj1.diameterSagittal}' to '${obj2.diameterSagittal}'\n`;
    }

    if (obj1.notes !== obj2.notes) {
      result += `notes changed from '${obj1.notes}' to '${obj2.notes}'\n`;
    }

    if (obj1.isTreatable !== obj2.isTreatable) {
      result += `isTreatable changed from '${obj1.isTreatable}' to '${obj2.isTreatable}'\n`;
    }

    if (obj1.isTreated !== obj2.isTreated) {
      result += `isTreated changed from '${obj1.isTreated}' to '${obj2.isTreated}'\n`;
    }

    if (obj1.APCoord !== obj2.APCoord) {
      result += `APCoord changed from '${obj1.APCoord}' to '${obj2.APCoord}'\n`;
    }

    if (obj1.RLCoord !== obj2.RLCoord) {
      result += `RLCoord changed from '${obj1.RLCoord}' to '${obj2.RLCoord}'\n`;
    }

    if (obj1.SICoord !== obj2.SICoord) {
      result += `SICoord changed from '${obj1.SICoord}' to '${obj2.SICoord}'\n`;
    }

    return result;
  }

  const log = () => {
    try {
      const findMatch = met.tumorassessments.filter(it => it.protocolItemId === protocolItemId);
      if (findMatch && findMatch.length > 0) {
        const changes = compareObjects(findMatch[0], values);
        if (changes && changes !== '') {
          const logPl = {
            itemId: protocolItemId,
            itemLabel: 'Met Assessment',
            actionType: changes,
          };
          addLog(logPl);
        }
      }
    } catch (e) {
      console.error('CREATE LOG ER', e);
    }

  };

  const saveChanges = () => {
    try {
      const payload = {
        tumorId: met.tumorId ? met.tumorId : '',
        protocolItemId,
        isTumor: values.isTumor,
        type: 'MET',
        diameterAxial: values.diameterAxial,
        diameterCoronal: values.diameterCoronal,
        diameterSagittal: values.diameterSagittal,
        volume: 0,
        // volume: values.volume,
        location: 'BRAIN',
        RLCoord: values.RLCoord,
        APCoord: values.APCoord,
        SICoord: values.SICoord,
        notes: values.notes,
        status: values.status,
        steroid: values.steroid,
        isTreated: values.isTreated,
        isTreatable: values.isTreatable,
      };
      console.log('payload', payload);
      addTumorAssessmnt(payload);
      log();
      closeError();
    } catch {
      console.error('err');
    }
  };

  const handleChangenVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };

  const handleChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  // const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (values.isTreatable && values.isTreated) {
  //     setValues({ ...values, isTreated: false });
  //   } else if (!values.isTreated) {
  //     setValues({ ...values, isTreated: false });
  //   }
  // };
  // const handleCheckTreatable = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValues({ ...values, isTreatable: !values.isTreatable });
  // };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (values.isTreatable && values.isTreated) {
      setValues({ ...values, isTreated: false });
    } else if (values.isTreatable && !values.isTreated) {
      setValues({ ...values, isTreated: true });
    } else if (values.isTreated) {
      setValues({ ...values, isTreated: false });
    }
  };
  const handleCheckTreatable = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (values.isTreatable && values.isTreated) {
      setValues({ ...values, isTreatable: false, isTreated: false });
    } else {
      setValues({ ...values, isTreatable: !values.isTreatable });
    }
  };


  // const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValues({ ...values, isTumor: !values.isTumor });
  // };

  const onClear = () => {
    setValues(MetItem);
  };

  const onPaste = () => {
    const measurement = copiedItem?.meta;

    if (Array.isArray(measurement)) {
      const img = data.find((item: {
        [x: string]: any; id: any;
      }) => item.ScanSeries[0].SeriesDescription === measurement[0].series);

      setValues({
        ...values,
        RLCoord: measurement[0].intersect.x.toFixed(2),
        APCoord: measurement[0].intersect.y.toFixed(2),
        SICoord: measurement[0].intersect.z.toFixed(2),
        diameterAxial: Number(Number(measurement[0].longestDiameter).toFixed(2)),
        diameterCoronal: Number(Number(measurement[0].shortestDiameter).toFixed(2)),
        volume: img,
      });

      setSelectedImage(img);
    }
  };
  const onReviewerPaste = () => {
    const measurement = copiedItem?.meta;

    if (Array.isArray(measurement)) {
      const img = data.find((item: {
        [x: string]: any; id: any;
      }) => item.ScanSeries[0].SeriesDescription === measurement[0].series);

      setValues({
        ...values,
        diameterAxial: Number(Number(measurement[0].longestDiameter).toFixed(2)),
        diameterCoronal: Number(Number(measurement[0].shortestDiameter).toFixed(2)),
        volume: img,
      });
    }
  };

  return (
    <div>
      {role === 'SITE COORDINATOR' &&
        <div>
          <div className='add-met__paste' >
            <IconButton
              key="paste"
              onClick={onPaste}
              size='small'
            >
              <FaPaste className="icon-secondary" />
            </IconButton>

            <Label size='h5'>Paste Measurement   </Label>
          </div>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Checkbox checked={values.isTreatable} onChange={handleCheckTreatable} label='hey'>
                <Label size='h5' color='ghost' >Is Treatable</Label>
              </Checkbox>
            </Grid>
            <Grid xs={12}>
              <Checkbox checked={values.isTreated} onChange={handleCheck} label='hey'>
                <Label size='h5' color='ghost' >Is/Will be part of treatment plan</Label>
              </Checkbox>
            </Grid>
            <Grid xs={12}>
              <div>
                {data && data?.length > 0 &&
                  <Dropdown
                    name='Reference Image'
                    label='Reference Image'
                    value={selectedImage}
                    onChange={handleChangeImage}
                  >
                    {data.map((it: any) => <MenuItem value={it} >{`${it.ScanSeries[0].Modality} ${it.ScanSeries[0].SeriesDescription} `} </MenuItem>)}
                  </Dropdown>}
              </div>
            </Grid>
            <Grid md={4}>
              <div>
                <FlaggableInput
                  type='number'
                  label='R-L Coord'
                  name='RLCoord'
                  id='RLCoord'
                  onChange={handleChangeInput}
                  value={values.RLCoord}
                // addFlag={addFlag}
                />
              </div>
            </Grid>
            <Grid md={4}>
              <div>
                <FlaggableInput
                  type='number'
                  label='A-P Coord'
                  name='APCoord'
                  id='APCoord'
                  onChange={handleChangeInput}
                  value={values.APCoord}
                // addFlag={addFlag}
                />
              </div>
            </Grid>
            <Grid md={4}>
              <div>
                <FlaggableInput
                  type='number'
                  label='S-I Coord'
                  name='SICoord'
                  id='SICoord'
                  onChange={handleChangeInput}
                  value={values.SICoord}
                // addFlag={addFlag}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      }

      <div>


        {(role === 'CLR1' || role === 'CLR2') &&
          <div>
            <IconButton
              key="paste"
              onClick={onReviewerPaste}
              size='small'
            >
              <FaPaste className="icon-secondary" />
            </IconButton>
          </div>}
        <Grid container spacing={2}>
          <Grid md={4}>
            <FlaggableInput
              type='number'
              label='Longest Diameter in mm'
              id='diameterAxial'
              noNegatives
              onChange={handleChangenVals}
              value={values.diameterAxial}
            />
          </Grid>
          <Grid md={4}>
            <FlaggableInput
              type='number'
              noNegatives
              label='Short Diameter (in plane) in mm'
              id='diameterCoronal'
              onChange={handleChangenVals}
              value={values.diameterCoronal}
            />
          </Grid>
          <Grid md={4}>
            <FlaggableInput
              type='number'
              noNegatives
              label='Third Diameter in mm'
              id='diameterSagittal'
              onChange={handleChangenVals}
              value={values.diameterSagittal}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Label size='subtext'>Notes</Label>
        <TextAreaInput
          type='number'
          id='notes'
          onChange={handleChangeNote}
          value={values.notes}
        />
      </div>
      <div className='super-centered'>
        {error.open && <Label size='h6' color='danger'>{error.message}</Label>}
        {err.open && <Label size='h6' color='danger'>{err.message}</Label>}
      </div>
      <div className='btn-row'>
        <Button kind='danger' onClick={onClear}> Clear </Button>
        <Button onClick={saveChanges} loading={loading}> Save Changes </Button>
      </div>
    </div >
  );
};

MetItemDetails.defaultProps = {
  // key: '_key',
};

MetItemDetails.displayName = 'MetItemDetails';


export default MetItemDetails;
