import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Survey } from '../../components/molecules';
import { Spinner, Alert, Label, Spacer } from '../../components/atoms';
import computeScore from '../../utils/scoring/FormulaParser';

interface LocationState {
  survey: any;
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}
const LinkedPatientSurvey = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { survey } = location?.state as LocationState;
  const [userSurvey, setUserSurvey] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  useEffect(() => {
    if (survey && survey?.questions) {
      try {
        const tmp = JSON.parse(survey.questions);
        setUserSurvey(tmp.sort((a: any, b: any) => a.order - b.order));
      } catch {
        console.error('survey could not be parsed');
      }
    }
  }, [survey]);

  const update = (pl: any) => {
    try {
      axios({
        method: 'PUT',
        url: `https://fc8j2fy50f.execute-api.us-east-1.amazonaws.com/prod/patientportal/${pl.id}`,
        data: pl.body,
      })
        .then((response) => {
          if (response.status !== 200) {
            setAlert({
              open: true,
              message: 'Error Saving Survey',
              kind: 'error',
            });
          } else {
            navigate(-1);
          }
        });
    } catch (e) {
      return 'error';
    }
    return 'hi';
  };



  const handleSubmitSurvey = async (updatedItem: any) => {
    try {
      const calcScore = computeScore({
        ...survey,
        questions: JSON.stringify(updatedItem),
      })[0];
      const payload = {
        id: survey.id,
        body: {
          status: 'completed',
          questions: JSON.stringify(updatedItem),
          score: calcScore !== 'undefined' ? JSON.stringify(calcScore) : '',
        },
      };
      await update(payload);
    } catch (e) {
      console.error(e);
    }
  };



  return (
    <div className="linked-patient-survey">
      {
        isLoading ?
          <div>
            <Spinner />
          </div>
          :
          <>
            <Spacer value={30} />
            <Label size='h4' center>{survey?.surveyLabel}</Label>
            <Spacer value={10} />
            <Survey
              userSurvey={userSurvey}
              setUserSurvey={setUserSurvey}
              onSubmit={handleSubmitSurvey}
              saveProgress={handleSubmitSurvey}
            />
          </>
      }

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />

    </div>
  );
};

export default LinkedPatientSurvey;
