import React from 'react';
// import classnames from 'classnames';
import './protocol.scss';
import { IProtocolTimePoint } from '../ProtocolTimePoint/ProtocolTimePoint';

export interface ProtocolProps  {
  id: string,
  className?: string,
}

export interface IProtocol {
  timepoints: IProtocolTimePoint[] | [];

}

const Protocol = (props: ProtocolProps): JSX.Element => {
  const { id, className } = props;
  return (
        <div key={`Protocol_${id}`} className="card">
        Protocol
        </div>
  ); 
};

Protocol.defaultProps = {
  className: '',
};

Protocol.displayName = 'Protocol';
export default Protocol;
