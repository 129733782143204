import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import MenuItem from '@mui/material/MenuItem';
import { Label, Modal, Button, Spacer, Alert, Dropdown } from '../../atoms';
import { useGetStudyInfoQuery, useCreateUserMutation } from '../../../api/builders/study.api';
import TextInput from '../TextInput/TextInput';
import './add-user.scss';

export interface AddUserProps {
  id: string,
  study: any,
}

const initialForm = {
  firstname: '',
  email: '',
  password: '',
  lastname: '',
  role: '',
};

export interface TypeError {
  status: number,
  data: { message: string },
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const AddUser = (props: AddUserProps): JSX.Element => {
  const { id, study } = props;
  const [newUserVals, setNewUserVals] = React.useState(initialForm);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedSite, setSelectedSite] = React.useState('');
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetStudyInfoQuery({ studyId: study?.id ? study.id : skipToken });
  const [error, setError] = React.useState({ open: false, message: '' });
  const [onAddUser, { isLoading: isAdding, isSuccess, isError, error: aError }] = useCreateUserMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const onCloseModal = () => {
    setIsOpen(false);
    setNewUserVals(initialForm);
    setError({ open: false, message: '' });
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };


  React.useEffect(() => {
    if (isError) {
      const apiError = aError as ApiError;
      setAlert({
        open: true,
        message: `Error Adding User: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Adding User',
        kind: 'success',
      });
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };



  const handleChangeVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUserVals({ ...newUserVals, [event.target.id]: event.target.value });
  };
  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewUserVals({ ...newUserVals, [event.target.name]: event.target.value });
  };
  const handleChangeSite = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSite(event.target.value);
  };

  function validateFields(vals: any) {
    // Check if first name is not empty
    if (!vals.firstname) {
      setError({
        open: true,
        message: 'First name required',
      });
      // console.error('firstname required');
      return true;
    }

    // Check if last name is not empty
    if (!vals.lastname) {
      setError({
        open: true,
        message: 'Last name required',
      });
      console.error('lastname required');
      return true;
    }

    if (!vals.role) {
      setError({
        open: true,
        message: 'Role required',
      });
      // console.error('role missing');
      return true;
    }

    // Check if email is a valid email address
    if (!/\S+@\S+\.\S+/.test(vals.email)) {
      setError({
        open: true,
        message: 'Please enter valid email address',
      });
      // console.error('email not valid / missing');
      return true;
    }

    if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{7,}(.*)/g.test(vals.password)) {
      // console.error('password not valid missing', vals.password);
      setError({
        open: true,
        message: 'Password must be at least 8 characters and include uppercase letter, lowercase letter, and number',
      });
      return true;
    }

    // Check if role is 'SITE COORDINATOR' and selectedSite is not empty
    if (vals.role === 'SITE COORDINATOR' && !selectedSite) {
      setError({
        open: true,
        message: 'Site Required for selected role',
      });
      return true;
    }
    // Check if last name is not empty
    if (!vals.lastname) {
      setError({
        open: true,
        message: 'Last name required',
      });
      // console.error('lastname required');
      return true;
    }
    // All fields are valid
    return false;
  }

  

  const handleAddUser = async () => {
    try {
      const validate = validateFields(newUserVals);
      if (!validate) {
        if (newUserVals.role === 'SITE COORDINATOR') {
          const payload = {
            ...newUserVals,
            siteId: selectedSite,
            studyId: study.id,
          };
          const result = await onAddUser(payload);
          if ('error' in result) {
            const errorm = result.error as { data: { message: string } };
            if (errorm.data && errorm.data.message) {
              setError({
                open: true,
                message: errorm.data.message,
              });
              return;
            }
          }
        } else {
          const payload = {
            ...newUserVals,
            studyId: study.id,
          };
          const result = await onAddUser(payload);
          if ('error' in result) {
            const errorm = result.error as { data: { message: string } };
            if (errorm.data && errorm.data.message) {
              setError({
                open: true,
                message: errorm.data.message,
              });
              return;
            }
          }
        }
        onCloseModal();
      }
    } catch (e) {
      const err = e as TypeError;
      if (err?.status === 400) {
        setError({
          open: true,
          message: err.data.message,
        });
      }
    }
  };


  return (
    <div key={`AddUser_${id}`} className="card">
      {/* <div onClick={onOpenModal} className='study-config__tile-btn' role='button' tabIndex={-1}>
        <Tile>
          <Label size="h4"> + Add User</Label>
        </Tile>
      </div> */}
      <Button onClick={onOpenModal}>Invite People</Button>
      <Modal
        open={modalIsOpen}
        onClose={onCloseModal}
        title="Add New User"
      >
        <div>
          <TextInput
            type="input"
            label="First Name"
            id="firstname"
            onChange={handleChangeVals}
            value={newUserVals.firstname}
          />
          <TextInput
            type="input"
            label="Last Name"
            id="lastname"
            onChange={handleChangeVals}
            value={newUserVals.lastname}
          />
          <TextInput
            type="input"
            label="Email"
            id="email"
            onChange={handleChangeVals}
            value={newUserVals.email}
          />
          {/* <TextInput
            type="input"
            label="Phone Number"
            id="phone"
            onChange={handleChangeVals}
            value={newUserVals.phone}
          /> */}
          <TextInput
            type="input"
            label="Temporary Password"
            id="password"
            onChange={handleChangeVals}
            value={newUserVals.password}
          />
          <Dropdown
            label="Role"
            id="role"
            name="role"
            onChange={handleChangeRole}
            value={newUserVals.role}
          >
            {/* training, pending start , recruiting , closed */}
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="SPONSOR">Sponsor</MenuItem>
            <MenuItem value="SPONSOR (BLINDED)">Sponsor Blinded</MenuItem>
            <MenuItem value="SITE COORDINATOR">Site Coordinator</MenuItem>
            <MenuItem value="CORELAB">Corelab</MenuItem>
            <MenuItem value="CLR1">Reviewer A</MenuItem>
            <MenuItem value="CLR2">Reviewer B</MenuItem>
            <MenuItem value="CLTR">Tie Breaker </MenuItem>
          </Dropdown>
          {(newUserVals.role === 'SITE COORDINATOR') &&
            <Dropdown
              label="Site"
              id="site"
              name="Site"
              onChange={handleChangeSite}
              value={selectedSite}
            >
              {data && data[0].sites?.length > 0 && data[0].sites.map((it: any) => <MenuItem key={it.id} value={it.id} >{it.siteLabel}</MenuItem>)}
            </Dropdown>
          }
          <Spacer value={20} />
          <div className='btn-row'>
            <Button loading={isAdding} onClick={handleAddUser}>Add User</Button>
          </div>
          <Spacer value={10} />
          <div className='btn-row'>
            {error.open && <Label size='h6' color='danger'>{error.message}</Label>}
          </div>

        </div>
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />

    </div>
  );
};

AddUser.defaultProps = {
};

AddUser.displayName = 'AddUser';
export default AddUser;
