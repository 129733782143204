import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Tile, Text, Label, Spacer, Badge } from '../../atoms';
import './audit-list-item.scss';
import RenderStatusIcon from '../../../utils/AuditBadge';

export interface AuditListItemProps {
  item: any,
  onClick: (audit: any) => void,
}

const AuditListItem = (props: AuditListItemProps): JSX.Element => {
  const { onClick, item } = props;
  const navigate = useNavigate();

  const onHover = (hoveritem: any) => {
    const filler = 'stupidv var';
    // TO DO: PREFETCH AUDIT DETAILS
  };


  return (
    <Tile onHover={() => onHover('item')} >
      <div className='audit-list-item' role="button" tabIndex={0} onClick={() => onClick(item)} >
        <div>
          <Label size="h4" weight={600} >{item.description}</Label>
          <Spacer value={10} />
          <div className='d-flex'>
            <Text size='sm'>Date: {moment(item.updatedAt).format('l')} </Text>
            <Spacer value={20} />
            <Text size='sm'>Findings: {item.numberOfFindings} </Text>
          </div>
        </div>
        <div className='audit-list-item__status' >
          <RenderStatusIcon status={item.status} />
        </div>
      </div>
    </Tile >
  );
};

AuditListItem.defaultProps = {
};

AuditListItem.displayName = 'AuditListItem';
export default AuditListItem;
