import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Survey } from '../../../components/molecules';
import { Spinner, Alert } from '../../../components/atoms';
import computeScore from '../../../utils/scoring/FormulaParser';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { useUpdateSurveyMutation } from '../../../api/builders/patientSurveys.api';

interface LocationState {
  survey: any;
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const PatientSurvey = () => {
  const somevar = 'PatientSurvey';
  const location = useLocation();
  const navigate = useNavigate();
  const { survey } = location?.state as LocationState;
  const [userSurvey, setUserSurvey] = useState([]);
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [update, { isLoading: isEditing, isError: isErrorEditing, isSuccess: isSuccessEditing, error: editError }] = useUpdateSurveyMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });
  React.useEffect(() => {
    if (isErrorEditing) {
      const apiError = editError as ApiError;
      setAlert({
        open: true,
        message: `Error Saving Survey: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorEditing]);

  React.useEffect(() => {
    if (isSuccessEditing) {
      // setAlert({
      //   open: true,
      //   message: 'Success!',
      //   kind: 'success',
      // });
      navigate(-1);
    }
  }, [isSuccessEditing]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  // React.useEffect(() => {
  //   const newBreadcrumb = [
  //     {
  //       label: 'Home',
  //       path: '/private/mysurveys',
  //     },
  //     {
  //       label: survey?.description ?? 'Survey',
  //       path: location.pathname,
  //       state: { survey },
  //     },
  //   ];
  //   setBreadcrumbs(newBreadcrumb);

  // }, [location.pathname]);


  useEffect(() => {
    console.log('survey', survey);
    if (survey && survey?.questions) {
      try {
        const tmp = JSON.parse(survey.questions);
        setUserSurvey(tmp.sort((a: any, b: any) => a.order - b.order));
      } catch {
        console.error('survey could not be parsed');
      }
    }
  }, [survey]);



  const handleSubmitSurvey = async (updatedItem: any) => {
    try {
      const calcScore = computeScore({
        ...survey,
        questions: JSON.stringify(updatedItem),
      })[0];
      const payload = {
        id: survey.id,
        body: {
          status: 'completed',
          questions: JSON.stringify(updatedItem),
          score: calcScore !== 'undefined' ? JSON.stringify(calcScore) : '',
        },
      };
      console.log('PL', payload, updatedItem);
      await update(payload);
      console.log('AFTER SURVEY FINISHES');
    } catch (e) {
      console.error(e);
    }
  };



  return (
    <div className="patient-survey">
      {
        isEditing ?
          <div>
            <Spinner />
          </div>
          :
          <Survey
            userSurvey={userSurvey}
            setUserSurvey={setUserSurvey}
            onSubmit={handleSubmitSurvey}
            saveProgress={handleSubmitSurvey}
          />
      }

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />

    </div>
  );
};

export default withAuthenticator(PatientSurvey);
