import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Label from '../Label/Label';
import './date-picker.scss';

export interface DatePickerProps {
  value: string;
  invalid?: boolean;
  onChange: (date: any) => void;
  onError?: () => void;
  id?: string;
  label: string;
  invalidText?: string;
  helperText?: string;
}

const CustomDatePicker = (props: DatePickerProps) => {
  const { value, invalid, onChange, id, label, invalidText } = props;
  const [inputVal, setInputVal] = React.useState<any>(new Date());
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const pickerRef = React.useRef<HTMLInputElement | null>(null);


  React.useEffect(() => {
    const val = new Date(value);
    setInputVal(val);
  }, [value]);

  const handleChange = (e: any) => {
    if (e && e.$d !== 'Invalid Date') {
      if (onChange) {
        onChange(e?.$d && e.$d);
      }
      setInputVal(e?.$d && e.$d);
      setPickerOpen(false); // Close the picker after selecting a date
    }
  };

  const handleInputFocus = () => {
    if (pickerRef) {
      setPickerOpen(true);
    }
  };

  const handlePickerChange = (newDate: Date | null) => {
    handleChange({ $d: newDate });
  };

  const handlePickerClose = () => {
    setPickerOpen(false);
  };

  return (
    <div className="date-picker__outer-wrapper" id={id}  >
      {label && (
        <Label className="input__label" size="h5" weight={600}>
          {label}
        </Label>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          open={pickerOpen}
          onClose={handlePickerClose}
          views={['year', 'month', 'day']}
          value={inputVal}
          ref={pickerRef}
          onChange={handlePickerChange}
          renderInput={({ inputRef, inputProps }) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input
                onClick={handleInputFocus}
                disabled
                ref={inputRef}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
                className='date-input' />
            </Box>
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

CustomDatePicker.defaultProps = {
  helperText: 'Choose Date',
  invalid: false,
  onError: () => console.error('er'),
  id: 'datepicker',
  invalidText: 'Invalid Date',
};

CustomDatePicker.displayName = 'DatePicker';

export default CustomDatePicker;
