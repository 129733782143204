import QueryString from 'qs';
import api from '../api';


export const studiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudies: builder.query({
      query: ({ order_by, pageCurrent, pageSize }) => `/study?${QueryString.stringify({ order_by, pageCurrent, pageSize })}`,
      // transformResponse: (response: any ) => response.items,
      providesTags: ['STUDIES'],
    }),
    getRegistries: builder.query({
      query: ({ order_by, pageCurrent, pageSize }) => `/study/registries?${QueryString.stringify({ order_by, pageCurrent, pageSize })}`,
      // transformResponse: (response: any ) => response.items,
      providesTags: ['REGISTRIES'],
    }),
    createStudy: builder.mutation({
      query: (payload) => ({ url: '/study', method: 'POST', body: payload }),
      invalidatesTags: ['STUDIES', 'REGISTRIES'],
    }),
    updateStudy: builder.mutation({
      query: (payload) => ({ url: `/study/${payload.id}`, method: 'PUT', body: payload }),
      invalidatesTags: ['STUDIES'],
    }),
    removeStudy: builder.mutation({
      query: (id) => ({ url: `/study/${id}`, method: 'DELETE' }),
      invalidatesTags: ['STUDIES'],
    }),
    getStudySites: builder.query({
      query: ({ studyID }) => ({ url:`/site/${ studyID }`, method: 'GET' }),
      transformResponse: (response: any ) => response.items,
      providesTags: ['SITES'],
    }),
  }),
});

export const { 
  usePrefetch,
  useGetStudiesQuery, 
  useCreateStudyMutation, 
  useUpdateStudyMutation, 
  useRemoveStudyMutation, 
  useGetStudySitesQuery, 
  useGetRegistriesQuery,
} = studiesApi;
