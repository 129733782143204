
const trialsMenu = [
  {
    title: 'Trials',
    url: '/private/studies',
    private: true,
  },
];

export default trialsMenu;

