import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { Tile, Label, Spacer, Alert } from '../../../components/atoms';
import { CareItemFactory, CarePathListItem, PatientEMRData } from '../../../components/molecules';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { useGetPatientcarepathwayStatsQuery, useGetPatientPathwaysQuery, useAdvancePatientStepMutation } from '../../../api/builders/registries.api';

interface LocationState {
  // state: {
  patient: any;
  // };
}

interface Step {
  completionDate: string;
  description: string;
  id: string;
  isFirst: boolean;
  isLast: boolean;
  label: string;
  nextSteps: { toStep: string; condition: string }[];
  order: number;
  patientStatus: string;
  relativeStartDay: string;
  relativeStartDayVariance: string;
  scheduledDate: string;
  stepLabel: string;
  type: string;
}

function findActiveStep(steps: Step[]): Step | undefined {
  const nullSteps = steps.filter(step => step.patientStatus === 'null');
  const pendingSteps = steps.filter(step => step.patientStatus === 'pending');
  const startedSteps = steps.filter(step => step.patientStatus === 'started');
  const completedSteps = steps.filter(step => step.patientStatus === 'COMPLETED');

  if (pendingSteps.length > 0 || startedSteps.length > 0) {
    return pendingSteps[0] || startedSteps[0];
  }

  if (completedSteps.length > 0) {
    const sortedCompletedSteps = completedSteps.sort((a, b) =>
      a.completionDate.localeCompare(b.completionDate),
    );
    const lastCompletedStep = sortedCompletedSteps[sortedCompletedSteps.length - 1];
    const index = steps.findIndex(step => step.id === lastCompletedStep.id);
    return steps[index + 1];
  }

  const orderOneStep = steps.find(step => step.order === 1);
  return orderOneStep || undefined;
}


type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const PatientCarePathway = () => {
  const location = useLocation();
  const { patient } = location?.state as LocationState;
  // eslint-disable-next-line no-nested-ternary
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetPatientPathwaysQuery(patient?.subjectId ? patient.subjectId : patient.id ? patient.id : skipToken);
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [currPath, setCurrPath] = useState<string | null>(null);
  const { data: pathway } = useGetPatientcarepathwayStatsQuery(currPath ?? skipToken);
  const [allsteps, setAllSteps] = useState<any | null>(null);
  // active step === step patient is currently on
  const [activeStep, setActiveStep] = useState<any | null>(null);
  // current step is step clicked
  const [currentStep, setCurrentStep] = useState<any | null>(null);
  const [edit, { isLoading: isEdititng, isSuccess, isError, error }] = useAdvancePatientStepMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  React.useEffect(() => {
    const newBreadcrumb = {
      label: patient?.mrn ?? 'Patient Care Pathway',
      path: location.pathname,
      state: { patient },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Saving Edits: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Saving Progress',
        kind: 'success',
      });
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && data[0].id) {
      setCurrPath(data[0].id);
    }
  }, [data]);

  useEffect(() => {
    if (Array.isArray(pathway)
      && pathway.length > 0
      && pathway[0].carepathway
      && Array.isArray(pathway[0].carepathway.steps)) {
      setAllSteps(pathway[0].carepathway.steps);
      const findActive = findActiveStep(pathway[0].carepathway.steps);
      setActiveStep(findActive);
      if (!currentStep) {
        setCurrentStep(findActive);
      }
    }
  }, [pathway]);

  const advanceStep = (newStatus: any) => {
    try {
      const pl = {
        patientcarepathwayId: currPath,
        body: newStatus,
      };
      edit(pl);
    } catch (e) {
      console.error(e);
    }
  };

  const handleClickItem = (newStep: any) => {
    setCurrentStep(newStep);
  };


  return (
    <>
      <div className="patient-care-pathway">
        {data?.length === 0 ?
          <div className='h-100 w-100 super-centered'>
            <Label uppercase size="h3" weight={600}>Patient has no Carepathway</Label>
          </div>
          :
          <>
            <div className='patient-care-pathway__panel1'>
              <Tile className='patient-care-pathway__tile' >
                <div>
                  <Label uppercase size="h3" weight={600}>
                    {(Array.isArray(pathway) && pathway.length > 0 && pathway[0].carepathway) ? pathway[0].carepathway.name : 'Care Pathway'}
                  </Label>
                  <Spacer value={10} />
                  {/* <div className="care-path-list">
                    {Array.isArray(allsteps) &&
                      <div>
                        {allsteps.map((step: any, index: number) => (
                          <CarePathListItem
                            step={step}
                            currentStep={activeStep}
                            isActive={step.id === currentStep.id}
                            handleClick={handleClickItem}
                            showConnectingLine={index > 0 && step.order === allsteps[index - 1].order}
                          />
                        ))}
                      </div>
                    }
                  </div> */}
                  {Array.isArray(allsteps) && (
                    <div className="care-path-list">
                      {allsteps.map((step: any, index: number) => {
                        const prevStep = allsteps[index - 1];
                        const showConnectingLine = index > 0 && Number(step.order) === Number(prevStep?.order);

                        return (
                          <div className='care-path-list-wrapper'>
                            <CarePathListItem
                              key={step.id}
                              step={step}
                              currentStep={activeStep}
                              isActive={step.id === currentStep.id}
                              handleClick={handleClickItem}
                              showConnectingLine={showConnectingLine}
                            />
                            {showConnectingLine && <div className="connecting-line" /> }
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Tile>
            </div>
            <div className='patient-care-pathway__panel2'>
              {currentStep && <CareItemFactory
                className='patient-care-pathway__tile'
                activeStep={activeStep}
                currentStep={currentStep}
                advanceStep={advanceStep}
                isLoading={isEdititng}
                patientPathInfo={pathway[0]}
                filePath={`s3://upstream-prod-files-fs/PatientCarePathway/${pathway.id}/`}
              />}
            </div>
            <div className='patient-care-pathway__panel3'>
              {/* eslint-disable-next-line no-nested-ternary */}
              <PatientEMRData className='patient-care-pathway__tile' id={patient?.subjectId ? patient.subjectId : patient.id ? patient.id : 'N/A'} />
            </div>
          </>
        }
      </div>
      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </>
  );
};

export default withAuthenticator(PatientCarePathway);
