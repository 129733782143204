import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import { Dropdown, Label, Spacer, Button } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import './add-study.scss';

export interface AddStudyProps {
  // onAddStudy: (payload: any) => void,
  id?: string,
}

export interface TypeError {
  status: number,
  data: { message: string },
}

const initialForm = {
  studyId: '',
  title: '',
  summary: '',
  description: '',
  status: '',
  sponsor: '',
};


const AddStudy = (props: AddStudyProps): JSX.Element => {
  // const { onAddStudy } = props;
  const [values, setValues] = React.useState(initialForm);
  const [error, setError] = React.useState({ open: false, message: '' });

  const handleChangeVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };
  const handleChangeSummary = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues({ ...values, [event.target.id]: event.target.value });
  };
  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const closeError = () => {
    setError({ open: false, message: '' });
  };


  const onSubmit = () => {
    // try {
    //   onAddStudy(values);
    // } catch (e) {
    //   const err = e as TypeError;
    //   if (err?.status === 400) {
    //     setError({
    //       open: true,
    //       message: err.data.message,
    //     });
    //   }
    // }
  };

  return (
      <div className="card">
        <TextInput
          name='title'
          label='Title'
          value={values.title}
          onChange={handleChangeVals}
        />
        <TextInput
          name='studyId'
          label='Study Id'
          value={values.studyId}
          onChange={handleChangeVals}
        />
        <TextInput
          name='stusponsordyId'
          label='Study Id'
          value={values.sponsor}
          onChange={handleChangeVals}
        />
        <Dropdown
          name='status'
          label='status'
          value={values.status}
          onChange={handleChangeStatus}
        >
          <MenuItem value="training">training</MenuItem>
        </Dropdown>
        <TextAreaInput
          name='summary'
          label='Summary'
          value={values.summary}
          onChange={handleChangeSummary}
        />
        <Spacer value={20} />

        <div className='btn-row'>
          <Button onClick={onSubmit}>Submit</Button>
        </div>
      </div>
  );
};

AddStudy.defaultProps = {
  id: 'what',
};

AddStudy.displayName = 'AddStudy';
export default AddStudy;
