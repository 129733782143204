import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Label, Spacer, Spinner } from '../../atoms';
import SearchBox from '../SearchBox/SearchBox';
import './clinic-visits.scss';

type Patient = {
  id: string;
  firstname: string;
  lastname: string;
};

type ClinicVisit = {
  id: string;
  visitDate: number;
  visitTime: string;
  firstname: string;
  lastname: string;
  visitLabel: string;
  status: string;
  attendingDoctorFirstname: string;
  attendingDoctorLastname: string;
};

type ClinicVisitsProps = {
  data: ClinicVisit[] | [];
  // currentUser: any;
  loading: boolean;
  openHandler: (item: ClinicVisit) => void;
  clinic: string;
};

const ClinicVisits: React.FC<ClinicVisitsProps> = ({ data, loading, openHandler, clinic }) => {
  const [tableData, setTableData] = useState<ClinicVisit[]>([]);
  const [sortColumn, setSortColumn] = useState<string>('visitTime');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [filterType, setFilterType] = useState<string>('today');
  const [searchTerm, setSearchTerm] = useState<string>('');
  // const [filterByCurrentUser, setFilterByCurrentUser] = useState<boolean>(clinic === 'Epilepsy' ? true : false);

  useEffect(() => {
    // Apply filters and sorting to the data
    setTableData([]);
    // let filteredData = data;
    let filteredData = [...data];
    if (filterType === 'today') {
      filteredData = data.filter((row) => {
        const visitDate = new Date(row.visitDate).toDateString();
        return visitDate === new Date().toDateString();
      });
    } else if (filterType === 'tomorrow') {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      filteredData = data.filter((row) => {
        const visitDate = new Date(row.visitDate).toDateString();
        return visitDate === tomorrow.toDateString();
      });
    } else if (filterType === 'this-week') {
      const thisWeek = new Date();
      const endOfWeek = new Date(
        thisWeek.getFullYear(),
        thisWeek.getMonth(),
        thisWeek.getDate() + (7 - thisWeek.getDay()),
      );
      filteredData = data.filter((row) => {
        const visitDate = new Date(row.visitDate).toDateString();
        return (
          new Date(visitDate) >= new Date() && new Date(visitDate) <= endOfWeek
        );
      });
    }

    if (searchTerm) {
      filteredData = filteredData.filter((row) => {
        const searchString = Object.values(row)
          .join(' ')
          .toLowerCase();
        return searchString.includes(searchTerm.toLowerCase());
      });
    }

    // if (filterByCurrentUser) {
    //   filteredData = filteredData.filter(
    //     (row) =>
    //       row.attendingDoctorFirstname.toLowerCase() ===
    //       currentUser.firstname.toLowerCase() &&
    //       row.attendingDoctorLastname.toLowerCase() ===
    //       currentUser.lastname.toLowerCase()
    //   );
    // }

    if (sortColumn) {
      filteredData.sort((a: any, b: any) => (
        a[sortColumn].toString().localeCompare(b[sortColumn].toString(), 'en', {
          numeric: true,
        }) * (sortOrder === 'asc' ? 1 : -1)
      ));
    }

    setTableData(filteredData);
  }, [data, filterType, searchTerm, sortColumn, sortOrder]);

  const handleHeaderClick = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const handleFilterClick = (filter: string) => {
    setFilterType(filter);
  };

  const handleSearchChange = (val: string) => {
    setSearchTerm(val);
  };

  // const handleCurrentUserFilterChange = () => {
  //   setFilterByCurrentUser(!filterByCurrentUser);
  // };

  // const renderProviderFilter = () => {
  //   let name = ''
  //   if (currentUser && currentUser.firstname && currentUser.lastname) {
  //     name = currentUser.firstname + ' ' + currentUser.lastname
  //   }
  //   return (
  //     <div>
  //       <Checkbox checked={filterByCurrentUser} onChange={handleCurrentUserFilterChange} name='filter' label={` Provider ${name}`} />
  //     </div>
  //   )
  // }

  return (
    <div className='clinic-visits'>
      <div className='clinic-visits__tile'>
        <div className='h-100'>
          <div className='clinic-visits__sticky'>
            <div className='clinic-visits__header'>
              <span className='w-100'>
                <Label size="h4" uppercase weight={600}  >CLINIC VISITS</Label>
              </span>
              <SearchBox onChange={handleSearchChange} placeholder='Search' />
            </div>
            <div className='clinic-visits__header'>
              <button type='button' onClick={() => handleFilterClick('today')} className={filterType === 'today' ? 'clinic-visits__active' : 'clinic-visits__toggle'}>
                Today
              </button>
              <button type='button' onClick={() => handleFilterClick('tomorrow')} className={filterType === 'tomorrow' ? 'clinic-visits__active' : 'clinic-visits__toggle'}>
                Tomorrow
              </button>
              <button type='button' onClick={() => handleFilterClick('this week')} className={filterType === 'this week' ? 'clinic-visits__active' : 'clinic-visits__toggle'}>
                This Week
              </button>
            </div>
            {/* <div className='clinic-visits__filter-wrapper'>
              {renderProviderFilter()}
            </div> */}
          </div>
          <Spacer value={10} />
          <table className='visits-table'>
            <thead>
              <tr>
                <th onClick={() => handleHeaderClick('visitDate')}>
                  <Label size='h6' uppercase>Date</Label>
                </th>
                <th onClick={() => handleHeaderClick('visitTime')}>
                  <Label size='h6' uppercase>Time</Label>
                </th>
                <th onClick={() => handleHeaderClick('firstname')}>
                  <Label size='h6' uppercase>Patient</Label>
                </th>
                <th colSpan={2} onClick={() => handleHeaderClick('visitLabel')}>
                  <Label size='h6' uppercase>Visit</Label>
                </th>
                <th onClick={() => handleHeaderClick('status')}>
                  <Label size='h6' uppercase>Status</Label>
                </th>
                <th onClick={() => handleHeaderClick('attendingDoctorLastname')}>
                  <Label size='h6' uppercase>Provider</Label>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item) => (
                <tr className='visits-table__row' onClick={() => openHandler(item)} key={item.id}>
                  <td><Label weight={200} size='h5'>{moment(item.visitDate).format('MM/DD')}</Label></td>
                  <td><Label weight={200} size='h5'>{item.visitTime}</Label></td>
                  <td><Label weight={200} size='h5'>{item.firstname} {item.lastname} </Label></td>
                  <td colSpan={2}><Label weight={200} size='h5'>{item.visitLabel} </Label></td>
                  <td><Label weight={200} size='h5'>{item.status} </Label></td>
                  <td><Label weight={200} size='h5'>{item.attendingDoctorFirstname} {item.attendingDoctorLastname} </Label></td>
                </tr>
              ))}
            </tbody>
          </table>
          {tableData.length === 0 &&
            <div className='clinic-visits__empty'>
              <Label size='h6'>Nothing to Show</Label>
              {loading &&
                <Spinner size={20} />
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ClinicVisits;


// import React from 'react';
// // import classnames from 'classnames';
// import './clinic-visits.scss';

// export interface ClinicVisitsProps  {
//   id: string,
//   className?: string,
// }

// const ClinicVisits = (props: ClinicVisitsProps): JSX.Element => {
//   const { id, className } = props;
//   return (
//         <div key={`ClinicVisits_${id}`} className='card'>
//         ClinicVisits
//         </div>
//   ); 
// };

// ClinicVisits.defaultProps = {
//   className: '',
// };

// ClinicVisits.displayName = 'ClinicVisits';
// export default ClinicVisits;
