import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Tile, Label, Text, Button, Spacer } from '../../atoms';
import './survey-list-item.scss';

export interface SurveyListItemProps {
  className?: string,
  survey: any,
  startSurvey: (item: any) => void,
}

const SurveyListItem = (props: SurveyListItemProps): JSX.Element => {
  const { survey, startSurvey } = props;


  return (
    <div className="survey-list-item">
      <Label size="h4" color="ghost">
        {survey?.description ? survey?.description : ''}
      </Label>
      <Spacer value={10} />
      <Label size="h4" color="ghost">
        {survey?.instructions ? survey?.instructions : ''}
      </Label>
      <Spacer value={10} />
      <Label size="h4" weight={200} color="ghost" >Estimated time to complete: about 5 minutes</Label>
      <Spacer value={30} />

      <div className='survey-list-item__btn'>
        {survey.status === 'completed' ?
          <Label size='h6' weight={300} color='success'>Completed</Label>
          :
          <Button onClick={() => startSurvey(survey)}> Start Survey <Spacer value={5} /> <BsArrowRight color='white' /></Button>
        }
      </div>
    </div>
  );
};

SurveyListItem.defaultProps = {
  className: '',
};

SurveyListItem.displayName = 'SurveyListItem';
export default SurveyListItem;
