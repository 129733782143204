import React, { useState, useEffect } from 'react';
import { Tile, Label, Spacer } from '../../../atoms';
import '../form-item.scss';

export interface TitleProps {
  label: any,
}

const Title = (props: TitleProps): JSX.Element => {
  const { label } = props;


  return (
    <div className='span-two' >
      <Spacer value={10} />
      <Label size="h3" weight={600} >
        {label}
      </Label>

    </div>
  );
};

Title.defaultProps = {
};

Title.displayName = 'Title';
export default Title;
