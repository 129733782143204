import React from 'react';
// import classnames from 'classnames';
import { FcCheckmark } from 'react-icons/fc';
import { AiFillEdit } from 'react-icons/ai';
import { BsCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import { Spacer, Button, Checkbox, Label, Modal } from '../../atoms';
import PrintForm from '../ProtocolPrintForm/ProtocolPrintForm';
import './met-review.scss';

export interface MetReviewProps {
  mets: Met[],
  itemId: string | undefined,
  cancelReviewMode: () => void,
  onChangeItem: (item: Met, indx: number) => void,
  submit: () => void,
  role: string,
  info: any,
  path: string | undefined,
  protocolItem: any,
}

export interface Met {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  isTreated: boolean,
  isTreatable: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  visit?: string,
  tumorassessments: MetHx[] | [],
}

export interface MetHx {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  tumorAssessmentId: string,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  protocolItemId: string,
  isTreated: boolean,
  isTreatable: boolean,
}
const MetReview = (props: MetReviewProps) => {
  const { mets, itemId, cancelReviewMode, onChangeItem, submit, role, path, info, protocolItem } = props;
  const [checked, setChecked] = React.useState<Array<string>>([]);
  const [printModal, setPrintModal] = React.useState(false);

  const handleSubmit = () => {
    submit();
    // cancelReviewMode();
  };

  const handleChecked = (met: string) => {
    const found = checked.includes(met);
    let newArr: string[] = [];
    if (found) {
      newArr = checked.filter((item) => item !== met);
    } else {
      newArr = checked.concat(met);
    }
    setChecked(newArr);
  };

  const renderRow = (item: Met, index: number, noAction?: boolean) => {
    if (item?.tumorassessments?.length > 0) {
      const findMatch = item.tumorassessments.filter(it => it.protocolItemId === itemId);
      if (findMatch && findMatch.length > 0) {
        return (
          <tr className='met-manager__met-item-wrapper' key={index} role="button" tabIndex={-1} >
            < td className='met-manager__table-td'>
              <Label size='h6'>
                {item.tumorLabel ? item.tumorLabel : <span>-</span>}
              </Label>
            </td>
            < td className='met-manager__table-td'>
              <Label size='h6'>
                {item.lobe ? item.lobe : <span>-</span>}
              </Label>
            </td>
            < td className='met-manager__table-td'>
              <Label size='h6'>
                {item.laterality ? item.laterality : <span>-</span>}
              </Label>
            </td>
            <td >
              <Label center size='subtext'>RL / AP / SI</Label>
              <Label size='h6'>
                <p className='d-flex-row-center'>
                  {item.RLCoord ? item.RLCoord : <span>-</span>} /{' '}
                  {item.APCoord ? item.APCoord : <span>-</span>} /{' '}
                  {item.SICoord ? item.SICoord : <span>-</span>}
                </p>
              </Label>
            </td>
            <td>
              <Label center size='subtext'>Axial / Coronal / Sagittal</Label>
              <Label size='h6'>
                <p className='d-flex-row-center'>
                  {findMatch[0].diameterAxial ? findMatch[0].diameterAxial : <span>-</span>}  /{' '}
                  {findMatch[0].diameterCoronal ? findMatch[0].diameterCoronal : <span>-</span>} /{' '}
                  {findMatch[0].diameterSagittal ? findMatch[0].diameterSagittal : <span>-</span>}
                </p>
              </Label>
            </td>
            < td className='met-manager__table-td'>
              <Label size='h6'>
                {item.isTreated ? <span>Yes</span> : <span>No</span>}
              </Label>
            </td>
            < td className='met-manager__table-td'>
              <Label size='h6'>
                {item.isTreatable ? <span>Yes</span> : <span>No</span>}
              </Label>
            </td>

            < td className='met-manager__table-td'>
              {item?.visit && item.visit === 'Screening and Baseline' ? <BsCheckCircleFill /> : <BsFillXCircleFill />}
            </td>
            {!noAction && < td className='met-manager__table-td'>
              <button type='button' aria-label='edit' className='icon-btn' onClick={() => onChangeItem(item, index)} ><AiFillEdit /></button>
            </td>}
            {!noAction && < td className='met-manager__table-td'>
              <span><Checkbox label='' checked={checked.includes(item.id)} onChange={() => handleChecked(item.id)} /></span>
            </td>}
          </tr>
        );
      }
    }

    return (
      <tr className='met-manager__met-item-wrapper' key={index} role="button" tabIndex={-1} >
        < td className='met-manager__table-td'>
          <Label size='h6'>
            {item.tumorLabel ? item.tumorLabel : <span>-</span>}
          </Label>
        </td>
        < td className='met-manager__table-td'>
          <Label size='h6'>
            {item.lobe ? item.lobe : <span>-</span>}
          </Label>
        </td>
        < td className='met-manager__table-td'>
          <Label size='h6'>
            {item.laterality ? item.laterality : <span>-</span>}
          </Label>
        </td>
        <td>
          <Label center size='subtext'>RL / AP / SI</Label>
          <Label size='h6'>
            <p className='d-flex-row-center'>
              {item.RLCoord ? item.RLCoord : <span>-</span>} / {' '}
              {item.APCoord ? item.APCoord : <span>-</span>} / {' '}
              {item.SICoord ? item.SICoord : <span>-</span>}
            </p>
          </Label>
        </td>
        <td>
          <Label center size='subtext'>Axial / Coronal / Sagittal</Label>
          <Label size='h6'>
            <p className='d-flex-row-center'>
              <span>-</span>  / <span>-</span> / <span>-</span>
            </p>
          </Label>
        </td>
        < td className='met-manager__table-td'>
          <Label size='h6'>
            {item.isTreated ? <span>Yes</span> : <span>No</span>}
          </Label>
        </td>
        < td className='met-manager__table-td'>
          <Label size='h6'>
            {item.isTreatable ? <span>Yes</span> : <span>No</span>}
          </Label>
        </td>
        < td className='met-manager__table-td'>
          {item?.visit && item.visit === 'Screening and Baseline' ? <BsCheckCircleFill /> : <BsFillXCircleFill />}
        </td>
        {!noAction && < td className='met-manager__table-td'>
          <button type='button' aria-label='edit' className='icon-btn' onClick={() => onChangeItem(item, index)} ><AiFillEdit /></button>
        </td>}
        {!noAction && < td className='met-manager__table-td'>
          <Checkbox label='' checked={checked.includes(item.id)} onChange={() => handleChecked(item.id)} />
        </td>}
      </tr>
    );
  };


  const renderReviewerRow = (item: Met, index: number, noAction?: boolean) => {
    if (item?.tumorassessments?.length > 0) {
      const findMatch = item.tumorassessments.filter(it => it.protocolItemId === itemId);
      if (findMatch && findMatch.length > 0) {
        return (
          <tr className='met-manager__met-item-wrapper' key={index} role="button" tabIndex={-1} >
            < td className='met-manager__table-td'>
              <Label size='h6'>
                {item.tumorLabel ? item.tumorLabel : <span>-</span>}
              </Label>
            </td>
            < td className='met-manager__table-td'>
              <Label size='h6'>
                {item.lobe ? item.lobe : <span>-</span>}
              </Label>
            </td>
            < td className='met-manager__table-td'>
              <Label size='h6'>
                {item.laterality ? item.laterality : <span>-</span>}
              </Label>
            </td>
            <td >
              <Label center size='subtext'>RL / AP / SI</Label>
              <Label size='h6'>
                <p className='d-flex-row-center'>
                  {item.RLCoord ? item.RLCoord : <span>-</span>} /{' '}
                  {item.APCoord ? item.APCoord : <span>-</span>} /{' '}
                  {item.SICoord ? item.SICoord : <span>-</span>}
                </p>
              </Label>
            </td>
            <td>
              <Label center size='subtext'>Axial / Coronal / Sagittal</Label>
              <Label size='h6'>
                <p className='d-flex-row-center'>
                  {findMatch[0].diameterAxial ? findMatch[0].diameterAxial : <span>-</span>}  /{' '}
                  {findMatch[0].diameterCoronal ? findMatch[0].diameterCoronal : <span>-</span>} /{' '}
                  {findMatch[0].diameterSagittal ? findMatch[0].diameterSagittal : <span>-</span>}
                </p>
              </Label>
            </td>
            {!noAction && < td className='met-manager__table-td'>
              <button type='button' aria-label='edit' className='icon-btn' onClick={() => onChangeItem(item, index)} ><AiFillEdit /></button>
            </td>}
            {!noAction && < td className='met-manager__table-td'>
              <span><Checkbox label='' checked={checked.includes(item.id)} onChange={() => handleChecked(item.id)} /></span>
            </td>}
          </tr>
        );
      }
    }

    return (
      <>
        <tr className='met-manager__met-item-wrapper' key={index} role="button" tabIndex={-1} >
          < td className='met-manager__table-td'>
            <Label size='h6'>
              {item.tumorLabel ? item.tumorLabel : <span>-</span>}
            </Label>
          </td>
          < td className='met-manager__table-td'>
            <Label size='h6'>
              {item.lobe ? item.lobe : <span>-</span>}
            </Label>
          </td>
          < td className='met-manager__table-td'>
            <Label size='h6'>
              {item.laterality ? item.laterality : <span>-</span>}
            </Label>
          </td>
          <td>
            <Label center size='subtext'>RL / AP / SI</Label>
            <Label size='h6'>
              <p className='d-flex-row-center'>
                {item.RLCoord ? item.RLCoord : <span>-</span>} / {' '}
                {item.APCoord ? item.APCoord : <span>-</span>} / {' '}
                {item.SICoord ? item.SICoord : <span>-</span>}
              </p>
            </Label>
          </td>
          <td>
            <Label center size='subtext'>Axial / Coronal / Sagittal</Label>
            <Label size='h6'>
              <p className='d-flex-row-center'>
                <span>-</span>  / <span>-</span> / <span>-</span>
              </p>
            </Label>
          </td>
          {!noAction && < td className='met-manager__table-td'>
            <button type='button' aria-label='edit' className='icon-btn' onClick={() => onChangeItem(item, index)} ><AiFillEdit /></button>
          </td>}
          {!noAction && < td className='met-manager__table-td'>
            <Checkbox label='' checked={checked.includes(item.id)} onChange={() => handleChecked(item.id)} />
          </td>}
        </tr>
      </>
    );
  };

  const sort = (items: Met[]) => {
    try {
      const arrayForSort = [...items];
      let test = [];
      test = arrayForSort.sort((a, b) => a.tumorLabel.localeCompare(b.tumorLabel));
      return test;
    } catch {
      console.error('failed to sort MET data');
      return items;
    }
  };





  return (
    <div className="card">
      <div className='border-heading'>
        <Label size='h4' weight={600}>Mets:</Label>
      </div>
      {role === 'SITE COORDINATOR' ?
        <table className='met-manager__table'>
          <thead className='met-manager__table-thead'>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>MET</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Lobe</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Laterality</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Coordinates</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Dimensions</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Treated</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Treatable</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Added At Baseline</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Actions</Label>
            </th>
            <th className='met-manager__table-th'> <FcCheckmark /></th>
          </thead>

          <tbody>
            {Array.isArray(mets) && mets.length > 0 && sort(mets).map((item: Met, index) => (
              renderRow(item, index)
            ),
            )}
          </tbody>
        </table>
        :
        <table className='met-manager__table'>
          <thead className='met-manager__table-thead'>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>MET</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Lobe</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Laterality</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Coordinates</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Dimensions</Label>
            </th>
            <th className='met-manager__table-th'>
              <Label size='h6' uppercase>Actions</Label>
            </th>
            <th className='met-manager__table-th'> <FcCheckmark /></th>
          </thead>

          <tbody>
            {Array.isArray(mets) && mets.length > 0 && sort(mets).map((item: Met, index) => (
              renderReviewerRow(item, index)
            ),
            )}
          </tbody>
        </table>
      }

      <Spacer value={10} />
      <div className='btn-row'>
        <Button kind='danger' onClick={cancelReviewMode}>Cancel</Button>
        <Button disabled={mets?.length !== checked?.length} onClick={() => setPrintModal(true)}>Sign and Submit</Button>
      </div>
      <Spacer value={20} />

      <Modal title='Print' size='lg' open={printModal} onClose={() => setPrintModal(false)}>
        <PrintForm
          protocolItem={protocolItem}
          info={info}
          path={path}
          submitSurvey={handleSubmit}
        >
          <>
            {role === 'SITE COORDINATOR' ?
              <table className='met-manager__table'>
                <thead className='met-manager__table-thead'>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>MET</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Lobe</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Laterality</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Coordinates</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Dimensions</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Treated</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Treatable</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Added At Baseline</Label>
                  </th>
                </thead>

                <tbody>
                  {Array.isArray(mets) && mets.length > 0 && sort(mets).map((item: Met, index) => (
                    renderRow(item, index, true)
                  ),
                  )}
                </tbody>
              </table>
              :
              <table className='met-manager__table'>
                <thead className='met-manager__table-thead'>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>MET</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Lobe</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Laterality</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Coordinates</Label>
                  </th>
                  <th className='met-manager__table-th'>
                    <Label size='h6' uppercase>Dimensions</Label>
                  </th>
                </thead>

                <tbody>
                  {Array.isArray(mets) && mets.length > 0 && sort(mets).map((item: Met, index) => (
                    renderReviewerRow(item, index, true)
                  ),
                  )}
                </tbody>
              </table>
            }
          </>
        </PrintForm>
      </Modal>
    </div>
  );
};

MetReview.defaultProps = {
  // key: '_key',
};

MetReview.displayName = 'MetReview';


export default MetReview;
