import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetSessionStatsQuery } from '../../api/builders/patientSurveys.api';
import { Label, Alert, Spacer, Button, Spinner, AddParticipant, ParticipantListItem, SearchBox } from '../../components';
import { SurveyListItem } from '../../components/molecules';




const LinkedPatientHome = () => {
  const somevar = 'LinkedPatientHome';
  const navigate = useNavigate();
  const { id } = useParams();
  const [surveys, setSurveys] = useState<any>(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  // const { data: surveys, isLoading: isLoadingData, isFetching: isFetchingData } = useGetSessionStatsQuery(payload ?? skipToken);
  // 439475c75959f6645948b48557397424

  const fetchSurveys = (hash: any) => {
    try {
      const doo = axios({
        method: 'GET',
        url: `https://wnbtkxpai5.execute-api.us-east-1.amazonaws.com/prod/session/statsbyhashid/${hash}`,
        // headers: headers,
      })
        .then(response => {
          const responseData = response.data;
          setSurveys(responseData.items); 
          return 'hi';
        });
    } catch (e) {
      return 'error';
    }
    return 'hi';
  };


  useEffect(() => {
    if (id && id.length > 0 && id !== 'nullID') {
      fetchSurveys(id);
    }
  }, [id]);


  const handleStartSurvey = async (survey: any) => {
    navigate('/mysurveys/survey', { state: { survey } });
  };

  const surveysToComplete = (survs: any) => {
    let tmp = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < survs.length; i++) {
      if (survs[i].status && survs[i].status !== 'completed') {
        tmp += 1;
      }
    }
    return tmp;
  };


  return (
    <div className="linked-patient-home">
      {
        (isLoadingData) ?
          <div className='linked-patient-home__empty'>
            <Spinner />
          </div>
          :
          <div>
            {
              surveys && Array.isArray(surveys) && surveys.length > 0 && surveys[0].usersurvey && Array.isArray(surveys[0].usersurvey) && surveys[0].usersurvey.length > 0 ?
                <>
                  {surveysToComplete(surveys[0].usersurvey) === 0 ?
                    <Label size="h4" bold color="primary">
                      You have no surveys to complete at this time
                    </Label>
                    :
                    <Label size="h4" bold color="primary">
                      You have {surveysToComplete(surveys[0].usersurvey)} surveys to complete before your next visit
                    </Label>
                  }
                  <Spacer value={15} />
                  {surveys[0].usersurvey.map((survey: any) => (
                    <SurveyListItem startSurvey={handleStartSurvey} survey={survey} />
                  ))
                  }
                </>
                :
                <div className='linked-patient-home__empty'>
                  <Label size='h3'>You have no surveys left to complete!</Label>
                </div>
            }
          </div>
      }

    </div>
  );
};

export default LinkedPatientHome;
