/* eslint-disable @typescript-eslint/space-infix-ops */
import React from 'react';
import { FaCheck, FaEllipsisH } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { TfiTime } from 'react-icons/tfi';
import { useCreateImgJobMutation } from '../../../api/builders/imageAnalysis.api';
import { Label, Spacer } from '../../atoms';
import Tab from '../Tab/Tab';
import Tabs from '../Tabs/Tabs';
import './care-step.scss';
import { Analysis, Files, Inputs } from './Tabs';



export interface CareStepProps {
  careCase: any,
  participant: any,
  readO?: boolean,
}

const Header = (headerProps: { title: string, apps: any, appIndex: number, changeApp: any }): JSX.Element => {
  const { title, apps, appIndex, changeApp } = headerProps;

  const renderStatus = (itemStatus: string) => {
    switch (itemStatus) {
      case 'pending':
        return (<div className='care-step__header-icon-wrapper'>
          <TfiTime size={35} className='icon-pending' />
          <div className='care-step__header-divider' />
          <TfiTime size={35} className='icon-pending' />
          <div className='care-step__header-divider' />
          <TfiTime size={35} className='icon-pending' />

        </div>);
      case 'processing started':
        return (<div className='care-step__header-icon-wrapper'>
          <span className='icon-success__wrapper'  >
            <FaCheck size={16} className='icon-success' />
          </span>
          <div className='care-step__header-divider-success' />
          <span className='icon-inProgress__wrapper'  >
            <FaEllipsisH size={16} className='icon-inProgress' />
          </span>
          <div className='care-step__header-divider' />
          <TfiTime size={35} className='icon-pending' />
        </div>);
      case 'error: processing interrupted':
        return (<div className='care-step__header-icon-wrapper'>
          <span className='icon-success__wrapper'  >
            <FaCheck size={16} className='icon-success' />
          </span>
          <div className='care-step__header-divider-success' />
          <span className='icon-error__wrapper'  >
            <IoClose size={20} className='icon-error' />
          </span>
          <div className='care-step__header-divider' />
          <TfiTime size={35} className='icon-pending' />
        </div>);
      case 'processing complete':
        return (<div className='care-step__header-icon-wrapper'>
          <span className='icon-success__wrapper'  >
            <FaCheck size={16} className='icon-success' />
          </span>
          <div className='care-step__header-divider-success' />
          <span className='icon-success__wrapper'  >
            <FaCheck size={16} className='icon-success' />
          </span>
          <div className='care-step__header-divider-success' />
          <span className='icon-success__wrapper'  >
            <FaCheck size={16} className='icon-success' />
          </span>
        </div>);
      default:
        return (<div className='care-step__header-icon-wrapper'>
          <span className='icon-pending__wrapper'  >
            <TfiTime size={35} className='icon-pending' />
          </span>
          <div className='care-step__header-divider' />
          <span className='icon-pending__wrapper'  >
            <TfiTime size={35} className='icon-pending' />
          </span>
          <div className='care-step__header-divider' />
          <span className='icon-pending__wrapper'  >
            <TfiTime size={35} className='icon-pending' />
          </span>
        </div>);
    }
  };
  const sort = (items: any) => {
    try {
      const test = JSON.parse(JSON.stringify(items));
      test.sort((a: any, b: any) => a.order - b.order);
      return test;
    } catch {
      console.error('err sorting');
      return items;
    }
  };
  return (
    <div className='care-step__header'>
      <div>
        {apps?.length > 0 &&
          <div className='d-flex-col'>
            {sort(apps).map((app: any, index: number) => (
              <button type='button' onClick={() => changeApp(index)} className={index === appIndex ? 'care-step__btn-active' : 'care-step__btn'}>{`${app.order}. ${app.title}`}</button>
            ))}
          </div>
        }
      </div>
      <div>
        {renderStatus(apps[appIndex].status)}
        {/* <Label size='subtext'>Status: {apps[appIndex].status}</Label> */}
      </div>
    </div>
  );
};

const CareStep = (props: CareStepProps): JSX.Element => {
  const { careCase, participant, readO } = props;
  const { title } = careCase.analysis;
  // eslint-disable-next-line no-unsafe-optional-chaining
  const [appIndex, setAppIndex] = React.useState(careCase?.userApps.length-1 || 0 );
  const [currStep, setCurrStep] = React.useState<any | null>(null);
  const [addJob] = useCreateImgJobMutation();

  const changeApp = (newIndex: number) => {
    setAppIndex(newIndex);
  };

  React.useEffect(() => {
    if (careCase?.userApps) {
      setCurrStep(careCase?.userApps[appIndex]);
    }
  }, [appIndex, careCase]);

  const handleStartProcessing = (appid: string) => {
    try {
      const payload = {
        userAppId: appid,
        pipelineId: careCase?.analysis?.pipelineId,
        participantId: careCase?.analysis?.participantId,
        analysisId: careCase?.analysis?.id,
      };
      addJob(payload);
    } catch (e) {
      console.error('err start processing', e);
    }
  };

  return (
    <div className="care-step">
      {currStep &&
        <div className="care-step__body">
          <Header title={title} changeApp={changeApp} apps={careCase?.userApps} appIndex={appIndex} />
          {readO ?
            <Tabs>
              <Tab title="Analysis">
                <Analysis careCase={currStep} />
              </Tab>
              <Tab title="Files">
                <Files careCase={currStep} />
              </Tab>
            </Tabs>
            :
            <Tabs>
              <Tab title="Inputs">
                <Inputs participant={participant} careCase={currStep} handleStartProcessing={handleStartProcessing} />
              </Tab>
              <Tab title="Analysis">
                <Analysis careCase={currStep} />
              </Tab>
              <Tab title="Files">
                <Files careCase={currStep} />
              </Tab>
            </Tabs>}
        </div>
      }
    </div>
  );
};

CareStep.defaultProps = {
  readO: false,
};

CareStep.displayName = 'CareStep';
export default CareStep;
