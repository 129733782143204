import React from 'react';
import { Spacer, Label } from '../../components';
import FDA from './image.png';

const Compliance = () => {
  const company = 'UPSTREAM VISION';

  return (
    <div className="compliance">
      <Spacer value={20} />
      <div className="compliance__header">
        <Label size="h2" bold center>{company} Regulatory Compliance</Label>
        <img alt="FDA" src={FDA} height={100} />
      </div>
      <Spacer value={20} />
      <Label size="text" center>
        Welcome to our Regulatory Compliance page, where we highlight our commitment to adhering to the highest standards in clinical trials management. As a trusted provider in the industry, we understand the critical importance of maintaining compliance with relevant regulations, including the FDA 21 CFR Part 11 guidelines.
      </Label>
      <Spacer value={20} />
      <Spacer value={20} />
      <Spacer value={20} />
      <Label size="text" >
        FDA 21 CFR Part 11 sets forth the requirements for electronic records and electronic signatures in industries regulated by the FDA, including the pharmaceutical and medical device sectors. These regulations aim to ensure the integrity, authenticity, and confidentiality of electronic records and the reliability of electronic signatures.
      </Label>
      <Spacer value={10} />
      <Label size="text" >
        {/* At {company}, we have implemented robust systems and processes that align with the FDA 21 CFR Part 11 requirements to ensure the secure and compliant management of clinical trial data. */}
        The {company} platform is a certified and validated software compliant with FDA 21 CFR Part 11.
        This commitment allows us to provide our clients with confidence in the integrity and reliability of their data throughout the trial lifecycle.
      </Label>
      <Spacer value={10} />

      <Label size="text" >
        By incorporating advanced technologies and adhering to the FDA&apos;s guidelines, we provide our clients with:
      </Label>
      <Spacer value={10} />

      <Label size="text" >
        1. Data Integrity: We employ rigorous data management practices to ensure the accuracy, completeness, and consistency of electronic records throughout the trial. This includes appropriate data validation and audit trail functionalities.
      </Label>
      <Spacer value={10} />

      <Label size="text" >
        2. Electronic Signatures: Our systems support secure electronic signatures that comply with the FDA&apos;s requirements, enabling authorized individuals to electronically sign and approve critical documents with confidence.
      </Label>
      <Spacer value={10} />

      <Label size="text" >
        3. Security and Access Controls: We have implemented robust security measures to protect against unauthorized access, data breaches, and ensure the confidentiality and privacy of sensitive trial information.
      </Label>
      <Spacer value={10} />

      <Label size="text" >
        4. Audit Trail Capabilities: Our systems maintain comprehensive audit trails, allowing for thorough monitoring, tracking, and reporting of data activities, ensuring transparency and traceability in compliance with regulatory requirements.
      </Label>
      <Spacer value={10} />

      <Label size="text" >
        By prominently displaying the FDA 21 CFR Part 11 logo, we affirm our dedication to meeting and exceeding the regulatory expectations set forth by the FDA. This logo serves as a symbol of our commitment to maintaining the highest standards of compliance, data integrity, and patient safety in clinical trials management.
      </Label>
      <Spacer value={10} />

      <Label size="text" >
        We continuously stay updated with the evolving regulatory landscape, ensuring that our processes and technologies remain aligned with the latest guidelines. Our team of experts works tirelessly to ensure that your clinical trial data is handled with the utmost care, accuracy, and compliance.
      </Label>
    </div>
  );
};

export default Compliance;
