/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';

import { BreadcrumbsContext } from './utils/breadcrumbs/breadcrumbs-context';
import { PageLayout } from './components';
import { Main, Login, Privacy, Terms, Support, FAQ, Compliance, LinkedPatientHome, LinkedPatientSurvey } from './pages';
import './App.scss?v=1.0';
import ForgotPassword from './pages/Auth/ForgotPassword';
import OTP from './pages/Auth/OTP';
import { logout, selectAuth } from './store/authentication/slice';

// eslint-disable-next-line import/no-named-as-default
import { trialsMenu, allMenu, cohortMenu, privacyMenu, registryMenu, patientMenu } from './utils/navigation';

import { ReviewerTasks, SiteSettings, StudySettings, StudyTracker, Studies, ReviewerParticipantTask, SiteStatus, Site, Study, Participant, Recent, StudyDrive, Registries, Audits, Audit, PrivacyDashboard, Hives, Hive, Cohort, SharedCohort, Patients, Logs, Cohorts, Patient, StudyDashboard, SharedCohorts, Registry, PatientCarePathway, RegistryPatients, RegistrySettings, ClinicPatients, CarePathwayTracker, Conferences, Conference, PatientHome, PatientSurvey } from './pages/private';
import { selectLan } from './store/intl';


Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    region: 'us-east-1',
  },
  Storage: {
    AWSS3: {
      bucket: 'upstream-dev-files-us-east-1-drive', // REQUIRED -  Amazon S3 bucket
      region: 'us-east-1', // OPTIONAL -  Amazon service region
    },
  },
});


function AppRoutes() {
  return <Routes>
    {/* the OG */}
    <Route path="home" element={<Main />} />
    <Route path="privacy" element={<Privacy />} />
    <Route path="compliance" element={<Compliance />} />

    <Route path="/mysurveys/:id" element={<LinkedPatientHome />} />
    <Route path="/mysurveys/survey" element={<LinkedPatientSurvey />} />

    {/* LINKED PATIENTS */}
    {/* <Route path="/mysurveys/:id"  >
      <Route index element={<LinkedPatientHome />} />
      <Route path="survey" element={<LinkedPatientSurvey />} />
    </Route> */}


    <Route path="legal"  >
      <Route path="terms" element={<Terms />} />
      <Route path="privacy" element={<Privacy />} />
    </Route>
    <Route path="support"  >
      <Route path="help" element={<Support />} />
      <Route path="faq" element={<FAQ />} />
    </Route>

    {/* AUTH */}
    <Route path="login" element={<Login />} />
    <Route path="forgot_password" element={<ForgotPassword />} />
    <Route path="OTP" element={<OTP />} />

    <Route path="private" >

      {/* Privacy / audits */}
      <Route path="privacy" element={<PrivacyDashboard />} />
      <Route path="audits"  >
        <Route index element={<Audits />} />
        <Route path="audit" element={<Audit />} />
      </Route>
      <Route path="hives">
        <Route index element={<Hives />} />
        <Route path="hive">
          <Route index element={<Hive />} />
          <Route path="cohort">
            <Route index element={<Cohort />} />
            <Route path="audit" element={<Audit />} />
          </Route>
        </Route>
      </Route>


      {/* CLINIC */}
      {/* <Route path="clinic">
        <Route index element={<ClinicDashboard />} />
      </Route>
      <Route path="conferences">
        <Route index element={<Conferences />} />
        <Route path="conference">
          <Route index element={<Conference />} />
        </Route>
      </Route> */}

      {/* PATIENTS */}
      <Route path="mysurveys"  >
        <Route index element={<PatientHome />} />
        <Route path="survey" element={<PatientSurvey />} />
      </Route>

      {/* Cohorts */}
      <Route path="patients"  >
        <Route index element={<Patients />} />
        <Route path=":linkedCohort" element={<Patients />} />
      </Route>


      <Route path="logs"  >
        <Route index element={<Logs />} />
      </Route>

      <Route path="cohorts"  >
        <Route index element={<Cohorts />} />
        <Route path="item" element={<Cohort />} />
        <Route path="shared" element={<SharedCohort />} />
        <Route path="item/patient" element={<Patient />} />
      </Route>

      {/* IMX */}
      <Route path="shared/cohorts"  >
        <Route index element={<SharedCohorts />} />
      </Route>


      {/* Study */}
      <Route path="studies"  >
        <Route index element={<Studies />} />
        <Route path="study"  >
          <Route index element={<Study />} />
          <Route path="recent" element={<Recent />} />
          <Route path="tasks" element={<ReviewerTasks />} />
          <Route path="drive" element={<StudyDrive />} />
          <Route path="dash" element={<StudyDashboard />} />
          <Route path="tracker" element={<StudyTracker />} />
          <Route path="settings" element={<StudySettings />} />
          <Route path="site-status" element={<SiteStatus />} />
          <Route path="activity" element={<ReviewerTasks />} />
          <Route path="task" element={<ReviewerParticipantTask />} />

          <Route path="site"  >
            <Route index element={<Site />} />
            <Route path="settings" element={<SiteSettings />} />
            <Route path="participant" element={<Participant />} />
            <Route path="site-status" element={<SiteStatus />} />
          </Route>

        </Route>
      </Route>

      {/* REGISTRIES */}
      <Route path="registries">
        <Route index element={<Registries />} />
        <Route path="patients"  >
          <Route index element={<RegistryPatients />} />
          <Route path=":linkedRegistry" element={<RegistryPatients />} />
        </Route>
        <Route path="registry">
          {/* <Route index element={<Registry />} /> */}
          <Route path="overview" element={<Registry />} />
          <Route path="settings" element={<RegistrySettings />} />
          <Route path="patients" element={<ClinicPatients />} />
          <Route path="tracker" element={<CarePathwayTracker />} />
          <Route path="patient" element={<PatientCarePathway />} />
          {/* <Route path="conference" element={<Conference />} /> */}
          <Route path="conferences">
            <Route index element={<Conferences />} />
            <Route path="conference">
              <Route index element={<Conference />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>


    {/* <Route path="*" element={<div><p>err</p></div>} /> */}
    <Route path="*" element={<Login />} />
  </Routes>;
}



function App() {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
  };
  const { token, groups, user } = useSelector(selectAuth);
  const { code, label } = useSelector(selectLan);
  const [breadcrumbs, setBreadcrumbs] = React.useState<any[]>([]);
  const contextValue = useMemo(() => ({ breadcrumbs, setBreadcrumbs }), [breadcrumbs, setBreadcrumbs]);

  useEffect(() => {
    if (code) {
      changeLanguage(code);
    }
  }, [code]);

  const getMenu = () => {
    switch (process.env.REACT_APP_FEATURE) {
      case 'MASTER':
        return allMenu;
      case 'TRIALS':
        return trialsMenu;
      case 'PRIVACY':
        return privacyMenu;
      case 'COHORT':
        return cohortMenu;
      case 'REGISTRY':
        return registryMenu;
      case 'PATIENT':
        return [];
      default:
        return allMenu;
    }
  };

  const memoizedValue = useMemo(() => ({ breadcrumbs, setBreadcrumbs }), [breadcrumbs, setBreadcrumbs]);

  return (
    <BreadcrumbsContext.Provider value={contextValue}>
      <BrowserRouter>
        <PageLayout
          token={token}
          logout={logout}
          navigation={getMenu()}
          userMenuItems={[{
            title: 'Sign out',
            url: '/login',
            private: true,
          }]}
          header
          footer
        >
          <AppRoutes />
        </PageLayout>
      </BrowserRouter>
    </BreadcrumbsContext.Provider>
  );
}

export default App;