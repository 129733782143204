/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment';
import { Label } from '../../atoms';
import './scan-card.scss';

export interface ScanCardProps {
  scan: any,
  onClick?: (arg0: any) => void,
}

const ScanCard = (props: ScanCardProps): JSX.Element => {
  const { scan, onClick } = props;

  const scanDragStart = (event: any, item: any) => {
    try {
      event.dataTransfer.setData('items', JSON.stringify(item));
    } catch (e) {
      console.error(e);
    }
  };

  const handleClick = (event: any, item: any) => {
    try {
      // event.dataTransfer.setData('items', JSON.stringify(item));
      onClick && onClick(item);
      console.debug('on click scan', item);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      draggable
      onDragStart={(e) => scanDragStart(e, scan)}
      onClick={(e)=>handleClick( e, scan) }
      className='scan-card'
      key={scan.id}>
      <Label uppercase size="h5" weight={600} >
        {scan.ScanSeries[0].SeriesDescription}
      </Label>
      <Label uppercase size="h6" >
        Study Date: {moment(scan.StudyDate).format('l')}
      </Label>
      <Label uppercase size="h6" >
        {scan.Modality && <div>Modality: {scan.Modality}</div>}
      </Label>
    </div >
  );
};

ScanCard.defaultProps = {
  onClick: () => null,
};

ScanCard.displayName = 'ScanCard';
export default ScanCard;
