import React from 'react';
// import classnames from 'classnames';
import './protocol-messages.scss';
import moment from 'moment';
import { Tile, Label, Spinner, Text, Modal, Button, Spacer } from '../../atoms';
import { useGetProtocolChatQuery } from '../../../api/builders/chats.api';

export interface ProtocolMessagesProps {
  protocolItem: string,

}

const ProtocolMessages = (props: ProtocolMessagesProps): JSX.Element => {
  const { protocolItem } = props;
  const { data } = useGetProtocolChatQuery(protocolItem);
  const [itemMessages, setItemMessanges] = React.useState<any>([]);


  React.useEffect(() => {
    if (data?.length > 0 && Array.isArray(data[0].chatmessage) && data[0].chatmessage.length > 0) {
      const filtered = data[0].chatmessage.filter((it: any) => it.tags[0].id === protocolItem);
      setItemMessanges(filtered);
    }
  }, [protocolItem]);

  const sort = (items: any) => {
    try {
      const arrayForSort = [...items];
      let test = [];
      test = arrayForSort.sort((a, b) => a.createdAt - b.createdAt);
      return test;
    } catch {
      console.error('failed to sort participant data');
      return items;
    }
  };

  return (
    <div className="card">
      {Array.isArray(itemMessages) && itemMessages.length > 0 ?
        sort(itemMessages).map((item: any, index: number) => (
          <div >
            <Label size='h4' weight={600}> {`${item?.sentBy?.firstname}  ${item?.sentBy?.lastname}  ${moment(item.createdAt).format('lll')}`} </Label>
            <Label size='h4' weight={200}>{item.message}</Label>
            <Spacer value={10} />
          </div>
        ))
        :
        <div className='super-centered'>
          <Label size='h4'>No Chat History</Label>
          <Spacer value={20} />
        </div>
      }
    </div>
  );
};

ProtocolMessages.defaultProps = {
};

ProtocolMessages.displayName = 'ProtocolMessages';
export default ProtocolMessages;
