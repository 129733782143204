import QueryString from 'qs';
import api from '../../../api/api';

export const studyStatusAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudyStOOtOOs: builder.query({
      query: ({ studyId, order_by, pageCurrent, pageSize }) => ({ url: `/study/requirementsbyid/${studyId}?${QueryString.stringify({ order_by, pageCurrent, pageSize })}`, method: 'GET' }),
      transformResponse: (response: any) => response,
    }),
    
  }), 
});

export const { usePrefetch, useGetStudyStOOtOOsQuery } = studyStatusAPI;