import QueryString from 'qs';
import api from '../../../api/api';

export const sitesStatusAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getSiteStatus: builder.query({
      query: ({ siteId, order_by, pageCurrent, pageSize }) => ({ url: `/site/requirementsbyid/${siteId}?${QueryString.stringify({ order_by, pageCurrent, pageSize })}`, method: 'GET' }),
      transformResponse: (response: any) => response,
      providesTags: ['SITE'],
    }),
    
  }), 
});

export const { usePrefetch, useGetSiteStatusQuery } = sitesStatusAPI;
