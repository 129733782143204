/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import * as dicomParser from 'dicom-parser';
import './dicom-table.scss';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import TAG_DICT from '../../../store/dicomdictionnary';
import SearchBox from '../SearchBox/SearchBox';
import FilterBox from '../FilterBox/FilterBox';
import { Spacer, Modal, Label, Button } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import { Scale } from '../QuestionTypes';




export interface DicomTableProps {
  id: string,
  className?: string,
  dicomFileUrl: string | undefined;
  handleSubmitFinding: (vals: any) => void,
  readOnly: boolean,
}

interface Findings {
  riskLevel: number;
  notes: string;
  phi: boolean;
  name: boolean;
  manufacturer: boolean;
  other: boolean;
  date: boolean;
  time: boolean;
  site: boolean;
  physician: boolean;
  mrn: boolean;
  age: boolean;
  imagePHI: boolean;
  nonPHIimageText: boolean;
}

const initial: Findings = {
  riskLevel: 0,
  phi: false,
  name: false,
  manufacturer: false,
  other: false,
  date: false,
  time: false,
  site: false,
  age: false,
  physician: false,
  mrn: false,
  imagePHI: false,
  nonPHIimageText: false,
  notes: '',
};

const DicomTable: React.FC<DicomTableProps> = ({ dicomFileUrl, handleSubmitFinding, readOnly }) => {
  const [header, setHeader] = useState<any | null>(null);
  const [popup, setPopup] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [findings, setFindings] = useState(initial);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any | null>(null);
  const [filter, setFilter] = useState('Clear');

  const closePopup = () => {
    setPopup(false);
    setCurrentRow(null);
    setFindings(initial);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFindings({ ...findings, [event.target.name]: event.target.value });
  };

  const handleToggleChange = (toggle: keyof Findings) => {
    const updatedFinding: Findings = {
      ...findings,
      [toggle]: !findings[toggle],
    };
    setFindings(updatedFinding);
  };

  const handleSubmit = () => {
    const pl = {
      ...findings,
      dicomGroup: currentRow?.group,
      dicomElement: currentRow?.elementValue,
    };
    handleSubmitFinding(pl);
    closePopup();
  };


  const handleRisk = (newVal: any) => {
    const nRisk = newVal[0].value;
    setFindings((prev) => ({ ...prev, riskLevel: nRisk }));
  };

  useEffect(() => {
    if (!dicomFileUrl)
      return;
    setSearchTerm('');
    setFindings(initial);
    setFilter('Clear');
    const headers: {
      tag: string; group: string; elementValue: string; elementType: string | undefined; tagName:
      /* eslint-disable @typescript-eslint/dot-notation */
      /* eslint-disable @typescript-eslint/no-shadow */
      /* eslint-disable @typescript-eslint/no-redeclare */
      /* eslint-disable consistent-return */
      /* eslint-disable no-useless-return */
      /* eslint-disable react/no-array-index-key */
      /* eslint-disable no-restricted-syntax */
      any; value: string | undefined;
    }[] = [];
    async function downloadAndParseDICOM(fileuri: string) {
      try {
        // Download the DICOM file
        const response = await axios.get(fileuri, { responseType: 'arraybuffer' });
        const dicomData = response.data;
        const dicomDict = TAG_DICT;
        // Parse the DICOM header
        const byteArray = new Uint8Array(dicomData);
        const dataSet = dicomParser.parseDicom(byteArray);

        // Print DICOM tags, elements, and values
        for (const tag in dataSet.elements) {
          if (Object.prototype.hasOwnProperty.call(dataSet.elements, tag)) {
            const element = dataSet.elements[tag];
            const tagValue = parseInt(tag.substr(1), 16);
            const group = tag.slice(1, 5);
            const elementValue = tag.slice(5);
            const elementType = element.vr;
            const stringtag = `(${group},${elementValue})` as string;
            const tagName = dicomDict[stringtag.toUpperCase()]?.name || 'Unknown';
            const value = dataSet.string(tag);

            const entry = {
              tag,
              group, elementValue, elementType, tagName, value,
            };
            headers.push(entry);
          }
        }

        // Access DICOM elements from the header
        // const patientName = dataSet.string('x00100010');
        // const studyDescription = dataSet.string('x00081030');
        // const seriesDescription = dataSet.string('x0008103e');

        // Do something with the parsed DICOM header
        setHeader(headers);
        setSearchResults(headers);
      } catch (error) {
        console.error('Error downloading or parsing the DICOM file:', error);
      }
    }

    downloadAndParseDICOM(dicomFileUrl);
  }, [dicomFileUrl]);


  const handleSearchChange = (value: string): void => {
    setSearchTerm(value);
  };

  const handleFilterClick = (arg0: string): void => {
    setFilter(arg0);
  };

  useEffect(() => {
    if (filter && Array.isArray(header)) {
      setSearchResults([]);
      let filteredData = searchResults;
      try {
        switch (filter) {
          case 'Clear':
            filteredData = header;
            break;
          case 'DA':
            filteredData = header.filter((d: any) => d.elementType === 'DA' || d.elementType === 'DT');
            break;
          case 'UI':
            filteredData = header.filter((d: any) => d.elementType === 'UI');
            break;
          default:
            filteredData = header.filter((d: any) => d.elementType === filter);
            break;
        }
        if (searchTerm) {
          filteredData = header.filter((row: any) => {
            const searchString = Object.values(row)
              .join(' ')
              .toLowerCase();
            return searchString.includes(searchTerm.toLowerCase());
          });
        }
        setSearchResults(filteredData);

      } catch (e) {
        console.error('Dicom header >>>', e);
      }
    }
  }, [filter, header, searchTerm]);

  const handleRadioChange = (index: number, value: string, _item: any) => {
    const updatedImageData = header.map((item: { tag: any; }) => {
      if (item.tag === _item.tag) {
        return {
          ..._item,
          reviewed: value,
        };
      }
      return item;
    });
    setHeader(updatedImageData);
    if (value === 'no') {
      setPopup(true);
      setCurrentRow(_item);
    }
  };

  const areAllRowsChecked = () => {
    const filler = 'var';
    if (Array.isArray(searchResults) && searchResults.length > 0) {
      return searchResults?.every((item: any) => item.reviewed && item.reviewed === 'yes');
    }
    return false;
  };

  // const areAnyRowsChecked = () => {
  //   const filler = 'var';
  //   return header?.some((item: any) => item.reviewed === 'yes');
  // };

  const handleAllRowsRadioChange = (value: string) => {
    const updatedImageData = header.map((item: any) => {
      if (searchResults.includes(item) && item?.reviewed !== 'no') {
        return {
          ...item,
          reviewed: value,
        };
      }
      return item;
    });
    setHeader(updatedImageData);
    if (value === 'no') {
      setPopup(true);
      setCurrentRow(null);
    }
  };

  return (
    <div className='h-100'>
      <div className='dicom-table__header'>
        <SearchBox onChange={handleSearchChange} placeholder='Search' />
        <Spacer value={10} />
        <FilterBox state={filter} onChange={handleFilterClick} />
      </div>
      <div className='dicom-table__body'>
        <table className="image-data-table">
          {/* <table className='dicom-table__table'> */}
          <thead className='dicom-table__table header'>
            <tr>
              <th>Tag</th>
              <th>Element</th>
              <th>Type</th>
              <th>DICOM Value</th>
              {!readOnly && <th className='td-right'>
                {/* Audit{' '} */}
                <Radio
                  checked={areAllRowsChecked()}
                  value={areAllRowsChecked()}
                  onChange={() => handleAllRowsRadioChange('yes')}
                  sx={{
                    color: '#6FD152',
                    '&.Mui-checked': {
                      color: '#6FD152',
                    },
                  }}
                />

                <Radio
                  checked={false}
                  value='no'
                  onChange={() => handleAllRowsRadioChange(' ')}
                  sx={{
                    color: '#5A5B5B',
                    '&.Mui-checked': {
                      color: '#5A5B5B',
                    },
                  }}
                />
              </th>}
            </tr>
          </thead>
          <tbody className='dicom-table__table body'>
            {searchResults && searchResults.map((item: {
              tagName: string; group: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; elementValue: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; elementType: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; value: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; reviewed?: string
            }, index: any) => (
              // eslint-disable-next-line no-nested-ternary
              <tr key={`dcmtag-${index}`} className={item.reviewed === 'yes' ? 'td-green' : item.reviewed === 'no' ? 'td-red' : 'no-bk'}>
                <td>{`${item.group},${item.elementValue}`}</td>
                <td>{item.tagName}</td>
                <td>{item.elementType}</td>
                <td className="wide-column">{item.value}</td>
                {!readOnly && <td >
                  <div className='radio-buttons'>
                    <Radio
                      // {...controlProps('no')}
                      checked={item.reviewed === 'yes'}
                      value='yes'
                      onChange={() => handleRadioChange(index, 'yes', item)}
                      sx={{
                        color: '#6FD152',
                        '&.Mui-checked': {
                          color: '#6FD152',
                        },
                      }}
                    />
                    <Radio
                      // {...controlProps('yes')}
                      checked={item?.reviewed === 'no'}
                      value='no'
                      onChange={() => handleRadioChange(index, 'no', item)}

                      sx={{
                        color: '#ea1616',
                        '&.Mui-checked': {
                          color: '#ea1616',
                        },
                      }}
                    />
                  </div>
                </td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal title='Findings' open={popup} onClose={closePopup}>
        <div>
          <Label size="h5" weight={600} color="primary" > Risk Level: {findings.riskLevel !== 0 && findings.riskLevel}</Label>
          <Spacer value={5} />
          <Scale
            answer={[{ 'value': findings.riskLevel, 'label': findings.riskLevel }]}
            key={`question-${0}`}
            onAnswer={handleRisk}
          />
          <Spacer value={20} />
          <div>
            <Label size="h5" weight={600} color="primary" > What issues did you find:</Label>
            <Spacer value={5} />
            <div className='dicom-table__btn-row'>
              <button type='button'
                className={findings.phi ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('phi')}
              >
                PHI
              </button>
              <button type='button'
                className={findings.age ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('age')}
              >
                AGE
              </button>
              <button type='button'
                className={findings.manufacturer ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('manufacturer')}
              >
                Manufacturer
              </button>
              <button type='button'
                className={findings.other ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('other')}
              >
                NON-PHI ID
              </button>
              <button type='button'
                className={findings.date ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('date')}
              >
                Date
              </button>
              <button type='button'
                className={findings.time ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('time')}
              >
                Time
              </button>
              <button type='button'
                className={findings.site ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('site')}
              >
                Site ID
              </button>
              <button type='button'
                className={findings.physician ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('physician')}
              >
                Physician ID
              </button>
              {/* <button type='button'
                className={findings.mrn ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('mrn')}
              >
                MRN
              </button> */}
              <button type='button'
                className={findings.nonPHIimageText ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('nonPHIimageText')}
              >
                NON-PHI imprinted in image
              </button>
              <button type='button'
                className={findings.imagePHI ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
                onClick={() => handleToggleChange('imagePHI')}
              >
                PHI imprinted in image
              </button>
            </div>
            <Spacer value={20} />
            <TextAreaInput
              value={findings.notes}
              onChange={handleChangeInput}
              name='notes'
              label='Notes:'
            />
            <Spacer value={20} />
            <div className='btn-row'>
              <Button disabled={findings.riskLevel === 0}onClick={handleSubmit}>Submit Findings</Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};


DicomTable.defaultProps = {
  className: '',
};

DicomTable.displayName = 'DicomTable';
export default DicomTable;