import React from 'react';
import classnames from 'classnames';
import './tab-content.scss';

export interface TabContentProps {
  active: boolean,
  children: JSX.Element
  id: any,
}

const TabContent = (props: TabContentProps): JSX.Element => {
  const { active, children, id } = props;
  const classes = classnames('tab-content', {
    'tab-content--selected': active,
  });

  return (
    <div className={classes} id={id}>
      TabContent {children}
    </div>
  );
};

TabContent.defaultProps = {
};

TabContent.displayName = 'TabContent';
export default TabContent;

