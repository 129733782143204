import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Label, Spacer, Checkbox } from '../../../atoms';
import '../form-item.scss';

export interface ChecklistProps {
  answer: any,
  onAnswer: (vals: any) => void,
  withCheck?: boolean,
  multi?: boolean,
  editable?: boolean,
  label: string,
}

const Checklist = (props: ChecklistProps): JSX.Element => {
  const { answer, onAnswer, label, editable } = props;
  const [checked, setChecked] = React.useState(
    !!answer.length && answer[0].value === 'checked',
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      onAnswer([{ value: 'checked', label }]);
    } else {
      onAnswer([]);
    }
  };

  return (
    <div className='span-two'>
      <Spacer value={5} />
      <div className='form-item__checklist'>
        <Checkbox checked={checked} onChange={handleChange} >
          <Label size="h6" weight={200}  >{label}</Label>
        </Checkbox>
      </div>
    </div>
  );
};

Checklist.defaultProps = {
  withCheck: true,
  multi: false,
  editable: true,
};

Checklist.displayName = 'List';
export default Checklist;
