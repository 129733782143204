import React from 'react';
import './sheild-icon.scss';
import Sheild from './shield PHI-01.png';
import Bad from './shieldBAD-01.png';

export interface SheildIconProps {
  size?: number,
  type: 'good' | 'bad',
}

const SheildIcon = (props: SheildIconProps) => {
  const { size, type } = props;

  return (
    <img alt="Logo" src={type === 'bad' ? Bad : Sheild} height={size} />
  );
};

SheildIcon.defaultProps = {
  size: 40,
};

SheildIcon.displayName = 'SheildIcon';


export default SheildIcon;
