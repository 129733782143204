import React, { useState } from 'react';
// import classnames from 'classnames';
import './audit-radio-buttons.scss';
import Radio from '@mui/material/Radio';
import { Spacer, Modal, Label, Button } from '../../atoms';
import { Scale } from '../QuestionTypes';
import TextAreaInput from '../TextAreaInput/TextAreaInput';

export interface AuditRadioButtonsProps {
  id: string;
  className?: string;
  status?: number;
  onsubmit?: (pl:  any) => void;
}

interface Findings {
  riskLevel: number;
  notes: string;
  phi: boolean;
  name: boolean;
  manufacturer: boolean;
  other: boolean;
  date: boolean;
  time: boolean;
  site: boolean;
  physician: boolean;
  mrn: boolean;
  imagePHI: boolean;
  nonPHIimageText: boolean;
}
const initial: Findings = {
  riskLevel: 0,
  phi: false,
  name: false,
  manufacturer: false,
  other: false,
  date: false,
  time: false,
  site: false,
  physician: false,
  mrn: false,
  imagePHI: false,
  nonPHIimageText: false,
  notes: '',
};
const AuditRadioButtons = (props: AuditRadioButtonsProps): JSX.Element => {
  const { id, className, status, onsubmit } = props;
  const [popup, setPopup] = useState(false);
  const [value, setValue] = useState(status);
  const [findings, setFindings] = useState(initial);
  
  const closePopup = () => {
    setPopup(false);
    setFindings(initial);
  };

  const handleSubmit = () => {
    if (onsubmit)
      onsubmit(findings);
    closePopup();
  };
  
  const handleRadioChange = (_value: number) => {
    setValue(_value);
    if (_value === -1) {
      setPopup(true);
    }
  };
  
  const handleRisk = (newVal: any) => {
    const nRisk = newVal[0].value;
    setFindings((prev) => ({ ...prev, riskLevel: nRisk }));
  };

  const handleToggleChange = (toggle: keyof Findings) => {
    const updatedFinding: Findings = {
      ...findings,
      [toggle]: !findings[toggle],
    };
    setFindings(updatedFinding);
  };
  
  const handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFindings({ ...findings, [event.target.name]: event.target.value });
  };

  return (
    <div>
    <div key={`AuditRadioButtons_${id}`} className="audit-radio-buttons">
      <Radio
        // {...controlProps('no')}
        checked={value === 1}
        value="yes"
        onChange={() => handleRadioChange(1)}
        sx={{
          color: '#6FD152',
          '&.Mui-checked': {
            color: '#6FD152',
          },
        }}
      />
      <Radio
        // {...controlProps('yes')}
        checked={value === -1}
        value="no"
        onChange={() => handleRadioChange(-1)}
        sx={{
          color: '#ea1616',
          '&.Mui-checked': {
            color: '#ea1616',
          },
        }}
      />
    </div>
    <Modal title='Findings' open={popup} onClose={closePopup} >
    <div >
      <Label size="h5" weight={600} color="primary" > Risk Level: {findings.riskLevel !== 0 && findings.riskLevel}</Label>
      <Spacer value={10} />
      <Scale
        answer={[{ 'value': findings.riskLevel, 'label': findings.riskLevel }]}
        key={`question-${0}`}
        onAnswer={handleRisk}
      />
      <Spacer value={20} />
      <div>
        <Label size="h5" weight={600} color="primary" > What issues did you find:</Label>
        <Spacer value={5} />
        <div className='dicom-table__btn-row'>
          <button type='button'
            className={findings.phi ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('phi')}
          >
            PHI
          </button>
          <button type='button'
            className={findings.name ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('name')}
          >
            NON-PHI Name
          </button>
          <button type='button'
            className={findings.manufacturer ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('manufacturer')}
          >
            Manufacturer ID
          </button>
          <button type='button'
            className={findings.other ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('other')}
          >
            Other non-PHI ID
          </button>
          <button type='button'
            className={findings.date ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('date')}
          >
            Date
          </button>
          <button type='button'
            className={findings.time ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('time')}
          >
            Time
          </button>
          <button type='button'
            className={findings.site ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('site')}
          >
            Site ID
          </button>
          <button type='button'
            className={findings.physician ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('physician')}
          >
            Physician ID
          </button>
          <button type='button'
            className={findings.mrn ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('mrn')}
          >
            MRN
          </button>
          <button type='button'
            className={findings.nonPHIimageText ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('nonPHIimageText')}
          >
            Non-PHI info imprinted in image
          </button>
          <button type='button'
            className={findings.imagePHI ? 'dicom-table__toggle-btn-active' : 'dicom-table__toggle-btn'}
            onClick={() => handleToggleChange('imagePHI')}
          >
            PHI info imprinted in image
          </button>
        </div>
        <Spacer value={20} />
        <TextAreaInput
          value={findings.notes}
          onChange={handleChangeInput}
          name='notes'
          label='Notes:'
        />
        <Spacer value={20} />
        <div className='btn-row'>
          <Button onClick={handleSubmit}>Submit Findings</Button>
        </div>
      </div>
    </div>
  </Modal>
  </div>
  );
};

AuditRadioButtons.defaultProps = {
  className: '',
  status: 0,
  onsubmit: () => console.info('on submit'),
};

AuditRadioButtons.displayName = 'AuditRadioButtons';
export default AuditRadioButtons;
