/* eslint-disable no-plusplus */
import React from 'react';
// import classnames from 'classnames';
import Fab from '@mui/material/Fab';
import { AiFillMessage } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { Tile, Label, Spinner, Text, Modal, Button } from '../../atoms';
import { chatApi, useAddMessageMutation, useUpdateMessageStatusMutation, useGetProtocolChatQuery } from '../../../api/builders/chats.api';
import NewMessage from '../NewMessage/NewMessage';
import { selectAuth } from '../../../store/authentication/slice';

import './message-factory.scss';

export interface MessageFactoryProps {
  id: string,
  className?: string,
  createChatRoom: () => void,
  participant: string,
  protocolItem: string,
}


const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
  color: 'inherit',
  bgcolor: 'rgba(32, 202, 249, 0.4)',
  '&:hover': {
    bgcolor: 'rgba(32, 202, 249, 0.4)',
  },
};


const MessageFactory = (props: MessageFactoryProps): JSX.Element => {
  const { createChatRoom, participant, protocolItem } = props;
  const { user } = useSelector(selectAuth);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { data } = useGetProtocolChatQuery(protocolItem);
  const { refetch: refetchMessages } =
  chatApi.endpoints.getProtocolChat.useQuerySubscription(protocolItem);
  const [addMessage, { isLoading: addingNew }] = useAddMessageMutation();
  const [updateReadStatus] = useUpdateMessageStatusMutation();
  const [unreadMsgs, setUnread] = React.useState(0);
  const [itemMessages, setItemMessanges] = React.useState<any>([]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const focus = React.useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (focus && focus !== null && focus.current) {
      focus.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    refetchMessages();
  }, [protocolItem]);

  React.useEffect(scrollToBottom, [itemMessages]);


  const checkIfNewMessages = () => {
    let unread = 0;
    if (open) {
      setUnread(0);
    } else if (itemMessages.length > 0) {
      for (let i = 0; i < itemMessages.length; i++) {
        if (data[0].chatmessage[i].read === false) {
          unread += 1;
        }
      }
      setUnread(unread);
    }
  };

  React.useEffect(() => {
    if (data?.length > 0 && Array.isArray(data[0].chatmessage) && data[0].chatmessage.length > 0) {
      const filtered = data[0].chatmessage.filter((it: any) => it.tags[0].id === protocolItem);
      setItemMessanges(filtered);
    }
  }, [data]);

  React.useEffect(() => {
    checkIfNewMessages();
  }, [itemMessages]);


  const markAsRead = async (chatItem: string) => {
    const readPl = {
      chatmessageId: chatItem,
      body: {
        read: true,
      },
    };
    await updateReadStatus(readPl);
  };

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setUnread(0);
    setAnchorEl(event.currentTarget);
    if (itemMessages.length > 0) {
      for (let i = 0; i < itemMessages.length; i++) {
        if (itemMessages[i].read === false) {
          markAsRead(itemMessages[i].id);
        }
      }
    }
  };

  const handleSubmitMessage = (message: string) => {
    const newMessagePl = {
      message,
      chatroomId: data[0].id,
      tag: protocolItem,
    };
    addMessage(newMessagePl);
  };

  const sort = (items: any) => {
    try {
      const arrayForSort = [...items];
      let test = [];
      test = arrayForSort.sort((a, b) => a.createdAt - b.createdAt);
      return test;
    } catch {
      console.error('failed to sort participant data');
      return items;
    }
  };



  return (
    <div>
      <Badge color="error" overlap="circular" badgeContent={unreadMsgs}>
        <button type='button' className='chat__button' aria-describedby={id} onClick={handleClick}  >
          <ChatBubbleIcon className='chat__icon' fontSize="small" />
        </button>
      </Badge>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {data?.length > 0 ?
          <div className='chat__wrapper'>
            <div className='chat__body'>
              <div className='chat__prev-messages-wrapper'>
                {Array.isArray(itemMessages) && itemMessages.length > 0 ?
                  sort(itemMessages).map((item: any, index: number) => (
                    <div key={item.id} ref={index === itemMessages.length - 1 ? focus : null}>
                      {item?.sentBy.id === user ?
                        <div className='chat__prev-message-mine-item'>
                          <Label size='subtext'>{moment(item.createdAt).format('lll')} </Label>
                          <div key={item.id} className='chat__prev-message-mine-body'>
                            <Text>{item.message}</Text>
                          </div>
                        </div>
                        :
                        <div className='chat__prev-message-item'>
                          <Label size='subtext'> {`${item?.sentBy?.firstname}  ${item?.sentBy?.lastname}  ${moment(item.createdAt).format('lll')}`} </Label>
                          <div key={item.id} className='chat__prev-message-body'>
                            <Text>{item.message}</Text>
                          </div>
                        </div>
                      }
                      {/* <div ref={focus} /> */}
                    </div>
                  ))
                  :
                  <div className='super-centered'>
                    <Label size='subtext'>No Chat History</Label>
                  </div>
                }
              </div>
              {addingNew && <div className='chat__prev-message-mine-item' ><Spinner /> </div>}
            </div>
            <div className='chat__footer'>
              <NewMessage handleNewMsg={handleSubmitMessage} id="message" />
            </div>
          </div>
          :
          <div className='chat__wrapper super-centered'>
            <Text>No Chat History</Text>
            <Button onClick={createChatRoom}>Initiate Chat</Button>
          </div>
        }
      </Popover>
    </div>
  );
};

MessageFactory.defaultProps = {
  className: '',
};

MessageFactory.displayName = 'MessageFactory';
export default MessageFactory;
