import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';


const api = createApi({
  baseQuery: retry(fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();
      if (token) headers.set('authorization', `Bearer ${token}`);
      return headers;
    },
  }), {
    maxRetries: 5,
  }),
  tagTypes: ['PATIENTS', 'PARTICIPANT_REQS', 'REQUIREMENT_IMAGES', 'TEMPLATES', 'TESTS', 'PARTICIPANT', 'STUDIES', 'STUDY', 'SITES', 'SITE', 'PARTICIPANTS', 'TUMORS', 'PROTOCOL', 'ACTIVITIES', 'CHATS', 'STUDYUSERS', 'PROTOCOLLOGS', 'IMG_ANALYSIS', 'SURVEY', 'REQUIREMENT_DOCUMENTS', 'REGISTRIES', 'STUDYSTATS', 'HIVES', 'HIVE', 'AUDITS', 'AUDIT', 'OPENCOHORT', 'COHORTS', 'PATIENTS', 'PATIENTFILES', 'LOGS', 'AUDITIMG', 'REGISTRY', 'CARETEMPLATES', 'OPENPATIENT', 'CONFERENCE', 'CONFERENCS', 'CONFERENCEPATS', 'PATIENTSSURVEYS'],
  endpoints: (builder) => ({
    createPUTSignedUrl: builder.mutation({
      query: (s3_path) => ({ url: '/files/binder/', method: 'POST', body: s3_path }),
      invalidatesTags: ['REQUIREMENT_IMAGES', 'REQUIREMENT_DOCUMENTS'],
    }),
    createSignedUrl: builder.mutation({ query: (s3_path) => ({ url: '/files/drive/', method: 'POST', body: s3_path }) }),
  }),
});

export const { useCreatePUTSignedUrlMutation, useCreateSignedUrlMutation } = api;

export default api;
