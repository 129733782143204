/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import './smart-crf.scss';
import SmartViewer from '../SmartViewer/SmartViewer';

export interface SmartCRFProps {
  id: string;
  item?: any;
  className?: string;
  visitId?: string;
  children?: React.ReactNode;
}

const SmartCRF = ({ id, className, children, item, visitId }: SmartCRFProps): JSX.Element => {
  const [showRightPanel, setShowRightPanel] = useState(false);

  const handleToggleRightPanel = () => {
    setShowRightPanel(!showRightPanel);
  };
  

  return (
    <div key={`SmartCRF_${id}`} className={`smart-crf ${className}`}>
      <div className="left-panel">{children}</div>
      <div className={`right-panel ${showRightPanel ? 'active' : ''}`}>
        <button className={`toggle-button ${showRightPanel ? 'active' : ''}`} onClick={handleToggleRightPanel}>
          <FiChevronLeft />
        </button>
        <div className={`right-panel-content ${showRightPanel ? 'active' : 'hidden'}`}>
        <SmartViewer id="smart-crf-viewer" item={item} visitId={visitId} />
        </div>
      </div>
    </div>
  );
};

SmartCRF.defaultProps = {
  className: '',
  item: null,
  visitId: null,
  children: null,
};

SmartCRF.displayName = 'SmartCRF';
export default SmartCRF;
