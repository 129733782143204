import React from 'react';

const FAQbody = [
  {
    title: 'Personal Data?',
    body: [
      'At Upstream Vision, we believe strongly in fundamental privacy rights — and that those fundamental rights should not differ depending on where you live in the world. That’s why we treat any data that relates to an identified or identifiable individual or that is linked or linkable to them by Upstream Vision as “personal data,” no matter where the individual lives. This means that data that directly identifies you — such as your name — is personal data, and also data that does not directly identify you, but that can reasonably be used to identify you — such as the serial number of your device — is personal data. Aggregated data is considered non-personal data for the purposes of this Privacy Policy.',
      'This Privacy Policy covers how Upstream Vision or an Upstream Vision-affiliated company (collectively, “Upstream Vision”) handles personal data whether you interact with us on our websites, through Upstream Vision apps, or in person (including by phone or when visiting our retail stores). Upstream Vision may also link to third parties on our services or make third-party apps available for download in our App Store. Upstream Vision’s Privacy Policy does not apply to how third parties define personal data or how they use it. We encourage you to read their privacy policies and know your privacy rights before interacting with them.',
    ],
  },
  {
    title: 'Your Privacy Rights at Upstream Vision',
    body: [
      'At Upstream Vision, we respect your ability to know, access, correct, transfer, restrict the processing of, and delete your personal data. We have provided these rights to our global customer base and if you choose to exercise these privacy rights, you have the right not to be treated in a discriminatory way nor to receive a lesser degree of service from Upstream Vision. Upstream Vision does not sell your data including as “sale” is defined in Nevada and California. ',
      'There may be situations where we cannot grant your request — for example, if you ask us to delete your transaction data and Upstream Vision is legally obligated to keep a record of that transaction to comply with law. We may also decline to grant a request where doing so would undermine our legitimate use of data for anti-fraud and security purposes, such as when you request deletion of an account that is being investigated for security concerns. Other reasons your privacy request may be denied are if it jeopardizes the privacy of others, is frivolous or vexatious, or would be extremely impractical. ',
    ],
  },
  {
    title: 'Personal Data Upstream Vision Collects from You',
    body: [
      'At Upstream Vision, we believe that you can have great products and great privacy. This means that we strive to collect only the personal data that we need. The personal data Upstream Vision collects depends on how you interact with Upstream Vision. Descriptions of how Upstream Vision handles personal data for certain individual services are available either in settings related to those features and/or at upstream.vision/legal/privacy/data.',
    ],
  },
  // {
  //   title: 'How do I delete my account?',
  //   body: [
  //     'You may email the study team by selecting the "Help" button on the top menu bar. Someone from the study team will contact you by the email address you used to login to confirm that your account has been deleted.',
  //     'When you create an Upstream Vision ID, apply for commercial credit, purchase and/or activate a product or device, download a software update, register for a class at an Upstream Vision Store, connect to our services, contact us (including by social media), participate in an online survey, or otherwise interact with Upstream Vision, we may collect a variety of information, including:',
  //     'Account Information. Your Upstream Vision ID and related account details, including email address, devices registered, account status, and age',
  //     'Device Information. Data from which your device could be identified, such as device serial number, or about your device, such as browser type',
  //     'Contact Information. Data such as name, email address, physical address, phone number, or other contact information',
  //     'Payment Information. Data about your billing address and method of payment, such as bank details, credit, debit, or other payment card information',
  //     'Transaction Information. Data about purchases of Upstream Vision products and services or transactions facilitated by Upstream Vision, including purchases on Upstream Vision platforms',
  //     'Fraud Prevention Information. Data used to help identify and prevent fraud, including a device trust score',
  //     'Usage Data. Data about your activity on and use of our offerings, such as app launches within our services, including browsing history; search history; product interaction; crash data, performance and other diagnostic data; and other usage data',
  //     'Health Information. Data relating to the health status of an individual, including data related to one\'s physical or mental health or condition. Personal health data also includes data that can be used to make inferences about or detect the health status of an individual.',
  //     'Fitness Information. Details relating to your fitness and exercise information where you choose to share them',
  //     'Financial Information. Details including salary, income, and assets information where collected, and information related to Upstream Vision-branded financial offerings',
  //     'Other Information You Provide to Us. Details such as the content of your communications with Upstream Vision, including interactions with customer support and contacts through social media channels',
  //     'You are not required to provide the personal data that we have requested. However, if you choose not to do so, in many cases we will not be able to provide you with our products or services or respond to requests you may have.',
  //   ],
  // },
  // {
  //   title: 'Personal Data Upstream Vision Receives from Other Sources',
  //   body: [
  //     'Upstream Vision may receive personal data about you from other individuals, from businesses or third parties acting at your direction, from our partners who work with us to provide our products and services and assist us in security and fraud prevention, and from other lawful sources. ',
  //     'Individuals. Upstream Vision may collect data about you from other individuals — for example, if that individual has sent you a product or gift card, invited you to participate in an Upstream Vision service or forum, or shared content with you. ',
  //     'At Your Direction. You may direct other individuals or third parties to share data with Upstream Vision. For example, you may direct your mobile carrier to share data about your carrier account with Upstream Vision for account activation, or for your loyalty program to share information about your participation so that you can earn rewards for Upstream Vision purchases. ',
  //     'Upstream Vision Partners. We may also validate the information you provide — for example, when creating an Upstream Vision ID, with a third party for security, and for fraud-prevention purposes.',
  //     'For research and development purposes, we may use datasets such as those that contain images, voices, or other data that could be associated with an identifiable person. When acquiring such datasets, we do so in accordance with applicable law, including law in the jurisdiction in which the dataset is hosted. When using such datasets for research and development, we do not attempt to reidentify individuals who may appear therein. ',
  //   ],
  // },
  {
    title: 'Upstream Vision’s Use of Personal Data',
    body: [
      'Upstream Vision may share personal data with Upstream Vision-affiliated companies, service providers who act on our behalf, our partners, developers, and publishers, or others at your direction. Further, Upstream Vision does not share personal data with third parties for their own marketing purposes. ',
      'Service Providers. Upstream Vision may engage third parties to act as our service providers and perform certain tasks on our behalf, such as processing or storing data, including personal data, in connection with your use of our services and delivering products to customers. Upstream Vision service providers are obligated to handle personal data consistent with this Privacy Policy and according to our instructions. They cannot use the personal data we share for their own purposes and must delete or return the personal data once they have fulfilled our request. ',
      'Partners. At times, Upstream Vision may partner with third parties to provide services or other offerings. For example, Upstream Vision financial offerings like Upstream Vision Card and Upstream Vision Cash are offered by Upstream Vision and our partners. Upstream Vision requires its partners to protect your personal data. ',
      'Others. Upstream Vision may share personal data with others at your direction or with your consent, such as when we share information with your carrier to activate your account. We may also disclose information about you if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate. We may also disclose information about you where there is a lawful basis for doing so, if we determine that disclosure is reasonably necessary to enforce our terms and conditions or to protect our operations or users, or in the event of a reorganization, merger, or sale. ',
    ],
  },
  {
    title: 'Protection of Personal Data at Upstream Vision',
    body: [
      'At Upstream Vision, we believe that great privacy rests on great security. We use administrative, technical, and physical safeguards to protect your personal data, taking into account the nature of the personal data and the processing, and the threats posed. We are constantly working to improve on these safeguards to help keep your personal data secure. For more information, visit our Upstream Vision Platform Security guide. ',
    ],
  },
  {
    title: 'Cookies and Other Technologies',
    body: [
      'Upstream Vision’s websites, online services, interactive applications, and advertisements may use “cookies” and other technologies such as web beacons. These technologies help us to better understand user behavior including for security and fraud prevention purposes, tell us which parts of our websites people have visited, and facilitate and measure the effectiveness of advertisements and web searches. ',
      'Communications Cookies. These cookies are used to enable network traffic to and from Upstream Vision’s systems, including by helping us detect any errors. ',
      'Strictly Necessary Cookies. These cookies are set as required to provide a specific feature or service that you have accessed or requested. For example, they allow us to display our websites in the proper format and language, to authenticate and verify your transactions, and to preserve the contents of your Bag when shopping online at Upstream Vision.com. ',
      'Other Cookies. These cookies are used to understand how visitors interact with our websites and online services, including by helping us to assess the effectiveness of advertisements and web searches. Upstream Vision also uses these cookies to remember choices you make while browsing, so we can provide you with a customized experience. ',
      'If you prefer that Upstream Vision not use cookies, we provide you with the means to disable their use. If you want to disable cookies and you’re using the Safari web browser, choose “Block all cookies” in Safari’s privacy settings. If you are using a different browser, check with your provider to find out how to disable cookies. Certain features of the Upstream Vision website may not be available if all cookies are disabled. ',
      'In addition to cookies, Upstream Vision uses other technologies that help us achieve similar objectives. ',
      'In some email messages Upstream Vision sends to you, we provide a “click-through URL” that links you to content on the Upstream Vision website. When you click one of these URLs, they pass through a separate server before arriving at the destination page on our website. We track this click-through to help us determine interest in particular topics and measure whether we are communicating with you effectively. If you prefer not to be tracked in this way, you should not click graphic or text links in email messages. ',
      'Upstream Vision generally treats data we collect using these cookies and similar technologies as nonpersonal data. However, to the extent that Internet Protocol (IP) addresses or similar identifiers are considered personal data by local law, we also treat these identifiers as personal data in those regions. In addition, Upstream Vision sometimes combines nonpersonal data collected from these technologies with other personal data Upstream Vision holds. When we combine data in this way, we treat the combined data as personal data for purposes of this Privacy Policy. ',
    ],
  },
  {
    title: 'Our Companywide Commitment to Your Privacy',
    body: [
      'To make sure your personal data is secure, we communicate our privacy and security guidelines to Upstream Vision employees and strictly enforce privacy safeguards within the company. ',
    ],
  },
  {
    title: 'Privacy Questions',
    body: [
      'If you have questions about Upstream Vision’s Privacy Policy or privacy practices including where a third-party service provider is acting on our behalf, would like to contact our Data Protection Officer, or would like to submit a complaint, you can contact us at upstream.vision/legal/privacy/contact or call the Upstream Vision Support number for your country or region. You can also ask us questions about how to submit a privacy complaint and we will endeavor to help. ',
    ],
  },
];

export default FAQbody;