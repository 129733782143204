import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import { useMediaQuery } from 'react-responsive';
import { useGetparticipantInfoQuery, useEditProtocolMutation } from '../../../api/builders/participantProtocol.api';
import { useAddChatRoomMutation } from '../../../api/builders/chats.api';
import { Label, Tile, Spacer, ProtocolItemFactory, ProtocolVisitItem, ProtocolVisitCriteriaItem, BreadcrumbWithMenu, Alert, Spinner, CareStep, ParticipantFiles, Tab, Tabs } from '../../../components';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { ProtocolBrowser, SnapshotBrowser } from '../../../components/molecules';
import { IProtocolTimePoint } from '../../../components/molecules/ProtocolTimePoint/ProtocolTimePoint';
import { IProtocolItemType } from '../../../components/molecules/ProtocolItem/ProtocolItem';


export type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  phantom: boolean | undefined;
  id: string | undefined;
  label: string | undefined;
  consentStatus: string;
};

type ProtocolTimePoint = {
  date: string | undefined,
  id: string | undefined,
  label: string | undefined,
  order: number | undefined,
  studyDay: string | undefined,
  updatedAt: number | undefined,
  visit: string | undefined,
  visitType: string | undefined,
  userProtocolItems: ProtocolItemType[],
};

type ProtocolItemType = {
  file: string | undefined,
  id: string | undefined,
  item: string | undefined,
  label: string | undefined,
  messages: string | undefined,
  order: number,
  createdAt: number | undefined,
  updatedAt: number | undefined,
  role: string | undefined,
  status: string,
  surveyId: string | undefined,
  type: string | undefined,
  userId: string | undefined,
  deviation: boolean | null;
};


export interface LocationState {
  participant: ParticipantItem,
  sitePath: string,
  activity?: Activity,
  acl: string,
  siteId: string,
}

type StateType = {
  visitIndex: number,
  itemIndex: number,
  minifiedPath: string,
  fullPath: string,
  item: IProtocolItemType | undefined,
  criteria: IProtocolItemType[] | [],
};


export type Activity = {
  timePointId: string,
  protocolItemId: string,
};

const initialState = {
  visitIndex: 0,
  itemIndex: 0,
  minifiedPath: '',
  fullPath: '',
  item: undefined,
  criteria: [],
};

const sort = (items: ProtocolItemType[]) => {
  try {
    const test = JSON.parse(JSON.stringify(items));
    // test.sort((a: ProtocolItemType, b: ProtocolItemType) => a.order - b.order);
    // return test;
    const typeOrder = [
      'radiology',
      'document',
      'file',
      'treatment plan',
      'survey',
      'image analysis',
      'CRF',
      'met report',
      'assessment',
    ];

    test.sort((a: any, b: any) => {
      const typeA = typeOrder.indexOf(a.type.toLowerCase());
      const typeB = typeOrder.indexOf(b.type.toLowerCase());
      return typeA - typeB;
    });

    return test;
  } catch (e) {
    console.error('err sorting', e);
    return items;
  }
};


const Participant = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { participant, sitePath, activity, acl, siteId } = location.state as LocationState;
  const { data, isFetching } = useGetparticipantInfoQuery({ participantId: participant?.id ? participant.id : skipToken });
  const [state, setState] = useState<StateType>(initialState);
  const [reviewMode, setReviewMode] = React.useState(false);
  const [small, setSmall] = useState(false);
  const [participantProtocol, setParticipantProtocol] = useState<IProtocolTimePoint[] | []>([]);
  const [editProtocol] = useEditProtocolMutation();
  const [addChatroom] = useAddChatRoomMutation();
  const [readOnly, setReadOnly] = React.useState(true);
  const [chatError, setChatError] = React.useState(false);
  const [showScans, setShowScans] = React.useState(false);
  const [activePanel, setActivePanel] = useState(1);

  const [gridState, setGridState] = useState('default');

  const handleGridChange = (newLayout: string) => {
    setGridState(newLayout);
  };



  const handlePanelToggle = (panel: number) => {
    setActivePanel(panel);
  };

  const openDrivePanel = () => {
    handlePanelToggle(2);
    setState({
      ...state,
      itemIndex: -1,
    });
  };
  const closeDrivePanel = () => {
    handlePanelToggle(1);
    let newIndex = 0;
    if (state?.criteria && state.item) {
      newIndex = state?.criteria.findIndex(item => item.id === state?.item?.id);
    }
    setState({
      ...state,
      itemIndex: newIndex,
    });
  };


  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);


  React.useEffect(() => {
    const newBreadcrumb = {
      label: participant?.participantId ?? 'participant',
      path: location.pathname,
      state: { participant, sitePath, activity, acl, siteId },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);
    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);

  const toggleReviewMode = () => {
    setReviewMode(!reviewMode);
  };

  React.useEffect(() => {
    if (acl && acl === 'admin') {
      setReadOnly(false);
    } else if (acl && acl === 'SITE COORDINATOR') {
      setReadOnly(false);
    }
  });

  const handleChangeDate = (payload: { visitId: string, visitDate: string }) => {
    const pL = {
      id: participant.id,
      body: {
        visitId: payload.visitId,
        visitDate: payload.visitDate,
      },
    };
    editProtocol(pL);
  };


  const changeSize = () => {
    setSmall(!small);
    closeDrivePanel();
  };

  const collapse = () => {
 
    setSmall(true);
    // }
  };
  const expand = () => {
    setSmall(false);
    // }
  };


  // useEffect(() => {
  //   if (data && Array.isArray(data)) {
  //     if (Array.isArray(data) && data[0].userProtocol && data[0].userProtocol.timePoints) {
  //       setParticipantProtocol(data[0].userProtocol.timePoints);
  //       if (activity) {
  //         const findVistIndex = data[0].userProtocol.timePoints.findIndex((it: any) => it.id === activity.timePointId);
  //         if (findVistIndex !== -1) {
  //           const findVist = data[0].userProtocol.timePoints[findVistIndex];
  //           const findItemIndex = sort(findVist.userProtocolItems).findIndex((it: any) => it.id === activity.protocolItemId);
  //           const findItem = sort(findVist.userProtocolItems)[findItemIndex];

  //           if (findVist && findItem) {
  //             const tmpPath = `${sitePath}participants/${data[0].id}/protocol/${data[0].userProtocol.timePoints[findVistIndex].visit}/${data[0].userProtocol.timePoints[findVistIndex].userProtocolItems[findItemIndex].item}`;
  //             setState({
  //               ...state,
  //               visitIndex: findVistIndex,
  //               itemIndex: findItemIndex,
  //               fullPath: tmpPath,
  //               item: findItem,
  //               criteria: sort(findVist.userProtocolItems),
  //             });
  //           }
  //         }
  //       } else {
  //         const tmp = sort(data[0].userProtocol.timePoints[state.visitIndex].userProtocolItems);
  //         const tmpPath = `${sitePath}participants/${data[0].id}/protocol/${data[0].userProtocol.timePoints[state.visitIndex].visit}/${tmp[state.itemIndex].item}`;
  //         setState({
  //           ...state,
  //           fullPath: tmpPath,
  //           item: tmp[state.itemIndex],
  //           criteria: sort(data[0].userProtocol.timePoints[state.visitIndex].userProtocolItems),
  //         });
  //       }
  //     }
  //   }
  // }, [data]);

  useEffect(() => {
    if (Array.isArray(data) && data[0].userProtocol && data[0].userProtocol.timePoints) {
      setParticipantProtocol(data[0].userProtocol.timePoints);
      if (activity) {
        const findVistIndex = data[0].userProtocol.timePoints.findIndex((it: any) => it.id === activity.timePointId);
        if (findVistIndex !== -1) {
          const findVist = data[0].userProtocol.timePoints[findVistIndex];
          const findItemIndex = sort(findVist.userProtocolItems).findIndex((it: any) => it.id === activity.protocolItemId);
          const findItem = sort(findVist.userProtocolItems)[findItemIndex];

          if (findVist && findItem) {
            const tmpPath = `${sitePath}participants/${data[0].id}/protocol/${data[0].userProtocol.timePoints[findVistIndex].visit}/${data[0].userProtocol.timePoints[findVistIndex].userProtocolItems[findItemIndex].item}`;
            setState({
              ...state,
              visitIndex: findVistIndex,
              itemIndex: findItemIndex,
              fullPath: tmpPath,
              item: findItem,
              criteria: sort(findVist.userProtocolItems),
            });
          }
        }
      } else {
        const tmp = sort(data[0].userProtocol.timePoints[state.visitIndex].userProtocolItems);
        const tmpPath = `${sitePath}participants/${data[0].id}/protocol/${data[0].userProtocol.timePoints[state.visitIndex].visit}/${tmp[state.itemIndex].item}`;
        setState({
          ...state,
          fullPath: tmpPath,
          item: tmp[state.itemIndex],
          criteria: sort(data[0].userProtocol.timePoints[state.visitIndex].userProtocolItems),
        });
      }
    }
  }, [data]);


  // const onChangeVisit = (el: ProtocolTimePoint, i: number) => {
  //   // setIsUploading(false);
  //   const tmpPath = `${sitePath}participants/${data[0].id}/protocol/${el.visit}/${el.userProtocolItems[0].item}`;
  //   setState((prev) => ({
  //     ...prev,
  //     visitIndex: i,
  //     fullPath: tmpPath,
  //     itemIndex: 0,
  //     item: el.userProtocolItems[0],
  //     criteria: sort(el.userProtocolItems),
  //   }));
  // };
  const onChangeVisit = (_newstate: any) => {
    setState((prev) => ({
      ...prev,
      ..._newstate,
      // visitIndex: i,
      // fullPath: tmpPath,
      // itemIndex: 0,
      // item: el.userProtocolItems[0],
      // criteria: sort(el.userProtocolItems),
    }));
  };

  const onChangeItem = (el: any) => {
    console.info('on change visit in participant');
  };

  // const onChangeItem = (el: ProtocolItemType, i: number) => {
  //   // setIsUploading(false);
  //   handlePanelToggle(1);
  //   const newIndex = state.criteria.findIndex(item => item.id === el.id);
  //   const tmpPath = `${sitePath}participants/${data[0].id}/protocol/${data[0].userProtocol.timePoints[state.visitIndex ? state.visitIndex : 0].visit}/${el.item}`;
  //   setState((prev) => ({
  //     ...prev,
  //     itemIndex: newIndex,
  //     fullPath: tmpPath,
  //     item: el,
  //   }));
  // };

  const createChatRoom = () => {
    if (siteId) {
      const payload = {
        chatRoomLabel: `${participant.id} Chatroom `,
        participantId: participant.id,
        siteId,
      };
      addChatroom(payload);
    } else {
      setChatError(true);
    }
  };


  const handleMediaQueryChange = (matches: any) => {
    if (matches) {
      // window.alert('Trigger a window's alert at viewport of 1244px');
    } else {
      // do something if false
    }
  };

  const isSmallDesktopWindow = useMediaQuery(
    { maxWidth: 900 }, undefined, handleMediaQueryChange,
  );

  const onSelectRequirement = (el: IProtocolItemType) => {

    const newIndex = state.criteria.findIndex(item => item.id === el.id);
    const tmpPath = `${sitePath}participants/${data[0].id}/protocol/${data[0].userProtocol.timePoints[state.visitIndex ? state.visitIndex : 0].visit}/${el.item}`;
    setState((prev) => ({
      ...prev,
      itemIndex: newIndex,
      fullPath: tmpPath,
      item: el,
    }));

  };

  return (
    <div className='participant_wrapper'>
      {/* <button type='button' onClick={() => handleGridChange('defualt')}>defualt Grid</button>
      <button type='button' onClick={() => handleGridChange('condensed')}>condensed Grid</button>
      <button type='button' onClick={() => handleGridChange('panelOpen')}>panelOpen Grid</button> */}

      {gridState === 'default' &&
        <div className='participant__scan-menu'>
          <button className='participant__scan-btn' type='button' onClick={() => handleGridChange('panelOpen')}  >
            <MdArrowBackIosNew className='participant__scan-icon' />Participant Drive</button>
        </div>
      }
    <div className={`participantflex ${gridState === 'panelOpen' ? 'show-panel3' : ''}`}>
        <ProtocolBrowser 
          className="panel1" 
          id='protocol-browser'
          protocol={participantProtocol} 
          acl={acl}
          onSelect={onSelectRequirement}
          // onChangeItem={onChangeItem}
          onChangeVisit={onChangeVisit}
          prefix={data ? `${sitePath}participants/${data[0].id}/protocol` : ''}
        />
        {/* Content for the second column */}
        {state.item && <Tile className='panel2' >
            <ProtocolItemFactory info={participantProtocol[state.visitIndex].visit} secondaryId={data[0].secondaryId} createChatRoom={createChatRoom} reviewMode={reviewMode} toggleReviewMode={toggleReviewMode} acl={acl} item={state.item} path={state.fullPath} participant={participant.id} collapse={collapse} expand={expand} readOnly={readOnly} visitId={participantProtocol[state.visitIndex].id} baselineMRI={data[0].baeslineMri ?? 'undefined'} />
        </Tile>}
          {/* Content for the third column */}
          <Tile className='panel3'>
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            <>
              {Array.isArray(data) &&
                <ParticipantFiles
                  participant={participant.id}
                  showScans={showScans}
                  setShowScans={() => setGridState('default')}
                  path={(sitePath && data) ? `${sitePath}participants/${data[0].id}/protocol/drop all files` : 'n/a'}
                />}
            </>
          </Tile>
       </div>
    </div >
  );
};

// export default Participant;
export default withAuthenticator(Participant);
