
const clinicalMenu = [
  {
    title: 'Clinic',
    url: '/private/clinic',
    private: true,
  },
  {
    title: 'Patient Conferences',
    url: '/private/conferences',
    private: true,
  },
];

export default clinicalMenu;

