/* eslint-disable arrow-body-style */
import QueryString from 'qs';
import api from '../api';


export const participantProtocolApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getparticipantInfo: builder.query({
      query: (payload) => ({ url: `/participant/statsbyid/${payload.participantId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['PROTOCOL'],
    }),
    editProtocol: builder.mutation({
      query: (payload) => ({ url: `/participant/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['PROTOCOL'],
    }),
    getStudyParticipantRequirementTimeline: builder.query({
      query: (payload) => ({ url: `/participant/requirement/entries/${payload.participantId}/${payload.userProtocolItemId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['PARTICIPANT_REQS'],
    }),
  }),
});

export const {  
  usePrefetch,
  useGetparticipantInfoQuery,
  useEditProtocolMutation,
  useGetStudyParticipantRequirementTimelineQuery,
} = participantProtocolApi;
