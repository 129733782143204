import QueryString from 'qs';
import api from '../api';

export const patientSurveys = api.injectEndpoints({
  endpoints: (builder) => ({
    getSessionStats: builder.query({
      query: (hashId) => ({ url: `/survey/session/statsbyhashid/${hashId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['PATIENTSSURVEYS'],
    }),
    updateSurvey: builder.mutation({
      query: (payload) => ({ url: `/usersurvey/registry/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['PATIENTSSURVEYS'],
    }),

  }),
});

export const {
  usePrefetch,
  useGetSessionStatsQuery,
  useUpdateSurveyMutation,
} = patientSurveys;
