import api from '../api';

export const cohortsApis = api.injectEndpoints({
  endpoints: (builder) => ({
    addCohort: builder.mutation({
      query: (payload) => ({ url: '/cohort', method: 'POST', body: payload }),
      invalidatesTags: ['COHORTS'],
    }),
    getCohorts: builder.query({
      query: () => ({ url: '/cohort', method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['COHORTS'],
    }),
    deleteCohort: builder.mutation({
      query: (payload) => ({ url: `/cohort/${payload.id}`, method: 'DELETE', body: payload.body }),
      invalidatesTags: ['COHORTS'],
    }),
    getCohortInfo: builder.query({
      query: (payload) => ({ url: `/cohort/statsbyid/type/${payload.type}/${payload.id}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['OPENCOHORT'],
    }),
    getEndpoints: builder.query({
      query: (cohortId) => ({ url: `/cohort/endpoint/${cohortId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['OPENCOHORT'],
    }),
    sendEmail: builder.mutation({
      query: (payload) => ({ url: '/cohort/email', method: 'POST', body: payload }),
    }),
    sendToEndpoint: builder.mutation({
      query: (payload) => ({ url: '/files/endpoint/', method: 'POST', body: payload }),
    }),
  }),
});

export const {  
  usePrefetch,
  useAddCohortMutation, 
  useGetCohortsQuery, 
  useDeleteCohortMutation,
  useSendEmailMutation,
  useGetCohortInfoQuery,
  useGetEndpointsQuery,
  useSendToEndpointMutation,
} = cohortsApis;
