import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BiRefresh } from 'react-icons/bi';
import FlatList from 'flatlist-react';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { Label, Spacer, Tile } from '../../../components';
import { SearchBox } from '../../../components/molecules';
import { Badge, Text } from '../../../components/atoms';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { useGetHivesQuery } from '../../../api/builders/hives.api';



const Hives = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const { data: hives, isError, isLoading, isFetching } = useGetHivesQuery('');

  const handleChangeSearch = (value: string) => {
    setSearchTerm(value);
  };


  const filteredHives = hives?.filter((hive: any) => (hive.hiveLabel?.toLowerCase().includes(searchTerm.toLowerCase()) || hive.summary?.toLowerCase().includes(searchTerm.toLowerCase())),
  );


  // eslint-disable-next-line react/no-unstable-nested-components
  const HiveItem = (hiveItemProps: { hive: any }): JSX.Element => {
    const { hive } = hiveItemProps;
    const { description, hiveLabel } = hive;

    const addHiveCrumb = () => {
      const newBreadcrumb = {
        label: 'Hives',
        path: location.pathname,
      };
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    };

    const onOpen = async (item: any) => {
      const newBreadcrumb = {
        label: 'Hives',
        path: location.pathname,
      };
      await setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
      navigate('/private/hives/hive', { state: { hive: item } });
    };

    return (
      <Tile onClick={() => onOpen(hive)}>
        <div className='hive-item'>
          <div>
            <Label size="h4" weight={600} >{hiveLabel}</Label>
            <Spacer value={10} />
            <Text size='sm'>{description}</Text>
            <Spacer value={20} />
            <div className='d-flex'>
              <Label size='subtext'>Last Audit: <span className='bold'>N/A</span> </Label>
            </div>
          </div>
          <span className='hive-item__badge'>
            <Badge label="status" color='blue' />
          </span>
        </div>
      </Tile>
    );
  };

  const renderHive = (item: any, idx: React.Key | null | undefined) => <HiveItem key={idx} hive={item} />;

  return (
    <div className="hives">
      <div className="hives__header">
        <span className="hives__header-title">
          <Label size="h2" weight={300}>
            Hives
          </Label>
          <button type="button" className="icon-btn" aria-label="refetch" >
            <BiRefresh />
          </button>
        </span>
        <Spacer value={30} />
        <Spacer value={30} />
        <Spacer value={30} />
        <Spacer value={30} />
        <SearchBox value={searchTerm}
          onChange={handleChangeSearch}
          placeholder='search' />
      </div>
      <div className="hives__body">
        {Array.isArray(filteredHives) && (
          <FlatList
            list={filteredHives}
            renderItem={renderHive}
            renderWhenEmpty={() => (
              <div className="hives__empty">
                <Label color="ghost" size="h3">
                  No Hives
                </Label>
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default withAuthenticator(Hives);
