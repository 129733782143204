/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
// import classnames from 'classnames';
import './participant-scans.scss';
import MenuItem from '@mui/material/MenuItem';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { Tile, Label, Dropdown } from '../../atoms';
import { useGetAllScansQuery } from '../../../api/builders/participantFiles.api';
import TextInput from '../TextInput/TextInput';

export interface ParticipantScansProps {
  participant: any
  // showScans: boolean
  // setShowScans: (s: any) => void,
}

interface Scan {
  StudyDate: string;
  StudyDescription: string;
  StudyInstanceUID: string;
  StudyTime: string;
  acl: string;
  bucket: string;
  createdAt: number;
  id: string;
  updatedAt: number;
  Modality?: string;
  ScanSeries: {
    PixelSpacing: string;
    SliceThickness: string;
    AcquisitionDate: string;
    Modality: string;
    SeriesInstanceUID: string | null | undefined;
    SeriesDescription: string,
  },
}

const ParticipantScans = (props: ParticipantScansProps): JSX.Element => {
  const { participant } = props;
  // const { participant, showScans, setShowScans } = props;
  const [scans, setScans] = useState<Scan[]>([]);
  
  const [filteredScans, setFilteredScans] = useState<Scan[]>([]);
  
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [studyDateFilter, setStudyDateFilter] = useState<string>('ANY');
  const [modalityFilter, setModalityFilter] = useState<string>('ALL');
  const { data, isLoading } = useGetAllScansQuery({ id: participant ?? skipToken });

  useEffect(() => {
    // Fetch scans from API
    if (Array.isArray(data) && data.length > 0) {
      setScans(data);
      setFilteredScans(data);
    }
  }, [data]);

  const scanDragStart = (event: any, scan: any) => {
    try {
      event.dataTransfer.setData('scan', JSON.stringify(scan));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const filtered = scans.filter( (scan) =>{
      const scanSeriesDescription = scan.ScanSeries.SeriesDescription.toLowerCase();
      const scanModality = scan.ScanSeries.Modality.toLowerCase();
      const scanDate = scan.StudyDate;
      return (
        (scanSeriesDescription.includes(searchTerm.toLowerCase()) &&  ( studyDateFilter === 'ANY' || scanDate === studyDateFilter ) &&  ( modalityFilter === 'ALL' ||  scanModality === modalityFilter.toLowerCase()) )
      );
    });
    setFilteredScans(filtered);
  }, [filteredScans, searchTerm, studyDateFilter, modalityFilter]);

  const uniqueStudyDates = Array.from(new Set(scans.map((scan) => scan.StudyDate))).sort((a, b) => Number(a) - Number(b));
  const uniqueModalities = Array.from(new Set(scans.map((scan) => scan.ScanSeries.Modality).filter((modality) => modality)));


  return (
    <div className="participant-scans">
      <div className="participant-scans__header">
        <Label size='h4' weight={600}>Available Scans</Label>
        <TextInput
            placeholder="Search by study description"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="participant-scans__search-row">
            <Dropdown
              label="Study date"
              name='studyDateFilter'
              value={studyDateFilter}
              onChange={(e) => setStudyDateFilter(e.target.value)}
            >
              <MenuItem value='ANY'>ANY</MenuItem>
              {uniqueStudyDates.length > 0 && uniqueStudyDates.map((date) => (
                <MenuItem key={date} value={date}>
                  {moment(date).format('MMM DD, YYYY')}
                </MenuItem>
              ))}
            </Dropdown>
            <Dropdown
              label="Modality"
              name='modality'
              value={modalityFilter}
              onChange={(e) => setModalityFilter(e.target.value)}
            ><MenuItem value='ALL'>ALL</MenuItem>
              {uniqueModalities.length > 0 ? uniqueModalities.map((modality) => (
                <MenuItem key={modality} value={modality}>
                  {modality}
                </MenuItem>
              ))
                :
                <MenuItem value=''>
                  No Options
                </MenuItem>
              }
            </Dropdown>
          </div>
      </div>  
      <div className="participant-scans__body">
        <div>Results: {isLoading && 'Loading ...'} {filteredScans.length} </div>
          <ul className='participant-scans__list'>
            {filteredScans.map((scan) => (
              <li
                draggable
                onDragStart={(e) => scanDragStart(e, scan)}
                className='participant-scans__item'
                key={scan?.ScanSeries?.SeriesInstanceUID}
                >
                <Label uppercase size="h6" weight={600} >
                  {scan.ScanSeries.SeriesDescription}
                </Label>
                <Label size="h6" >
                  Study Date: {moment(scan.StudyDate).format('l')}
                </Label>
                <Label size="h6" >
                  {scan.ScanSeries.Modality && <div>Modality: {scan.ScanSeries.Modality}</div>}
                </Label>
                <Label size="h6" >
                  {scan.ScanSeries.SliceThickness && <div>SliceThickness: {scan.ScanSeries.PixelSpacing}\{scan.ScanSeries.SliceThickness} mm</div>}
                </Label>
              </li>
            ))}
          </ul>
      </div>
    </div>
  );
};

ParticipantScans.defaultProps = {
};

ParticipantScans.displayName = 'ParticipantScans';
export default ParticipantScans;
