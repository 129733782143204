import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { BiRefresh } from 'react-icons/bi';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, Button, Spacer, Dropdown, DeIdentifiedPatient, LogItem, Modal, CohortUserItem } from '../../../components';
import { useGetLogsQuery, cohortApi } from './api';


export interface Item {
  id: string,
  acl: string | null,
  description: string | null,
  shared: boolean,
  numberOfPatients: number,
  cohortLabel: string,
  createdAt: number,
  type: string
}

interface HistoryProps {
  cohort: Item;
}



const Logs = (props: HistoryProps): JSX.Element => {
  const { cohort } = props;
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetLogsQuery({ id: cohort?.id ? cohort.id : skipToken });
  const { refetch: refetchPats } =
    cohortApi.endpoints.getLogs.useQuerySubscription({ id: cohort?.id ? cohort.id : skipToken });

  return (
    <div>
      <div className='study__heading d-flex-row _apart'>
        <Label size="h4" weight={600} >Logs</Label>
        <button type='button' className='icon-btn' aria-label='refetch' onClick={refetchPats}>
          {/* <Refresh color='#171544' size={20} /> */}
        </button>
      </div>
      <Grid container spacing={2}>
        <Grid xs={3}>
          <Label center size='subtext'>User</Label>
        </Grid>
        <Grid xs={3}>
          <Label center size='subtext'> Date </Label>
        </Grid>
        <Grid xs={3}>
          <Label center size='subtext'>Item</Label>
        </Grid>
        <Grid xs={3}>
          <Label center size='subtext'>Action</Label>
        </Grid>
      </Grid>
      {Array.isArray(data) && data.map((item: any) => (
        <LogItem
          user={item.user.email}
          item={item.itemLabel}
          action={item.actionType}
          date={item.createdAt}
        />
      ))}
    </div>
  );
};

export default Logs;
