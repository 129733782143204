import React from 'react';
import classnames from 'classnames';
import './care-list-item.scss';

export interface CareListItemProps {
  id: string,
  className?: string,
}

const CareListItem = (props: CareListItemProps): JSX.Element => {
  const { id, className } = props;
  return (
    <div className="care-list-item">
      CareListItem
    </div>
  );
};

CareListItem.defaultProps = {
  className: '',
};

CareListItem.displayName = 'CareListItem';
export default CareListItem;
