import React from 'react';
// import classnames from 'classnames';
import './protocol-action-bar.scss';
import { FaRegFlag, FaFlag, FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { TbCircleCheck, TbCircleX } from 'react-icons/tb';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { RiAlertFill } from 'react-icons/ri';
import { useGetProtocolChatQuery, useAddMessageMutation } from '../../../api/builders/chats.api';
import { useEditProtocolMutation } from '../../../api/builders/participantProtocol.api';
import { Tile, Label, Spacer, Text, Modal, Button } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';


export interface ProtocolActionBarProps {
  protocolItem: any,
  participantId: string | undefined,
  readOnly: boolean,
  status: any,
}


const ProtocolActionBar = (props: ProtocolActionBarProps): JSX.Element => {
  const { protocolItem, participantId, readOnly, status } = props;
  const { data } = useGetProtocolChatQuery(protocolItem?.id ? protocolItem.id : skipToken);
  const [addMessage, { isLoading: addingNew }] = useAddMessageMutation();
  const [changeStatus, { isLoading: isEdititng }] = useEditProtocolMutation();
  const [verifyStatus, setVerifyStatus] = React.useState(false);
  const [verifyApprove, setVerifyApprove] = React.useState(false);
  const [verifyDeviation, setVerifyDeviation] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState(false);
  const [rejectItem, setRejectItem] = React.useState(false);

  const handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };


  const handleReviewItem = async (itemId: string, newStatus: string) => {
    try {
      const reviewPl = {
        id: participantId,
        body: {
          itemId,
          itemStatus: newStatus,
        },
      };
      await changeStatus(reviewPl);
    } catch {
      console.error('er');
    }
  };

  const handleSubmitMessage = () => {
    const newMessagePl = {
      message: `This item has been marked as deviation from the protocol: ${message}`,
      chatroomId: data[0].id,
      tag: protocolItem.id,
    };
    addMessage(newMessagePl);
  };

  const handleDeviation = (itemId: string, newsStatus: string) => {
    try {
      if (message.length > 1) {
        /* eslint-disable  no-unneeded-ternary */
        const pL = {
          id: participantId,
          body: {
            itemId,
            deviation: (protocolItem.deviation && protocolItem.deviation === true) ? false : true,
            // itemStatus: newsStatus,
          },
        };
        changeStatus(pL);
        if (Array.isArray(data) && data.length > 0) {
          handleSubmitMessage();
        }
      } else {
        setError(true);
      }
    } catch {
      console.error('er');
    }
  };


  const handleReviewStatus = (itemId: any, newStatus: string) => {
    handleReviewItem(itemId, newStatus);
  };




  return (
    <div className='protocol-action-bar'>
      {!readOnly && <div className='protocol-action-bar__item'>
        {status === 'missing' ?
          <button disabled type='button' className='protocol-action-bar__btn-or' onClick={() => setVerifyStatus(true)}>
            <RiAlertFill size={16} className='protocol-action-bar__missing-active' />
          </button>
          :
          <button disabled={readOnly} type='button' className='protocol-action-bar__btn-or' onClick={() => setVerifyStatus(true)}>
            <RiAlertFill size={20} className='protocol-action-bar__missing' />
          </button>
        }
      </div>}
      <Spacer value={10} />
      <div className='protocol-action-bar__item'>
        {
          protocolItem.deviation && protocolItem.deviation === true ?
            <button disabled={readOnly} type='button' className='protocol-action-bar__btn-red' onClick={() => setVerifyDeviation(true)} >
              <FaFlag size={16} className='protocol-action-bar__deviation-active' />
            </button>
            :
            <div>
              <button disabled={readOnly} type='button' className='protocol-action-bar__btn-red' onClick={() => setVerifyDeviation(true)} >
                <FaRegFlag size={16} className='protocol-action-bar__deviation' />
              </button>
            </div>
        }
      </div>
      <Spacer value={10} />
      {!readOnly && <div className='protocol-action-bar__item'>
        <button disabled={status === 'rejected'} type='button' className='protocol-action-bar__btn-or' onClick={() => setRejectItem(true)}>
          <IoClose size={20} className='protocol-action-bar__reject' />
        </button>
      </div>}
      <Spacer value={10} />
      {!readOnly && <div className='protocol-action-bar__item'>
        <button disabled={status === 'approved'} type='button' className='protocol-action-bar__btn-green' onClick={() => setVerifyApprove(true)}>
          <FaCheck size={12} className='protocol-action-bar__aprove' />
        </button>
      </div>}

      <Modal size='sm' title='Mark Item Deviation'
        open={verifyDeviation} onClose={() => setVerifyDeviation(false)}>
        <div>
          {protocolItem.deviation && protocolItem.deviation === true ?
            <Label size='h4' >Would you like to remove deviation flag from this item?</Label> :
            <Label size='h4' >Would you like to mark this item as a Deviation?</Label>
          }
          <Spacer value={20} />
          <TextAreaInput
            name='message'
            label='Message (Required)'
            value={message}
            onChange={handleChangeInput}
          />
          {error && <Label weight={600} size='subtext' color='danger'>Message is Required</Label>}
          <Spacer value={20} />
          <div className='btn-row'>
            <Button kind='danger' onClick={() => setVerifyDeviation(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleDeviation(protocolItem.id, protocolItem.status)}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <Modal size='sm' title='Rejected Item'
        open={rejectItem} onClose={() => setRejectItem(false)}>
        <div>
          <Label size='h4' >Would you like to change the status of this item to Rejected?</Label>
          <Spacer value={20} />
          <TextAreaInput
            name='message'
            label='Message (Required)'
            value={message}
            onChange={handleChangeInput}
          />
          {error && <Label weight={600} size='subtext' color='danger'>Message is Required</Label>}
          <Spacer value={20} />
          <div className='btn-row'>
            <Button kind='danger' onClick={() => setRejectItem(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleReviewStatus(protocolItem.id, 'rejected')}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <Modal size='sm' title='Mark Item Missing' open={verifyStatus} onClose={() => setVerifyStatus(false)} >
        <div>
          <Label size='h4' >Would you like to change the status of this item to Missing?</Label>
          <Spacer value={20} />
          <div className='btn-row'>
            <Button kind='danger' onClick={() => setVerifyStatus(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleReviewItem(protocolItem.id, 'missing')}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <Modal size='sm' title='Approve Item' open={verifyApprove} onClose={() => setVerifyApprove(false)} >
        <div>
          <Label size='h4' >Would you like to change the status of this item to Approved?</Label>
          <Spacer value={20} />
          <div className='btn-row'>
            <Button kind='danger' onClick={() => setVerifyApprove(false)}>
              Cancel
            </Button>
            <Button onClick={() => handleReviewItem(protocolItem.id, 'approved')}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ProtocolActionBar.defaultProps = {
};

ProtocolActionBar.displayName = 'ProtocolActionBar';
export default ProtocolActionBar;
