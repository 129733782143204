/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import TabBar from '../TabBar/TabBar';
// import classnames from 'classnames';
import './tab-view.scss';

export interface TabViewProps  {
  id: string,
  children: JSX.Element | Array<JSX.Element>,
  headers: Array<any> | [],
  className?: string,
  onChangeTab?: (event: any)=>void,
  showheader?: boolean,
}

const TabView = (props: TabViewProps): JSX.Element => {
  const { id, children, headers, onChangeTab, showheader } = props;
  
  const [activeTab, setActiveTab] = useState(0);
  
  // const setActiveTabHeader = (list: any[], activeid: number) => {
  //   // set all headings as deselected
  //   for (let i = 0; i < list.length; i++) {
  //     list[i].className = '';
  //   }
  //   // set selected heading as selected
  //   list[activeid].className = 'selected';
  // };

  // const setActiveTab = (tabid: number) => {
  //   // hide all tab content
  //   let tabs = children;
  //   for (let i = 0; i < tabs.length; i++) {
  //     tabs[i].className = 'tab hidden'
  //   }
  //   // set selected tab content as visible
  //   tabs[tabid].className = 'tab'
  // }
  
  // const changeTab = (event: any) => {
  //   if (onChangeTab !== undefined) {
  //       onChangeTab(event)
  //   }
  //   let id = event.target.getAttribute('data-tab-id')
  //   // setActiveTab(id)

  //   let list = event.target.parentElement.parentElement.children
  //   setActiveTabHeader(list, id)
  // }

  const handleClick = (e: any) => {
    e.preventDefault();
  };
  

  // eslint-disable-next-line react/no-unstable-nested-components
  const Headers = ( headerProps: any ): JSX.Element => {
    const { data } = headerProps;
    return (<div className="tab-view-header"><ul>
      { data.map((header: {
        id: any; label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; 
      }) => (
        <div className="tab-view-tab tab-view-vl" >
            <li key={header.id}>
              <a data-tab-id={header.id} onClick={handleClick}>{header.label}</a>
            </li>
        </div>))
      }
    </ul></div>);
  };

  const renderActive = () => {
    if (Array.isArray(children)) {
      if (activeTab < children.length) {
        return children[activeTab];
      }
      return children[0];
    }
    return children;
  };

  return (
        <div key={`TabView_${id}`} className="tab-view-wrapper">
        {showheader && <Headers data={headers} />}
        <div className="tab-view-content" >
          {renderActive()}
        </div> 
        </div>
  ); 
};

TabView.defaultProps = {
  className: '',
  showheader: false,
  onChangeTab: ()=>console.info('on change tab parent not defined'),
};

TabView.displayName = 'TabView';
export default TabView;
