import React from 'react';
// import classnames from 'classnames';
import { FaSearch } from 'react-icons/fa';
import './advanced-emr-search.scss';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  onChangeField?: (value: string) => void;
  onSubmit?: (value: string) => void;
  placeholder?: string;
};

const SearchBox = ({ value, onChange, onSubmit, placeholder, onChangeField }: Props) => {
  const [state, setState] = React.useState({
    value: value || '',
  });
  const [field, setField] = React.useState('mrn');
  const [blur, setBlur] = React.useState(false);

  React.useEffect(() => {
    if (typeof value === 'string') {
      setState({ value });
    }
  }, [value]);

  const handleChangeField = (val: any) => {
    setField(val.target.value);
    if (onChangeField) {
      onChangeField(val.target.value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { value } = e.target;
    setState({ value });
    if (onChange) onChange(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(state.value);
    }
  };

  const ops = ['mrn', 'name', 'MCL_ID', 'Date_of_Procedure', 'age', 'PatientBirthDate', 'sex',
    'smoking', 'pky', 'hx_cancer', 'cancer', 'histology', 'density', 'spiculation', 'location', 'emphysema', 'FDG-Avidity'];

  const opss = [
    {
      name: 'MRN',
      value: 'mrn',
    },
    {
      name: 'Name',
      value: 'name',
    },
    {
      name: 'Medical ID',
      value: 'MCL_ID',
    },
    {
      name: 'Patient Birth Date',
      value: 'PatientBirthDate',
    },
    {
      name: 'Histology',
      value: 'histology',
    },
    {
      name: 'Location',
      value: 'location',
    },
  ];

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      if (onSubmit) {
        onSubmit(state.value);
      }      // callMyFunction();
    }
  };
  return (
    <div className='textfield'>
      <div
        className={`textfield__input ${blur && 'textfield__input--focused'}`}
      >
        <div className='textfield__input-adornment'>
          <select onChange={handleChangeField} value={field} className='textfield__select' name="cars" id="cars">
            {opss.map((item: any) => (
              <option value={item.value}>{item.name}</option>
            ))}
          </select>
        </div>
        <input
          placeholder={placeholder}
          value={state.value}
          onChange={handleChange}
          className='textfield__input-field'
          onFocus={() => setBlur(!blur)}
          onBlur={() => setBlur(false)}
          onKeyDown={handleKeyPress}
        />
      </div>
    </div>
  );
};
SearchBox.defaultProps = {
  value: '',
  onChange: () => null,
  onSubmit: () => null,
  onChangeField: () => null,
  placeholder: 'Enter text to search',
};

export default SearchBox;