import React from 'react';
import * as Component from '../../components';



const Support = () => {
  const somevar = 'SUPPORT';
  return (
    <div className="support">{somevar}</div>
  );
};

export default Support;
