import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { BsPlus } from 'react-icons/bs';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { AuditActionItem, AuditDicom, CloseAudit } from '../../../components/molecules';
import { Label, Spacer } from '../../../components';
import { Spinner, Tile } from '../../../components/atoms';
import { useGetAuditStatsByIdQuery, useGetSimilarImgsMutation, useUpdateAuditMutation } from '../../../api/builders/hives.api';
import RenderStatusIcon from '../../../utils/AuditBadge';


interface LocationState {
  audit: any;
}

type Payload = {
  hives: { id: string }[];
  studies: { id: string }[];
};
const Audit = () => {
  const location = useLocation();
  const { audit } = location?.state as LocationState;
  const { breadcrumbs, setBreadcrumbs } = useContext(BreadcrumbsContext);
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetAuditStatsByIdQuery((audit && audit?.id) ?? skipToken);
  const [getImgs] = useGetSimilarImgsMutation();
  const [saveAudit] = useUpdateAuditMutation();

  const [currentSerie, setCurrentSerie] = useState<any>(null);
  const [currentStack, setCurrentStack] = useState<any>();
  // const coldfiles = useGetColdAuditFileArrayQuery({ id: currentSerie !== null ? getPayload(currentSerie?.id) : skipToken });



  const handleSaveAudit = () => {
    try {
      const payload = {
        auditId: audit.id,
        body: {
          status: 'in progress',
        },
      };
      saveAudit(payload);
    } catch (e) {
      console.error(e);
    }
  };

  const get3imgs = (pl: any) => {
    try {
      const payload = {
        auditId: audit.id,
        body: pl,
      };
    } catch (e) {
      console.error('e', e);
    }
  };

  const get5imgs = (num: any) => {
    if (isLoadingData || isFetchingData) {
      return;
    }
    try {
      const getIDFromPayload = (payload: Payload): { scanHiveId?: string; scanStudyId?: string } => {
        const { hives, studies } = payload;
        const result: { scanHiveId?: string; scanStudyId?: string } = {};

        if (hives.length > 0 && hives[0].id) {
          result.scanHiveId = hives[0].id;
        }

        if (studies.length > 0 && studies[0].id) {
          result.scanStudyId = studies[0].id;
        }

        return result;
      };


      const field = getIDFromPayload(data);

      const payload = {
        auditId: audit.id,
        body: {
          numberOfNewScans: num,
          ...field,
        },
      };
      getImgs(payload);
    } catch (e) {
      console.error('e', e);
    }
  };


  useEffect(() => {
    if (data && (Array.isArray(data.scans) && data.scans.length > 0)) {
      setCurrentStack(data.scans);
      setCurrentSerie(data.scans[0]);
    }
  }, [data]);

  const handleClick = (_item: any) => {
    try {
      const serie = _item;
      setCurrentSerie(serie);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const newBreadcrumb = {
      label: 'Audit',
      path: location.pathname,
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);


  if (!location?.state) {
    return (
      <div className='d-flex-row-center'>
        <Label size="h4" weight={600} color="primary">Error Loading Audit Details</Label>
      </div>
    );
  }
  return (
    <div className="audit">
      <div className='audit__header'>
        <span className='d-flex'>
          <Label size="h2" weight={300}>
            {audit.description}
          </Label>
          <span className='audit__header-badge'>
            <RenderStatusIcon status={audit.status} />
          </span>
        </span>
      </div>
      {data && (Array.isArray(currentStack) && currentStack.length > 0) ?
        <div className='audit__body'>
          <aside className='audit__body-aside'>
            {currentStack.map((item: any) => (
              <AuditActionItem
                item={item}
                onClick={handleClick}
                active={currentSerie.id === item.id}
                getImgs={get3imgs}
                readOnly={audit.status === 'cleared'}
              />
            ))}
            {isLoadingData || isFetchingData && <div className='btn-row'><Spinner /></div>}

            {audit.status !== 'cleared' &&
              <>
                <Tile className="audit__add-btn" onClick={() => get5imgs(5)}>
                  <Label uppercase size="h5" weight={600} >
                    <BsPlus size={30} />
                  </Label>
                </Tile>
                <Spacer value={10} />
                <CloseAudit audit={data} />
              </>
            }
          </aside>
          <div className='audit__body-panel1'>
            {currentSerie &&
              <AuditDicom auditStarted={handleSaveAudit} series={currentSerie} audit={audit} readOnly={audit.status === 'cleared'} />
            }
          </div>
        </div>
        :
        <div className="audits__empty">
          <Label color="ghost" size="h3">
            Nothing to audit
          </Label>
        </div>
      }
    </div >
  );
};

export default withAuthenticator(Audit);
