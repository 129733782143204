import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
// import classnames from 'classnames';
import './downloadable-file.scss';
import { BsFileEarmarkRichtextFill } from 'react-icons/bs';
import { Label, Tile, Checkbox, Text } from '../../atoms';
// import { useDownLoadFileMutation } from '../../../api/api';
import { useDownLoadFileMutation } from '../../../pages/private/StudyDrive/api';
import FileListItem from '../FileListItem/FileListItem';



export interface DownloadableFileProps {
  item: File
}

type File = {
  originalPath: string,
  anonymizedPath: string,
  filename?: string,
};

const DownloadableFile = (props: DownloadableFileProps): JSX.Element => {
  const { item } = props;
  const [fileToGet, setFileToGet] = React.useState<any>(null);
  const [download] = useDownLoadFileMutation();

  const getLabel = (longName: string) => {
    const split = longName.split('/');
    const shortened = split[split.length - 1];
    return shortened;
  };

  const handleFileClick = async (clickedFile: any) => {
    const newPath = `s3://${clickedFile.bucket}/${clickedFile.coldKey}`;
    const payload = { files : [{ s3_path: newPath }] };
    const yourbrandnewfile = await download(payload).unwrap();
    const url = window.URL.createObjectURL(new Blob([yourbrandnewfile[0].signedUrl]));
    const link = document.createElement('a');
    link.href = url;
    if (item.filename) {
      link.setAttribute('download', `${item.filename}`);
    } else {
      link.setAttribute('download', `${getLabel(clickedFile.originalPath)}`);
    }
    document.body.appendChild(link);
    link.click();
  };

  // file-list-item--wrapper 
  return (
    <div className="downloadable-file downloadable-file__wrapper" role='button' tabIndex={-1} onClick={() => handleFileClick(item)}>
      <div className="file-list-item--header">
      <BsFileEarmarkRichtextFill />
      <Text>{item.filename ? item.filename : getLabel(item.originalPath)}</Text>
      </div>
    </div>
  );
};

DownloadableFile.defaultProps = {
};

DownloadableFile.displayName = 'DownloadableFile';
export default DownloadableFile;
