
const privacyMenu = [
  {
    title: 'AUDITS',
    url: '/private/audits',
    private: true,
  },
  {
    title: 'HIVES',
    url: '/private/hives',
    private: true,
  },
];

export default privacyMenu;

