import React from 'react';
/* eslint-disable no-plusplus */
import { AiFillEdit } from 'react-icons/ai';
import { BiTrash, BiRefresh } from 'react-icons/bi';
import { GrFormAdd } from 'react-icons/gr';
import { Label, Button, Modal } from '../../atoms';
import { metApi, useRemoveTumorMutation, useAddMETMutation } from '../../../api/builders/met.api';
import './met-table.scss';
import DeleteVerification from '../DeleteVerification/DeleteVerification';
import AddMet from '../AddMet/AddMet';

export interface MetTableProps {
  itemId: string | undefined,
  data: Met[],
  participant: string | undefined,
  acl: string,
  onChangeItem: (item: Met, indx: number) => void,
  visitId: string | undefined,
  role: string,
}

export interface Met {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  isTreated: boolean,
  isTreatable: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  visit?: string,
  tumorassessments: MetHx[] | [],
}

export interface MetHx {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  tumorAssessmentId: string,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  protocolItemId: string,
  isTreated: boolean,
  isTreatable: boolean,
}


const MetTable = (props: MetTableProps): JSX.Element => {
  const { onChangeItem, itemId, acl, participant, data, visitId, role } = props;
  const [itemToDelete, setItemToDelete] = React.useState<Met | null>(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [addNewModal, setAddNewModal] = React.useState(false);

  const [deleteTumor, { isLoading: isDeleting }] = useRemoveTumorMutation();
  const { refetch: refetchTumors } =
    metApi.endpoints.getparticipantTumors.useQuerySubscription({ participantId: participant });
  const [addMET, { isLoading: isAddingMet, isError: isErrorAdding }] = useAddMETMutation();


  const openDeleteMetModal = (item: Met) => {
    setItemToDelete(item);
    // setOpenDelete(true);
  };

  const openAddNewMet = () => {
    const label = `MET-${data.length + 1}`;
    setAddNewModal(true);
  };

  const closeAddModal = () => {
    setAddNewModal(false);
  };


  const closeDeleteMetModal = () => {
    setOpenDelete(false);
    setItemToDelete(null);
  };

  const deleteSelectedTumor = async (item: Met) => {
    try {
      await deleteTumor(item.id);
      setOpenDelete(false);
    } catch (e) {
      console.error(e);
    }
  };


  // write a typescript function that takes an array of objects and creates a new array of strings with the values from the fields label and diameter

  const renderRow = (item: Met, index: number) => {
    if (item.tumorassessments.length > 0) {
      const findMatch = item.tumorassessments.filter(it => it.protocolItemId === itemId);
      if (findMatch && findMatch.length > 0) {
        return (
          <tr className='met-manager__met-item-wrapper' key={item.id} role="button" tabIndex={-1} >
            < td className='met-manager__table-td'>
              {item.tumorLabel ? item.tumorLabel : <span>-</span>}
            </td>
            < td className='met-manager__table-td'>
              {item.lobe ? item.lobe : <span>-</span>}
            </td>
            < td className='met-manager__table-td'>{item.laterality ? item.laterality : <span>-</span>} </td>
            <td className='met-manager__coords-td'>
              <Label center size='subtext'>RL / AP / SI</Label>
              <span>
                {item.RLCoord ? item.RLCoord : <span>-</span>} / {' '}
                {item.APCoord ? item.APCoord : <span>-</span>} / {' '}
                {item.SICoord ? item.SICoord : <span>-</span>}
              </span>
            </td>
            <td className='met-manager__coords-td'>
              <Label center size='subtext'>Longest / Shortest (in plane)</Label>
              {/* <Label center size='subtext'>Axial / Coronal / Sagittal</Label> */}
              <span>
                {findMatch[0].diameterAxial ? findMatch[0].diameterAxial : <span>-</span>}  /{' '}
                {findMatch[0].diameterCoronal ? findMatch[0].diameterCoronal : <span>-</span>} 
                {/* /{' '} */}
                {/* {findMatch[0].diameterSagittal ? findMatch[0].diameterSagittal : <span>-</span>} */}
              </span>
            </td>
            < td className='met-manager__table-td'>
              <AiFillEdit className="met-manager__table-open" size={16} onClick={() => onChangeItem(item, index)} />
            </td>
          </tr>
        );
      }
    }
    return (
      <tr className='met-manager__met-item-wrapper' key={item.id} role="button" tabIndex={-1} >
        < td className='met-manager__table-td'>
          {item.tumorLabel ? item.tumorLabel : <span>-</span>}
        </td>
        < td className='met-manager__table-td'>
          {item.lobe ? item.lobe : <span>-</span>}
        </td>
        < td className='met-manager__table-td'>
          {item.laterality ? item.laterality : <span>-</span>}
        </td>
        <td className='met-manager__coords-td'>
          <Label center size='subtext'>RL / AP / SI</Label>
          <span>
            {item.RLCoord ? item.RLCoord : <span>-</span>} / {' '}
            {item.APCoord ? item.APCoord : <span>-</span>} / {' '}
            {item.SICoord ? item.SICoord : <span>-</span>}
          </span>
        </td>
        <td className='met-manager__coords-td'>
          <Label center size='subtext'>Longest / Shortest</Label>
          <span>
            -
            {/* / {' '}
            / {' '} */}
          </span>
        </td>
        <td className='met-manager__table-td'>
          <AiFillEdit className="met-manager__table-open" size={16} onClick={() => onChangeItem(item, index)} />
          {acl === 'admin' && <BiTrash className="met-manager__table-open" size={16} onClick={() => openDeleteMetModal(item)} />}
        </td>
      </tr>
    );
  };

  const sort = (items: Met[]) => {
    try {
      const arrayForSort = [...items];
      let test = [];
      test = arrayForSort.sort((a, b) => a.tumorLabel.localeCompare(b.tumorLabel));
      return test;
    } catch {
      console.error('failed to sort MET data');
      return items;
    }
  };

  return (
    <div className="card">

      <div className='border-heading'>
        <div className='d-flex-row'>
          <Label size='h4' weight={600}>Mets</Label>
          <button type='button' className='icon-btn' aria-label='refetch' onClick={refetchTumors}>
            <BiRefresh />
          </button>
        </div>
        {role === 'SITE COORDINATOR' && <Button kind='ghost' icon={GrFormAdd} onClick={openAddNewMet} />}
      </div>

      <table className='met-manager__table'>
        <thead className='met-manager__table-thead'>
          <tr>
            <th className='met-manager__table-th'>MET</th>
            <th className='met-manager__table-th'>Lobe</th>
            <th className='met-manager__table-th'>Laterality</th>
            <th className='met-manager__table-th'>Coordinates</th>
            <th className='met-manager__table-th'>Diameters</th>
            <th className='met-manager__table-th'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data.length > 0 && sort(data).map((item: Met, index) => (
            renderRow(item, index)
          ),
          )}
        </tbody>
      </table>

      <Modal size="sm" open={openDelete} onClose={closeDeleteMetModal} title="Delete Study">
        <DeleteVerification
          loading={isDeleting}
          item={itemToDelete}
          name='Met'
          onCancel={closeDeleteMetModal}
          onDelete={deleteSelectedTumor}
        />
      </Modal>

      <Modal
        open={addNewModal}
        onClose={closeAddModal}
        title='Add New MET'
      >
        <AddMet
          label={`MET-${Array.isArray(data) ? (data.length + 1) : 1}`}
          isAddingMet={isAddingMet}
          addMET={addMET}
          itemId={itemId}
          participant={participant}
          onClose={closeAddModal}
          visitId={visitId}
        />
      </Modal>
    </div>
  );
};

MetTable.defaultProps = {
};

MetTable.displayName = 'MetTable';
export default MetTable;
