import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Label from '../Label/Label';

import './date-time-picker.scss';

export interface DateTimePickerProps {
  value: string,
  invalid?: boolean,
  onChange: (date: any) => void,
  onError?: () => void,
  id?: string,
  label: string,
  invalidText?: string,
  helperText?: string,
}

const CustomDateTimePicker = (props: DateTimePickerProps) => {
  const { value, invalid, onChange, id, onError, label, invalidText } = props;
  const [inputVal, setInputVal] = React.useState('');

  React.useEffect(() => {
    setInputVal(value);
  }, [value]);

  React.useEffect(() => {
    setInputVal('');
  }, []);


  const handleChange = (e: any) => {
    if (e && e.$d !== 'Invalid Date') {
      if (onChange) {
        onChange(e?.$d && e.$d);
      }
      setInputVal(e?.$d && e.$d);
    }
  };

  const createInvalid = () => {
    const isInvalid = invalid ? (
      <span className="text-input__invalid-text">{invalidText}</span>
    ) : null;
    return isInvalid;
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const containerStyle = {
    width: '100%', // Set the container width to fill its parent's width
    height: '100%', // Set the container height to fill its parent's height
  };

  return (
    <Box style={containerStyle} id='wtf'>
      {label && <Label className="input__label" size="h5" weight={600} color="primary" >{label}</Label>}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          value={inputVal}
          onChange={handleChange}
          label="DateTimePicker"
          // eslint-disable-next-line react/jsx-props-no-spreading
          // renderInput={(params) => <TextField {...params} />}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <input
                disabled
                ref={inputRef}
                onKeyDown={onKeyDown}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
                className='date-input' />
              {InputProps?.endAdornment}
            </Box>
          )}
        />
      </LocalizationProvider>
    </Box>

  );
};

CustomDateTimePicker.defaultProps = {
  helperText: 'Choose Date',
  invalid: false,
  onError: () => console.error('er'),
  id: 'datepicker',
  invalidText: 'Invalid Date',
};

CustomDateTimePicker.displayName = 'DateTimePicker';


export default CustomDateTimePicker;
