import React, { useState } from 'react';
// import classnames from 'classnames';
import { Tile, Label, Text, Button, Spacer } from '../../atoms';
import Question from '../Question/Question';
import './survey.scss';

export interface SurveyProps {
  userSurvey: any,
  setUserSurvey: (updatedItem: any) => void,
  onSubmit: (updatedItem: any) => void,
  saveProgress: (updatedItem: any) => void,
  viewAll?: boolean,
}

const Survey = (props: SurveyProps): JSX.Element => {
  const { userSurvey, setUserSurvey, onSubmit, viewAll, saveProgress } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = async () => {
    let goTo = 'next';
    if (Array.isArray(userSurvey[currentIndex].answer) && userSurvey[currentIndex].answer.length === 1 && userSurvey[currentIndex].answer[0].goto) {
      goTo = userSurvey[currentIndex].answer[0]?.goto;
    }
    if (goTo) {
      if (goTo === 'next') {
        setCurrentIndex(prev => prev + 1);
      } else if (goTo === 'end') {
        setCurrentIndex(userSurvey.length + 1);
      } else if (goTo !== 'next' && goTo !== 'end' && Number(goTo)) {
        setCurrentIndex(Number(goTo) - 1);
      } else {
        setCurrentIndex(prev => prev + 1);
      }
    }
  };


  const prev = () => {
    if (userSurvey[currentIndex] && userSurvey[currentIndex].prev) {
      const prevs = userSurvey[currentIndex].prev;
      setCurrentIndex(prevs);
    } else {
      setCurrentIndex(previous => previous - 1);
    }
  };

  const handleViewAllAnswer = (ans: any, index: number) => {
    const updatedSurvey = [...userSurvey];
    updatedSurvey[index].answer = ans;
    setUserSurvey(updatedSurvey);
  };

  const onAnswer = (answers: any) => {
    const updatedSurvey = [...userSurvey];
    updatedSurvey[currentIndex].answer = answers;

    if (Array.isArray(answers) && answers.length === 1 && answers[0].goto) {

      if (typeof (answers[0].goto) === 'number') {
        const goTo: number = answers[0]?.goto ? answers[0].goto - 1 : 0;

        updatedSurvey[goTo].prev = currentIndex;

      } else {
        let goTo = -1;
        switch (answers[0].goto) {
          case 'next':
            goTo = currentIndex + 1;
            break;
          default:
            goTo = currentIndex + 1;
        }

        if (goTo < updatedSurvey.length && updatedSurvey[goTo].prev) {
          delete updatedSurvey[goTo].prev;
        }

      }

    } else {
      const goTo = currentIndex + 1;

      if (goTo < updatedSurvey.length && updatedSurvey[goTo].prev) {
        delete updatedSurvey[goTo].prev;
      }
    }

    setUserSurvey(updatedSurvey);
  };

  const decline = () => {
    setCurrentIndex(old => old + 1);
  };

  const submitSurvey = () => {
    onSubmit(userSurvey);
  };

  const handleSaveParticial = () => {
    saveProgress(userSurvey);
  };

  const handleNext = () => {
    if (currentIndex + 1 === userSurvey.length) {
      submitSurvey();
    } else {
      next();
    }
  };

  return (
    <div className='survey'>
      <Spacer value={20} />
      {userSurvey?.length > 0 ?
        <div>
          {viewAll ?
            <div>
              {userSurvey.map((q: any, index: number) => (
                <div className='survey-view-all-item'>
                  <Question
                    key={`survey-question-${q.id}`}
                    question={q}
                    onAnswer={(it) => handleViewAllAnswer(it, index)}
                    currentIndex={index}
                  />
                  <Spacer value={20} />
                </div>
              ))}
              <Spacer value={20} />
              <div className='btn-row'>
                <Button size='wide' onClick={handleSaveParticial}>
                  Save Progress
                </Button>
                <Button size='wide' onClick={submitSurvey}>
                  Submit for review
                </Button>
              </div>
              <Spacer value={30} />
            </div>
            :
            <div className='survey-content-container'>
              <div className='survey-nav-container'>
                <Button onClick={prev} disabled={currentIndex === 0} >Prev</Button>
                <Label center size='h5'>{`Question ${currentIndex + 1}/${userSurvey.length}`}</Label>
                {/* <Button onClick={next} kind='secondary' size='small' >Next</Button> */}
                <Button
                  disabled={
                    userSurvey[currentIndex]?.answer?.length === 0 ||
                    (userSurvey[currentIndex]?.answer?.length > 0 && userSurvey[currentIndex]?.answer[0]?.value === '')
                  }
                  // onClick={next}
                  onClick={handleNext}
                  
                >
                  {currentIndex + 1 === userSurvey.length ? 'Done' : 'Next'}
                </Button>
              </div>

              <Spacer value={30} />

              <Question
                key={`survey-question-${currentIndex}`}
                question={userSurvey[currentIndex]}
                onAnswer={onAnswer}
                currentIndex={currentIndex}
              />

              {/* {
                userSurvey[currentIndex].skippable &&
                <div className='userSurvey__skip' role='button' tabIndex={-1} onClick={decline}><Label italic bold size='h6' color='secondary' >
                  Decline to answer
                </Label></div>
              } */}
              <Spacer value={30} />
            </div>
          }
        </div>
        :
        <Label size='h4'> There are no questions for this survey </Label>
      }
    </div>
  );
};

Survey.defaultProps = {
  viewAll: false,
};

Survey.displayName = 'Survey';
export default Survey;
