import React from 'react';
// import classnames from 'classnames';
import './shield-icon.scss';
import { Link } from 'react-router-dom'; // Assuming you're using React Router

import Shield from './shield.svg';
import Broken from './broken.svg';

export interface ShieldIconProps  {
  size?: number,
  type?: 'shield' | 'broken' | undefined,
  link?: string; // New property for the link URL
}

const ShieldIcon = (props: ShieldIconProps) => {
  const { size, type, link } = props;

  const handleIconClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    console.info('click');
    event.stopPropagation();
    // You can add additional logic here if needed
  };

  const renderIcon = () => {
    if (link) {
      return (
        <div className="shield-link">
          <Link to={link}  onClick={handleIconClick}>
            <img
              src={type === 'shield' ? Shield : Broken}
              alt={type}
              style={{ width: size, height: size }}
              className="shield-image"
            />
          </Link>
        </div>
      );
    }

    return (
      <img
        src={type === 'shield' ? Shield : Broken}
        alt={type}
        style={{ width: size, height: size }}
        className="shield-image"
      />
    );
  };

  return <div className="shield-icon">{renderIcon()}</div>;
};  

ShieldIcon.defaultProps = {
  size: 40,
  type: 'shield',
  link: undefined,
};

ShieldIcon.displayName = 'ShieldIcon';


export default ShieldIcon;
