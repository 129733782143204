import React from 'react';
import Lottie from 'lottie-react';
import { InsightecTarget } from '../../components/molecules';
// import { ScrollableSection, Spacer, UI } from '../../components';
import Background from './corelab-insightec-01.png';
import groovyWalkAnimation from './insightec1.json';

export default function Main() {
  return (
    <div className="main">
            {/* <img src={Background} alt="img" className='main__img'/> */}
            <Lottie animationData={groovyWalkAnimation} loop={false} />
            
            {/* <InsightecTarget /> */}
      {/* <UI />
    <ScrollableSection ><div>hi you section 1</div></ScrollableSection>    
    <ScrollableSection><div>hi you section 2</div></ScrollableSection>    
    <ScrollableSection><div>hi you section 3</div></ScrollableSection>     */}
    </div>
  );
}