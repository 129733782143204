/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import classNames from 'classnames';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import DropZone from '../DropZone/DropZone';
import FilesList from '../FilesList/FilesList';
import { Text, Button, Label, Spacer } from '../../atoms';
import { useCreateSignedUrlMutation } from '../../../api/api';
import FileTree from '../FileTree/FileTree';
import UploadZone from '../UploadZone/UploadZone'; import './drop-all-files.scss';

export interface DropAllFilesProps {
  path: string,
}

const CONCURRENT_UPLOAD = 100;

interface CallbackInterface {
  (arg: any): void;
}

const getFile = (fileEntry: FileSystemFileEntry) => {
  try {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
  } catch (err) {
    return undefined;
  }
};

interface DicomFileInterface {
  file: File;
  fileSystemEntry: FileSystemEntry;
  dcmData: any;
  signedUrl: string;
}

// interface DicomTreeItem {
//   dicomfileinterface: DicomFileInterface[];
//   count: number;
// }

interface DicomFileReducerStateInterface {
  queue: DicomFileInterface[];
  uploaded: DicomFileInterface[];
  deadletterqueue: DicomFileInterface[];
  tree: object | null;
  uploadedtree: object | null;
  loading: boolean;
  loaded: boolean;
}


const DicomFilesReducer = (state: DicomFileReducerStateInterface, action: { type: string, payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE':
      let res = payload.map((it: any) => {
        const el = { file: getFile(it as FileSystemFileEntry), fileSystemEntry: it, dcmData: null, signedUrl: null };
        return el;
      });

      res = [...state.queue, ...res];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // res = [...new Map(res.map((v: { fileSystemEntry: any; }) => [v.fileSystemEntry, v])).queues()];
      res = [...res.reduce((map: { set: (arg0: any, arg1: any) => any; }, obj: { fileSystemEntry: any; }) => map.set(obj.fileSystemEntry.fullPath, obj), new Map()).values()];

      const tree = res.reduce((r: any, p: any) => {
        const path = p.fileSystemEntry.fullPath;
        const names = path.split('/');
        if (names.length > 0 && names[0] === '') {
          names.shift();
        }
        names.reduce((q: { name: any; children: never[]; }[], name: any) => {
          let temp = q.find(o => o.name === name);
          if (!temp) q.push(temp = { name, children: [] });
          return temp.children;
        }, r);
        return r;
      }, []);

      return {
        ...state,
        queue: res,
        tree,
      };
    case 'UPLOAD':
      return state;
    case 'PROGRESS':

      const filetmp = {
        ...payload,
        signedUrl: '',
      };

      const tmp = [...state.uploaded, filetmp];

      const uploadedtree = tmp.reduce((r: any, p: any) => {
        if (p.fileSystemEntry) {
          const path = p.fileSystemEntry.fullPath;
          const names = path.split('/');
          if (names.length > 0 && names[0] === '') {
            names.shift();
          }
          names.reduce((q: { name: any; children: never[]; }[], name: any) => {
            let temp = q.find(o => o.name === name);
            if (!temp) q.push(temp = { name, children: [] });
            return temp.children;
          }, r);
        }
        return r;
      }, []);

      return {
        ...state,
        uploaded: tmp as DicomFileInterface[],
        uploadedtree,
      };


    case 'CLEAR':
      return { ...state, tree: {}, uploadedtree: {}, queue: [], uploaded: [], deadletterqueue: [] };
    default:
      return state;
  }
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  width: '100%',
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(32, 202, 249, 0.2)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#20caf9' : '#20caf9',
  },
}));

const saveFileS3 = async (url: string, localfile: DicomFileInterface, progressCallback: CallbackInterface) => {
  const { file } = localfile;
  const getType = file as File;
  const fileType = (!getType.type || getType.type === '') ? 'application/dcm' : getType.type;
  if (file) {
    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: { 'Content-Type': fileType },
    });
    progressCallback(localfile);
  }
};

const save = (data: { signedUrl: string }[], convertedFiles: any[], progressCallback: CallbackInterface) => {
  try {
    const arrayofpromises = data.map((element, index) => saveFileS3(element.signedUrl, convertedFiles[index], progressCallback));
    const results = Promise.allSettled(arrayofpromises);
    return results;
  } catch (e) {
    console.error('e', e);
  }
  return null;
};

const uploadFiles = async (destinationPath: string, filesArray: any[], signedUrlCallback: { (arg: any): any }, progressCallback: CallbackInterface) => {
  try {
    const N = CONCURRENT_UPLOAD;
    for (let i = 0; i < filesArray.length; i += N) {
      const payload: any[] = [];
      const convertedFiles: any[] = [];


      for (let j = 0; j < N; j++) {
        if (filesArray[i + j]) {
          const fileobject = filesArray[i + j];
          const filesystementry = fileobject.fileSystemEntry;
          const convertedFile = await getFile((filesystementry as FileSystemFileEntry));
          const getType = convertedFile as File;
          const fileType = (!getType.type || getType.type === '') ? 'application/dcm' : getType.type;
          payload.push({
            s3_path: destinationPath + filesystementry.fullPath,
            ContentType: fileType,
          });
          convertedFiles.push({ ...fileobject, file: convertedFile });
        }
      }
      let signedurldata = null;
      let success = 0;
      while (success === 0) {
        try {
          signedurldata = await signedUrlCallback({ files: payload }).unwrap(); // getPUTSignedUrl
          success = 1;
        } catch (error) {
          success = 0;
          await setTimeout(() => {
          }, 5000);
        }
      }

      const res = await save(signedurldata, convertedFiles, progressCallback);

      // const retry = await Promise.all(res.filter((it) => it.status === 0));
      // MUST CHECK IF FAIlED PROMISES

    }

  } catch (e) {
    console.error(e);
  }
  // const onUpload = async () => {
};

const initstate = { queue: [], uploaded: [], deadletterqueue: [], tree: {}, uploadedtree: {}, loading: false, loaded: false };


const DropAllFiles = (props: DropAllFilesProps): JSX.Element => {
  const { path } = props;
  // Create 'active' state for dropzone:
  const [isDropActive, setIsDropActive] = React.useState(false);
  const [progress, setProgress] = React.useState<{ total: number, current: number }>({ total: 0, current: 0 });
  // Create state for dropped files:
  const [state, dispatch] = React.useReducer(DicomFilesReducer, initstate);
  const [isUploading, setUploading] = React.useState<boolean>(false);
  const [isDoneUploading, setDoneUploading] = React.useState<boolean>(true);
  const [isFlat, setFlat] = React.useState<boolean>(false);
  const [getPUTSignedUrl] = useCreateSignedUrlMutation();

  React.useEffect(() => {
    setProgress(prev => ({ ...prev, total: state.queue.length }));
  }, [state.queue]);

  React.useEffect(() => {
    dispatch({
      type: 'CLEAR',
      payload: null,
    });
  }, [path]);

  const updateProgress = (arg: any) => {
    setProgress(prev => ({ ...prev, current: prev.current + 1 }));
    dispatch({ type: 'PROGRESS', payload: arg });
  };

  // Create handler for dropzone's onDragStateChange:
  const onDragStateChange = React.useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive);
  }, []);


  const onUpload = async () => {
    setDoneUploading(false);
    // making copy of array before sending
    // remove uploaded files
    setUploading(true);
    const arraytoupload = [...state.queue].filter((it) => state.uploaded.findIndex((up) => up.fileSystemEntry.fullPath === it.fileSystemEntry.fullPath) < 0);
    await uploadFiles(path, arraytoupload, getPUTSignedUrl, updateProgress);
    // setUploading(false);
    setDoneUploading(true);
    // onCancelUpload();
  };

  const normalise = (value: number) => ((value - 0) * 100) / (progress.total - 0);

  const onFilesDrop = React.useCallback(async (droppedfiles: FileSystemEntry[]) => {
    dispatch({ type: 'UPDATE', payload: droppedfiles });
  }, []);

  const onClearFiles = () => {
    // setFiles([]);
    setUploading(false);
    setDoneUploading(true);
    dispatch({
      type: 'CLEAR',
      payload: null,
    });
    setProgress(prev => ({ ...prev, total: 0, current: 0 }));
  };



  return (
    <div className="card">
      {state.queue.length > 0 &&
        <div>
          <div className='dicom-uploader-barWrapper d-flex-row'>
            <BorderLinearProgress variant="determinate" value={normalise(progress.current)} />
            <Label size='subtext' className='dicom-uploader-progressLabel'>{`${progress.current} / ${progress.total} (${Math.round(normalise(progress.current))}%)`}</Label>
          </div>
        </div>
      }
      <Spacer value={5} />
      <div className='dicom-uploader-splitwrapper'>
        <DropZone
          active
          className={classNames('dicom-uploader-dropzone', {
            'dicom-uploader-dropActive': isDropActive,
            'dicom-uploader-uploadingActive': isUploading,
          })}
          onDragStateChange={onDragStateChange}
          onFilesDrop={onFilesDrop}
        >
          {state.queue.length === 0 ?
            <div className='dicom-uploader-tagline-wrapper'>
              <AiOutlineCloudUpload size={40} />
              <Text className='dicom-uploader-tagline'>Drag and drop your files here</Text>
            </div>
            :
            <div className='dicom-uploader-filelist'>
              <Text className='dicom-uploader-tagline'>Dropped Files</Text>
              {isFlat || state.tree.length === 0 ?
                <FilesList files={state.queue.map((it: any) => it.fileSystemEntry)} /> :
                <FileTree tree={state.tree} />
              }
            </div>
          }
        </DropZone>
        {isUploading &&
          <UploadZone
            active
            className={classNames('dicom-uploader-dropzone', {
              'dicom-uploader-dropActive': isDropActive,
              'dicom-uploader-dropzoneLoaded': isUploading,
            })}
          >

            {state.uploaded.length === 0 ?
              <Text className='dicom-uploader-tagline'>Files will show when successfully secured and uploaded</Text>
              :
              <div className='dicom-uploader-filelist'>
                <Text className='dicom-uploader-tagline'>Uploaded Files</Text>
                {isFlat || state.tree.length === 0 ?
                  <FilesList files={state.uploaded.map((it: any) => it.file)} /> :
                  <FileTree tree={state.uploadedtree} />
                }
              </div>
            }
          </UploadZone>
        }
      </div>
      <Spacer value={20} />
      {!isDoneUploading ?
        <div className='btn-row'>
          <Label size='h4' weight={600} color='danger' center>
            Please do not navigate away from this page or resize your window while your upload is in progress. Doing so may interrupt or cancel your upload. Please wait until your upload is complete before performing any other actions. Thank you for your cooperation.
          </Label>
        </div>
        :
        <div className='btn-row'>
          <Label size='h6' weight={600} color='danger' center>
            **Please note that previously uploaded files may take some time to process and appear in the File list
          </Label>
        </div>
      }
      <Spacer value={20} />
      <div className='btn-row'>
        <Button loading={!isDoneUploading} disabled={state.queue.length <= state.uploaded.length} onClick={onUpload}> upload {state.queue.length - state.uploaded.length} files</Button>
        <Button onClick={onClearFiles}>clear files</Button>
      </div>
    </div>
  );
};

DropAllFiles.defaultProps = {
};

DropAllFiles.displayName = 'DropAllFiles';
export default DropAllFiles;
