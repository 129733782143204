import React from 'react';
import classnames from 'classnames';
import './tile.scss';

type Props = {
  children: JSX.Element,
  shadow?: 'none' | 'default' | 'light' | 'strong',
  kind?: 'default' | 'primary' | 'secondary' | 'tertiary' | 'background' | 'success' | 'danger',
  className?: string,
  onClick? : () => void,
  onHover? : () => void
};

const Tile = ({ shadow, kind, className, onClick, onHover, children }: Props) => {
  const classes = classnames('tile',
    `tile--shadow--${shadow}`,
    `tile--${kind}`,
    className,
  );
  
  // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
  return <div onClick={onClick} onMouseEnter={onHover} className={classes} aria-hidden="true" >{children}</div>;
};
Tile.defaultProps = {
  kind: 'default',
  shadow: 'default',
  className: 'tile',
  onClick: () => null,
  onHover: () => null,
};
export default Tile;
