/* eslint-disable no-self-compare */
/* eslint-disable import/extensions */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation, Trans } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import { FcSettings, FcAreaChart } from 'react-icons/fc';
import { AiOutlineDashboard } from 'react-icons/ai';
import { GrFormAdd } from 'react-icons/gr';
import { BiRefresh } from 'react-icons/bi';
import { useMediaQuery } from 'react-responsive';
import { BreadcrumbsContext, Breadcrumb } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { useGetStudyInfoQuery, useCreateSiteMutation, studyApi } from '../../../api/builders/study.api';
import { usePrefetch } from '../StudyTracker/api';
import { Label, Text, Alert, Spacer, Button, Modal, TextInput, SiteListItem, Dropdown } from '../../../components';
import { IconButton } from '../../../components/atoms';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import CustomProgress from '../../../components/atoms/CustomProgress/CustomProgress';

enum UserRole {
  Admin = 'admin',
  Corelab = 'CORELAB',
  StudyCoordinator = 'STUDY COORDINATOR',
  SiteAdmin = 'SITE ADMIN',
  SiteCoordinator = 'SITE COORDINATOR',
  SiteMember = 'SITE MEMBER',
  Sponsor = 'SPONSOR',
  StudyOwner = 'STUDY SPONSOR OWNER',
  User = 'USER',
}

type StudyItem = {
  label?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  summary?: string | undefined;
  sponsor?: string | undefined;
  studyId?: string | undefined;
  acl?: string | undefined;
};

interface LocationState {
  // state: {
  study: StudyItem;
  // };
}

interface StudyHeaderProps {
  acl: UserRole;
  onDashboard: () => void;
  onTracker: () => void;
  onOpenSettings: () => void;
  onMouseEnter: () => void;
}

type SiteItem = {
  id: string | undefined;
  name: string | undefined;
  region: string | undefined;
  siteId: string | undefined;
  siteLabel: string | undefined;
  status: string | undefined;
  prefix: string | undefined;
};

const initialForm = {
  siteLabel: '',
  name: '',
  status: '',
  siteId: '',
  prefix: '',
  region: '',
};

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const StudyHeader: React.FC<StudyHeaderProps> = ({
  acl,
  onDashboard,
  onTracker,
  onOpenSettings,
  onMouseEnter,
}) => {
  switch (acl) {
    case UserRole.Admin:
    case UserRole.StudyCoordinator:
    case UserRole.Sponsor:
    default:
      return (<>
        <IconButton onClick={onDashboard} customIcon={<AiOutlineDashboard size={20} />} />
        <IconButton onClick={onTracker} customIcon={<FcAreaChart size={20} />} />
        <IconButton onClick={onOpenSettings} customIcon={<FcSettings size={20} />} />
      </>);
  }

};



const RenderStudy: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { study } = location?.state as LocationState;
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [newSiteVals, setNewSiteVals] = React.useState(initialForm);
  const [AddSite, { isLoading: isAdding, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useCreateSiteMutation();
  const { t, i18n } = useTranslation();

  const { refetch: refetchSites } =
    studyApi.endpoints.getStudyInfo.useQuerySubscription({ studyId: study?.id ? study.id : skipToken });

  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetStudyInfoQuery({ studyId: study?.id ? study.id : skipToken });
  const [err, setErr] = React.useState({ open: false, message: '' });
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Adding New Site: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding New Site',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  function validateFields(vals: { [key: string]: any }) {

    /* eslint-disable no-restricted-syntax */
    for (const field in vals) {
      if (!vals[field]) {
        setErr({
          open: true,
          message: `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
        });
        return true;
      }
    }
    return false;
  }

  const onTracker = () => {
    navigate('/private/studies/study/tracker', { state: { study } });
  };

  const onDashboard = () => {
    if (data) {
      navigate('/private/studies/study/dash', { state: { study: data[0] } });
    }
  };

  React.useEffect(() => {
    const newBreadcrumb = {
      label: study?.studyId ?? 'Study',
      path: location.pathname,
      state: { study },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);

  const onCloseModal = () => {
    setIsOpen(false);
    setNewSiteVals(initialForm);
    setErr({ open: false, message: '' });
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const handleChangenewSiteVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSiteVals({ ...newSiteVals, [event.target.id]: event.target.value });
  };

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSiteVals({ ...newSiteVals, [event.target.name]: event.target.value });
  };

  const addSite = async (_values: any) => {
    try {
      const validate = validateFields(_values);
      if (!validate) {
        const newsite = {
          studyId: study.id,
          name: _values.name,
          siteLabel: _values.siteLabel,
          status: _values.status || 'building',
          siteId: _values.siteId,
          region: _values.region,
          prefix: _values.prefix,
        };
        const a = await AddSite(newsite);
        onCloseModal();
      }
    } catch (addSiteError) {
      console.error(' error', addSiteError);
    }
  };
  const handleMediaQueryChange = (matches: any) => {
    if (matches) {
      // window.alert("Trigger a window's alert at viewport of 1244px");
    } else {
      // do something if false
    }
  };
  const isSmallDesktopWindow = useMediaQuery(
    { maxWidth: 786 }, undefined, handleMediaQueryChange,
  );

  const onOpenSettings = () => {
    navigate('/private/studies/study/settings', { state: { study } });
  };

  const prefetchStats = usePrefetch('getStudyStOOtOOs');

  const onHover = () => {
    const PL = {
      studyId: study?.id,
      pageSize: 10,
      orderBy: 'recent',
    };
    prefetchStats(study?.id ? PL : skipToken);
  };


  if (!location?.state) {
    return (
      <div className='d-flex-row-center'>
        <Label size="h4" weight={600} color="primary">Error Loading Audit Details</Label>
      </div>
    );
  }
  return (
    <div className="study">
      <div className='study__header'>
        <div className='w-100 d-flex-row'>
          <Label size="h2" weight={300} >{study?.title}</Label>
          <div className='d-flex-row' onMouseEnter={onHover}>
            <StudyHeader
              acl={study?.acl as UserRole || UserRole.User}
              onDashboard={onDashboard}
              onTracker={onTracker}
              onOpenSettings={onOpenSettings}
              onMouseEnter={onHover}
            />
          </div>

        </div>
        <Spacer value={10} />
        <Grid container spacing={6}>
          <Grid xs={6}>
            <Text size="sm">{study?.description}</Text>
            <Spacer value={20} />
          </Grid>
          <Grid xs={6}>
            <div className='study__row-item'>
              <Label size="h6" weight={600} >SPONSOR:</Label>
              <Text size="sm">{study?.sponsor}</Text>
            </div>
            <div className='study__row-item'>
              <Label size="h6" weight={600} >STUDY ID:</Label>
              <Text size="sm">{study?.studyId}</Text>
            </div>
            <div className='study__row-item'>
              <Label size="h6" weight={600} >SUMMARY:</Label>
              <Text size="sm">{study?.summary}</Text>
            </div>
          </Grid>
        </Grid>
      </div>
      <Spacer value={10} />
      <div className='study__body'>
        <div className='study__heading'>
          <div className="d-flex-row">
            <Label size="h4" weight={300} >{t('Sites')}</Label>
            <button type='button' className='icon-btn' aria-label='refetch' onClick={refetchSites}>
              <BiRefresh />
            </button>
          </div>
          {study.acl === 'admin' &&
            <div>
              <Button kind='ghost' icon={GrFormAdd} onClick={onOpenModal} />
            </div>
          }
        </div>
        <Spacer value={5} />
        <Grid container spacing={2}>
          <Grid xs={4} style={{ paddingBottom: 0 }}>
            <Label center size="subtext">{t('Label')}</Label>
          </Grid>
          <Grid xs={4} style={{ paddingBottom: 0 }}>
            <Label center size="subtext">{t('Region')}</Label>
          </Grid>
          <Grid xs={3} style={{ paddingBottom: 0 }}>
            <Label center size="subtext">{t('Status')}</Label>
          </Grid>
          <Grid xs={12} style={{ paddingBottom: 0 }}>
            {(isLoadingData || isFetchingData || isAdding) ?
              <div className='super-centered'>
                {/* <Spinner /> */}
                <CustomProgress className="custom" />
              </div>
              :
              <div>
                {Array.isArray(data) && data.length > 0 && data[0].sites.map((item: SiteItem) => (
                  <SiteListItem site={item} study={study} key={item.id} />
                ))}
              </div>
            }
          </Grid>
        </Grid>
      </div>
      <Spacer value={15} />

      <Modal
        open={modalIsOpen}
        onClose={onCloseModal}
        title="Add New Site"
      >
        <div>
          <TextInput
            type="input"
            label="Site ID"
            id="siteId"
            onChange={handleChangenewSiteVals}
            value={newSiteVals.siteId}
          />
          <Spacer value={10} />
          <TextInput
            type="input"
            label="Label"
            id="siteLabel"
            onChange={handleChangenewSiteVals}
            value={newSiteVals.siteLabel}
          />
          <Spacer value={10} />
          <TextInput
            type="input"
            label="Site Prefix"
            id="prefix"
            onChange={handleChangenewSiteVals}
            value={newSiteVals.prefix}
          />
          <Spacer value={10} />
          <TextInput
            type="input"
            label="Description"
            id="name"
            onChange={handleChangenewSiteVals}
            value={newSiteVals.name}
          />
          <Spacer value={10} />
          <Dropdown
            label="Region"
            id="region"
            name="region"
            onChange={handleChangeStatus}
            value={newSiteVals.region}
          >
            {/* training, pending start , recruiting , closed */}
            <MenuItem value="Global">Global</MenuItem>
            <MenuItem value="US">US</MenuItem>
            <MenuItem value="EU">EU</MenuItem>
            <MenuItem value="CA">CA</MenuItem>
          </Dropdown>
          <Spacer value={10} />

          <Dropdown
            label="Status"
            id="status"
            name="status"
            onChange={handleChangeStatus}
            value={newSiteVals.status}
          >
            {/* training, pending start , recruiting , closed */}
            <MenuItem value="training">Training</MenuItem>
            <MenuItem value="pending start">Pending Start</MenuItem>
            <MenuItem value="recruiting">Recruiting</MenuItem>
            <MenuItem value="closed">Closed</MenuItem>
          </Dropdown>
          <Spacer value={20} />
          <div className='btn-row'>
            {err.open && <Label size='h6' color='danger'>{err.message}</Label>}
          </div>
          <Spacer value={20} />
          <div className='d-flex-row-center'>
            <Button outline kind="danger" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button onClick={() => addSite(newSiteVals)} >
              Add
            </Button>
          </div>
        </div>
      </Modal>

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div >
  );
};

const Study = () => {
  const location = useLocation();
  return (
    <div className="study__wrapper">
      {
        location?.state
          ?
          <RenderStudy />
          :
          <div className='d-flex-row-center'>
            <Label size="h4" weight={600} color="primary">Error Loading Study Details</Label>
          </div>
      }
    </div>
  );
};

export default withAuthenticator(Study);
