/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
import classNames from 'classnames';
import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import axios from 'axios';
import dicomParser from 'dicom-parser';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Button from '../../atoms/Button/Button';
import DropZone from '../DropZone/DropZone';
import FilesList from '../FilesList/FilesList';
import { Text, Spacer, Label } from '../../atoms';

// import classnames from 'classnames';
import './dicom-uploader.scss';
import { useCreateSignedUrlMutation } from '../../../api/api';
import FileTree from '../FileTree/FileTree';
import UploadZone from '../UploadZone/UploadZone';
import { useGetProtocolFilesQuery } from '../../../api/builders/participantFiles.api';
import { useCreateLogMutation } from '../../../api/builders/logs.api';



type Props = {
  key: string,
  active?: boolean | true,
  path: string,
  acl: string,
  itemId: string | undefined,
  itemStatus: string | undefined,
  handleChangeStatus: (item: string, status: string) => void,
  // setIsUploading?: (p: boolean) => void,
};

const CONCURRENT_UPLOAD = 100;

interface CallbackInterface {
  (arg: any): void;
}

const getFile = (fileEntry: FileSystemFileEntry) => {
  try {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
  } catch (err) {
    return undefined;
  }
};

interface DicomFileInterface {
  file: File;
  fileSystemEntry: FileSystemEntry;
  dcmData: any;
  signedUrl: string;
}

// interface DicomTreeItem {
//   dicomfileinterface: DicomFileInterface[];
//   count: number;
// }

interface DicomFileReducerStateInterface {
  queue: DicomFileInterface[];
  uploaded: DicomFileInterface[];
  deadletterqueue: DicomFileInterface[];
  tree: object | null;
  uploadedtree: object | null;
  loading: boolean;
  loaded: boolean;
}


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  width: '100%',
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(32, 202, 249, 0.2)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#20caf9' : '#20caf9',
  },
}));

const DicomFilesReducer = (state: DicomFileReducerStateInterface, action: { type: string, payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE':
      let res = payload.map((it: any) => {
        const el = { file: getFile(it as FileSystemFileEntry), fileSystemEntry: it, dcmData: null, signedUrl: null };
        return el;
      });

      res = [...state.queue, ...res];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // res = [...new Map(res.map((v: { fileSystemEntry: any; }) => [v.fileSystemEntry, v])).queues()];
      res = [...res.reduce((map: { set: (arg0: any, arg1: any) => any; }, obj: { fileSystemEntry: any; }) => map.set(obj.fileSystemEntry.fullPath, obj), new Map()).values()];

      const tree = res.reduce((r: any, p: any) => {
        const path = p.fileSystemEntry.fullPath;
        const names = path.split('/');
        if (names.length > 0 && names[0] === '') {
          names.shift();
        }
        names.reduce((q: { name: any; children: never[]; }[], name: any) => {
          let temp = q.find(o => o.name === name);
          if (!temp) q.push(temp = { name, children: [] });
          return temp.children;
        }, r);
        return r;
      }, []);

      return {
        ...state,
        queue: res,
        tree,
      };
    case 'UPLOAD':
      return state;
    case 'PROGRESS':

      const filetmp = {
        ...payload,
        signedUrl: '',
      };

      const tmp = [...state.uploaded, filetmp];

      const uploadedtree = tmp.reduce((r: any, p: any) => {
        if (p.fileSystemEntry) {
          const path = p.fileSystemEntry.fullPath;
          const names = path.split('/');
          if (names.length > 0 && names[0] === '') {
            names.shift();
          }
          names.reduce((q: { name: any; children: never[]; }[], name: any) => {
            let temp = q.find(o => o.name === name);
            if (!temp) q.push(temp = { name, children: [] });
            return temp.children;
          }, r);
        }
        return r;
      }, []);

      return {
        ...state,
        uploaded: tmp as DicomFileInterface[],
        uploadedtree,
      };


    case 'CLEAR':
      return { ...state, tree: {}, uploadedtree: {}, queue: [], uploaded: [], deadletterqueue: [] };
    default:
      return state;
  }
};

const parseDicom = async (dcmfile: { file: any; }, callback: any) => {
  const dfile = await dcmfile?.file;
  // const result = await parseDicom(dfile);
  // const myresults = (res: any) => res;
  if (dfile) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(dfile);
    reader.onload = (ev) => {
      try {
        const arrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;
        if (arrayBuffer) {
          const byteArray = new Uint8Array(arrayBuffer);
          const dataSet = dicomParser.parseDicom(byteArray);
          // let patientName = formatName(dataSet.string('x00100010'));
          const patientId = dataSet.string('x00100020');
          const patientDob = dataSet.string('x00100030');
          const patientSex = dataSet.string('x00100040');
          const seriesDesc = dataSet.string('x0008103e');
          callback({ ...dcmfile, dcmData: { patientId, patientDob, patientSex, seriesDesc } });
        }
      } catch (error) {
        // alert('Please Select a valid dcm file');
        console.error(`Error : ${error}`);
        // location.reload();
      }
    };
  }
};

const myCallback = (param: any) => {
  const a = 1;
  return param;
};


const saveFileS3 = async (url: string, localfile: DicomFileInterface, progressCallback: CallbackInterface) => {
  const { file } = localfile;
  if (file) {
    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: { 'Content-Type': 'application/dcm' },
    });
    progressCallback(localfile);
  }
};

const save = (data: { signedUrl: string }[], convertedFiles: any[], progressCallback: CallbackInterface) => {
  try {
    const arrayofpromises = data.map((element, index) => saveFileS3(element.signedUrl, convertedFiles[index], progressCallback));
    const results = Promise.allSettled(arrayofpromises);
    return results;
  } catch (e) {
    console.error('e', e);
  }
  return null;
};

const uploadFiles = async (destinationPath: string, filesArray: any[], signedUrlCallback: { (arg: any): any }, progressCallback: CallbackInterface) => {
  try {
    const N = CONCURRENT_UPLOAD;
    for (let i = 0; i < filesArray.length; i += N) {
      const payload: any[] = [];
      const convertedFiles: any[] = [];


      for (let j = 0; j < N; j++) {
        if (filesArray[i + j]) {
          const fileobject = filesArray[i + j];
          const filesystementry = fileobject.fileSystemEntry;
          const convertedFile = await getFile((filesystementry as FileSystemFileEntry));
          payload.push({
            s3_path: destinationPath + filesystementry.fullPath,
            ContentType: 'application/dcm',
          });
          convertedFiles.push({ ...fileobject, file: convertedFile });
        }
      }
      let signedurldata = null;
      let success = 0;
      while (success === 0) {
        try {
          signedurldata = await signedUrlCallback({ files: payload }).unwrap(); // getPUTSignedUrl
          success = 1;
        } catch (error) {
          success = 0;
          await setTimeout(() => {
          }, 5000);
        }
      }

      const res = await save(signedurldata, convertedFiles, progressCallback);

      // const retry = await Promise.all(res.filter((it) => it.status === 0));
      // MUST CHECK IF FAIlED PROMISES

    }

  } catch (e) {
    console.error(e);
  }
  // const onUpload = async () => {
};

const initstate = { queue: [], uploaded: [], deadletterqueue: [], tree: {}, uploadedtree: {}, loading: false, loaded: false };

const DicomUploader = (props: Props) => {
  const { key, active, path, itemStatus, itemId, handleChangeStatus, acl } = props;
  // Create 'active' state for dropzone:
  const [isDropActive, setIsDropActive] = React.useState(false);
  const [progress, setProgress] = React.useState<{ total: number, current: number }>({ total: 0, current: 0 });
  // Create state for dropped files:
  const [state, dispatch] = React.useReducer(DicomFilesReducer, initstate);
  const [isUploading, setUploading] = React.useState<boolean>(false);
  const [isDoneUploading, setDoneUploading] = React.useState<boolean>(true);
  const [isFlat, setFlat] = React.useState<boolean>(false);
  const [getPUTSignedUrl] = useCreateSignedUrlMutation();
  const { data } = useGetProtocolFilesQuery({ protocolItemId: itemId });
  const [addLog] = useCreateLogMutation();



  React.useEffect(() => {
    setProgress(prev => ({ ...prev, total: state.queue.length }));
  }, [state.queue]);

  React.useEffect(() => {
    dispatch({
      type: 'CLEAR',
      payload: null,
    });
  }, [itemId]);

  const updateProgress = (arg: any) => {
    setProgress(prev => ({ ...prev, current: prev.current + 1 }));
    dispatch({ type: 'PROGRESS', payload: arg });
  };

  // Create handler for dropzone's onDragStateChange:
  const onDragStateChange = React.useCallback((dragActive: boolean) => {
    setIsDropActive(dragActive);
  }, []);

  const parseFiles = async (droppedFiles: FileSystemEntry[]): Promise<PromiseSettledResult<any>[]> => {
    const parsedFilesPromises = droppedFiles.map((file) => getFile(file as FileSystemFileEntry));
    const parsedFiles = await Promise.allSettled(parsedFilesPromises);
    return parsedFiles;
  };
  // Create handler for dropzone's onFilesDrop:
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onFilesDrop = React.useCallback(async (droppedfiles: FileSystemEntry[]) => {
    dispatch({ type: 'UPDATE', payload: droppedfiles });
  }, []);

  const onCancelUpload = () => {
    if (isUploading) {
      return;
    }
    dispatch({
      type: 'CLEAR',
      payload: null,
    });
  };

  const logUpload = () => {
    try {
      const logPl = {
        itemId,
        itemLabel: itemStatus,
        actionType: `Started upload ${state.queue.length} files`,
      };
      addLog(logPl);
    } catch (e) {
      console.error('CREATE LOG ER', e);
    }
  };

  const onUpload = async () => {
    logUpload();
    setDoneUploading(false);
    // making copy of array before sending
    // remove uploaded files
    setUploading(true);
    const arraytoupload = [...state.queue].filter((it) => state.uploaded.findIndex((up) => up.fileSystemEntry.fullPath === it.fileSystemEntry.fullPath) < 0);
    await uploadFiles(path, arraytoupload, getPUTSignedUrl, updateProgress);
    // setUploading(false);
    setDoneUploading(true);
    // onCancelUpload();
  };

  const onClearFiles = () => {
    // setFiles([]);
    setUploading(false);
    setDoneUploading(true);
    dispatch({
      type: 'CLEAR',
      payload: null,
    });
    setProgress(prev => ({ ...prev, total: 0, current: 0 }));
  };

  const normalise = (value: number) => ((value - 0) * 100) / (progress.total - 0);

  const confirm = () => {
    setUploading(prev => !prev);
    if (itemId) {
      handleChangeStatus(itemId, 'pending review');
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderActions = ({ access }: any): JSX.Element => {
    switch (access) {
      case 'admin':
        return (<>
          <div className='btn-row'>
            <Button loading={!isDoneUploading} disabled={state.queue.length <= state.uploaded.length} onClick={onUpload}>de-identify and upload {state.queue.length - state.uploaded.length} files</Button>
            <Button onClick={onClearFiles}>clear files</Button>
          </div>
          {state.uploaded.length >= 0 && <div className='btn-row'>
            <Button onClick={confirm}>confirm and close</Button>
          </div>}
        </>);
      case 'SITE COORDINATOR':
        return (<>
          <div className='btn-row'>
            <Button loading={!isDoneUploading} disabled={state.queue.length <= state.uploaded.length} onClick={onUpload}>de-identify and upload {state.queue.length - state.uploaded.length} files</Button>
            <Button onClick={onClearFiles}>clear files</Button>
          </div>
          {state.uploaded.length >= 0 && <div className='btn-row'>
            <Button onClick={confirm}>confirm and close</Button>
          </div>}
        </>);
      // case 'CORELAB':
      //   return (<>
      //     <div className='btn-row'>
      //       <Button loading={!isDoneUploading} disabled={state.queue.length <= state.uploaded.length} onClick={onUpload}>de-identify and upload {state.queue.length - state.uploaded.length} files</Button>
      //       <Button onClick={onClearFiles}>clear files</Button>
      //     </div>
      //     {state.uploaded.length >= 0 && <div className='btn-row'>
      //       <Button onClick={confirm}>confirm and close</Button>
      //     </div>}
      //   </>);
      default:
        return (
          <div />
        );
    }
  };

  return (
    <div
      key={key}
      className='dicom-uploader-wrapper'
    >
      {state.queue.length > 0 &&
        <div>
          <div className='dicom-uploader-barWrapper d-flex-row'>
            <BorderLinearProgress variant="determinate" value={normalise(progress.current)} />
            <Label size='subtext' className='dicom-uploader-progressLabel'>{`${progress.current} / ${progress.total} (${Math.round(normalise(progress.current))}%)`}</Label>
          </div>
          <Button kind='ghost' onClick={() => setFlat(prev => (!prev))}>{isFlat ? 'Show file tree' : 'Flatten file tree'}</Button>
        </div>
      }
      <div className='dicom-uploader-splitwrapper'>
        <DropZone
          active={active}
          className={classNames('dicom-uploader-dropzone', {
            'dicom-uploader-dropActive': isDropActive,
            'dicom-uploader-uploadingActive': isUploading,
          })}
          onDragStateChange={onDragStateChange}
          onFilesDrop={onFilesDrop}
        >
          {state.queue.length === 0 ?
            <div className='dicom-uploader-tagline-wrapper'>
              <AiOutlineCloudUpload size={40} />
              <Text className='dicom-uploader-tagline'>Drag and drop the folder containing your DICOM files here</Text>
            </div>
            :
            <div className='dicom-uploader-filelist'>
              <Text className='dicom-uploader-tagline'>Dropped Files</Text>
              {isFlat || state.tree.length === 0 ?
                <FilesList files={state.queue.map((it: any) => it.fileSystemEntry)} /> :
                <FileTree tree={state.tree} />
              }
            </div>
          }
        </DropZone>
        {isUploading &&
          <UploadZone
            active={active}
            className={classNames('dicom-uploader-dropzone', {
              'dicom-uploader-dropActive': isDropActive,
              'dicom-uploader-dropzoneLoaded': isUploading,
            })}
          >

            {state.uploaded.length === 0 ?
              <Text className='dicom-uploader-tagline'>Files will show when successfully secured and uploaded</Text>
              :
              <div className='dicom-uploader-filelist'>
                <Text className='dicom-uploader-tagline'>Uploaded Files</Text>
                {isFlat || state.tree.length === 0 ?
                  <FilesList files={state.uploaded.map((it: any) => it.file)} /> :
                  <FileTree tree={state.uploadedtree} />
                }
              </div>
            }
          </UploadZone>
        }
      </div>
      {!isDoneUploading &&
        <div className='btn-row'>
          <Label size='h4' weight={600} color='danger' center>
            Please do not navigate away from this page or resize your window while your upload is in progress. Doing so may interrupt or cancel your upload. Please wait until your upload is complete before performing any other actions. Thank you for your cooperation.
          </Label>
        </div>
      }
      <Spacer value={10} />
      <RenderActions access={acl} />
    </div>
  );
};

DicomUploader.defaultProps = {
  key: '_key',
};

export default DicomUploader;

//  