
const cohortMenu = [
  {
    title: 'Patients',
    url: '/private/patients',
    private: true,
  },
  {
    title: 'Logs',
    url: '/private/logs',
    private: true,
  },
  {
    title: 'Cohorts',
    url: '/private/cohorts',
    private: true,
  },
];

export default cohortMenu;
