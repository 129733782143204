import React from 'react';
// import classnames from 'classnames';
import './flaggable-input.scss';
import Flag from '../Flag/Flag';

export interface FlagItem {
  user: string,
  userEmail: string,
  message: string, 
  time: string,
  item: string,
}

export interface FlaggableInputProps {
  disabled?: boolean,
  invalid?: boolean,
  label?: string | undefined,
  name?: string,
  value?: string | number,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  id: string,
  placeholder?: string,
  type?: string,
  autocomplete?: string,
  flaggable?: boolean,
  noNegatives?: boolean,
  addFlag?: (payload: FlagItem) => void,
}

const FlaggableInput = (props: FlaggableInputProps) => {
  const { disabled, addFlag, label, name, value, onChange, id, placeholder, type, autocomplete, flaggable, noNegatives } = props;
  const [controlledValue, setControlledValue] = React.useState<number | string>();

  React.useEffect(() => {
    if (noNegatives && value && value < 0) {
      return;
    }
    setControlledValue(value);
  }, [value]);

  return (
    <div className='flaggable-input'>
      {label && <span className='flaggable-input__label'>{label}</span>}
      <div className='flaggable-input__input-wrapper'>
        <input
          id={id}
          aria-label={id}
          onChange={onChange}
          value={controlledValue}
          disabled={disabled}
          autoComplete={autocomplete}
          className='flaggable-input__input'
          // defaultValue={props.defaultValue}
          type={type}
          placeholder={placeholder}
          name={name}
        />
        {/* {flaggable && addFlag && <Flag key='flag' addFlag={addFlag} item={id}/>} */}
      </div>
    </div>
  );
};

FlaggableInput.defaultProps = {
  flaggable: true,
  noNegatives: false,
  label: '',
  disabled: false,
  invalid: false,
  type: 'text',
  name: '',
  value: '',
  onChange: () => { },
  addFlag: () => { },
  placeholder: null,
  autocomplete: 'off',
};

FlaggableInput.displayName = 'FlaggableInput';


export default FlaggableInput;
