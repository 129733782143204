import React from 'react';
// import classnames from 'classnames';
import Grid from '@mui/material/Unstable_Grid2';
import './add-participant.scss';
import { Label, Modal, Button, Spacer, Tile, Spinner } from '../../atoms';
import TextInput from '../TextInput/TextInput';
import { useSearchPatientsQuery } from '../../../api/builders/study.api';

export interface AddParticipantProps {
  addPat: (payload: ParticipantItem, tx: string) => void,
  onOpen: boolean,
  onClose: () => void,
  participants: ParticipantItem[],
  prefix: string,
}

type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  type: string | undefined;
  id: string;
  consentStatus: string;
  group?: string
};

const initialForm = {
  screeningId: '',
  participantId: '-',
  type: '',
  id: '',
  consentStatus: '',
  search: '',
};

const AddParticipant = (props: AddParticipantProps) => {
  const { addPat, onOpen, onClose, participants, prefix } = props;
  const [newParticipantVals, setNewParticipantVals] = React.useState(initialForm);
  const [modalView, setModalView] = React.useState('choice');
  const [searchScreen, setSearchScreen] = React.useState('search');
  const [selectedPatient, setSelectedPatient] = React.useState<any>(null);
  const [error, setError] = React.useState({ open: false, message: '' });
  const [payload, setPayload] = React.useState({ pageSize: 10, type: 'mrn', query: '', orderBy: 'recent', pageCurrent: 1 });
  const { data: searchResults, isLoading: isLoadingData, isFetching: isFetchingData } = useSearchPatientsQuery(payload);

  const closeError = () => {
    setError({ open: false, message: '' });
  };

  const handleSelectPat = (pat: any) => {
    if (pat.id === selectedPatient?.id) {
      setSelectedPatient(null);
    } else {
      setSelectedPatient(pat);
    }
  };

  const handleChangenewParticipantVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewParticipantVals({ ...newParticipantVals, [event.target.id]: event.target.value });
  };

  const onCloseModal = () => {
    onClose();
    setNewParticipantVals(initialForm);
    setModalView('choice');
    closeError();
    setSelectedPatient(null);
    setSearchScreen('search');
    setPayload({ pageSize: 10, type: 'mrn', query: '', orderBy: 'recent', pageCurrent: 1 });
  };

  const addParticipant = (_values: ParticipantItem) => {
    try {
      if (modalView === 'PHANTOM') {
        const found = participants.filter((p) => p.participantId === newParticipantVals.participantId);
        if (found.length > 0) {
          setError({ open: true, message: 'Phantom ID must be unique' });
        } else if (found.length === 0) {
          const a = addPat(_values, 'FUS');
          onCloseModal();
        }
      }
      if (modalView === 'PARTICIPANT') {
        const found = participants.filter((p) => p.participantId === newParticipantVals.participantId);
        if (found.length > 0) {
          setError({ open: true, message: 'Participant ID must be unique' });
        } else if (found.length === 0) {
          const a = addPat(_values, 'FUS');
          onCloseModal();
        }
      }
      if (modalView === 'CANDIDATE') {
        const found = participants.filter((p) => p.screeningId === newParticipantVals.screeningId);
        if (found.length > 0) {
          setError({ open: true, message: 'Screening ID must be unique' });
        } else if (found.length === 0) {
          const a = addPat(_values, 'FUS');
          onCloseModal();
        }
      }
      if (modalView === 'PATIENT') {
        const found = participants.filter((p) => p.screeningId === newParticipantVals.screeningId);
        if (found.length > 0) {
          setError({ open: true, message: 'Screening ID must be unique' });
        } else if (found.length === 0) {
          const pl = {
            screeningId: '',
            participantId: _values.participantId,
            type: 'participant',
            subjectId: selectedPatient?.id,
            id: '',
            consentStatus: '',
            search: '',
          };
          const a = addPat(pl, 'FUS');
          onCloseModal();
        }
      }
    } catch (err) {
      console.error(' error ', error);
    }
  };


  const phantomMode = () => {
    setModalView('PHANTOM');
    const tmp = participants?.filter((item) => item.type && item.type === 'phantom');
    const label = `PHANTOM-0${tmp.length + 1}`;
    setNewParticipantVals({
      ...newParticipantVals,
      participantId: label,
      type: 'phantom',
    });
  };

  const candidateMode = () => {
    setModalView('CANDIDATE');
    setNewParticipantVals({ ...newParticipantVals, type: 'candidate', screeningId: `${prefix}-SC-` });
  };

  const participantMode = () => {
    setModalView('PARTICIPANT');
    setNewParticipantVals({ ...newParticipantVals, type: 'participant', participantId: `${prefix}-` });
  };

  const patientMode = () => {
    setModalView('PATIENT');
  };

  const cancelChoice = () => {
    setModalView('choice');
    setNewParticipantVals(initialForm);
    closeError();
    setSelectedPatient(null);
    setSearchScreen('search');
  };

  const searchMRN = () => {
    setPayload(prev => ({ ...prev, query: newParticipantVals.search }));
    setSearchScreen('RESULTS');
  };

  return (
    <Modal
      open={onOpen}
      onClose={onCloseModal}
      title="Add New Participant"
      size="sm"
    >
      <div>
        {modalView === 'choice' && (
          <div >
            <Spacer value={15} />
            <div role="button" tabIndex={0} onClick={phantomMode} className="add-participant__tile">
              <Label size="h5" weight={600} color="primary"  > Add Phantom</Label>
            </div>
            <div role="button" tabIndex={-1} onClick={candidateMode} className="add-participant__tile">
              <Label size="h5" weight={600} color="primary"  >Add Screening Candidate</Label>
            </div>
            <div role="button" tabIndex={-2} onClick={participantMode} className="add-participant__tile">
              <Label size="h5" weight={600} color="primary"  >Add New Participant</Label>
            </div>
            <div role="button" tabIndex={-2} onClick={patientMode} className="add-participant__tile">
              <Label size="h5" weight={600} color="primary"  >Add Participant from Patient</Label>
            </div>

          </div>
        )}
        {modalView === 'PHANTOM' && (
          <div className='add-participant__body'>
            <TextInput
              type="input"
              label="Phantom ID"
              id="participantId"
              onChange={handleChangenewParticipantVals}
              value={newParticipantVals.participantId}
            />
            <Spacer value={20} />
            <div className='d-flex-row-center'>
              <Button outline kind="danger" onClick={cancelChoice}>
                Cancel
              </Button>
              <Button onClick={() => addParticipant(newParticipantVals)} >
                Add
              </Button>
            </div>
          </div>
        )}
        {modalView === 'CANDIDATE' && (
          <div className='add-participant__body'>
            <TextInput
              type="input"
              label="Screening Id"
              id="screeningId"
              onChange={handleChangenewParticipantVals}
              value={newParticipantVals.screeningId}
            />
            <Spacer value={20} />
            <div className='d-flex-row-center'>
              <Button outline kind="danger" onClick={cancelChoice}>
                Cancel
              </Button>
              <Button onClick={() => addParticipant(newParticipantVals)} >
                Add
              </Button>
            </div>
          </div>
        )}
        {modalView === 'PATIENT' && (
          <div className='add-participant__body'>
            {searchScreen === 'search' &&
              <div>
                <TextInput
                  type="input"
                  label="Search by MRN"
                  id="search"
                  onChange={handleChangenewParticipantVals}
                  value={newParticipantVals.search}
                />

                <Spacer value={20} />
                <div className='btn-row'>
                  <Button outline kind="danger" onClick={cancelChoice}>
                    Cancel
                  </Button>
                  <Button onClick={searchMRN} >
                    Search
                  </Button>
                </div>
              </div>
            }
            {isFetchingData &&
                  <div>
                    <Spinner />
                  </div>
            }
            {(!isFetchingData && searchScreen === 'RESULTS' ) &&
                  <div>
                    {(searchResults && Array.isArray(searchResults.items) && searchResults.items.length > 0) ?
                      <div>
                        <Label size='h6' uppercase color='primary'>{searchResults.items.length} Result:</Label>
                        <Spacer value={10} />
                        {searchResults.items.map((pat: any) => (
                          <Tile
                            className={selectedPatient?.id === pat.id ? 'add-participant__patient-selected' : 'add-participant__patient'}
                            onClick={() => handleSelectPat(pat)} >
                            <Grid container>
                              <Grid xs={4}>
                                <Label size='subtext'>MRN</Label>
                                <Label size='h6' color='primary'>{pat.mrn}</Label>
                              </Grid>
                              <Grid xs={4}>
                                <Label size='subtext'>First Name</Label>
                                <Label size='h6' color='primary'>{pat.firstname}</Label>
                              </Grid>
                              <Grid xs={4}>
                                <Label size='subtext'>Last Name</Label>
                                <Label size='h6' color='primary'>{pat.lastname}</Label>
                              </Grid>
                            </Grid>
                          </Tile>
                        ))}
                        <Spacer value={20} />
                        <div className='btn-row'>
                          <Button outline kind="danger" onClick={() => setSearchScreen('search')}>
                            Search Again
                          </Button>
                          <Button disabled={selectedPatient === null} onClick={() => setSearchScreen('ADDSELECTED')} >
                            Add Particpant
                          </Button>
                        </div>
                      </div>
                      :
                      <div>
                        <Label size='h5' center weight={200} color='danger'>Nothing found</Label>
                        <Spacer value={20} />
                        <div className='btn-row'>
                          <Button outline kind="danger" onClick={() => setSearchScreen('search')}>
                            Search Again
                          </Button>
                          <Button onClick={participantMode} >
                            Add New Particpant
                          </Button>
                        </div>
                      </div>
                    }
                  </div>
                
            }
            {(!isFetchingData && searchScreen === 'ADDSELECTED') &&
              <div className='add-participant__body'>
                <Tile
                  className='add-participant__patient-selected'
                   >
                  <Grid container>
                    <Grid xs={4}>
                      <Label size='subtext'>MRN</Label>
                      <Label size='h6' color='primary'>{selectedPatient.mrn}</Label>
                    </Grid>
                    <Grid xs={4}>
                      <Label size='subtext'>First Name</Label>
                      <Label size='h6' color='primary'>{selectedPatient.firstname}</Label>
                    </Grid>
                    <Grid xs={4}>
                      <Label size='subtext'>Last Name</Label>
                      <Label size='h6' color='primary'>{selectedPatient.lastname}</Label>
                    </Grid>
                  </Grid>
                </Tile>
                <Spacer value={20} />
                <TextInput
                  type="input"
                  label="Participant ID"
                  id="participantId"
                  onChange={handleChangenewParticipantVals}
                  value={newParticipantVals.participantId}
                />

                <Spacer value={20} />
                <div className='btn-row'>
                  <Button outline kind="danger" onClick={cancelChoice}>
                    Cancel
                  </Button>
                  <Button onClick={() => addParticipant(newParticipantVals)} >
                    Add
                  </Button>
                </div>
              </div>
            }
          </div>
        )}

        {modalView === 'PARTICIPANT' && (
          <div className='add-participant__body'>

            <TextInput
              type="input"
              label="Participant ID"
              id="participantId"
              onChange={handleChangenewParticipantVals}
              value={newParticipantVals.participantId}
            />

            <Spacer value={20} />
            <div className='btn-row'>
              <Button outline kind="danger" onClick={cancelChoice}>
                Cancel
              </Button>
              <Button onClick={() => addParticipant(newParticipantVals)} >
                Add
              </Button>
            </div>
          </div>
        )}
        <Spacer value={15} />
        {error.open && <div className='btn-row'><Label size='h5' color='danger'>{error.message}</Label></div>}
      </div>
    </Modal>
  );
};

AddParticipant.defaultProps = {
  // key: '_key',
};

AddParticipant.displayName = 'AddParticipant';


export default AddParticipant;
