import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import FlatList from 'flatlist-react';
import './protocol-file-display.scss';
import { BsFileEarmarkRichtextFill } from 'react-icons/bs';
import { useGetStudyFilesQuery, useCreateZipMutation, useDownLoadFileMutation } from '../../../pages/private/StudyDrive/api';
import { Label, Tile, Button, Spinner, Alert } from '../../atoms';
import FilesList from '../FilesList/FilesList';
import DownloadableFile from '../DownloadableFile/DownloadableFile';


export interface ProtocolFileDisplayProps {
  protocolItem: ProtocolItem,
}

type ProtocolItem = {
  // id: string,
  // label: string,
  // item: string,
  file: string | undefined,
  id: string | undefined,
  item: string | undefined,
  label: string | undefined,
  messages: string | undefined,
  order: number,
  createdAt: number | undefined,
  updatedAt: number | undefined,
  role: string | undefined,
  status: string | undefined,
  surveyId: string | undefined,
  type: string | undefined,
  userId: string | undefined,
};

const ProtocolFileDisplay = (props: ProtocolFileDisplayProps) => {
  const { protocolItem } = props;
  const { id, label, item } = protocolItem;
  const [payload, setPayload] = React.useState<any>(null);
  const [{ pageSize, current: pageCurrent }, setPagination] = React.useState({ pageSize: 15, current: 1 });
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetStudyFilesQuery(payload !== null ? payload : skipToken);
  const [allData, setAllData] = React.useState<any[] | []>([]);
  const [downloading, setDownloading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [createZip, { isLoading: isLoadingZip }] = useCreateZipMutation();
  const [download] = useDownLoadFileMutation();

  const handleClose = () => {
    setOpen(false);
  };


  const handleDownloadZip = async (newData: any) => {
    const newFilePath = `${newData.bucket}/${newData.archiveFilePath}`;
    const downloadPayload = { files: [{ s3_path: newFilePath }] };
    const yourbrandnewfile = await download(downloadPayload).unwrap();
    const url = window.URL.createObjectURL(new Blob([yourbrandnewfile[0].signedUrl]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'results.zip');
    document.body.appendChild(link);
    link.click();
  };

  const handleCreateZip = async () => {
    setDownloading(true);
    setOpen(true);
    const newArr: any[] = [];
    allData.map((a: any) => newArr.push(a.coldKey));
    const zipPayload = {
      bucket: data.items[0].bucket,
      keys: newArr,
      archiveFilePath: `COLD/s3zip/${id}/result.zip`,
      archiveFolderPath: protocolItem.item,
      archiveFormat: 'zip',
    };
    const getPath = await createZip(zipPayload).unwrap();
    if (getPath && getPath.archiveFilePath) {
      const newFilePath = `${getPath.bucket}/${getPath.archiveFilePath}`;
      const downloadPayload = { files: [{ s3_path: newFilePath }] };
      const yourbrandnewfile = await download(downloadPayload).unwrap();
      const url = window.URL.createObjectURL(new Blob([yourbrandnewfile[0].signedUrl]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'results.zip');
      document.body.appendChild(link);
      link.click();
    }
    setDownloading(false);
  };


  React.useEffect(() => {
    if (id) {
      setAllData([]);
      setPagination({
        pageSize: 15, current: 1,
      });
      setPayload({
        id,
        pageSize: 15,
        pageCurrent: 1,
        orderBy: 'recent',
      });
    }
  }, [protocolItem]);


  const getLabel = (longName: string) => {
    const split = longName.split('/');
    const shortened = split[split.length - 1];
    return shortened;
  };

  React.useEffect(() => {
    if (Array.isArray(data?.items) && data.items.length > 0) {
      const curr = allData.concat(data.items);
      setAllData(curr);
    }
  }, [data]);


  const renderItem = (file: any) => (
    // <div role='button' tabIndex={-1} aria-label="select participant" className="protocol-file-display__item">
    //   <Tile>
    //     <div>
    //       <Label size='h4'> {getLabel(file.originalPath)} </Label>
    //     </div>
    //   </Tile>
    // </div>
    // <div className="file-list-item--wrapper" role='button' tabIndex={-1} onClick={() => handleFileClick(file)}>
    //   <div className="file-list-item--header">
    //     {/* <BsFileEarmarkRichtextFill /> */}
    //     <Text>📑{getLabel(file.originalPath)}</Text>
    //   </div>
    // </div>
    <DownloadableFile item={file} />

  );

  const fetchMore = () => {
    if (data.pageCurrent === data.pageTotal) {
      return;
    }
    setPayload({
      ...payload,
      pageSize: 15,
      pageCurrent: data.pageCurrent + 1,
      orderBy: 'recent',
    });
  };
  return (
    <div className="card">
      {data && data?.items?.length > 0 ?
        <div>
          {/* <Button onClick={handleCreateZip} loading={downloading}>Download all</Button> */}
          <Label size='h5'>*{data.total} Files Uploaded:</Label>

          <FlatList list={allData} renderItem={renderItem} renderWhenEmpty={() => <div className='super-centered' > <Label size='h4'>No Files Uploaded </Label></div>}
            pagination={{
              hasMore: data?.pageCurrent < data?.pageTotal,
              loadMore: fetchMore,
            }}
            paginationLoadingIndicator={<div>Getting more items...</div>}
            wrapperHtmlTag='div'
            className="protocol-file-display__wrapper"
          // style={{ overflow: 'auto', maxHeight: '600px' }}
          />
        </div>
        :
        <div className='btn-row'>
          <Label size='h4'>No Files Uploaded</Label>
        </div>
      }
        {/* <Alert open={open} onClose={handleClose} kind="info" message='The file is getting prepared and will start downloading shortly.' /> */}
    </div>
  );
};

ProtocolFileDisplay.defaultProps = {
};

ProtocolFileDisplay.displayName = 'ProtocolFileDisplay';


export default ProtocolFileDisplay;
