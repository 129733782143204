import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { AiFillWarning } from 'react-icons/ai';
import './blackhole-list-item.scss';
import { Label, Spacer } from '../../atoms';

interface BlackholeListItemProps {
  item: any,
  className?: string,
  onOpen?: (pat: any) => void,
  draggable?: boolean,
}

const BlackholeListItem = (props: BlackholeListItemProps): JSX.Element => {
  const { item, className, onOpen, draggable } = props;
  const { id, mrn, lastname, firstname, since } = item;

  const daysSince = moment().diff(moment(since), 'days');
  const progress = Math.min((daysSince / 15) * 100, 100);

  let itemBackgroundColorClass = '';
  let iconColorClass = '';

  if (daysSince > 30) {
    itemBackgroundColorClass = 'high-background-color';
    iconColorClass = 'high-icon-color';
  } else if (daysSince > 15) {
    itemBackgroundColorClass = 'medium-background-color';
    iconColorClass = 'medium-icon-color';
  } else if (daysSince > 7) {
    itemBackgroundColorClass = 'low-background-color';
    iconColorClass = 'low-icon-color';
  }

  const dragStart = (event: any, pat: any) => {
    try {
      event.dataTransfer.setData('patient', JSON.stringify(pat));
    } catch (e) {
      console.error(e);
    }
  };

  const handleClick = () => {
    if (onOpen) {
      onOpen(item);
    }
  };



  return (
    <div
      draggable={draggable}
      onDragStart={(e) => dragStart(e, item)}
      className={classnames('blackhole-list-item', className, itemBackgroundColorClass)}
      role='button'
      tabIndex={-1}
      onClick={handleClick}
    >
      <div className='blackhole-list-item__indicator'>
        <AiFillWarning size={20} className={classnames(iconColorClass)} />
      </div>
      <div className="blackhole-list-item__body">
        <Label weight={200} size='h5'>{firstname}, {lastname}</Label>
        <Spacer value={5} />
        <Label weight={200} size='h5'>{mrn}</Label>
      </div>
    </div>
  );
};

BlackholeListItem.defaultProps = {
  className: '',
  draggable: true,
  onOpen: () => console.info('click'),
};
export default BlackholeListItem;
