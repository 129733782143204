import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Button, Label, TextInput, Spacer, Tile, Logo } from '../../components';

const OTP = () => {
  const navigate = useNavigate();

  const [hasSendCode, setHasSendCode] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [session, setSession] = useState(null);
  const [error, setError] = useState(false);
  const [state, setState] = React.useState({
    username: '',
    code: '',
    password: '',
    confirmPassword: '',
    status: '',
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };


  const getCode = async (username: string) => {
    try {
      const res = await Auth.signIn(username);
      console.info('Auth::::::==>getOTPCode res', res);
      setSession(res);
      setHasSendCode(true);
    } catch (err) {
      setError(true);
      console.error('ERROR', err);
    } finally {
      setIsSending(false);
    }
  };

  const onSubmit = async (values: { username: any; password: any; confirmPassword: any; code: any; }) => {
    const { username, password, confirmPassword, code } = values;

    try {
      setIsUpdating(true);
      setError(false);
      const res = await Auth.sendCustomChallengeAnswer(session, code);

    } catch (err) {
      setError(true);
      // setError(err.message);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="auth" >
      <Label size="h1" color="primary" weight={700} uppercase>Sign in with One Time Password</Label>
      <Spacer value={10} />

      {hasSendCode ?
        <div>
          <div>
            <Tile className="auth_tile" >
              <div className="auth__body">
                <div className="auth__form">
                  <div className="auth-form__inputs_wrapper">
                    <Label color='primary' size='h4'> Please Enter Code to Sign in</Label>
                    <TextInput
                      id="code"
                      value={state.code}
                      placeholder="Code"
                      label="Code"
                      onChange={onChange}
                    />
                    <Spacer value={10} />
                  </div>
                  <Spacer value={10} />

                  <div className="super-centered auth__btn-wrapper">
                    <Button loading={state.status === 'loading'} size="large" className="auth__btn-styles" onClick={() => onSubmit(state)} >
                      Reset Password
                    </Button>
                  </div>
                </div>
              </div>
            </Tile>

          </div>
        </div> :
        <div>
          <Tile className="auth_tile" >
            <div className="auth__body">
              <div className="auth__form">
                <div className="auth-form__inputs_wrapper">
                  <TextInput
                    id="username"
                    type="Email"
                    autocomplete="Email"
                    value={state.username}
                    placeholder="Email"
                    label="Email"
                    onChange={onChange}
                  />
                </div>
                <Spacer value={10} />

                <div className="super-centered auth__btn-wrapper">
                  <Button loading={state.status === 'loading'} size="large" className="auth__btn-styles" onClick={() => getCode(state.username)} >
                    Get Code
                  </Button>
                </div>
              </div>
            </div>
          </Tile>

        </div>
      }

      <Spacer value={10} />
      {error && <Label color='danger'>Cannot Sign In with OTP at this time.</Label>}
    </div>
  );
};
export default OTP;
