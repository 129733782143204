import React from 'react';
// import classnames from 'classnames';
import './flaggable-dropdown.scss';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { Label } from '../../atoms';

type Props = {
  children?: any;
  value: string;
  name?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  invalid?: boolean;
  disabled?: boolean;
  onChange: (value: any) => void;
};


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 2,
    position: 'relative',
    margin: '0.5em 0',
    backgroundColor: 'ffffff',
    border: '.5px solid rgba(90, 90, 90, 0.2)',
    fontSize: '0.875em',
    padding: '8px 26px 8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Raleway',
    ].join(','),
    '&:focus': {
      borderRadius: 2,
      borderColor: 'rgba(90, 90, 90, 0.2)',
      boxShadow: '0 5px 15px rgba(0,0,0, / 8%)',
    },
  },
}));

const FlaggableDropdown = (props: Props) => {
  const { value, onChange, id, children, name, invalid, disabled, placeholder, label } = props;
  return (
    <FormControl className='dropdown' >
      {label &&
        <Label size='subtext'>
          {label}
        </Label>
      }
      <Select
        labelId='demo-customized-select-label'
        id={id}
        value={value}
        name={name}
        error={invalid}
        disabled={disabled}
        onChange={onChange}
        input={<BootstrapInput />}
        label={placeholder}
      >
        {children}
      </Select>
    </FormControl>
  ); 
};

FlaggableDropdown.defaultProps = {
  children: <MenuItem value=''><em>No Options</em></MenuItem>,
  name: 'Dropdown',
  placeholder: 'Selet',
  invalid: false,
  disabled: false,
  id: 'x',
  label: '',
};

FlaggableDropdown.displayName = 'FlaggableDropdown';


export default FlaggableDropdown;
