/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

export type Credentials = {
  username:string,
  password:string,
};

interface RejectedAction extends Action {
  error: Error
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected');
}
type InitialStateType = { user: any | null, status: string | null, error: any | null, token: string | null, groups: string[] | null, challenge: string | null };

const initialState:InitialStateType = { user: null, status: 'idle', error: null, token: null, groups: null, challenge: null };

export const fetchUser = createAsyncThunk('authentication/fetchUser', async () => {
  const user = await Auth.currentAuthenticatedUser();
  const { accessToken } = user.signInUserSession;

  return accessToken;
});

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ username, password  }: Credentials, thunkAPI)=>{
    try {
      const user = await Auth.signIn(username.toLowerCase(), password);

      if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return thunkAPI.rejectWithValue(user);
      }

      const { accessToken } = user.signInUserSession;
      const groups = accessToken.payload['cognito:groups'] || [];
      // if (!groups.includes('CLAM')) {
      //   throw new Error("User doesn't have access to this feature");
      // }
      return accessToken;
    } catch (e) {
      console.error('error', e);
      throw e;
    }
  },
);

export const login = createAsyncThunk('authentication/login', async ({ username, password  }: Credentials) => {
  const user = await Auth.signIn(username, password);
  const { accessToken } = user.signInUserSession;
  const groups = accessToken.payload['cognito:groups'] || [];
  if (!groups.includes('admin')) {
    throw new Error('User is not admin');
  }
  return accessToken.jwtToken;
});

export const logout = createAsyncThunk('authentication/logout', async () => {
  await Auth.signOut();
});

const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setUser: (state, { payload: user }) => ({ ...state, user }),
  },
  extraReducers: (builder) => {
    builder
    
      .addCase(loginUser.fulfilled, (state, { payload:token }) => 
        // Add user to the state array
        ({
          ...state,
          token: token.jwtToken,
          groups: token.payload['cognito:groups'] || [],
          user: token.payload.username,
          status: 'succeeded',
          error: null,
        }),
      )
      .addCase(loginUser.rejected, (state, action: any) => {
        console.error('rejected');
        return ({
          ...state,
          token: null,
          status: 'failed',
          error:  action?.payload?.challengeName === 'NEW_PASSWORD_REQUIRED' ? 'NEW_PASSWORD_REQUIRED' : action?.error?.message,
          challenge:  action?.payload?.challengeParam,
        });
      },
      )
      .addCase(loginUser.pending, (state, action) => 
        // Add user to the state array
        ({
          ...state,
          status: 'loading',
          error: null,
        }),
      )
      .addCase(logout.fulfilled, () => ({ ...initialState, status: 'succeeded' }))
      .addCase(fetchUser.pending, (state: any) => ({
        ...state,
        status: 'loading',
        error: null,
      }))
      .addCase(fetchUser.fulfilled, (state, { payload: token }) => ({
        ...state,
        token: token.jwtToken,
        groups: token.payload['cognito:groups'] || [],
        user: token.payload.username,
        status: 'succeeded',
      }))
      .addCase(fetchUser.rejected, (state) => ({
        ...state,
        token: null,
        status: 'failed',
      }))
      .addCase(login.pending, (state) => ({
        ...state,
        status: 'loading',
        error: null,
      }))
      .addCase(login.fulfilled, (state, { payload: token }) => ({
        ...state,
        token,
        status: 'succeeded',
      }))
    ;
  },
});

export const { setUser } = slice.actions;

const authenticationReducer = slice.reducer;
export default authenticationReducer;

export const selectUser = (state: any) => state.authentication.user;
export const selectAuth = (state: any) => state.authentication;