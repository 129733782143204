import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import './question-types.scss';

export interface ListProps {
  answer: any,
  onAnswer: (vals: any) => void,
  withCheck?: boolean,
  multi?: boolean,
  option: any,
}

const List = (props: ListProps): JSX.Element => {
  const { answer, onAnswer, withCheck, option, multi } = props;
  const [selected, setSelected] = useState(false);

  const classes = {
    'question__list': true,
    [`question__list--${selected}`]: true,
    [`question__list--${withCheck ? 'with-check' : 'no-check'}`]: true,
  };

  const labelClasses = {
    'question__list-label': true,
  };

  useEffect(() => {
    const someVarSoLinterSHUTSUP = 0;
    setSelected(answer.findIndex((it: any) => it?.label === option.label) !== -1);
  }, [answer, option.label]);


  const onClick = () => {
    if (multi) {
      const idx = answer.findIndex((it: any) => it?.label === option.label);
      if (idx === -1) {
        if (option?.action && option?.action === 'clear') {
          onAnswer([option]);
        } else {
          const idx2 = answer.findIndex((it: any) => it?.action === 'clear');
          if (idx2 !== -1) {
            onAnswer?.([option]);
          } else {
            onAnswer?.([...answer, option]);
          }

        }
      } else {
        const selection = [...answer];
        selection.splice(idx, 1);
        onAnswer(selection);
      }

    } else {
      onAnswer([option]);
    }
    setSelected(!selected);
  };


  return (
    <div className='card'>
      <div role='button' tabIndex={-1} onClick={onClick} className={classnames(classes)}>
        {withCheck && (selected ? <BsCheckCircle className='selected-icon' /> : <BsCircle />)}
        <div className={classnames(labelClasses)}>{option?.label}</div>
      </div>
    </div>
  );
};

List.defaultProps = {
  withCheck: true,
  multi: false,
};

List.displayName = 'List';
export default List;
