/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
import React from 'react';
import classnames from 'classnames';

import './drop-zone.scss';
import FilesList from '../FilesList/FilesList';
import { getConditionalStyle } from '../DataTable/util';

 
// Define interface for component props/api:
export interface DropZoneProps {
  onDragStateChange?: (isDragActive: boolean) => void
  onDrag?: () => void
  onDragIn?: () => void
  onDragOut?: () => void
  onDrop?: () => void
  onFilesDrop?: (files: FileSystemEntry[]) => void
  active?: boolean | true
  className?: string
}

const mapItemListToArray = (items: DataTransferItemList) => {
  const array: FileSystemEntry[] = [];
  // // eslint-disable-next-line no-plusplus
  for (let i = 0; i < items.length; i++) {
    const item = items[i].webkitGetAsEntry();
    array.push(item!);
  }

  return array;
};

const readDirectory = async (directory:any ) => new Promise<any []>((resolve, reject) => {
  const dirReader = directory.createReader();
  let entries : any[] = [];
  const getEntries = () => {
    dirReader.readEntries((results: any[]) => {
      if (results.length) {
        entries = entries.concat(results);
        getEntries();
      } else {
        resolve(entries);
      }
    }, (error: any) => {
    });
  };
  getEntries();
});
const scanForFiles = async (item: any, filearray: FileSystemEntry[], level = 0 ) => { 
  // let filearray: FileSystemEntry[] = [];
  const offset = '   ->    '.repeat(level + 1);
  try {
  
    if (item.isDirectory) {
      const entries = await readDirectory(item);      
      const promisesarray = entries.map((entry)=>scanForFiles(entry, filearray, level + 1));
      await Promise.all(promisesarray);
    } else if (item.isFile) {
      await filearray.push(item); // DO NOT REMOVE THE AWAIT!!!
    } else {
      console.error('format not handled', item);
    }
  } catch (error) {
    console.error('error scanning files', error);
  }
  return filearray;
};
const s2canForFiles = async (item: any ) => { 
  let filearray: FileSystemEntry[] = [];
  try {
    if (item.isDirectory) {
      const entries = await readDirectory(item);
      await entries.forEach(async (entry: FileSystemEntry) => {
        const res = await scanForFiles(entry, filearray);
        filearray = [...filearray, ...res];
      });
    } else if (item.isFile) {
      filearray.push(item);

    } else {
      console.error('format not handled', item);
    }

  } catch (error) {
    console.error('error scanning files', error);
  }
  return filearray;
};
  
const DropZone = React.memo(
  (props: React.PropsWithChildren<DropZoneProps>) => {
    const {
      onDragStateChange,
      onFilesDrop,
      onDrag,
      onDragIn,
      onDragOut,
      onDrop,
      active,
      className,
    } = props;

    const classes = {
      'drop-zone': true,
      'drop-zone-wrapper': true,
      'drop-zone-active': active,
      [`${className}`]: true,
    };
    
    // Create state to keep track when DropZone is active/non-active:
    const [isDragActive, setIsDragActive] = React.useState(false);
    // Prepare ref for DropZone element:
    const DropZoneRef = React.useRef<null | HTMLDivElement>(null);

  

    // Create handler for dragenter event:
    const handleDragIn = React.useCallback(
      (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        onDragIn?.();

        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
          setIsDragActive(true);
        }
      },
      [onDragIn],
    );

    // Create handler for dragleave event:
    const handleDragOut = React.useCallback(
      (event: { preventDefault: () => void; stopPropagation: () => void; }) => {
        event.preventDefault();
        event.stopPropagation();
        onDragOut?.();

        setIsDragActive(false);
      },
      [onDragOut],
    );

    // Create handler for dragover event:
    const handleDrag = React.useCallback(
      (event: { preventDefault: () => void; stopPropagation: () => void; }) => {
        event.preventDefault();
        event.stopPropagation();

        onDrag?.();
        if (!isDragActive) {
          setIsDragActive(true);
        }
      },
      [isDragActive, onDrag],
    );



    const handleDroppedItems = async (droppeditems: DataTransferItemList) => {
      // const filearray: any[] = [];
      // eslint-disable-next-line prefer-const
      let filearray: FileSystemEntry[] = [];

      const arr = mapItemListToArray(droppeditems);
      const promisearray = arr.map(async (item) => scanForFiles(item, filearray));
      const scanned = await Promise.all(promisearray);
      const flatscanned = scanned.flat();
      return flatscanned;
    };

    // Create handler for drop event:
    const handleDrop = React.useCallback(
      async (event: any ) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragActive(false);
        onDrop?.();
        if (event.dataTransfer.items ) {
          const items = await handleDroppedItems(event.dataTransfer.items);
          onFilesDrop?.(items);
          event.dataTransfer.clearData();
        }
      },
      [onDrop, onFilesDrop],
    );

    // Obser active state and emit changes:
    React.useEffect(() => {
      onDragStateChange?.(isDragActive);
    }, [isDragActive]);

    // Attach listeners to DropZone on mount:
    React.useEffect(() => {
      const tempZoneRef = DropZoneRef?.current;
      if (tempZoneRef) {
        tempZoneRef.addEventListener('dragenter', handleDragIn);
        tempZoneRef.addEventListener('dragleave', handleDragOut);
        tempZoneRef.addEventListener('dragover', handleDrag);
        tempZoneRef.addEventListener('drop', handleDrop);
      }

      // Remove listeners from DropZone on unmount:
      return () => {
        tempZoneRef?.removeEventListener('dragenter', handleDragIn);
        tempZoneRef?.removeEventListener('dragleave', handleDragOut);
        tempZoneRef?.removeEventListener('dragover', handleDrag);
        tempZoneRef?.removeEventListener('drop', handleDrop);
      };
    }, []);

    // Render <div> with ref and children:
    return (
      <div className={classnames(classes)} ref={DropZoneRef}>{props.children}</div>
    );
  },
);

DropZone.displayName = 'DropZone';

export default DropZone;