import QueryString from 'qs';
import api from '../../../api/api';

export const cohortApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCohortInfo: builder.query({
      query: (payload) => ({ url: `/cohort/statsbyid/type/${payload.type}/${payload.id}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['OPENCOHORT'],
    }),
    getLogs: builder.query({
      query: (payload) => ({ url: `/log/statsbyid/${payload.id}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['LOGS'],
    }),
    addPatients: builder.mutation({
      query: (payload) => ({ url: '/patient', method: 'POST', body: payload }),
      invalidatesTags: ['OPENCOHORT'],
    }),
    sendEmail: builder.mutation({
      query: (payload) => ({ url: '/cohort/email', method: 'POST', body: payload }),
    }),
    deletePatient: builder.mutation({
      query: (payload) => ({ url: `/patient/${payload.id}`, method: 'DELETE', body: payload.body }),
      invalidatesTags: ['OPENCOHORT'],
    }),
  }),
});

export const { useGetCohortInfoQuery, useAddPatientsMutation, useSendEmailMutation, useGetLogsQuery, useDeletePatientMutation } = cohortApi;
