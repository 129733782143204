import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BsChevronCompactRight, BsCheckCircleFill } from 'react-icons/bs';
import { Label, Tile, Button, Alert, Spacer } from '../../../components';
import { ConferenceCRF } from '../../../components/molecules';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { useGetConferenceStatsByIdQuery, conferencesApi, useUpdateConferenceMutation } from '../../../api/builders/conference.api';

interface LocationState {
  // state: {
  conference: any;
  // };
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const Conference = () => {
  const location = useLocation();
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const { conference } = location?.state as LocationState;
  const { data: info, isLoading: isLoadingConferences, isFetching: isFetchingConferences } = useGetConferenceStatsByIdQuery(conference.id);
  const [edit, { isLoading: isAdding, isError: isErrorAdding, error: Adderror, isSuccess: isSuccessAdding }] = useUpdateConferenceMutation();
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = Adderror as ApiError;
      setAlert({
        open: true,
        message: `Error Completing Conference: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Completing Conference',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  const handleSelectPatient = (newpat: any) => {
    setSelectedPatient(newpat);
  };

  React.useEffect(() => {
    if (info?.length > 0 && Array.isArray(info[0].patients) && info[0].patients.length > 0) {
      setSelectedPatient(info[0].patients[0]);
    }
  }, [info]);

  React.useEffect(() => {
    const newBreadcrumb = {
      label: conference?.conferenceLabel ?? 'Conference',
      path: location.pathname,
      state: { conference },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);

  const handleEditConference = () => {
    try {
      const pl = {
        conferenceUID: conference.id,
        payload: {
          status: 'Completed',
        },
      };
      edit(pl);
      console.log('EDIT', pl);
    } catch (e) {
      console.error(e);
    }
  };


  console.log('conference', conference);
  console.log('info', info);

  return (
    <div className="conference">
      <div className="conference__header">
        <Label size="h3" weight={300}>
          {conference?.conferenceLabel}
        </Label>
        <Spacer value={10} />
        {(info && info[0]?.status === 'Completed') ?
          <Label size='h5' italic color='success'> Conference Complete</Label>
          :
          <Button onClick={handleEditConference}>Complete Conference</Button>
        }
      </div>

      <div className="conference__body">
        <div className='conference__panel1'>
          <Tile className='conference__tile'>
            <div className='conference__tile-body'>
              <div>
                {info?.length > 0 && Array.isArray(info[0].patients) && info[0].patients.length > 0 &&
                  <>
                    {info[0].patients.map((item: any) => (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {/* REGISTRYTODO::: on conference__patient and conference__patient-selected, find a way to track which patients are completed */}
                        {item.id === selectedPatient?.id ?
                          <div key={item.id} className='conference__patient-selected__wrapper'>
                            <div className='conference__patient-selected'>
                              {item.steps?.patientStatus === 'COMPLETED' &&
                                <div className='conference__patient__indicator'>
                                  <BsCheckCircleFill className='green-text' />
                                </div>
                              }
                              <span>
                                <Label uppercase size='h5' weight={600} color="primary">{item.firstname}, {item.lastname}</Label>
                                <Label uppercase size='h6' weight={400} color='ghost'>{item.mrn}</Label>
                              </span>
                            </div>
                            <div className='conference__patient-selected__indicator'>
                              <BsChevronCompactRight />
                            </div>
                          </div>
                          :
                          <div key={item.id} className='conference__patient' role='button' tabIndex={-1} onClick={() => handleSelectPatient(item)}>
                            {item.steps?.patientStatus === 'COMPLETED' &&
                              <div className='conference__patient__indicator'>
                                <BsCheckCircleFill className='green-text' />
                              </div>
                            }
                            <span>
                              <Label uppercase size='h5' weight={600} color='ghost'>{item.firstname}, {item.lastname}</Label>
                              <Label uppercase size='h6' weight={400} color='ghost'>{item.mrn}</Label>
                            </span>
                          </div>
                        }
                      </>
                    ))}
                  </>
                }
              </div>
            </div>
          </Tile>
        </div>
        <div className='conference__panel2'>
          <Tile className='conference__tile'>
            <div className='h-100 w-100'>
              {selectedPatient ?
                <ConferenceCRF patient={selectedPatient} />
                :
                <div className='w-100 h-100 super-centered'>
                  <Label size='h6'>Select Patient To Begin</Label>
                </div>
              }
            </div>
          </Tile>
        </div>
      </div>
      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

export default withAuthenticator(Conference);
