/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation, Trans } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import { BiRefresh } from 'react-icons/bi';
import { FcSettings, FcAreaChart } from 'react-icons/fc';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Tooltip from '@mui/material/Tooltip';
import { GrFormAdd } from 'react-icons/gr';
import { useMediaQuery } from 'react-responsive';
import { useGetSiteInfoQuery, useCreateParticipantMutation, useEditParticipantMutation, sitesAPI } from './api';
import { Label, Alert, Spacer, Button, Spinner, AddParticipant, ParticipantListItem, SearchBox } from '../../../components';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { usePrefetch } from '../SiteStatus/api';

type SiteItem = {
  id: string | undefined;
  name: string | undefined;
  region: string | undefined;
  siteId: string | undefined;
  siteLabel: string | undefined;
  status: string | undefined;
  drive_s3_path: string | undefined;
};

type StudyItem = {
  label?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  summary?: string | undefined;
  treatments?: string | undefined;
  acl?: string | undefined;
};


type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  type: string | undefined;
  id: string;
  consentStatus: string;
  group?: string,
};

interface LocationState {
  site: SiteItem;
  study: StudyItem;
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const sortData = (
  participants: any[],
  sortBy: string,
  sortOrder: 'asc' | 'desc',
): any[] => {
  if (participants.length > 0) {
    const sorted = [...participants].sort((a, b) => {
      let valueA: any;
      let valueB: any;
      if (sortBy === 'id') {
        const idField = a.type === 'participant' || a.type === 'phantom' ? 'participantId' : 'screeningId';
        valueA = a[idField];
        valueB = b[idField];
      } else {
        valueA = a[sortBy];
        valueB = b[sortBy];
      }
      if (valueA < valueB) return sortOrder === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortOrder === 'asc' ? 1 : -1;
      if (sortBy === 'id') {
        const typeA = a.type;
        const typeB = b.type;
        if (typeA < typeB) return sortOrder === 'asc' ? -1 : 1;
        if (typeA > typeB) return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return sorted;
  }
  return participants; // Return the original array if it is empty
};

type RenderTrackerProps = {
  acl: string;
  onHover: () => void;
  onOpen: () => void;
  onOpenSettings: () => void;
};

const RenderTracker = ({ acl, onHover, onOpen, onOpenSettings }: RenderTrackerProps) => {
  switch (acl) {
    case 'admin':
    case 'SPONSOR':
    case 'STUDY COORDINATOR':
      return (
        <div className="d-flex-row" onMouseEnter={onHover}>
          <Button kind="ghost" onClick={onOpen}>
            <FcAreaChart size={20} />
          </Button>
          <Button kind="ghost" onClick={onOpenSettings}>
            <FcSettings size={20} />
          </Button>
        </div>
      );
    case '(BLINDED)':
    case 'SPONSOR (BLINDED)':
    case 'SITE COORDINATOR':
    case 'CORELAB':
      return <div />;
    default:
      return <div />;
  }
};
type RenderAddProps = {
  acl: string;
  onOpenModal: () => void;
  siteStatus: string;
};

const RenderAdd = ({ acl, onOpenModal, siteStatus }: RenderAddProps) => {
  switch (acl) {
    case 'admin':
    case 'SITE COORDINATOR':
      return (
        <Button kind="ghost" icon={GrFormAdd} onClick={onOpenModal} />
        // <Button kind='ghost' disabled={siteStatus !== 'setup' && siteStatus !== 'recruiting'} icon={GrFormAdd} onClick={onOpenModal} />
      );
    case '(BLINDED)':
    case 'STUDY COORDINATOR':
    case 'SPONSOR (BLINDED)':
    case 'CORELAB':
      return <div />;
    default:
      return <div />;
  }
};

const Site = () => {
  const location = useLocation();
  const prefetchStats = usePrefetch('getSiteStatus');
  const navigate = useNavigate();
  const { site, study } = location.state as LocationState;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetSiteInfoQuery({ siteId: site?.id ? site.id : skipToken });
  const [AddNewParticipant, { isLoading: isAdding, isError: isErrorAdding, error: Adderror, isSuccess: isSuccessAdding }] = useCreateParticipantMutation();
  const [EditParticipant, { isLoading: isEditing, isError: isErrorEditing, isSuccess: isSuccessEditing, error: editError }] = useEditParticipantMutation();
  const { refetch: refetchParticipants } =
    sitesAPI.endpoints.getSiteInfo.useQuerySubscription({ siteId: site?.id ? site.id : skipToken });
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [participants, setParticipants] = React.useState<any[]>([]);
  const [sortedData, setSortedData] = React.useState<any[]>([]);
  const [sortBy, setSortBy] = React.useState<'type' | 'id' | 'treatment'>('id');
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('asc');
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const handleChangeSearch = (value: string) => {
    setSearchTerm(value);
  };
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = Adderror as ApiError;
      setAlert({
        open: true,
        message: `Error Adding New Participant: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding New Participant',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  React.useEffect(() => {
    if (isErrorEditing) {
      const apiError = editError as ApiError;
      setAlert({
        open: true,
        message: `Error Adding New Participant: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorEditing]);

  React.useEffect(() => {
    if (isSuccessEditing) {
      setAlert({
        open: true,
        message: 'Success Adding New Participant',
        kind: 'success',
      });
    }
  }, [isSuccessEditing]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  React.useEffect(() => {
    if (Array.isArray(data) && data.length > 0 && data[0].participants) {
      const part = sortData(data[0].participants, 'id', 'asc');
      setParticipants(part);
      setSortedData(part);
    }
  }, [data]);


  React.useEffect(() => {
    const newBreadcrumb = {
      label: site?.siteLabel ?? 'Site',
      path: location.pathname,
      state: { study, site },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);


  React.useEffect(() => {
    setSortedData(sortData(participants, sortBy, sortOrder));
  }, [participants, sortBy, sortOrder]);


  const handleSortBy = (key: 'type' | 'id' | 'treatment') => {
    if (key === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
      setSortBy(key);
    }
  };

  const onOpen = () => {
    navigate('/private/studies/study/site/site-status', { state: { site, acl: study.acl } });
  };

  const onOpenSettings = () => {
    navigate('/private/studies/study/site/settings', { state: { site, study } });
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const convertCandidate = async (_new: { id: string, participantId: string }) => {
    const payload = {
      id: _new.id,
      body: {
        studyId: study.id,
        participantId: _new.participantId,
        type: 'participant',
      },
    };
    await EditParticipant(payload);
  };

  const onUpdateConsent = async (_new: { id: string, status: string, backgroundId?: string }) => {
    let payload = {};
    if (_new.status === 'consented' && _new.backgroundId) {
      payload = {
        id: _new.id,
        body: {
          status: _new.status,
          siteId: site.id,
          backgroundId: _new.backgroundId,
        },
      };
    } else {
      payload = {
        id: _new.id,
        body: {
          status: _new.status,
          siteId: site.id,
        },
      };
    }
    await EditParticipant(payload);
  };

  const onUpdateTx = async (_new: { id: string, treatment: string, }) => {
    let payload = {};
    payload = {
      id: _new.id,
      body: {
        group: _new.treatment,
        siteId: site.id,
      },
    };
    await EditParticipant(payload);
  };

  const addParticipant = async (_values: any, tx: string) => {
    try {
      if (_values.subjectId) {
        const newPat = {
          studyId: study.id,
          screeningId: _values.screeningId,
          participantId: _values.participantId,
          type: _values.type,
          siteId: site.id,
          // group: tx,
          subjectId: _values.subjectId,
        };
        const a = await AddNewParticipant(newPat);
        onCloseModal();
      } else {
        const newPat = {
          studyId: study.id,
          screeningId: _values.screeningId,
          participantId: _values.participantId,
          type: _values.type,
          siteId: site.id,
          // group: tx,
        };
        const a = await AddNewParticipant(newPat);
        onCloseModal();
      }
    } catch (error) {
      console.error('SHOW error MESSAGE HERE', error);
    }
  };


  const onHover = () => {
    const PL = {
      siteId: site?.id,
      pageSize: 10,
      orderBy: 'recent',
    };
    prefetchStats(site?.id ? PL : skipToken);
  };

  // Filter the studies based on the search term
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const filteredParticipants = sortedData?.filter((participant: ParticipantItem) =>( participant.participantId?.toLowerCase().includes(searchTerm.toLowerCase() )));
  return (
    <div className='site'>
      <div className="site__header">
        <div className='site__title_wrapper'>
          <Label size="h2" weight={300} >{site?.siteLabel}</Label>
          <div className="studies__search">
          <SearchBox value={searchTerm} 
            onChange={handleChangeSearch}
            placeholder={t('search') as string} />
        </div>
         <RenderTracker acl={study?.acl!} onHover={onHover} onOpen={onOpen} onOpenSettings={onOpenSettings} />
        </div>
        
        <Spacer value={20} />
      </div>

      <div className="site__body">
        <div className='study__heading'>
          <div className="d-flex-row">
            <Label size="h4" weight={300} >Participants</Label>
            <button type='button' className='icon-btn' aria-label='refetch' onClick={refetchParticipants}>
              <BiRefresh />
            </button>
          </div>
          <RenderAdd acl={study?.acl!} onOpenModal={onOpenModal} siteStatus={site?.status!} />
        </div>
        

        <Spacer value={5} />
        <Grid container spacing={2}>
          <Grid xs={2} className='site__center'>
            <button className='site__btn' type='button' onClick={() => handleSortBy('type')}>Type</button>
          </Grid>
          <Grid xs={4} className='site__center'>
            <button className='site__btn' type='button' onClick={() => handleSortBy('id')}>Id</button>
          </Grid>
          <Grid xs={3}>
            <Label center size='subtext'>Consent Status </Label>
          </Grid>
          <Grid xs={2}>
            <Label center size='subtext'>Treatment</Label>
          </Grid>
          {study?.acl !== 'SPONSOR' && <Grid xs={1} >
            <Label className='site__action-wrapper' size='subtext'>Actions </Label>
          </Grid>}
        </Grid>
        {isLoadingData || isFetchingData || isAdding || isEditing ?
          <div className='super-centered'>
            <Spinner />
          </div>
          :
          <div className="site__scrollable">
            {Array.isArray(filteredParticipants) && filteredParticipants.length > 0 && filteredParticipants.map((item: ParticipantItem) => (
              <ParticipantListItem
                key={item.id}
                participant={item}
                convertCandidate={convertCandidate}
                prefix={Array.isArray(data) ? data[0].siteId : ''}
                sitePath={Array.isArray(data) ? data[0].drive_s3_path : ''}
                binderPath={Array.isArray(data) ? data[0].binder_s3_path : ''}
                onUpdateConsent={onUpdateConsent}
                onUpdateTx={onUpdateTx}
                acl={study?.acl ? study.acl : 'unknown'}
                siteId={site.id ? site.id : 'n/a'}
                treatments={study?.treatments ? JSON.parse(study.treatments) : []}
              />
            ))}
          </div>
        }
        <Spacer value={10} />
      </div>
      
      <Spacer value={10} />
      
      {Array.isArray(data) && data.length > 0 && data[0].participants &&
        <AddParticipant
          addPat={addParticipant}
          onOpen={modalIsOpen}
          onClose={onCloseModal}
          participants={Array.isArray(data) ? data[0].participants : []}
          prefix={Array.isArray(data) ? data[0].prefix : ''}
        />}

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />

    </div>

  );
};

// export default Site;
export default withAuthenticator(Site);



