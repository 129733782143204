import React from 'react';
import Symbol from './infinity-2.png';
import Egg from './Logo.svg';
import Haynes from './Haynes logo-01.png';


import './logo.scss';

type Props = {
  size: number;
  type:string;
};

const Logo = ({ size, type }: Props) => {
  switch (type) {
    case 'egg':
      return (<img alt="Logo" src={Haynes} height={size} />);
    case 'text':
      return (<img alt="Logo" src={Haynes} height={size} />);
    default:
      return (<img alt="Logo" src={Haynes} height={size} />);
  }

};

// const Logo = ({ size }: Props) => <img alt="Logo" src={Egg} height={size} />;


export default Logo;
