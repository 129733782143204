import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Label, TextInput, Spacer, Tile, Logo } from '../../components';
import { login, Credentials, selectAuth, loginUser, logout } from '../../store/authentication/slice';
import api from '../../api/api';
import Haynes from './Haynes logo-01.png';

const Auth = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    username: '',
    password: '',
    code: '',
    status: '',
  });
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { token, error } = useSelector(selectAuth);

  useEffect(() => {
    if (token) {
      switch (process.env.REACT_APP_FEATURE) {
        case 'MASTER':
          navigate('/private/patients');
          break;
        case 'TRIALS':
          navigate('/private/studies');
          break;
        case 'PRIVACY':
          navigate('/private/audits');
          break;
        case 'COHORT':
          navigate('/private/cohorts');
          break;
        case 'IMX':
          navigate('/private/shared/cohorts');
          break;
        case 'REGISTRY':
          navigate('/private/registries');
          break;
        case 'PATIENT':
          navigate('/private/mysurveys');
          break;
        default:
          navigate('/private/audits');
      }
    }
  }, [token]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  // dispatch(login({ username:'+33769068308', password:'changeMe123!@#' }));
  React.useEffect(() => {
    dispatch(logout());
    dispatch(api.util.resetApiState());
  }, []);

  useEffect(() => {
    if (error === 'NEW_PASSWORD_REQUIRED') {
      navigate('new_password_required');
    }
  }, [error]);

  const onSubmit = async (credentials: Credentials) => {
    setState((prev) => ({ ...prev, 'status': 'loading' }));
    await dispatch(loginUser(credentials));
    // navigate(-2);
    setState((prev) => ({ ...prev, 'status': 'loggedin' }));
    // add timeout
  };

  const goToForgot = () => {
    navigate('/forgot_password');
  };
  const goToOTP = () => {
    navigate('/OTP');
  };
  const { t } = useTranslation();

  return (
    <div className='auth-wrapper'>
      <div className="auth">
        {/* <div className='animation'>
        <Lottie animationData={groovyAnimation} loop={false} />
      </div> */}
        <div className='auth__logo'>
          <img alt="Logo" src={Haynes} height={300} />
          <Label size="h3" color="primary" weight={600} uppercase >
            Haynes Neurosurgical Group Patient Portal
          </Label>
        </div>
        <div className="auth__content">
          {/* <Label size="h1" bold color="primary" weight={900} uppercase>
            {t('logIn')}</Label>
          <Spacer value={10} /> */}
          <Tile className="auth__tile" >
            <div className="auth__body">
              <div className="auth__form">
                <Spacer value={10} />

                <div className="auth-form__inputs_wrapper">
                  <TextInput
                    id="username"
                    type="Email"
                    autocomplete="Email"
                    value={state.username}
                    placeholder={t('email') || 'Email'}
                    label={t('email') || 'Email'}
                    onChange={onChange}
                  />
                  <Spacer value={10} />
                  <TextInput
                    id="password"
                    type="password"
                    value={state.password}
                    autocomplete="current-password"
                    placeholder={t('password') || 'Password'}
                    label={t('password') || 'Password'}
                    onChange={onChange}
                  />
                </div>
                <Spacer value={10} />

                <div className="auth__btn-wrapper">
                  <Button loading={state.status === 'loading'} size="large" className="auth__btn-styles" onClick={() => onSubmit(state)} >
                    {t('logIn')}
                  </Button>
                  <Spacer value={10} />
                  <div>
                    <Button kind='ghost' onClick={goToForgot} >{t('forgotPassword')}</Button>
                    {/* <Button kind='ghost' onClick={goToOTP} >Sign in with OTP</Button> */}
                  </div>
                </div>
                {error && <div className="auth__error">
                  {error}
                </div>}
              </div>
            </div>
          </Tile>
        </div>
      </div>
    </div>
  );
};

export default Auth;
