import React, { useState, useEffect } from 'react';
import { Tile, Label } from '../../../atoms';
import TextInput from '../../TextInput/TextInput';
import '../form-item.scss';

export interface NumberProps {
  answer: any,
  onAnswer: (vals: any) => void,
  editable?: boolean,
  label: string,
  qNumber: number,
  noNumbers: boolean,
  className: string,
}

const Number = (props: NumberProps): JSX.Element => {
  const { answer, onAnswer, editable, label, qNumber, noNumbers, className } = props;
  const [value, setValue] = useState('');

  useEffect(() => {
    if (Array.isArray(answer) && answer[0] && answer[0].value) {
      setValue(answer[0].value);

      // if (state.wordCount === 0 && answer[0].value) {
      //   const count = answer[0].value;
      //   setState({
      //     wordCount: countWords(count),
      //     charCount: count.length,
      //   });

      //   if (countWords(count) > 300) {
      //     setInvalid(true);
      //   }
      // }
    }
  }, [answer]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onAnswer([{ 'value': event.target.value, 'label': event.target.value }]);
  };



  return (
    <div className={`question-types__text ${className}`}>
      {noNumbers ?
        <Label size="h6" weight={200}  >{label}</Label>
        :
        <Label size="h6" weight={200}  >{qNumber}: {label}</Label>
      }
      <TextInput
        value={value}
        onChange={onChange}
        type='number'
        disabled={!editable}
      />
      {/* {editable ?
        <TextInput
          value={value}
          onChange={onChange}
          type='number'
        />
        :
        <Label size="h6" >
          {value}
        </Label>
      } */}
    </div>
  );
};

Number.defaultProps = {
  editable: true,
};

Number.displayName = 'Number';
export default Number;
