import React from 'react';
import FlatList from 'flatlist-react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, Spinner, Checkbox, Button } from '../../atoms';
import { useGetCohortInfoQuery } from '../../../api/builders/cohorts.api';
import './patients-table.scss';

export interface PatientsTableProps {
  patients: Patient[] | [],
  loading: boolean,
  id: string | undefined;
  isAdding: boolean,
  onAdd: () => void,
  checked: any,
  setChecked: any, 
  deIdentified?: boolean,
}

export interface Patient {
  id: string,
  mrn: string,
  firstname: string,
  lastname: string,
  gender: string,
  // dob: number,
  PatientBirthDate: string,
  MCL_ID?: string,
  histology?: string,
  location?: string,
  PatientSex?: string,
  pky?: number,
  cancer?: string,
  hx_cancer?: string,
  density?: string,
  spiculation?: string,
  FDGAvidity?: string,
  secondaryId?: string,
}

const PatientsTable = (props: PatientsTableProps): JSX.Element => {
  const { patients, loading, deIdentified, id, onAdd, setChecked, checked } = props;
  // const [checked, setChecked] = React.useState<Array<string>>([]);
  const [allChecked, setAllChecked] = React.useState(false);


  const handleChecked = (cohort: string) => {
    const found = checked.includes(cohort);
    let newArr: any[] = [];
    if (found) {
      newArr = checked.filter((item: any) => item !== cohort);
    } else {
      newArr = checked.concat(cohort);
    }
    setChecked(newArr);
  };

  const checkAll = (arr: { id: string }[]): string[] => {
    const someFillerVarBecauseIDontUnderstandThatNextLineBsLinterError = 0;
    return arr.map(obj => obj.id);
  };

  const handleCheckAll = () => {
    if (allChecked) {
      setAllChecked(false);
      setChecked([]);
    } else {
      const allpats = checkAll(patients);
      setChecked(allpats);
      setAllChecked(true);
    }
  };

  const getDob = (date: string) => {
    const birthDate = new Date(Number(date) * 1000);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    return age;
  };


  const handleAdd = () => {
    onAdd();
  };


  const renderRow = (item: Patient) => {
    const { mrn, firstname, lastname, PatientBirthDate, secondaryId } = item;
    return (
      <tr className='patients-table__row' key={item.id}  >
        <td className='patients-table__td'>
          <Checkbox label='' checked={checked.includes(item.id)} onChange={() => handleChecked(item.id)} />
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{deIdentified ? secondaryId : mrn}</Label>
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{firstname} {lastname}</Label>
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{getDob(PatientBirthDate)}</Label>
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{item?.PatientSex ? item.PatientSex : '-'}</Label>
        </td>
        {/* <td className='patients-table__td'>
          <Label size="h5" center>{item?.histology ? item.histology : '-'}</Label>
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{item?.location ? item.location : '-'}</Label>
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{item?.pky ? item.pky : '-'}</Label>
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{item?.hx_cancer ? item.hx_cancer : '-'}</Label>
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{item?.density ? item.density : '-'}</Label>
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{item?.spiculation ? item.spiculation : '-'}</Label>
        </td>
        <td className='patients-table__td'>
          <Label size="h5" center>{item?.FDGAvidity ? item.FDGAvidity : '-'}</Label>
        </td> */}
      </tr>
    );
  };


  return (
    <div>
      <div className='d-flex-row'>
        {/* <Button kind='secondary' loading={isAdding} onClick={handleAdd} disabled={checked.length <= 0}>Add to {Array.isArray(data) && data.length > 0 ? data[0].cohortLabel : 'Cohort'}</Button> */}
      </div>
      <table className='patients-table'>
        <thead className='patients-table__thead'>
          <tr>
            <th className='patients-table__th'>
              <Checkbox onChange={handleCheckAll} checked={allChecked} >
                <Label center size='subtext'>{checked.length > 0 ? `${checked.length}` : ' '}</Label>
              </Checkbox>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>{deIdentified ? 'Patient ID' : 'MRN'}</Label>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>Name</Label>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>Age</Label>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>Gender</Label>
            </th>
            {/* <th className='patients-table__th'>
              <Label size="subtext" center>Histology</Label>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>Location</Label>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>PKY</Label>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>cancer</Label>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>Density</Label>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>Spiculation</Label>
            </th>
            <th className='patients-table__th'>
              <Label size="subtext" center>FDG</Label>
            </th> */}
          </tr>
        </thead>
        <tbody>
          {loading ?
            <div className='w-100 h-100 super-centered'>
              <Spinner />
            </div>
            :
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{Array.isArray(patients) && patients.length > 0 && patients.map((item: any) => (
              renderRow(item)
            ))}</>
          }
        </tbody>
      </table>
    </div>
  );
};

PatientsTable.defaultProps = {
  deIdentified: false,
};

PatientsTable.displayName = 'PatientsTable';
export default PatientsTable;
