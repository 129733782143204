import QueryString from 'qs';
import api from '../../../api/api';

export const siteSettingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    editSite: builder.mutation({
      query: (payload) => ({ url: `/site/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['SITE', 'SITES', 'STUDY'],
    }),
  }),
});

export const { usePrefetch, useEditSiteMutation } = siteSettingsApi;
