
/* eslint-disable  */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GiSettingsKnobs } from 'react-icons/gi';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import { useReactToPrint } from 'react-to-print';
import { BsFillCircleFill, BsPrinter } from 'react-icons/bs';
import { AiOutlineCloseCircle, AiOutlineClose } from 'react-icons/ai';
import moment from 'moment';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetSiteStatusQuery } from './api';
import { Label, Button, Spacer, Modal, ProtocolMessages, Tile, ProtocolItemFactory, Spinner } from '../../../components';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';


type SiteItem = {
  id: string | undefined;
  name: string | undefined;
  region: string | undefined;
  siteId: string | undefined;
  siteLabel: string | undefined;
  status: string | undefined;
  drive_s3_path: string | undefined;
};


interface LocationState {
  site: SiteItem;
  acl: string,
}


const SiteStatus = () => {
  const location = useLocation();
  const { site, acl } = location.state as LocationState;
  const [payload, setPayload] = React.useState<any>(null);
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetSiteStatusQuery(payload !== null ? payload : skipToken);
  const [expanded, setExpanded] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [openItem, setOpenItem] = React.useState<any | null>(null);
  const [openPItem, setOpenPItem] = React.useState<any | null>(null);
  const [patients, setPatients] = React.useState<any | null>(null);
  const [showPhantoms, setShowPhantoms] = React.useState(true);
  const [showImageAnalysis, setShowImageAnalysis] = React.useState<boolean>(acl === 'SITE COORDINATOR' ? false : true);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [showMandatory, setShowMandatory] = React.useState<boolean>(true);
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);

  React.useEffect(() => {
    const newBreadcrumb = {
      label: 'Participant Tracker',
      path: location.pathname,
      state: { site },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);

  ///////////////////////////// LOADING & FETCHING  ///////////////////////////// 
  const loadMore = () => {
    setPayload({
      ...payload,
      pageSize: 10,
      pageCurrent: data.pageCurrent + 1,
      orderBy: 'recent',
    });
  };

  React.useEffect(() => {
    if (site) {
      const siteId = site.id;
      setPayload({
        siteId,
        pageSize: 10,
        orderBy: 'recent',
      });
    }
  }, [site]);

  React.useEffect(() => {
    if (data && Array.isArray(data.items) && data.items.length > 0) {
      const pats = Array.isArray(patients) ? patients : [];
      const curr = pats.concat(data.items);
      setPatients(curr);
    }
  }, [data]);


  ///////////////////////////// FILTERING & MANAGING ///////////////////////////// 

  const toggleShowMandatory = () => {
    setOpenPItem(null);
    setShowMandatory(!showMandatory);
  };

  const togglePhantoms = () => {
    setOpenPItem(null);
    setShowPhantoms((prevShowPhantoms) => !prevShowPhantoms);
  };

  const toggleShowImageAnalysis = () => {
    setOpenPItem(null);
    setShowImageAnalysis(!showImageAnalysis);
  };




  const filteredData = React.useMemo(() => {
    let filteredObjects = patients || [];

    // Filter out phantom patients
    if (!showPhantoms) {
      filteredObjects = filteredObjects.filter((object: any) => object.type !== "phantom");
    }

    // Filter out non-mandatory items if showMandatory is true
    if (!showMandatory) {
      filteredObjects = filteredObjects.map((object: any) => {
        const timepoints = object?.userProtocol?.timePoints || [];
        const filteredTimepoints = timepoints.map((timepoint: any) => {
          const userProtocolItems = timepoint?.userProtocolItems || [];
          const filteredItems = userProtocolItems.filter(
            (item: any) => item.mandatory !== false
          );
          return { ...timepoint, userProtocolItems: filteredItems };
        });
        return { ...object, userProtocol: { ...object.userProtocol, timePoints: filteredTimepoints } };
      });
    }


    // Filter out image analysis items if showImageAnalysis is false
    if (!showImageAnalysis) {
      filteredObjects = filteredObjects.map((object: any) => {
        const timepoints = object?.userProtocol?.timePoints || [];
        const filteredTimepoints = timepoints.map((timepoint: any) => {
          const userProtocolItems = timepoint?.userProtocolItems || [];
          const filteredItems = userProtocolItems.filter(
            (item: any) => item.type !== "image analysis"
          );
          return { ...timepoint, userProtocolItems: filteredItems };
        });
        return { ...object, userProtocol: { ...object.userProtocol, timePoints: filteredTimepoints } };
      });
    }
    return filteredObjects;
  }, [patients, showPhantoms, showMandatory, showImageAnalysis]);



  const toggleView = () => {
    setExpanded(!expanded);
    setOpenPItem(null);
  };

  const closeProtocol = () => {
    setOpenPItem(null);
  }

  const closeModal = () => {
    setModal(false);
    setOpenItem(null);
  };

  const openModal = (it: any) => {
    setModal(true);
    setOpenItem(it);
  };

  const openProtocolItem = (it: any) => {
    setOpenPItem(it);
  };

  function sortObjectsByParticipantId(objects: any) {
    // Create a new array with the sorted objects
    var sortedObjects = objects.slice().sort(function (a: any, b: any) {
      // Extract the numeric part of the participantId field for each object
      var aId = parseInt(a.participantId.replace('PHANTOM-', ''));
      var bId = parseInt(b.participantId.replace('PHANTOM-', ''));

      // Compare the numeric parts of the participantId fields
      if (aId < bId) {
        return -1;
      } else if (aId > bId) {
        return 1;
      } else {
        // If the numeric parts are equal, compare the full participantId fields
        if (a.participantId < b.participantId) {
          return -1;
        } else if (a.participantId > b.participantId) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    // Return the sorted array of objects
    return sortedObjects;
  };

  ///////////////////////////// POPOVER SETTINGS / CONTROLS   ///////////////////////////// 

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  ///////////////////////////// PRINTING  ///////////////////////////// 

  const componentRef = React.useRef(null);

  const handleAfterPrint = React.useCallback(() => {
    console.info("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.info("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: openItem?.participantId ? openItem?.participantId : 'Participant Info',
    onBeforeGetContent: () => console.info('loading'),
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true
  });



  ///////////////////////////// RENDERING ///////////////////////////// 

  const renderItemCell = (pItem: any) => {
    if (pItem?.deviation === true) {
      return <td key={pItem.id} className='tracker-table__td-red' onClick={() => openProtocolItem(pItem)} />;
    } else {
      switch (pItem.status.toLowerCase()) {
        case 'approved':
          return <td key={pItem.id} className='tracker-table__td-green' onClick={() => openProtocolItem(pItem)} />;
        case 'not started':
          return <td key={pItem.id} className='tracker-table__td-grey' onClick={() => openProtocolItem(pItem)} />;
        case 'not initiated':
          return <td key={pItem.id} className='tracker-table__td-grey' onClick={() => openProtocolItem(pItem)} />;
        case 'pending review':
          return <td key={pItem.id} className='tracker-table__td-blue' onClick={() => openProtocolItem(pItem)} />;
        case 'rejected':
          return <td key={pItem.id} className='tracker-table__td-orange' onClick={() => openProtocolItem(pItem)} />;
        case 'missing':
          return <td key={pItem.id} className='tracker-table__td-orange' onClick={() => openProtocolItem(pItem)} />;
        default:
          return <td key={pItem.id} className='tracker-table__td-grey' onClick={() => openProtocolItem(pItem)} />;
      }
    }
  };

  const renderIcons = (step: any) => {
    if (step?.deviation === true) {
      return <BsFillCircleFill className='circle-red' />;
    } else {
      switch (step.status.toLowerCase()) {
        case 'approved':
          return <BsFillCircleFill className='circle-green' />;
        case 'not started':
          return <BsFillCircleFill className='circle-grey' />;
        case 'not initiated':
          return <BsFillCircleFill className='circle-grey' />;
        case 'pending review':
          return <BsFillCircleFill className='circle-blue' />;
        case 'rejected':
          return <BsFillCircleFill className='circle-orange' />;
        case 'missing':
          return <BsFillCircleFill className='circle-orange' />;
        default:
          return <BsFillCircleFill className='circle-grey' />;
      }
    }
  };

  const PatTable = (tableProps: any) => {
    const { patData } = tableProps;
    const { timePoints } = patData.length > 0 ? patData[0].userProtocol : [];


    const PatTableVisitHeader = (headerProps: any) => {
      const { protocolData } = headerProps;
      return (<tr>
        <th > <span>{' '}</span> </th>
        {protocolData.map((item: any) => (
          <th colSpan={item.userProtocolItems.length}>
            {item.visit}
          </th>
        ))}
      </tr>);
    };

    const PatTableItemHeader = (headerProps: any) => {
      const { protocolData } = headerProps;
      return (
        <tr>
          <th > <span>{' '}</span> </th>
          {protocolData.map((item: any) => (
            <>
              {item.userProtocolItems.map((itemm: any) => (
                <th>
                  {itemm.item}
                </th>
              ))}
            </>
          ))}
        </tr>
      );
    };

    const PatTableBody = (bodyProps: any) => {
      const { patients } = bodyProps;
      return (
        <tbody>
          {sortObjectsByParticipantId(patients).map((item: any) => (
            <tr>
              <th onClick={() => openModal(item)} className='cd-tracker-table__patient-label'>{item.participantId}</th>
              {item.userProtocol.timePoints.map((one: any) => (
                <>
                  {one.userProtocolItems.map((oi: any) => (
                    // <span>
                    renderItemCell(oi)
                    // </span>
                  ))}
                </>
              ))}
            </tr>
          ))}
        </tbody>
      );
    };


    // const PatTableVisitHeader = (headerProps: any) => {
    //   const { protocolData } = headerProps;
    //   return (<tr>
    //     <th > <span>{' '}</span> </th>
    //     {protocolData.map((item: any) => (
    //       <th key={item.id} colSpan={item.userProtocolItems.length}>
    //         {item.visit}
    //       </th>
    //     ))}
    //   </tr>);
    // };

    // const PatTableItemHeader = (headerProps: any) => {
    //   const { protocolData } = headerProps;
    //   return (
    //     <tr>
    //       <th > <span>{' '}</span> </th>
    //       {protocolData.map((item: any) => (
    //         <div key={item.id}>
    //           {item.userProtocolItems.map((itemm: any) => (
    //             <th key={itemm.id}>
    //               {itemm.item}
    //             </th>
    //           ))}
    //         </div>
    //       ))}
    //     </tr>
    //   );
    // };

    // const PatTableBody = (bodyProps: any) => {
    //   const { patients } = bodyProps;
    //   return (
    //     <tbody>
    //       {sortObjectsByParticipantId(patients).map((item: any) => (
    //         <tr key={item.id}>
    //           <th onClick={() => openModal(item)} className='cd-tracker-table__patient-label'>{item.participantId}</th>
    //           {item.userProtocol.timePoints.map((one: any) => {
    //             one.userProtocolItems.map((oi: any) => { renderItemCell(oi) }
    //             )
    //           }
    //           )}
    //         </tr>
    //       ))}
    //     </tbody>
    //   );
    // };
    return (<div>
      <table className='tracker-table'>
        <PatTableVisitHeader protocolData={timePoints} />
        <PatTableItemHeader protocolData={timePoints} />
        <PatTableBody patients={patData} />
      </table>
    </div>);
  };

  const PatTableCondensed = (tableProps: any) => {
    const { patData } = tableProps;
    const { timePoints } = patData.length > 0 ? patData[0].userProtocol : [];

    const CondensedHeader = (headerProps: any) => {
      const { protocolData } = headerProps;

      return (<tr>
        <th>Subject</th>
        {protocolData?.length > 0 && protocolData.map((item: any) => (
          <th key={item.id}>
            <Tooltip title={item.visit} arrow placement="top">
              {/* <span>{generateAcronym(item.visit)}</span> */}
              <span>{item.visit}</span>
            </Tooltip>
          </th>
        ))}
      </tr>);
    };

    const CondensedBody = (bodyProps: any) => {
      const { patients } = bodyProps;

      const getStatusClassNames = (it: any) => {
        if (it?.deviation === true) {
          return 'bk-red';
        } else {
          switch (it && it.status.toLowerCase()) {
            case 'approved':
              return 'bk-green';
            case 'not started':
              return 'bk-gray';
            case 'not initiated':
              return 'bk-gray';
            case 'pending review':
              return 'bk-blue';
            case 'rejected':
              return 'bk-orange';
            case 'missing':
              return 'bk-orange';
            default:
              return 'bk-gray';
          }
        }
      };

      return (
        <tbody>
          {sortObjectsByParticipantId(patients).map((item: any) => (
            <tr key={item.id}>
              <th onClick={() => openModal(item)} className='cd-tracker-table__patient-label'> {item?.participantId}</th>
              {item?.userProtocol?.timePoints?.map((one: any) => (
                // <td onClick={() => openModal(one)}>
                <td key={one.id}>
                  {/* {getcompleted(one)} */}
                  <div className='cd-tracker-table__td'>
                    {one.userProtocolItems.map((oi: any) => (
                      <Tooltip title={oi.item} arrow placement="top" key={oi.id}>
                        <div onClick={() => openProtocolItem(oi)} className={getStatusClassNames(oi)} />
                      </Tooltip>
                    ))}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    };

    return (
      <table className='cd-tracker-table'>
        <CondensedHeader protocolData={timePoints} />
        <CondensedBody patients={patData} />
      </table>);
  };

  React.useEffect(() => {
    if (data && data?.pageCurrent < data?.pageTotal && filteredData.length === 0) {
      loadMore();
    }
  }, [filteredData])

  const MemoisedCDTable = React.useMemo(() => PatTableCondensed, [expanded, patients, showPhantoms]);
  const MemoisedTable = React.useMemo(() => PatTable, [expanded, patients, showPhantoms]);
  
  return (
    <div className="site-status">
      {data ?
        <div>
          <div className="site-status__header">
            <Label size='h1'> {site?.siteLabel} Participant Tracker</Label>
            <Spacer value={20} />
          </div>
          <Grid container spacing={2} >
            <Grid sm={openPItem ? 8 : 12}>
              {Array.isArray(patients) && patients.length > 0 &&
                <div className='site-status__table-wrapper'>
                  <button type='button' className='icon-btn' tabIndex={-1} onClick={handleClick} >
                    <GiSettingsKnobs />
                  </button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <div role='button' className='site-status__settings' tabIndex={-1} onClick={handleClose}>
                      <FormGroup>
                        <FormControlLabel control={<Switch checked={expanded} onChange={toggleView} />} label='Expanded' />
                        <FormControlLabel control={<Switch checked={showPhantoms} onChange={togglePhantoms} />} label='Show Phantoms' />
                        <FormControlLabel control={<Switch checked={showMandatory} onChange={toggleShowMandatory} />} label='Show Non-Mandatory' />
                        <FormControlLabel control={<Switch checked={showImageAnalysis} onChange={toggleShowImageAnalysis} />} label='Show Image Analysis' />
                      </FormGroup>
                    </div>
                  </Popover>
                  {filteredData?.length > 0 ?
                    <div>
                      {expanded ?
                        <MemoisedTable patData={filteredData} />
                        :
                        <MemoisedCDTable patData={filteredData} />
                      }
                      <Spacer value={20} />
                      <Button loading={isLoadingData || isFetchingData} disabled={data.pageCurrent >= data.pageTotal} onClick={loadMore}>Load More</Button>
                    </div>
                    :
                    <div className='super-centered'>
                      <Spacer value={30} />
                      <Label size='h4' color='ghost'>Nothing to show</Label>
                    </div>
                  }
                </div>
              }
            </Grid>
            <Grid sm={openPItem ? 4 : 0}>
              {openPItem &&
                <Tile className='site-status__protocol-wrapper'>
                  <>
                  <div className='site-status__btn-row'>
                    <button className='icon-btn' onClick={closeProtocol}>
                      <AiOutlineClose size={16}/>
                    </button>
                  </div>
                  <ProtocolItemFactory
                    createChatRoom={() => console.info('chatroom init')}
                    reviewMode={false}
                    toggleReviewMode={() => console.info('toggleReviewMode')}
                    acl={'SPONSOR'}
                    item={openPItem}
                    path={''}
                    participant={'123'}
                    collapse={() => console.info('collapse')}
                    expand={() => console.info('expand')}
                    readOnly={true}
                    visitId='n/a'
                    secondaryId={'n/a'}
                    baselineMRI={'undefined'} />
                    </>
                </Tile>
              }
            </Grid>
          </Grid>
          <div className='tracker-guide'>
            <div className='tracker-guide__accepted' />
            <Label size='h6' color='success'>All Items Completed</Label>
            <div className='tracker-guide__started' />
            <Label color='primary' size='h6'>Started / Pending Review</Label>
            <div className='tracker-guide__rejected' />
            <Label color='warning' size='h6'>Rejected</Label>
            <div className='tracker-guide__deviation' />
            <Label color='danger' size='h6'>Deviation</Label>
            <div className='tracker-guide__incomplete' />
            <Label color='ghost' size='h6'>Incomplete / Not Initiated</Label>
          </div>
          <Spacer value={20} />
        </div>
        :
        <div className='w-100 h-100 super-centered'>
          <Spinner />
        </div>
      }

      <Modal title={openItem?.participantId ? openItem?.participantId : 'Participant Info'} open={modal} onClose={closeModal}>
        <div>
          {openItem?.userProtocol?.timePoints &&
            <div>
              <div className='d-flex'>
                <Button icon={BsPrinter} onClick={handlePrint}>Print </Button>
              </div>
              <Spacer value={10} />
              <div className='stepper' ref={componentRef}>
                {openItem?.userProtocol?.timePoints.map((visit: any, index: number) => (
                  <div key={visit.id}>
                    <Label size='h4' color='primary' weight={600}>{visit.visit}</Label>
                    <Spacer value={5} />
                    {visit.userProtocolItems.map((step: any, index: number) => (
                      <div key={step.id}>
                        <div className='stepper__label'>
                          {renderIcons(step)}
                          <Spacer value={10} />
                          <Label>{step.item}</Label>
                        </div>
                        <div className='stepper__wrapper'>
                          <div className='stepper__divider'>
                            <span className='stepper__description'>
                              {step.status}
                            </span>
                            <span className='stepper__description'>
                              Last Update {moment(step.updatedAt).format('l')}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                    <Spacer value={20} />
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
      </Modal>
    </div>
  );
};

export default withAuthenticator(SiteStatus);
