import React from 'react';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';



const PrivacyDashboard = () => {
  const somevar = 'PrivacyDashboard';
  return (
    <div className="privacy-dashboard">private {somevar}</div>
  );
};

export default withAuthenticator(PrivacyDashboard);
