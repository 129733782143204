/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export type IntlState = {
  lan: { code: string; label: string; flag: string; } | { code: 'en', label: 'English', flag: '🇺🇸' };
};

const initialState: IntlState = {
  lan: { code: 'en', label: 'English', flag: '🇺🇸' },
};

export const IntlSlice = createSlice({
  name: 'intl',
  initialState,
  reducers: {
    setIntl: (state, { payload }) => {
      state.lan = payload.lan;   
    },
  },
  extraReducers: (builder) => {},
});

// export default IntlSlice.reducer;

const intlReducer = IntlSlice.reducer;
export default intlReducer;

export const { setIntl } = IntlSlice.actions;

