/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  */
import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import './image-series-display.scss';
import { BsTrash3, BsDownload, BsFillEyeFill, BsArrowsAngleExpand, BsFileEarmarkRichtextFill, BsArrowsFullscreen, BsArrowsAngleContract } from 'react-icons/bs';
import { IoCloseOutline } from 'react-icons/io5';
import Grid from '@mui/material/Unstable_Grid2';
import { ImWarning } from 'react-icons/im';
import moment from 'moment';
import { Label, Tile, Alert, Text, Button, Spacer, Modal } from '../../atoms';
import { participantFilesApi, useDownLoadFileMutation, useGetProtocolSeriesQuery, useDownloadSeriesMutation, useRemoveImageMutation, useGetImgCountQuery, useCreateAlignmentJobMutation } from '../../../api/builders/participantFiles.api';
import DicomViewer from '../DicomViewer/DicomViewer';
import { useCreateLogMutation } from '../../../api/builders/logs.api'
import { FcOpenedFolder } from 'react-icons/fc';
import CustomProgress from '../../atoms/CustomProgress/CustomProgress';
import DicomViewer3D from '../DicomViewer3D/DicomViewer3D';

export interface ImageSeriesDisplayProps {
  protocolItem: ProtocolItem,
  message?: string,
  acl: string,
  isEdititng: boolean,
  handleReviewItem: (item: string, status: string) => void,
  path: string,
  participant: string | undefined;
}

type ProtocolItem = {
  file: string | undefined,
  id: string | undefined,
  item: string | undefined,
  label: string | undefined,
  messages: string | undefined,
  order: number,
  createdAt: number | undefined,
  updatedAt: number | undefined,
  role: string | undefined,
  status: string | undefined,
  surveyId: string | undefined,
  type: string | undefined,
  userId: string | undefined,
};

interface SerieInterface {
  id: string;
  SeriesInstanceUID: string;
}

interface StudyInterface {
  id: string;
  series: Array<SerieInterface> | null;
}

interface StudyFilesReducerStateInterface {
  tree: Array<StudyInterface> | [] | null;
  loading: boolean;
  loaded: boolean;
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const initstate = { tree: [], loading: false, loaded: false };
const inithistory: any[] | (() => any[]) = [];

const StudyFilesReducer = (state: StudyFilesReducerStateInterface, action: { type: string, payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE':
      if (Array.isArray(payload)) {
        const studies = payload.reduce((q: any, it: any) => {
          let temp = q.find((o: { studyInstanceUID: any; }) => o.studyInstanceUID === it.StudyInstanceUID);
          if (!temp) {
            const study = { series: [], studyDescription: it.StudyDescription, studyInstanceUID: it.StudyInstanceUID, studyDate: it.StudyDate, id: it.id, updatedAt: it.updatedAt, createdAt: it.createdAt, studyTime: it.studyTime };
            q.push(study);
            temp = study;
          }
          if (Array.isArray(it.ScanSeries)) {
            temp.series = it.ScanSeries.reduce((qs: any, its: any) => {
              const seriestemp = qs.find((o: { id: any; SeriesInstanceUID: any; }) => o.SeriesInstanceUID === its.SeriesInstanceUID);
              if (!seriestemp) {
                qs.push(its);
              }
              return qs;
            }, temp.series);
          }
          return q;
        }, []);
        // eslint-disable-next-line no-case-declarations
        const tempstate = { ...state };
        tempstate.tree = studies;
        return tempstate;
      }
      return state;


      break;
    default:
      return state;
  }
};

// eslint-disable-next-line react/jsx-props-no-spreading
// const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const ImageSeriesDisplay = (props: ImageSeriesDisplayProps): JSX.Element => {
  const { protocolItem, message, acl, handleReviewItem, path, participant } = props;
  const { id } = protocolItem;
  const [state, dispatch] = React.useReducer(StudyFilesReducer, initstate);
  const [snack, setSnack] = React.useState({ open: false, message: '' });
  const [addLog] = useCreateLogMutation();
  const [downloadlink, setLink] = React.useState('');

  const [getFile, info] = useDownloadSeriesMutation();
  const [deleteFile] = useRemoveImageMutation();
  const [requestAlignment, { isLoading: isAlignEditing, isError: isAlignError, isSuccess: isAlignSuccess, error: Adderror, }] = useCreateAlignmentJobMutation();
  const [download, downloadfileinfo] = useDownLoadFileMutation();
  const [preview, setPreview] = React.useState(false);
  const [fileToPreview, setFileToPreview] = React.useState<any>(null);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });
  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  React.useEffect(() => {
    if (isAlignError) {
      const apiError = Adderror as ApiError;
      setAlert({
        open: true,
        message: `Error Requesting Alignment: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isAlignError]);

  React.useEffect(() => {
    if (isAlignSuccess) {
      setAlert({
        open: true,
        message: 'Success Sending Alignment Request',
        kind: 'success',
      });
    }
  }, [isAlignSuccess]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack({ open: false, message: '' });
  };
  const { data: series, isLoading: isLoadingData, isFetching: isFetchingData } = useGetProtocolSeriesQuery(id !== null ? id : skipToken);
  const { data: previousFiles } = useGetImgCountQuery(path ? encodeURIComponent(path) : skipToken);
  const { refetch: refetchPrevious } =
    participantFilesApi.endpoints.getImgCount.useQuerySubscription(path ? encodeURIComponent(path) : skipToken);

  React.useEffect(() => {
    dispatch({ type: 'UPDATE', payload: series });
  }, [series]);

  React.useEffect(() => {
    if (path.length > 0) {
      refetchPrevious();
    }
  }, [id]);



  const scanDragStart = (event: any, serie: any) => {
    try {
      event.dataTransfer.setData('items', JSON.stringify(serie));
    } catch (e) {
      console.error(e);
    }
  };

  const logDownload = (item: any) => {
    try {
      const logPl = {
        itemId: id,
        itemLabel: item.id,
        actionType: 'Downloaded Image',
      };
      addLog(logPl);
    } catch (e) {
      console.error('CREATE LOG ER', e);
    }
  };

  const onDelete = (item: any) => {
    try {
      const delPl = {
        seriesId: item.id,
        protocolItemId: protocolItem.id,
      };
      deleteFile(delPl);
    } catch (e) {
      console.error('CREATE LOG ER', e);
    }
  };

  const onDownload = async (item: any) => {

    setSnack({ open: true, message: 'Preparing your file. Please wait.' });
    if (series && series[0]) {
      const downloadPl = {
        bucket: series[0].bucket,
        seriesId: item.id,
        archiveFilePath: `COLD/s3zip/${item?.SeriesDescription}.zip`,
        archiveFolderPath: item?.SeriesDescription,
        archiveFormat: 'zip',
      };
      const mydata = await getFile(downloadPl).unwrap();
      const newPath = `s3://${mydata.bucket}/${mydata.archiveFilePath}`;
      const downloadPayload = { files: [{ s3_path: newPath }] };
      const yourbrandnewfile = await download(downloadPayload).unwrap();
      setLink(yourbrandnewfile[0].signedUrl);
      const downloadobject = { label: item.SeriesDescription, link: yourbrandnewfile[0].signedUrl };
      setSnack({ open: true, message: 'File downloaded.' });
      const link = document.createElement('a');
      link.href = yourbrandnewfile[0].signedUrl;
      link.rel = 'noreferrer';
      document.body.appendChild(link);
      link.click();
      logDownload(item);
    }

  };

  const closePreview = () => {
    setFileToPreview(null);
    setPreview(false);
  };

  const onView = async (item: any) => {
    await closePreview();
    setFileToPreview(item);
    setPreview(true);
  };

  const extractPixelSpacing = (pixelSpacing: string): { xSpacing: number; ySpacing: number } | null => {
    const pattern = /(\d+\.\d+)/g;
    const matches = pixelSpacing.match(pattern);

    if (matches && matches.length >= 2) {
      const xSpacing = parseFloat(matches[0]);
      const ySpacing = parseFloat(matches[1]);

      return { xSpacing, ySpacing };
    }

    return null;
  };

  const handleRequestAlignment = (img: any) => {
    try {
      const alignmentPl = {
        seriesId: img,
        userProtocolItemId: id,
        participantId: participant ?? 'n/a',
      }
      // requestAlignment(alignmentPl);
    } catch (e) {
      console.error('could not handle request alignmnt');
    }
  };

  const Study = (studyProp: any): JSX.Element => {
    const { item, acl, condensed } = studyProp;
    const { series } = item;

    const determinIsDraggable = () => {
      if (acl === 'admin' || acl === 'SPONSOR (BLINDED' || acl === 'CORELAB' || acl === 'SITE COORDINATOR') {
        if (protocolItem.status !== 'accepted') {
          return true;
          // if (protocolItem?.item?.toLowerCase() === 'additional scans') {
          //   return true
          // } else if (protocolItem?.item?.toLowerCase() === 'drop all scans') {
          //   return true
          // } else if (protocolItem?.item?.toLowerCase() === 'all scans') {
          //   return true
          // }
        }
      }
    };

    const Serie = (serieProp: any): JSX.Element => {
      const { item: serie } = serieProp;
      const { id: serieId, alignment, SeriesDescription, Modality, SpacingBetweenSlices, SliceThickness, PixelSpacing, qc_score } = serie;

      return (
        <div
          className="image-series-display--serie-wrapper"
          id={id}
          draggable={determinIsDraggable()}
          onDragStart={(e) => scanDragStart(e, serie)}>
          <div
            className="image-series-display--serie-header">
            <Label weight={condensed ? 500 : 600} size={condensed ? 'h7' : 'h6'}>{' '}{Modality}{' - '}{SeriesDescription}</Label>
            <Spacer value={5} />
            {qc_score !== 'null' && <Label weight={condensed ? 500 : 600} size={condensed ? 'h7' : 'h6'}> Quality:
              {Array.from({ length: qc_score }, (_, index) => (
                <span> &#9733; </span>
              ))}
            </Label>}

          </div>
          {!fileToPreview && <div className="image-series-display--serie-info">

            {!condensed && <div>
              <Label weight={600} size="h6">Space Between Slices: {SpacingBetweenSlices ? SpacingBetweenSlices : 'N/A'}</Label>
              {/* <Label weight={600} size="h6">Slice Thickness: {SliceThickness ? SliceThickness : 'N/A'}</Label> */}
              <Label weight={600} size="h6">
                {/* Pixel Spacing: {PixelSpacing ? PixelSpacing : 'N/A'} */}
                {PixelSpacing ?
                  <span>Vox {extractPixelSpacing(PixelSpacing)?.xSpacing.toFixed(2)} x {extractPixelSpacing(PixelSpacing)?.ySpacing.toFixed(2)} x {parseFloat(SliceThickness).toFixed(2)} mm</span>
                  : 'N/A'}
              </Label>
            </div>}
          </div>}
          {!condensed && <div className="image-series-display--serie-alignment">
            {alignment ?
              <span className='image-series-display--aligned'>
                <Label weight={600} size="h6" uppercase >Aligned with Ref.</Label>
              </span>
              :
              <Button onClick={() => handleRequestAlignment(id)} className='image-series-display__button'><Label weight={600} size="h6" uppercase >Request Alignment</Label></Button>
            }
          </div>}
          <div className={fileToPreview ? "image-series-display--serie-actions-stacked" : "image-series-display--serie-actions"}>
            {(!condensed && protocolItem.status !== 'approved') && <button onClick={() => onDelete(serie)} className='icon-btn'><BsTrash3 /></button>}
            <button onClick={() => onDownload(serie)} className='icon-btn'><BsDownload /></button>
            <button onClick={() => onView(serie)} className='icon-btn'><BsFillEyeFill /></button>
          </div>
        </div >
      );
    };

    return (
      <div className="image-series-display__browser">
        <div className="image-series-display--header">
          {!condensed && <FcOpenedFolder />}
          {!condensed ?
            <Text>{moment(item?.studyDate).format('LLL')}  -  {item.studyDescription}</Text> :
            <div className='image-series-display--study_title'>
              <Text>{moment(item?.studyDate).format('L')}</Text>
              <Text>{item.studyDescription}</Text>
            </div>
          }
        </div>
        <div className="image-series-display--series-wrapper">
          {series?.map((serie: any) => <Serie item={serie} condensed={condensed} />)}
        </div>
      </div>
    );
  };

  const handleReviewStatus = (itemId: any, newStatus: string) => {
    handleReviewItem(itemId, newStatus);
  };

  const ReviewActions = ({ access, itemId }: any): JSX.Element => {
    switch (access) {
      case 'CORELAB':
      case 'admin':
        return (<div className='btn-row'>
          <Button onClick={() => handleReviewStatus(itemId, 'rejected')} kind='danger'>Reject</Button>
          <Button onClick={() => handleReviewStatus(itemId, 'approved')} >Approve</Button>
        </div>);
      default:
        return (
          <div />
        );
    }
  };
  const RenderActions = ({ itemStatus, access, itemId }: any): JSX.Element => {
    switch (itemStatus) {
      case 'pending review':
        // return (<div />);
        return (<ReviewActions access={access} itemId={itemId} />);
      default:
        return (
          <div />
        );
    }
  };


  return (
    <div className="image-series-display">
      {downloadlink && <div>Your file is ready, click here to download: <a href={downloadlink} target="__blank">download file </a></div>}
      {isLoadingData && <div className="progress"><CustomProgress className="custom" /></div>}
      {series && series?.length > 0 &&
        <div className={`image-series-display__wrapper ${preview ? 'preview' : ''}`}>
          <div className={`image-series-display__panel ${preview ? 'preview' : ''} left ${fullScreen ? 'fullScreen' : ''}`}>
            {fileToPreview &&
              <div className='image-series-display__dicom-wrapper'>
                <div className='image-series-display__dicom-wrapper__btn-wrapper'>
                  <Label weight={600} size="h6">{' '}{fileToPreview.Modality}{' - '}{fileToPreview.SeriesDescription}</Label>
                  <div>
                    <button onClick={() => setFullScreen(!fullScreen)} className='icon-btn'>{fullScreen ? <BsArrowsAngleContract /> : <BsArrowsFullscreen />}</button>
                    <button onClick={closePreview} className='icon-btn'><IoCloseOutline /></button>
                  </div>
                </div>
                <div className='image-series-display__dicom-wrapper__viewer-wrapper'>
                  <DicomViewer series={fileToPreview} viewerId={'dicomViewer'} prefix={protocolItem.id} className={`image-series-display__dicom-wrapper__viewer ${fullScreen ? 'fullScreen' : ''}`} />
                </div>
                <div className='image-series-display__dicom-wrapper__viewer-wrapper'>
                  <DicomViewer3D series={fileToPreview} viewerId={'dicomViewer'} prefix={protocolItem.id} className={`image-series-display__dicom-wrapper__viewer ${fullScreen ? 'fullScreen' : ''}`} />
                </div>
              </div>
            }
          </div>
          <div className={`image-series-display__panel ${preview ? 'preview' : ''} right ${fullScreen ? 'fullScreen' : ''}`}>
            {state.tree?.map((it: any) => <Study item={it} acl={acl} condensed={preview} />)}
          </div>
        </div>

      }
      {((!series || series?.length <= 0) && !isLoadingData) &&
        <div className='super-centered'>
          <Spacer value={30} />
          {previousFiles && previousFiles.count > 0 ?
            <Label size='h4' color='warning' center><ImWarning className='warinig-icon' />{`${previousFiles.count} files previously uploaded. Your files are being decoded and getting organized. please come back later.`}</Label>
            :
            <Label size='h4' color='ghost' center>No Previous Uploads</Label>
          }
        </div>
      }

      {message && <Label size='h4'>Your files are being decoded and getting organized. please come back later.</Label>}
      <Alert onClose={handleClose} severity="info" open={snack.open} message=' The file is getting prepared and will start downloading shortly.' />
      <Alert onClose={handleClose} severity="error" open={info.isError} message='Error Downloading File' />
      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div >
  );
};

ImageSeriesDisplay.defaultProps = {
  message: '',
};

ImageSeriesDisplay.displayName = 'ImageSeriesDisplay';
export default ImageSeriesDisplay;