import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { BiRefresh } from 'react-icons/bi';
import { LuRefreshCcw, LuPlusCircle } from 'react-icons/lu';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, Button, Spacer, Dropdown, DeIdentifiedPatient, LogItem, Modal, CohortUserItem } from '../../../components';
import { useGetAllLogsQuery, logsApis } from '../../../api/builders/logs.api';



const Logs = () => {
  const somevar = 'Logs';
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetAllLogsQuery('');
  const { refetch: refetchPats } =
    logsApis.endpoints.getAllLogs.useQuerySubscription('');


  return (
    <div className='logs'>
      <div className='study__heading d-flex-row '>
        <Label size="h2" weight={300} >All Logs</Label>
        <button type='button' className='icon-btn' aria-label='refetch' onClick={refetchPats}>
          <BiRefresh />
        </button>
      </div>
      <Grid container spacing={2}>
        <Grid xs={3}>
          <Label center size='subtext'>User</Label>
        </Grid>
        <Grid xs={3}>
          <Label center size='subtext'> Date </Label>
        </Grid>
        <Grid xs={3}>
          <Label center size='subtext'>Item</Label>
        </Grid>
        <Grid xs={3}>
          <Label center size='subtext'>Action</Label>
        </Grid>
      </Grid>
      {Array.isArray(data) && data.map((item: any) => (
        <LogItem
          user={item.user.email}
          item={item.itemLabel}
          action={item.actionType}
          date={item.createdAt}
        />
      ))}
    </div>);
};

export default Logs;
