import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import classnames from 'classnames';
import './protocol-browser.scss';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Label, Tile } from '../../atoms';
import ProtocolTimePoint, { IProtocolTimePoint } from '../ProtocolTimePoint/ProtocolTimePoint';
import ProtocolVisitItem from '../ProtocolVisitItem/ProtocolVisitItem';
import { IProtocolItemType } from '../ProtocolItem/ProtocolItem';
import ProtocolVisitCriteriaItem from '../ProtocolVisitCriteriaItem/ProtocolVisitCriteriaItem';

export interface ProtocolBrowserProps {
  // Define the props for the ProtocolBrowser component
  id: string | null;
  className?: string;
  acl: string | undefined;
  protocol: IProtocolTimePoint[] | undefined;
  onSelect: (arg0: IProtocolItemType) => void;
  onChangeVisit: (arg0: any) => void;
  // onChangeItem: (arg0: any) => void;
  prefix: string;
}

interface ProtocolBrowserState {
  visitIndex: number,
  itemIndex: number,
  minifiedPath: string,
  fullPath: string,
  item: IProtocolItemType | undefined,
  criteria: IProtocolItemType[] | [],
}
const initialState = {
  visitIndex: 0,
  itemIndex: 0,
  minifiedPath: '',
  fullPath: '',
  item: undefined,
  criteria: [],
};

const sort = (items: IProtocolItemType[]) => {
  try {
    const test = JSON.parse(JSON.stringify(items));
    // test.sort((a: IProtocolItemType, b: IProtocolItemType) => a.order - b.order);
    // return test;
    const typeOrder = [
      'radiology',
      'document',
      'file',
      'treatment plan',
      'survey',
      'image analysis',
      'CRF',
      'met report',
      'assessment',
    ];

    test.sort((a: any, b: any) => {
      const typeA = typeOrder.indexOf(a.type.toLowerCase());
      const typeB = typeOrder.indexOf(b.type.toLowerCase());
      return typeA - typeB;
    });

    return test;
  } catch (e) {
    console.error('err sorting', e);
    return items;
  }
};

const sortAndFilter = (items: IProtocolItemType[], itemType: string) => {
  try {
    const test = JSON.parse(JSON.stringify(items));
    // test.sort((a: IProtocolItemType, b: IProtocolItemType) => a.order - b.order);
    const filtered = test.filter((i: IProtocolItemType) => i.type === itemType);
    return filtered;
  } catch {
    console.error('err sorting');
    return items;
  }
};
interface TimepointsPanelProps {
  protocol: IProtocolTimePoint[];
  state: {
    visitIndex: number;
  };
  onChangeTimePoint: (item: IProtocolTimePoint, index: number) => void;
  acl: string;
  isCondensed: boolean;
}

const TimepointsPanel = memo(({ protocol, state, onChangeTimePoint, acl, isCondensed }: TimepointsPanelProps) => (
    <div className={`timepoints-panel ${isCondensed ? 'condensed' : ''}`}>
      {protocol &&
        protocol.length > 0 &&
        protocol.map((item: IProtocolTimePoint, index) => (
          <ProtocolVisitItem
            item={item}
            key={item.id}
            active={state.visitIndex === index}
            current={item?.iscurrent || false}
            index={index}
            onChangeItem={onChangeTimePoint}
            handleChangeDate={() => console.info('onchange')}
            readOnly={false}
            acl={acl}
            condensed={isCondensed}
          />
        ))}
    </div>
));

interface TimepointControlPanelProps {
  onUpdateStatus: (_status: any) => void;
  acl: string;
  isCondensed: boolean;
  items: IProtocolItemType[] | [],
}

const TimepointControlPanel = memo(({ acl, onUpdateStatus, isCondensed, items }: TimepointControlPanelProps) => (
    <div className={`timepoints-control-panel ${isCondensed ? 'condensed' : ''}`}>
      add controls, participant status
    </div>
));

const ProtocolBrowser = (props: ProtocolBrowserProps): JSX.Element => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { id, className, protocol, acl, onSelect, prefix, onChangeVisit } = props;
  const [isCondensed, setCondensed] = useState<boolean>(false);
  const [state, setState] = useState<ProtocolBrowserState>(initialState);

 

  const toggleCondense = (): void => {
    setCondensed(!isCondensed);
  };

  const classes = classnames(className, 'protocol-browser', isCondensed ? ' condensed' : '');

  useEffect(
    () =>{
    // Perform any initialization or API calls here
      if (protocol && protocol[0] && protocol![0].userProtocolItems && protocol[0].userProtocolItems[0]) { 
        const tmp = sort(protocol[0].userProtocolItems);
        const tmpPath = `${prefix}participants/${protocol[state.visitIndex].visit}/${tmp[state.itemIndex].item}`;
        setState({
          ...state,
          fullPath: tmpPath,
          item: tmp[state.itemIndex],
          criteria: sort(protocol[state.visitIndex].userProtocolItems),
        });
        
        // setState((prev) => ({
        //   ...prev,
        //   visitIndex: 0,
        //   // fullPath: tmpPath,
        //   itemIndex: 0,
        //   item: protocol![0].userProtocolItems[0],
        //   criteria: sort(protocol![0].userProtocolItems),
        // }));
      }
    },
    // () => {
    //   // Cleanup any resources or subscriptions here
    //   // Equivalent to componentWillUnmount
    // }
    [protocol],
  );
  const handleChangeTimePoint = (el: IProtocolTimePoint, i: number) => {
    // setIsUploading(false);
    const tmpPath = `${prefix}/${el.visit}/${el.userProtocolItems[0].item}`;
    const newState = { visitIndex: i,
      fullPath: tmpPath,
      itemIndex: 0,
      item: el.userProtocolItems![0],
      criteria: sort(el.userProtocolItems) };

    setState((prev) => ({
      ...prev,
      ...newState,
    }));
    onChangeVisit(newState);
  };
  
  const handleChangeItem = (el: IProtocolItemType, i: number) => {
    // setIsUploading(false);
    // handlePanelToggle(1);
    const newIndex = state.criteria.findIndex(item => item.id === el.id);
    const tmpPath = `${prefix}/${protocol![0].visit}/${el.item}`;
    setState((prev) => ({
      ...prev,
      itemIndex: newIndex,
      fullPath: tmpPath,
      item: el,
    }));
    onSelect(el);
  };

  return (
    <Tile key={`ProtocolBrowser_${id}`} className={classes}>
      {/* <Tile key={`ProtocolBrowser_${id}`} className={`${className} ${' '} protocol-browser ${isCondensed ? 'condensed' : ''}`}> */}
      <>
        <div className="header">
          <div className="headerbar">
            <Label uppercase size="h3" weight={600}>
              Study Protocol
            </Label>
            {isCondensed ? (
              <button className="icon-btn" type="button" onClick={toggleCondense}>
                {' '}
                <MdArrowForwardIos />
              </button>
            ) : (
              <button className="icon-btn" type="button" onClick={toggleCondense}>
                {' '}
                <MdArrowBackIosNew />
              </button>
            )}
          </div>
        </div>
        <div className="body">
          <TimepointsPanel
            protocol={protocol!}
            state={state}
            onChangeTimePoint={handleChangeTimePoint}
            acl={acl!}
            isCondensed={isCondensed}
          />

          <div className={`reqs-panel ${isCondensed ? ' condensed' : ''}`}>
            {state.criteria && state.criteria.length > 0 &&
                      <div>
                        {state.criteria.some(item => item.type === 'radiology') &&
                          <div>
                            <div className='participant__protocol-scroll-section-header'>
                              <Label uppercase size="h6" color='primary'>
                                {t('Radiology')}
                              </Label>
                            </div>
                            {sortAndFilter(state.criteria, 'radiology').map((item: IProtocolItemType, index: number) => (
                              <ProtocolVisitCriteriaItem
                                item={item}
                                key={item.id}
                                active={state?.itemIndex !== -1 && state?.item ? state?.item?.id === item.id : false}
                                index={index}
                                onChangeItem={handleChangeItem}
                                isCondensed={isCondensed}
                              />
                            ))}
                          </div>
                        }
                        {state.criteria.some(item => item.type === 'document') &&
                          <div>
                            <div className='participant__protocol-scroll-section-header'>
                              <Label uppercase size="h6" color='primary'>
                                {t('Documents')}
                              </Label>
                            </div>
                            {sortAndFilter(state.criteria, 'document').map((item: IProtocolItemType, index: number) => (
                              <ProtocolVisitCriteriaItem
                                // isUploading={isUploading}
                                item={item}
                                key={item.id}
                                active={state?.itemIndex !== -1 && state?.item ? state?.item?.id === item.id : false}
                                index={index}
                                onChangeItem={handleChangeItem}
                                isCondensed={isCondensed}
                              />
                            ))}
                            {sortAndFilter(state.criteria, 'file').map((item: IProtocolItemType, index: number) => (
                              <ProtocolVisitCriteriaItem
                                // isUploading={isUploading}
                                item={item}
                                key={item.id}
                                active={state?.itemIndex !== -1 && state?.item ? state?.item?.id === item.id : false}
                                index={index}
                                onChangeItem={handleChangeItem}
                                isCondensed={isCondensed}
                              />
                            ))}
                          </div>
                        }
                        {state.criteria.some(item => item.type === 'treatment plan') &&
                          <div>
                            <div className='participant__protocol-scroll-section-header'>
                              <Label uppercase size="h6" color='primary'>
                                Planning
                              </Label>
                            </div>
                            {sortAndFilter(state.criteria, 'treatment plan').map((item: IProtocolItemType, index: number) => (
                              <ProtocolVisitCriteriaItem
                                // isUploading={isUploading}
                                item={item}
                                key={item.id}
                                active={state?.itemIndex !== -1 && state?.item ? state?.item?.id === item.id : false}
                                index={index}
                                onChangeItem={handleChangeItem}
                                isCondensed={isCondensed}
                              />
                            ))}
                          </div>
                        }
                        {state.criteria.some(item => item.type === 'survey') &&
                          <div>
                            <div className='participant__protocol-scroll-section-header'>
                              <Label uppercase size="h6" color='primary' >
                                {t('Surveys')}
                              </Label>
                            </div>
                            {sortAndFilter(state.criteria, 'survey').map((item: IProtocolItemType, index: number) => (
                              <ProtocolVisitCriteriaItem
                                // isUploading={isUploading}
                                item={item}
                                key={item.id}
                                active={state?.itemIndex !== -1 && state?.item ? state?.item?.id === item.id : false}
                                index={index}
                                onChangeItem={handleChangeItem}
                                isCondensed={isCondensed}
                              />
                            ))}
                          </div>
                        }
                        {state.criteria.some(item => item.type === 'image analysis') &&
                          <div>
                            <div className='participant__protocol-scroll-section-header'>
                              <Label uppercase size="h6" color='primary'>
                                {t('Imaging Analysis')}
                              </Label>
                            </div>
                            {sortAndFilter(state.criteria, 'image analysis').map((item: IProtocolItemType, index: number) => (
                              <ProtocolVisitCriteriaItem
                                // isUploading={isUploading}
                                item={item}
                                key={item.id}
                                active={state?.itemIndex !== -1 && state?.item ? state?.item?.id === item.id : false}
                                index={index}
                                onChangeItem={handleChangeItem}
                                isCondensed={isCondensed}
                              />
                            ))}
                          </div>
                        }
                        {state.criteria.some(item => item.type === 'CRF') &&
                          <div>
                            <div className='participant__protocol-scroll-section-header'>
                              <Label uppercase size="h6" color='primary'>
                                CRF
                              </Label>
                            </div>
                            {sortAndFilter(state.criteria, 'CRF').map((item: IProtocolItemType, index: number) => (
                              <ProtocolVisitCriteriaItem
                                item={item}
                                key={item.id}
                                active={state?.itemIndex !== -1 && state?.item ? state?.item?.id === item.id : false}
                                index={index}
                                onChangeItem={handleChangeItem}
                                isCondensed={isCondensed}
                              />
                            ))}
                          </div>
                        }
                        {(state.criteria.some(item => item.type === 'met report') || state.criteria.some(item => item.type === 'assessment')) &&
                          <div>
                            <div className='participant__protocol-scroll-section-header'>
                              <Label uppercase size="h6" color='primary'>
                                Assesments
                              </Label>
                            </div>
                            {sortAndFilter(state.criteria, 'met report').map((item: IProtocolItemType, index: number) => (
                              <ProtocolVisitCriteriaItem
                                item={item}
                                key={item.id}
                                active={state?.itemIndex !== -1 && state?.item ? state?.item?.id === item.id : false}
                                index={index}
                                onChangeItem={handleChangeItem}
                                isCondensed={isCondensed}
                              />
                            ))}
                            {sortAndFilter(state.criteria, 'assessment').map((item: IProtocolItemType, index: number) => (
                              <ProtocolVisitCriteriaItem
                                item={item}
                                key={item.id}
                                active={state?.itemIndex !== -1 && state?.item ? state?.item?.id === item.id : false}
                                index={index}
                                onChangeItem={handleChangeItem}
                                isCondensed={isCondensed}
                              />
                            ))}
                          </div>
                        }
                      </div>
                    }
          </div>
        </div>
        {/* <div className="footer">footer</div> */}
      </>
    </Tile>
  );
};

ProtocolBrowser.defaultProps = {
  className: '',
};

ProtocolBrowser.displayName = 'ProtocolBrowser';

const MemoizedProtocolBrowser = React.memo(ProtocolBrowser);
export default ProtocolBrowser;
