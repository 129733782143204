import React from 'react';
import { useNavigate } from 'react-router-dom';
// import classnames from 'classnames';
import './site-list-item.scss';
import Grid from '@mui/material/Grid';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tile, Label, Badge, ShieldIcon } from '../../atoms';
import { usePrefetch as usePrefetchSite } from '../../../pages/private/Site/api';

export interface SiteListItemProps {
  site: SiteItem,
  study: StudyItemProps
}

type StudyItemProps = {
  studyId?: string | undefined;
  summary?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  acl?: string | undefined;
};


type SiteItem = {
  id: string | undefined;
  name: string | undefined;
  region: string | undefined;
  siteId: string | undefined;
  siteLabel: string | undefined;
  status: string | undefined;
  privacy?: string | undefined;
};

const SiteListItem = ({ site, study }: SiteListItemProps) => {
  const { id, siteLabel, status, region, privacy } = site;
  const navigate = useNavigate();
  const prefetchSite = usePrefetchSite('getSiteInfo');

  const onOpen = (_item: SiteItem) => {
    navigate('/private/studies/study/site', { state: { site: _item, study } });
  };

  const getColor = (_status: string | undefined) => {
    if (_status) {
      switch (_status.toLowerCase()) {
        case 'pending start':
          return 'blue';
        case 'training':
          return 'yellow';
        case 'recruiting':
          return 'green';
        case 'closed':
          return 'red';
        default:
          return 'blue';
      }
    } else {
      return 'unknown';
    }
  };
  const onHover = (hoveritem: any) => {
    prefetchSite({ siteId: hoveritem?.id ? hoveritem.id : skipToken }, { force: true });
  };
  return (
    <div className='site-list-item' key={id} role="button" tabIndex={0} onClick={() => onOpen(site)} >
      <Tile onHover={()=>onHover(site)} className='site-list-item__tile'>
        {/* <div className='site-list-item__grid'>
            <Label size="h5">{siteLabel}</Label>
          <div>
            <div className='site-list-item__status-pill' >
              <Label size="h6" weight={700} color='white' uppercase >{status}</Label>
            </div>
          </div>
        </div> */}
        <Grid container spacing={5}>
          <Grid item sm={4} >
            <Label center size="h5">{siteLabel}</Label>
          </Grid>
          <Grid item  sm={4}  >
            <Label center size="h6">{region?.toUpperCase()}</Label>
          </Grid>
          <Grid item sm={3}>
            <div className='site-list-item__status' >
            <Badge color={getColor(status)} label={status} />
            </div>
          </Grid>
          <Grid item sm={1}>
            <div className='site-list-item__shield' >
              {privacy === 'broken' && <ShieldIcon link='compliance' type="broken" size={48} /> }
              {privacy !== 'shield' && <ShieldIcon link='compliance' type="shield" size={48} /> }
            </div>
          </Grid>
        </Grid>
      </Tile>
    </div>
  );
};

SiteListItem.defaultProps = {
  // key: '_key',
};

SiteListItem.displayName = 'SiteListItem';


export default SiteListItem;
