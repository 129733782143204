/* eslint-disable import/no-cycle */
import React from 'react';
// import classnames from 'classnames';
import './protocol-item.scss';
import { FaRegFlag, FaFlag } from 'react-icons/fa';
import { LuAlignCenterHorizontal } from 'react-icons/lu';
import { Tile, Label, Spacer, Text, Modal, Button } from '../../atoms';
import DropZone from '../DropZone/DropZone';
import ProtocolFileDisplay from '../ProtocolFileDisplay/ProtocolFileDisplay';
import ImageSeriesDisplay from '../ImageSeriesDisplay/ImageSeriesDisplay';
import ReadOnlyMetReview from '../ReadOnlyMetReview/ReadOnlyMetReview';
import MessageFactory from '../MessageFactory/MessageFactory';
import TreatmentPlan from '../TreatmentPlan/TreatmentPlan';
import DicomUploader from '../DicomUploader/DicomUploader';
import DocumentsDrive from '../DocumentsDrive/DocumentsDrive';
import DocumentUploader from '../DocumentUploader/DocumentUploader';
import ProtocolActionBar from '../ProtocolActionBar/ProtocolActionBar';
import ProtocolSurvey from '../ProtocolSurvey/ProtocolSurvey';
import ProtocolLogs from '../ProtocolLogs/ProtocolLogs';
import CRFManager from '../CRFManager/CRFManager';
import ProtocolImageAnalysis from '../ProtocolImageAnalysis/ProtocolImageAnalysis';
import { useEditProtocolMutation } from '../../../api/builders/participantProtocol.api';
import MetManager from '../MetManager/MetManager';


export type ProtocolItemType = {
  file: string | undefined;
  id: string | undefined;
  item: string | undefined;
  label: string | undefined;
  messages: string | undefined;
  order: number;
  createdAt: number | undefined;
  updatedAt: number | undefined;
  role: string | undefined;
  status: string | undefined;
  surveyId: string | undefined;
  type: string | undefined;
  userId: string | undefined;
  deviation: boolean | null;
  alignmentReference: boolean | null | undefined;
};

type Props = {
  item: ProtocolItemType;
  path: string;
  participant: string | undefined;
  collapse: () => void;
  expand: () => void;
  readOnly: boolean;
  isBlinded?: boolean;
  reviewMode: boolean;
  acl: string,
  toggleReviewMode: () => void,
  createChatRoom: () => void,
  visitId: string | undefined,
  baselineMRI: any,
  info?: any,
  secondaryId: string;
  // setIsUploading?: (p: boolean) => void,
};

type EditPayload = {
  itemId: string;
  itemStatus: string;
};

const getColor = (status: string) => {
  switch (status) {
    case 'incomplete':
      return 'orange-text';
    case 'completed':
      return 'green-text';
    case 'accepted':
      return 'green-text';
    case 'approved':
      return 'green-text';
    case 'pending review':
      return 'blue-text';
    case 'pending':
      return 'blue-text';
    case 'missing':
      return 'orange-text';
    case 'rejected':
      return 'red-text';
    default:
      return '';
  }
};

const getRoleTest = (role: string) => {
  switch (role) {
    case 'CLR1':
      return 'Reviewer A';
    case 'CLR2':
      return 'Reviewer B';
    case 'CLTR':
      return 'Tie-Breaker';
    default:
      return 'Reviewer';
  }
};

const ProtocolItemFactory = (props: Props) => {
  const { item, info, path, participant, collapse, expand, readOnly, acl, toggleReviewMode, reviewMode, createChatRoom, visitId, baselineMRI, secondaryId } = props;
  const [changeStatus, { isLoading: isEdititng }] = useEditProtocolMutation();
  const [statusModal, setStatusModal] = React.useState(false);
  const [editPl, setEditPl] = React.useState<EditPayload | null>(null);

  const handleCloseModal = () => {
    setEditPl(null);
    setStatusModal(false);
  };

  const handleChangeStatus = (itemId: string, newStatus: string) => {
    try {
      const pL = {
        itemId,
        itemStatus: newStatus,
      };
      setEditPl(pL);
      setStatusModal(true);
    } catch {
      console.error('er');
    }
  };


  const handleChangeMetStats = (itemId: string, status: string, steroid: string) => {
    try {
      const pL = {
        id: participant,
        body: {
          itemId,
          clinicalStatus: status,
          corticosteroidUse: steroid,
          itemStatus: item.status,
        },
      };
      changeStatus(pL);
    } catch {
      console.error('er');
    }
  };

  const confirmStatus = async () => {
    try {
      const editPayload = {
        id: participant,
        body: {
          ...editPl,
        },
      };
      // console.log('EDIT NO MSG', editPl);
      await changeStatus(editPayload);
      handleCloseModal();
      // }
    } catch {
      console.warn('error');
    }
  };

  const handleUpdateTreatment = async (itemId: string, updates: any, status: string) => {
    try {
      const reviewPl = {
        id: participant,
        body: {
          itemId,
          itemStatus: status,
          itemContent: JSON.stringify(updates),
        },
      };
      // console.log('reviewPl', reviewPl);
      await changeStatus(reviewPl);
    } catch {
      console.error('reviewPl er');
    }
  };


  const handleInitiate = async (itemId: string, newStatus: string) => {
    try {
      const reviewPl = {
        id: participant,
        body: {
          itemId,
          itemStatus: newStatus,
          secondaryId,
          email: true,
          userRole: item.role,
        },
      };
      await changeStatus(reviewPl);
    } catch {
      console.error('er');
    }
  };
  const handleReviewItem = async (itemId: string, newStatus: string) => {
    try {
      const reviewPl = {
        id: participant,
        body: {
          itemId,
          itemStatus: newStatus,
        },
      };
      // console.log('reviewPl', reviewPl);
      await changeStatus(reviewPl);
    } catch {
      console.error('er');
    }
  };


  const ProtocolEditItem = ({ protocolItem, type, access }: any): JSX.Element => {
    switch (type) {
      case 'radiology':
        return (
          <div className='w-100'>
            <DicomUploader
              // setIsUploading={setIsUploading}
              active={false}
              acl={acl}
              path={path}
              itemId={protocolItem.id}
              itemStatus={protocolItem.item}
              handleChangeStatus={handleChangeStatus}
            />
            <ImageSeriesDisplay
              path={path}
              handleReviewItem={handleReviewItem} protocolItem={protocolItem} acl={acl} isEdititng={isEdititng} 
              participant={participant}
              />
            {/* {acl === 'admin' &&
              <div className='w-100 super-centered'>
                <Button kind='warning' loading={isEdititng} onClick={() => handleReviewItem(protocolItem.id, 'missing')}>Alert Missing Requirements</Button>
              </div>
            } */}
          </div>
        );
      case 'document':
        return (
          <div>
            <DocumentUploader
              active={false}
              acl={acl}
              path={path}
              itemId={protocolItem.id}
              itemStatus={protocolItem.item}
              handleChangeStatus={handleChangeStatus}
            />
            <DocumentsDrive protocolId={protocolItem.id} />
          </div>
        );
      case 'treatment plan':
        return <TreatmentPlan isEdititng={isEdititng} id={protocolItem.id} item={protocolItem} handleUpdateTreatment={handleUpdateTreatment} itemStatus={protocolItem.status}
        />;
      case 'CRF':
        return <CRFManager visitId={visitId} path={path} info={info} id={protocolItem.id} item={protocolItem} itemStatus={protocolItem.status} participant={participant} />;
      case 'audio':
        return <DropZone key="hey" />;
      case 'survey':
        return <ProtocolSurvey               acl={acl}
        path={path} info={info} protocolItem={protocolItem} participant={participant} />;
      case 'image analysis':
        return <ProtocolImageAnalysis protocolItem={protocolItem} participant={participant} />;
      case 'assessment':
        return (
          <>
            <MetManager
              participant={participant}
              itemId={protocolItem.id}
              collapse={collapse}
              expand={expand}
              itemStatus={protocolItem.status}
              role={protocolItem.role}
              handleChangeStatus={handleChangeStatus}
              acl={acl}
              reviewMode={reviewMode}
              toggleReviewMode={toggleReviewMode}
              handleChangeMetStats={handleChangeMetStats}
              clinicalStatus={protocolItem?.clinicalStatus ? protocolItem.clinicalStatus : 'unknown'}
              corticosteroidUse={protocolItem?.corticosteroidUse ? protocolItem.corticosteroidUse : 'unknown'}
              visitId={visitId}
              baselineMRI={baselineMRI}
              path={path}
              protocolItem={protocolItem}
              info={info}
            />
            {/* {
              acl === 'admin' &&
              <div className='w-100 super-centered'>
                <Button loading={isEdititng} kind='warning' onClick={() => handleReviewItem(protocolItem.id, 'missing')}>Alert Missing Requirements</Button>
              </div>
            } */}
          </>
        );
      case 'met report':
        return (
          <>
            <MetManager
              participant={participant}
              itemId={protocolItem.id}
              collapse={collapse}
              expand={expand}
              itemStatus={protocolItem.status}
              role={protocolItem.role}
              handleChangeStatus={handleChangeStatus}
              acl={acl}
              reviewMode={reviewMode}
              toggleReviewMode={toggleReviewMode}
              handleChangeMetStats={handleChangeMetStats}
              clinicalStatus={protocolItem?.clinicalStatus ? protocolItem.clinicalStatus : 'unknown'}
              corticosteroidUse={protocolItem?.corticosteroidUse ? protocolItem.corticosteroidUse : 'unknown'}
              visitId={visitId}
              baselineMRI={baselineMRI}
              path={path}
              protocolItem={protocolItem}
              info={info}
            />
            {/* {
              acl === 'admin' &&
              <div className='w-100 super-centered'>
                <Tile>
                  <div className='d-flex'>
                    <Button loading={isEdititng} kind='warning' onClick={() => handleReviewItem(protocolItem.id, 'missing')}>Alert Missing Requirements</Button>
                    <Button loading={isEdititng} onClick={() => handleDeviation(protocolItem.id, protocolItem.status)} >Mark as deviation</Button>
                  </div>
                </Tile>
              </div>
            } */}
          </>
        );
      default:
        return (
          <div className="super-centered">
            <Text>Error Loading Item</Text>
          </div>
        );
    }
  };

  const ProtocolMissingItem = ({ protocolItem, type, access }: any): JSX.Element => {
    switch (type) {
      case 'radiology':
        return (
          <>
            <DicomUploader
              active={false}
              acl={acl}
              path={path}
              itemId={protocolItem.id}
              itemStatus={protocolItem.item}
              handleChangeStatus={handleChangeStatus}
            />
            <ImageSeriesDisplay
              path={path}  participant={participant}
              handleReviewItem={handleReviewItem} protocolItem={protocolItem} acl={acl} isEdititng={isEdititng} />
          </>
        );
      case 'document':
        return (
          <DocumentUploader
            active={false}
            acl={acl}
            path={path}
            itemId={protocolItem.id}
            itemStatus={protocolItem.item}
            handleChangeStatus={handleChangeStatus}
          />
        );
      case 'CRF':
        return <CRFManager visitId={visitId} path={path} info={info} id={protocolItem.id} item={protocolItem} itemStatus={protocolItem.status} participant={participant} />;
      case 'treatment plan':
        return <TreatmentPlan isEdititng={isEdititng} id={protocolItem.id} item={protocolItem} handleUpdateTreatment={handleUpdateTreatment} itemStatus={protocolItem.status}
        />;
      case 'survey':
        return <ProtocolSurvey                acl={acl}
         protocolItem={protocolItem} readOnly participant={participant} path={path} info={info} />;
      case 'image analysis':
        return <ProtocolImageAnalysis protocolItem={protocolItem} participant={participant} />;
      case 'audio':
        return (
          <DocumentUploader
            active={false}
            acl={acl}
            path={path}
            itemId={protocolItem.id}
            itemStatus={protocolItem.item}
            handleChangeStatus={handleChangeStatus}
          />
        );
      case 'assessment':
        return (
          <>
            <MetManager
              participant={participant}
              itemId={protocolItem.id}
              collapse={collapse}
              expand={expand}
              itemStatus={protocolItem.status}
              role={protocolItem.role}
              handleChangeStatus={handleChangeStatus}
              acl={acl}
              reviewMode={reviewMode}
              toggleReviewMode={toggleReviewMode}
              handleChangeMetStats={handleChangeMetStats}
              clinicalStatus={protocolItem?.clinicalStatus ? protocolItem.clinicalStatus : 'unknown'}
              corticosteroidUse={protocolItem?.corticosteroidUse ? protocolItem.corticosteroidUse : 'unknown'}
              visitId={visitId}
              baselineMRI={baselineMRI}
              path={path}
              protocolItem={protocolItem}
              info={info}
            />
          </>
        );
      default:
        return (
          <div className="super-centered">
            <Text>Error Loading Item</Text>
          </div>
        );
    }
  };
  const ProtocolBlindedItem = ({ protocolItem, type, access }: any): JSX.Element => (
    <div className="super-centered">
      <Text>This requirement has been blinded as per protocol.</Text>
    </div>
  );

  const ProtocolReadOnlyItem = ({ protocolItem, type, access }: any): JSX.Element => {
    // console.log(' ProtocolReadOnlyItem >>> acl', type);
    switch (type) {
      case 'radiology':
        return (<ImageSeriesDisplay  participant={participant}  path={path} protocolItem={protocolItem} acl={acl} handleReviewItem={handleReviewItem} isEdititng={isEdititng} />);
      case 'document':
        return <DocumentsDrive protocolId={protocolItem.id} />;
      case 'treatment plan':
        return <TreatmentPlan isEdititng={isEdititng} id={protocolItem.id} item={protocolItem} handleUpdateTreatment={handleUpdateTreatment} itemStatus={protocolItem.status}
        />;
      case 'survey':
        return <ProtocolSurvey                acl={acl}
         protocolItem={protocolItem} readOnly participant={participant}  path={path} info={info} />;
      case 'image analysis':
        return <ProtocolImageAnalysis protocolItem={protocolItem} participant={participant} readOnly />;
      case 'audio':
        return <DocumentsDrive protocolId={protocolItem.id} />;
      case 'CRF':
        return <CRFManager visitId={visitId} path={path} info={info} id={protocolItem.id} item={protocolItem} itemStatus={protocolItem.status} participant={participant} readOnly />;
      case 'tumor':
      case 'assessment':
      case 'met report':
        return <ReadOnlyMetReview participant={participant} role={protocolItem.role} itemId={protocolItem.id} acl={acl} handleReviewItem={handleReviewItem} isEdititng={isEdititng} status={protocolItem.status} />;

      default:
        return (
          <div className="super-centered">
            <Text>Error Loading Item</Text>
          </div>
        );
    }
  };


  const Factory = ({ protocolItem, type, status, isreadOnly, isBlinded }: any): JSX.Element => {
    if (isBlinded) {
      return <ProtocolBlindedItem protocolItem={protocolItem} type={type} status={status} readOnly={isreadOnly} />;
    }
    if (readOnly) {
      return <ProtocolReadOnlyItem protocolItem={protocolItem} type={type} status={status} readOnly={isreadOnly} />;
    }
    switch (status) {
      case 'pending review':
      case 'approved':
        return <ProtocolReadOnlyItem protocolItem={protocolItem} type={type} status={status} readOnly={isreadOnly} />;
        break;
      case 'incomplete':
      case 'not started':
      case 'pending':
      case 'rejected':
        return <ProtocolEditItem protocolItem={protocolItem} type={type} status={status} readOnly={isreadOnly} />;
      case 'missing':
        return <ProtocolMissingItem protocolItem={protocolItem} type={type} status={status} readOnly={isreadOnly} />;
      case 'not initiated':
        return (
          <div className='super-centered w-100'>
            <Text>Intiate Item for {getRoleTest(item?.role ?? 'n/a')}?</Text>
            <Button loading={isEdititng} onClick={() => handleInitiate(protocolItem.id, 'pending')}>Intiate</Button>
          </div>
        );
      default:
        return (
          <div className="super-centered">
            <Text>Error Loading Item</Text>
          </div>
        );
    }
  };

  interface ActionFactoryProps {
    access: string;
    status: string | undefined;
    isreadOnly: boolean;
    isBlinded?: boolean | false;
    item?: any;
    participant?: any;
  }

  const ActionFactory: React.FC<ActionFactoryProps> = ({ access, status, isreadOnly, isBlinded }: any): JSX.Element => {
    if (access !== 'admin' && access !== 'CORELAB') {
      return <div >
        <Spacer value={10} />
        {item && item.deviation === true &&
          <span className='d-flex'>
            <FaFlag size={16} className='protocol-item__deviation' />
            <Label size='h6' uppercase className='protocol-action-bar__deviation-active' >Deviation</Label>
          </span>
        }
      </div>;
    }
    switch (status) {
      // case 'approved':
      // return <ProtocolActionBar status={status} protocolItem={item} participantId={participant} readOnly />;
      // break;
      case 'approved':
      case 'pending review':
      case 'incomplete':
      case 'not started':
      case 'pending':
      case 'rejected':
      case 'missing':
      case 'not initiated':
        return (
          <ProtocolActionBar status={status} protocolItem={item} participantId={participant} readOnly={false} />
        );
      default:
        return (
          <ProtocolActionBar status={status} protocolItem={item} participantId={participant} readOnly />
        );
    }
  };


  const Header = (headerProps: { req: any; type: any; status: any, deviation: any }): JSX.Element => {
    const { req, type, status, deviation } = headerProps;
    return (
      <div>
        <div className="protocol-item__header_wrapper align-center">
          <Label uppercase size="h3" weight={600}>
            {req}
          </Label>
          {deviation === true && <div className="protocol-item__header_deviation__item">
            <Label uppercase size="h4" weight={600}>Item Marked as Deviation</Label>
          </div>}
          <div className='protocol-item__header_actionwrapper'>
            <ActionFactory access={acl} status={item.status} isreadOnly={readOnly} />
            <MessageFactory id="chat" createChatRoom={createChatRoom} participant={participant!} protocolItem={item.id!} />
          </div>
        </div>

        {/* <Spacer value={10} /> */}
        <div className="protocol-item__subheader_wrapper">
          <div>
            <Label uppercase size="h6">
              Type: {type}
            </Label>
            <Spacer value={5} />
            <div className="_make-row">
              <Label uppercase size="h6">
                Status:
              </Label>
              <Spacer value={5} />
              <Label uppercase size="h6" className={getColor(status)}>
                {status}
              </Label>
            </div>
            
          </div>
          {item?.alignmentReference === true && 
            <div className='refalign'>
              <LuAlignCenterHorizontal size={20} className='icon' />
              <Label uppercase size="h6" className='text'>REFERENCE FOR ALIGNEMENT</Label>
            </div>
          }
          <div>
             {/* <Spacer value={10} /> */}
            {participant && item.id &&
              <ProtocolLogs itemId={item.id} />
            } 
            </div>
          {/* <ActionFactory status={item.status} isreadOnly={readOnly} /> */}
        </div>
      </div>
    );
  };
  const MemoisedHeader = React.useMemo(() => Header, [item.status]);

  return (
    // <Tile className="protocol-item-card">
    <div className="protocol-item-card__wrapper">
      <div className="protocol-item-card__header">
      {
        item.type !== 'image analysis2' && <>
          <Header req={item.item} status={item.status} type={item.type} deviation={item.deviation} />
          <Spacer value={15} />
          {/* <Label size='h8'>visit id {props?.visitId}</Label> */}
        </>
      }
      </div>
      <div className="protocol-item-card__body">
        <Factory protocolItem={item} type={item.type ? item.type : 'error'} status={item.status ? item.status : 'error'} isreadOnly={readOnly} />
      </div>
      <Modal open={statusModal} onClose={handleCloseModal} title="Confirm Changes">
        <div>
          <Label size="h5" weight={600} color="primary">
            By clicking &apos;Confirm&apos;, you are stating that to the best of your knowledge you have provided accurate
            and complete information to meet this requirement.
          </Label>
          <Spacer value={10} />
          <Label size="h5" weight={600} color="primary">
            This item will be reviewed and you may be contacted if some questions arise.
          </Label>
          <Spacer value={20} />
          <div className="btn-row">
            <Button kind="danger" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button onClick={confirmStatus}>Confirm</Button>
          </div>
        </div>
      </Modal>
    </div>
    // </Tile>
  );
};

ProtocolItemFactory.defaultProps = {
  isBlinded: false,
  info: null,
  // setIsUploading: () => console.log('//'),
};

export default ProtocolItemFactory;
