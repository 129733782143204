import React from 'react';
import { IconContext } from 'react-icons';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/extensions
import './i18n';
import App from './App';
import store from './store/store';
// import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); 

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <IconContext.Provider value={{ className: 'global-class-name' }}>
        <App />
      </IconContext.Provider>
    </Provider>
  </React.StrictMode>);
