/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { ChangeEvent, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import { GrFormAdd } from 'react-icons/gr';
import { BiRefresh } from 'react-icons/bi';
import { IoTrashOutline } from 'react-icons/io5';
import { AiFillPlusCircle } from 'react-icons/ai';
import FlatList from 'flatlist-react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { Spinner, Label, Spacer, StudyItem, Alert, Modal, TextInput, Button, Dropdown } from '../../../components';
import { SearchBox, TextAreaInput } from '../../../components/molecules';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { useGetStudiesQuery, studiesApi, useCreateStudyMutation, usePrefetch } from '../../../api/builders/studies.api';

type StudiesTableProps = {
  title?: string | undefined;
  items: Array<any> | null;
};

type StudyItemProps = {
  label?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  summary?: string | undefined;
};

const renderItem = (item: any, idx: React.Key | null | undefined) => <StudyItem key={idx} item={item} />;
export interface TypeError {
  status: number;
  data: { message: string };
}

const initialForm = {
  studyId: '',
  title: '',
  summary: '',
  status: '',
  sponsor: '',
  type: 'trial',
};

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean;
  message: string;
  kind: AlertType;
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}
interface ModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  size: 'sm' | 'md' | 'lg';
  values: {
    title: string;
    studyId: string;
    sponsor: string;
    status: string;
    summary: string;
  };
  handleChangeVals: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSummary: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  addInput: () => void;
  handleChangeTreatments2: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  removeInput: (index: number) => void;
  inputs: { label: string; percent: string }[];
  err: { open: boolean; message: string };
  isAdding: boolean;
  onAddStudy: () => void;
}
const MemoizedModal: React.FC<ModalProps> = React.memo(
  ({
    open,
    onClose,
    title,
    size,
    values,
    handleChangeVals,
    handleChangeSummary,
    addInput,
    handleChangeTreatments2,
    removeInput,
    inputs,
    err,
    isAdding,
    onAddStudy,
  }) => (
    <Modal open={open} onClose={onClose} title={title} size={size}>
      <div>
        <TextInput name="title" label="Title" value={values.title} onChange={handleChangeVals} />
        <TextInput name="studyId" label="Trial ID" value={values.studyId} onChange={handleChangeVals} />
        <TextInput name="sponsor" label="Sponsor" value={values.sponsor} onChange={handleChangeVals} />
        <Dropdown name="status" label="Status" value={values.status} onChange={handleChangeVals}>
          <MenuItem value="setup">Setup</MenuItem>
          <MenuItem value="training">Training</MenuItem>
        </Dropdown>
        <Spacer value={10} />
        <div className="add-study-treatment-header">
          <Label size="h5" weight={600} color="primary">
            Treatments
          </Label>
          <button type="button" className="document-drive__icon-btn" aria-label="refetch" onClick={addInput}>
            <GrFormAdd size={15} />
          </button>
        </div>
        {inputs.length > 0 &&
          inputs.map((el, index) => (
            <div className="d-flex-row" key={index}>
              <TextInput
                placeholder="Label"
                id="label"
                value={inputs[index].label}
                onChange={(e) => handleChangeTreatments2(e, index)}
              />
              <Spacer value={10} />
              <TextInput
                placeholder="Percent"
                id="percent"
                value={inputs[index].percent}
                onChange={(e) => handleChangeTreatments2(e, index)}
              />
              <Button icon={IoTrashOutline} kind="ghost" onClick={() => removeInput(index)} />
            </div>
          ))}
        <TextAreaInput name="summary" label="Summary" value={values.summary} onChange={handleChangeSummary} />
        <Spacer value={20} />
        <div className="btn-row">
          {err.open && (
            <Label size="h6" color="danger">
              {err.message}
            </Label>
          )}
        </div>
        <Spacer value={20} />
        <div className="btn-row">
          <Button loading={isAdding} onClick={onAddStudy}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  ),
);

const StudiesManager = () => {
  const [payload, setPayload] = React.useState<any>({
    pageSize: 10,
    orderBy: 'recent',
  });
  const { t, i18n } = useTranslation();
  const [AddStudy, { isLoading: isAdding, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] =
    useCreateStudyMutation();
  const { setBreadcrumbs } = React.useContext(BreadcrumbsContext);

  const { data, isError, isLoading, isFetching } = useGetStudiesQuery(payload);
  const prefetchNext = usePrefetch('getStudies');

  const { refetch: refetchParticipants } = studiesApi.endpoints.getStudies.useQuerySubscription(payload);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const [err, setErr] = React.useState({ open: false, message: '' });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [values, setValues] = React.useState(initialForm);
  const [studies, setStudies] = React.useState<any>(null);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });
  const [inputs, setInputs] = React.useState<any>([
    {
      percent: '',
      label: '',
    },
  ]);
  // Filter the studies based on the search term
  const filteredStudies = studies?.filter((study: any) =>( study.title?.toLowerCase().includes(searchTerm.toLowerCase() ) || study.summary?.toLowerCase().includes(searchTerm.toLowerCase()) ),
  );

  const handleChangeSearch = (value: string) => {
    setSearchTerm(value);
  };

  const addInput = () => {
    const tmp = [...inputs];
    tmp.push({
      percent: '',
      label: '',
    });
    setInputs(tmp);
  };

  const removeInput = (index: number) => {
    const tmp = [...inputs];
    tmp.splice(index, 1);
    setInputs(tmp);
  };

  const handleChangeTreatments = (e: any, index: number) => {
    const tmp = [...inputs];
    tmp[index].e.target.id = e.target.value;
    setInputs(tmp);
  };

  const handleChangeTreatments2 = (event: any, index: number) => {
    const { id, value } = event.target;
    const newItems = [...inputs];
    newItems[index] = {
      ...newItems[index],
      [id]: value,
    };
    setInputs(newItems);
  };

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Adding New Trial: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding New Trial',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  const loadMore = () => {
    setPayload({
      ...payload,
      pageSize: 10,
      pageCurrent: data.pageCurrent + 1,
      orderBy: 'recent',
    });
  };

  function validateFields(vals: { [key: string]: any }) {
    /* eslint-disable no-restricted-syntax */
    for (const field in vals) {
      if (!vals[field]) {
        setErr({
          open: true,
          message: `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
        });
        return true;
      }
    }
    return false;
  }

  React.useEffect(() => {
    if (data && Array.isArray(data.items) && data.items.length > 0) {
      const items = Array.isArray(studies) ? studies : [];
      const curr = data.pageCurrent > 1 ? items.concat(data.items) : data.items;
      setStudies(curr);
      const PL = {
        pageCurrent: data.pageCurrent + 1,
        pageSize: 10,
        orderBy: 'recent',
      };
      prefetchNext(PL);
    }
  }, [data]);

  const handleChangeSummary = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleChangeVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  React.useEffect(() => {
    setBreadcrumbs([]);
  }, []);

  const onCloseModal = () => {
    setIsOpen(false);
    setValues(initialForm);
    setErr({ open: false, message: '' });
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };

  const onAddStudy = async () => {
    try {
      const validate = validateFields(values);
      if (!validate) {
        const pl = {
          ...values,
          description: values.summary,
          treatments: JSON.stringify(inputs),
        };
        const a = await AddStudy(pl);
        onCloseModal();
      }
      // return a;
    } catch (error) {
      console.error('error', error);
    }
  };

  if (isLoading || isAdding || isFetching) {
    return (
      <div className="super-centered">
        <Spacer value={30} />
        <Spinner />
      </div>
    );
  }
  return (
    <div className="studies">
      <div className="studies__wrapper">
        <div className="studies__header">
          <div className="studies__title_wrapper">
            <Label size="h2" weight={300}>
              {t('Trials')}
            </Label>
            <button type="button" className="icon-btn" aria-label="refetch" onClick={refetchParticipants}>
              <BiRefresh />
            </button>
          </div>
          <div className="studies__search">
          <SearchBox value={searchTerm} 
            onChange={handleChangeSearch}
            placeholder={t('search') as string} />
        </div>
          <div className="d-flex-row-center pr-12">
            <button type="button" className="studies__icon-btn" aria-label="refetch" onClick={onOpenModal}>
              <AiFillPlusCircle size={24} className="studies__icon-btn-icon" />
            </button>
          </div>
        </div>
        <Spacer value={10} />
        <div className="studies__body">
          {Array.isArray(filteredStudies) && (
            <FlatList
              list={filteredStudies.filter((item: any) => item.type !== 'registry')}
              renderItem={renderItem}
              renderWhenEmpty={() => (
                <div className="h-100 w-100 super-centered">
                  <Label color="ghost" size="h2">
                    No Trials
                  </Label>
                </div>
              )}
            />
          )}
        </div>

        {data?.pageCurrent < data?.pageTotal && (
          <div className="studies__footer">
            <Button onClick={loadMore}>Load More</Button>
          </div>
        )}

        <MemoizedModal
          open={modalIsOpen}
          onClose={onCloseModal}
          title="Add New Trial"
          size="sm"
          values={values}
          handleChangeVals={handleChangeVals}
          handleChangeSummary={handleChangeSummary}
          addInput={addInput}
          handleChangeTreatments2={handleChangeTreatments2}
          removeInput={removeInput}
          inputs={inputs}
          err={err}
          isAdding={isAdding}
          onAddStudy={onAddStudy}
        />
      </div>
      <Alert severity={alert.kind} open={alert.open} onClose={resetAlert} message={alert.message} />
    </div>
  );
};

export default withAuthenticator(StudiesManager);
