import React from 'react';
import Popover from '@mui/material/Popover';
import './breadcrumb-with-menu.scss';
import { Label } from '../../atoms';


export interface BreadcrumbWithMenuProps {
  label: string | undefined,
  menu: JSX.Element,
}

const BreadcrumbWithMenu = (props: BreadcrumbWithMenuProps) => {
  const { label, menu } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="card">
      <button type='button' className='icon-btn' tabIndex={-1} onClick={handleClick} >
        <Label size='h4' color='primary' weight={600} uppercase>{label}
        </Label>
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div role='button' tabIndex={-1} onClick={handleClose}>
          {menu}
        </div>
      </Popover>
    </div>
  );
};

BreadcrumbWithMenu.defaultProps = {
};

BreadcrumbWithMenu.displayName = 'BreadcrumbWithMenu';


export default BreadcrumbWithMenu;
