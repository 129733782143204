import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsCircleFill, BsTrash } from 'react-icons/bs';
import Grid from '@mui/material/Unstable_Grid2';
import { Tile, Spacer, Label, Button, Modal, Badge, Checkbox } from '../../atoms'; import './de-identified-patient.scss';
import DeleteVerification from '../DeleteVerification/DeleteVerification';
import { useDeletePatientMutation } from '../../../pages/private/SharedCohort/api';

export interface DeIdentifiedPatientProps {
  id: string,
  patientId: string,
  gender: string,
  dob: number,
  checked: boolean,
  handleCheck: (id: string) => void,
  patient: any,
  cohortId: string,
  cohort: string,
  type?: string,
}

const DeIdentifiedPatient = (props: DeIdentifiedPatientProps): JSX.Element => {
  const { id, patientId, dob, checked, handleCheck, gender, patient, cohortId, cohort, type } = props;
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deletePatient, { isLoading }] = useDeletePatientMutation();

  const closeDeleteMetModal = () => {
    setOpenDelete(false);
  };
  const openDeleteMetModal = () => {
    setOpenDelete(true);
  };


  const getDob = (date: number) => {
    const birthDate = new Date(date * 1000);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    return age;
  };

  const onOpen = () => {
    navigate('/private/cohorts/item/patient', { state: { patient, secondary: true, cohort } });
  };

  const handleDelete = () => {
    try {
      const payload = {
        id,
        body: {
          cohortId,
          mrn: patientId,
        },
      };
      deletePatient(payload);
    } catch (e) {
      console.error('err deleting patient', e);
    }
  };

  return (
    <div key={`DeIdentifiedPatient_${id}`} className="de-identified-patient">
      <Tile>
        <div className='de-identified-patient__body' role='button' tabIndex={-1} >
          <Grid container spacing={2}>
            <Grid xs={4} onClick={onOpen} >
              <Label size="h5" center>{patientId}</Label>
            </Grid>
            <Grid xs={4} onClick={onOpen} >
              <Label size="h5" center>{getDob(dob)}</Label>
            </Grid>
            <Grid xs={3} onClick={onOpen} >
              <Label size="h5" center>{gender}</Label>
            </Grid>
            <Grid xs={1} >
              {type !== 'shared' &&
                <div className='super-centered'>
                  <span className='cohort-item__trash-wrapper'>
                    <button type='button' className='icon-btn' onClick={openDeleteMetModal} aria-label='refetch'><BsTrash /></button>
                  </span>
                </div>
              }
            </Grid>
          </Grid>
        </div>
      </Tile>
      <Modal size="sm" open={openDelete} onClose={closeDeleteMetModal} title="Delete Patient">
        <DeleteVerification
          loading={isLoading}
          item={patient}
          name='Patient'
          onCancel={closeDeleteMetModal}
          onDelete={handleDelete}
        />
      </Modal>
    </div>
  );
};

DeIdentifiedPatient.defaultProps = {
  type: 'n/a',
};

DeIdentifiedPatient.displayName = 'DeIdentifiedPatient';
export default DeIdentifiedPatient;
