/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Snapshot {
  id: string;
  userId: string;
  meta: Record<string, any>; // You can define the specific structure of the meta information
  fileUrl: string; // Add a property for the file URL

}

interface SnapshotState {
  snapshots: Snapshot[];
  savedSnapshots: Snapshot[];
  loading: boolean;
}

const initialState: SnapshotState = {
  snapshots: [],
  savedSnapshots: [],
  loading: false,
};

const snapshotSlice = createSlice({
  name: 'snapshot',
  initialState,
  reducers: {
    registerSnapshot: (state, action: PayloadAction<Snapshot>) => {
      state.snapshots.push(action.payload);
    },
    deleteSnapshot: (state, action: PayloadAction<string>) => {
      state.snapshots = state.snapshots.filter(snapshot => snapshot.id !== action.payload);
    },
    resetSnapshots: state => {
      state.snapshots = [];
    },
    saveSnapshot: (state, action: PayloadAction<Snapshot>) => {
      state.savedSnapshots.push(action.payload);
    },
    listSnapshots: (state, action: PayloadAction<Snapshot[]>) => {
      state.savedSnapshots = action.payload;
    },
  },
});

export const { registerSnapshot, deleteSnapshot, resetSnapshots, listSnapshots, saveSnapshot } = snapshotSlice.actions;

export default snapshotSlice.reducer;
