import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Alert as MuiAlert, Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import './alert.scss';

type AlertType = 'success' | 'error' | 'info' | 'warning';

interface Props  {
  severity: AlertType;
  open: boolean;
  onClose: () => void;
  message?: string;
  // You can add other props that you want to pass down to MuiAlert
}


const Alert = ({ open, onClose, severity, message }: Props) => (
  <Snackbar open={open} autoHideDuration={10000} onClose={onClose}
  >
    <MuiAlert onClose={onClose}
      severity={severity}
      elevation={6} variant='filled'
      action={
        <IconButton
          aria-label='close'
          color='inherit'
          size='small'
          onClick={onClose}
        >
          <CloseIcon fontSize='inherit' />
        </IconButton>
      }>
      {message}
    </MuiAlert>
  </Snackbar>
);

Alert.defaultProps = {
  message: 'Alert',
};

export default Alert;
