import React from 'react';
// import classnames from 'classnames';
import './tab-bar.scss';

export interface TabsBarProps  {
  id: string,
  className?: string,
  tabs: Array<any> | []
}

const TabsBar = (props: TabsBarProps) => {
  const { id } = props;
  return (
        <div key={`TabsBar_${id}`} className="card">
        <div>TabsBar</div>
        </div>
  ); 
};

TabsBar.defaultProps = {
  className: '',
};

TabsBar.displayName = 'TabsBar';
export default TabsBar;
