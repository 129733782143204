/* eslint-disable react/no-array-index-key */
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import React from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';
import { Spacer, Label } from '../../components';
import Privacybody from './Privacybody';

const Terms = () => {
  const company = 'UPSTREAM VISION';
  const product = 'UPSTREAM CLOUD';
  const url = 'upstream.vision';
  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="terms">
      <Spacer value={20} />
      <Label size="h2" bold center>{company} Privacy Policy</Label>
      <Spacer value={20} />
      <Label size="text" center>
        The Privacy Policy describes how we collects, uses, and shares your personal data.
      </Label>
      <Spacer value={15} />

      <Label size="text" center>
        Please take a moment to familiarize yourself with our privacy practices, accessible via the headings below, and contact us if you have any questions.</Label>
      <Spacer value={20} />

      <Spacer value={20} />
      <Spacer value={20} />
      {Privacybody.map((el, index) => (
        <Accordion key={`faq-${index}`} expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionSummary
            expandIcon={<MdOutlineExpandMore />}
          >
            <Label size="h4">
              {el.title}
            </Label>
          </AccordionSummary>
          {el.body.map((el2, index2) => (
            <AccordionDetails key={`faq-detail-${index2}`}>
              <div className="help__faq-text">
                <Label>
                  {el2}
                </Label>
              </div>
            </AccordionDetails>
          ))}
        </Accordion>),
      )}
    
    <Spacer value={20} />
    <Label size="subtext" justify>
        Last revised: September 12, 2022
      </Label>
    </div>
  );
};
export default Terms;
