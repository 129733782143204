/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, StudyDriveBySite } from '../../../components';
import { useGetStudySitesQuery } from './api';
import { DocumentsDrive, Tab, TabView } from '../../../components/molecules';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';

type StudyItem = {
  label?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  summary?: string | undefined;
  sponsor?: string | undefined;
  studyId?: string | undefined;
  acl?: string | undefined;
};

interface LocationState {
  study: StudyItem;
}


const drivetabs = [
  { id: 0, label: 'By site' },
  { id: 1, label: 'By requirement' },
];
const StudyDrive = () => {
  const location = useLocation();
  const { study } = location?.state as LocationState;
  const [tab, setTab] = useState(1);
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetStudySitesQuery({ studyId: study?.id ? study.id : skipToken });
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);

  React.useEffect(() => {
    const newBreadcrumb = {
      label: 'Study Drive',
      path: location.pathname,
      state: { study },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);



  return (
    <div className="study-drive">
      {/* <TabView id='tab_view_study_drive' headers={drivetabs} showheader={false} >
        <Tab id="0" label="By study">
      <StudyDriveBySite sites={Array.isArray(data) && data.length > 0 ? data : []} />
        </Tab>      </TabView> */}

      <StudyDriveBySite sites={Array.isArray(data) && data.length > 0 ? data : []} />

    </div>
  );
};

export default withAuthenticator(StudyDrive);