import React from 'react';
// import classnames from 'classnames';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import moment from 'moment';
import './protocol-logs.scss';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCohortLogsQuery, logsApis } from '../../../api/builders/logs.api';
import { Tile, Label, Spinner, Text, Spacer, Button } from '../../atoms';


export interface ProtocolLogsProps {
  itemId: string,
}

const ProtocolLogs = (props: ProtocolLogsProps): JSX.Element => {
  const { itemId } = props;
  const { data, isError, isFetching } = useGetCohortLogsQuery(itemId);
  const { refetch: refetchLogs } =
    logsApis.endpoints.getCohortLogs.useQuerySubscription(itemId);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);


  React.useEffect(() => {
    refetchLogs();
  }, [itemId]);


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const focus = React.useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (focus && focus !== null && focus.current) {
      focus.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  React.useEffect(scrollToBottom, [data]);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const sort = (items: any) => {
    try {
      const arrayForSort = [...items];
      let test = [];
      test = arrayForSort.sort((a, b) => a.createdAt - b.createdAt);
      return test;
    } catch {
      console.error('failed to sort logs');
      return items;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <button type='button' className='protocol-logs__btn' aria-describedby={id} onClick={handleClick}  >
        <ReceiptLongIcon className='protocol-logs__icon' fontSize="small" />
        {/* <Label color='secondary' uppercase size="h6"> History </Label> */}
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {data?.length > 0 ?
          <div className='chat__wrapper'>
            <div className='chat__body'>
              <div className='chat__prev-messages-wrapper'>
                {Array.isArray(data) && data.length > 0 ?
                  sort(data).map((item: any, index: number) => (
                    <div key={item.id} ref={index === data.length - 1 ? focus : null}>
                      {/* <Label size='subtext'>{moment(item.createdAt).format('lll')} </Label> */}
                      <Label size='subtext'>{`${item.user.firstname} ${item.user.lastname} ${moment(item.createdAt).format('lll')}`}</Label>
                      <Text>{item.actionType}</Text>
                      <Spacer value={10} />
                    </div>
                  ))
                  :
                  <div className='super-centered'>
                    <Label size='subtext'>No History</Label>
                  </div>
                }
              </div>
              {isFetching && <div className='chat__prev-message-mine-item' ><Spinner /> </div>}
            </div>
          </div>
          :
          <div className='chat__wrapper super-centered'>
            <Text>No History</Text>
          </div>
        }
      </Popover>
    </div>
  );
};

ProtocolLogs.defaultProps = {
};

ProtocolLogs.displayName = 'ProtocolLogs';
export default ProtocolLogs;
