/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';

import { FiFilter } from 'react-icons/fi';
import { Tile, Label, Text, Button, Spacer } from '../../atoms';
import ProgressBar from '../ProgressBar/ProgressBar';
import SearchBox from '../SearchBox/SearchBox';
import './analysis-reports.scss';

export interface AnalysisReportsProps {
  volumetricData: any,
  isLoading?: boolean,
}

const PETSUVReport = (props: AnalysisReportsProps): JSX.Element => {
  const { volumetricData, isLoading } = props;
  const [filter, setFilter] = useState('All');
  const [searchResults, setSearchResults] = useState(Array.isArray(volumetricData) ? volumetricData : []);
  const [searchTerm, setSearchTerm] = useState('');
  const divRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    if (filter && Array.isArray(volumetricData)) {
      setSearchResults([]);
      let filteredData = searchResults;
      try {
        switch (filter) {
          case 'All':
            filteredData = volumetricData;
            break;
          case '1.4':
            filteredData = volumetricData.filter((d: any) => parseFloat(d.suvr) >= parseFloat('1.4'));
            break;
          case '1':
            filteredData = volumetricData.filter((d: any) => parseFloat(d.suvr) >= parseFloat('1'));
            break;
          case '1.2':
            filteredData = volumetricData.filter((d: any) => parseFloat(d.suvr) >= parseFloat('1.2'));
            break;
          case 'Lobes':
            filteredData = volumetricData.filter((row: any) => {
              const searchString = Object.values(row)
                .join(' ')
                .toLowerCase();
              return searchString.includes('lobe');
            });
            break;
          case 'Gyri':
            filteredData = volumetricData.filter((row: any) => {
              const searchString = Object.values(row)
                .join(' ')
                .toLowerCase();
              return searchString.includes('gyrus');
            });
            break;
          default:
            break;
        }
        if (searchTerm) {
          filteredData = volumetricData.filter((row: any) => {
            const searchString = Object.values(row)
              .join(' ')
              .toLowerCase();
            return searchString.includes(searchTerm.toLowerCase());
          });
        }
        setSearchResults(filteredData);

      } catch (e) {
        console.error('VOLUMETRIC DATA ERROR>>>', e);
      }
    }
  }, [filter, volumetricData, searchTerm]);

  const handleFilterClick = (newFilter: string) => {
    setFilter(newFilter);
  };

  const handleSearchChange = (val: string) => {
    setSearchTerm(val);
  };

  const getBarColor = (value: number) => {
    const val = Number(value);
    if (val <= 1.2) {
      return 'success';
    }
    if (val > 1.2 && val <= 1.4) {
      return 'warning';
    } if (val > 1.4) {
      return 'danger';
    }
    return 'primary';
  };

  const centiloid = (_suvr: number) => {
    const centiloid_b = -142.24;
    const centiloid_slope = 151.42;
    return _suvr * centiloid_slope + centiloid_b;
  };

  /* eslint-disable no-plusplus */
  const amyloidCheckAll = () => {
    try {
      let amyloid_level = 'NO';
      const data = volumetricData;
      let check = false;
      for (let i = 0; i < data.length; i++) {
        const suvr_level = data[i]?.suvr;

        if (suvr_level < 1.2) {
          amyloid_level = 'NONE';
          check = false;
        } else if (suvr_level < 1.4) {
          amyloid_level = 'LOW';
          check = true;
        } else if (suvr_level >= 1.4) {
          amyloid_level = 'CONSISTENT WITH AD';
          check = true;
        }
      }

      if (check) {
        return (
          <div className='SUV__yellow'>
            <Label size="h6">BETA-AMYLOID LEVEL DETECTED ({amyloid_level})</Label>
          </div>
        );
      }
      return (
        <div className='SUV__green'>
        <Label size="h6" >NO BETA-AMYLOID LEVEL CONSISTENT WITH AD DETECTED</Label>
        </div>
      );
    } catch {
      return (
        <Label size="h6">Error Calculating Beta Amyloid</Label>
      );
    }
  };

  const amyloidCheck = (_suvr : number) => {
    try {
      let amyloid_level = 'NO';
      let check = false;
      const suvr_level = _suvr;
      if (suvr_level < 1.2) {
        amyloid_level = 'NONE';
        check = false;
      } else if (suvr_level < 1.4) {
        amyloid_level = 'LOW';
        check = true;
      } else if (suvr_level >= 1.4) {
        amyloid_level = 'CONSISTENT WITH AD';
        check = true;
      }

      if (check) {
        return (
          <div className='SUV__yellow'>
            <Label size="h6">BETA-AMYLOID LEVEL DETECTED ({amyloid_level})</Label>
          </div>
        );
      }
      return (
        <div className='SUV__green'>
        <Label size="h6" >NO BETA-AMYLOID LEVEL CONSISTENT WITH AD DETECTED</Label>
        </div>
      );
    } catch {
      return (
        <Label size="h6">Error Calculating Beta Amyloid</Label>
      );
    }
  };
  
  const captureSnapshot = async (snapshotName:string) => {
    try {
      // Here you can save the captured image or perform other operations
      if (divRef.current) {
        html2canvas(divRef.current).then((canvas) => {
          // Convert the canvas to an image URL
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = canvas?.width;
          // eslint-disable-next-line no-unsafe-optional-chaining
          tempCanvas.height = canvas?.height + 50;
          const tempContext = tempCanvas.getContext('2d');
          if (tempContext) {
            tempContext.drawImage(canvas!, 0, 0);
            const bannerHeight = 50; // Height of the banner in pixels
            tempContext.fillStyle = '#000'; // Color of the banner background
            tempContext.fillRect(0, tempCanvas.height - bannerHeight, tempCanvas.width, bannerHeight);
    
            const text = '2023 © UPV';
            tempContext.font = '10px Arial'; // Font style for the text
            tempContext.fillStyle = '#fff'; // Color of the text
            tempContext.textBaseline = 'middle';
            tempContext.textAlign = 'center';
            tempContext.fillText(text, tempCanvas.width / 2, tempCanvas.height - bannerHeight / 2);
        
          }
    

          const imageUrl = tempCanvas.toDataURL('image/png');
          // Here you can save the captured image or perform other operations
          // Create an anchor element to trigger the download
          const anchor = document.createElement('a');
          anchor.href = imageUrl;
          anchor.download = `${snapshotName}.png`; // Specify the desired file name and format

          // Trigger the download programmatically
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        });
      }
    } catch (error) {
      console.error('Failed to capture snapshot:', error);
    }
  };
  return (
    <div className='card'>
      <div ref={divRef}>
      {(Array.isArray(volumetricData) && volumetricData.length > 0) ?
        <div>
          <div className='report__header'>
            <div className='report__header__title'><Label uppercase size="h6">Beta-amyloid level in Signature Area </Label></div>
            <div className='report__header__wrapper'>
              <div className='report__item'>
                <Label uppercase size='h6'>{`SUVr: ${Number(volumetricData[0].global_suvr).toFixed(2)}`}</Label>
                {/* <Label center size='h6'>{`${Number(volumetricData[0].global_suvr).toFixed(2)}`}</Label> */}
              </div>
              <div className='report__item'>
                <Label uppercase size='h6'>{`Centiloid: ${centiloid(Number(volumetricData[0].global_suvr)).toFixed(1)}`}</Label>
                {/* <Label uppercase size='h6'>{`Centiloid: ${Number(volumetricData[0].global_centiloid).toFixed(2)}`}</Label> */}
                {/* <Label center size='h6'>{`${Number(volumetricData[0].global_centiloid).toFixed(2)} %`}</Label> */}
              </div>
              <div className='report__item'>
                {amyloidCheck(Number(volumetricData[0].global_suvr))}
              </div>
            </div>
          </div>
          <Label size='subtext' center>*FOR INFORMATION ONLY, NOT INTENDED FOR CLINICAL DIAGNOSTIC PURPOSE</Label>
          <Spacer value={10} />
          <div className='report__thead-wrapper'>
            <div className='filter__wrapper'>
              <FiFilter size={12}/>
              <span className='filter__spacer'>SUVR &gt;= {' '}</span>
              <button type='button' onClick={() => handleFilterClick('1')} className={filter === '1' ? 'filter__active' : 'filter__btn'}> 1 </button>
              <span className='filter__spacer'>{' / '}</span>
              <button type='button' onClick={() => handleFilterClick('1.2')} className={filter === '1.2' ? 'filter__active' : 'filter__btn'}> 1.2 </button>
              <span className='filter__spacer'>{' / '}</span>
              <button type='button' onClick={() => handleFilterClick('1.4')} className={filter === '1.4' ? 'filter__active' : 'filter__btn'}> 1.4 </button>
              <span className='filter__spacer'>{' | '}</span>
              <button type='button' onClick={() => handleFilterClick('Lobes')} className={filter === 'Lobes' ? 'filter__active' : 'filter__btn'}> Lobes </button>
              <span className='filter__spacer'>{' | '}</span>
              <button type='button' onClick={() => handleFilterClick('Gyri')} className={filter === 'Gyri' ? 'filter__active' : 'filter__btn'}> Gyri </button>
              <span className='filter__spacer'>{' | '}</span>
              <button type='button' onClick={() => handleFilterClick('All')} className={filter === 'All' ? 'filter__active' : 'filter__btn'}> Clear </button>
            </div>
            <Spacer value={30} />
            <SearchBox onChange={handleSearchChange} placeholder='Search' />
          </div>
          <div className='report__body'>
            <table className='report-table report__table-container'>
              <thead >
                <tr>
                  <th> <Label size='h6' uppercase>Label</Label> </th>
                  <th> <Label size='h6' uppercase>Side</Label> </th>
                  <th> <Label size='h6' uppercase>VOL (cm3)</Label> </th>
                  <th> <Label size='h6' uppercase>SUV</Label> </th>
                  <th> <Label size='h6' uppercase>SUVR</Label></th>
                  <th> <Label size='h6' uppercase>ASSYMETRY (%)</Label></th>
                  <th> <Label size='h6' uppercase>CENTILOID*</Label></th>
                </tr>
              </thead>
              {(Array.isArray(searchResults) && searchResults.length > 0) ?

                <tbody className='report__table-content'>
                  {searchResults?.map((item: any) => (
                    <tr className='report-table__row' key={item?.id}>
                      <td> <Label size='h6'>{item?.anatomy_label}</Label> </td>
                      <td> <Label size='h6'>{item?.side}</Label></td>
                      <td> <Label size='h6'>{(item.vol_mm3 / 1000).toFixed(2)}</Label> </td>
                      <td> <Label size='h6'>{(item.suv_average * 1).toFixed(2)}</Label> </td>
                      <td>
                        {(item.suvr !== 'null') ?
                          <span>
                            <Label size='h6'>{Number(item.suvr).toFixed(3)}</Label>
                            <ProgressBar min={0} max={3} value={Number(item.suvr)} kind={getBarColor((item.suvr))} />
                          </span>
                          :
                          <span>null</span>
                        }
                      </td>
                      <td><Label size='h6'>{(item.asymmetry * 100).toFixed(2)}</Label> </td>
                      <td><Label size='h6'>{(centiloid(item.suvr) * 1).toFixed(2)}*</Label> </td>
                    </tr>
                  ))}
                </tbody>
                :
                <tr className='text-center'>
                  <td colSpan={7} rowSpan={10}>
                    <Spacer value={30} />
                    <Label size='h6' center> No Results
                    </Label>
                  </td>
                </tr>
              }
            </table>
          </div>
        </div>
        :
        <div className='text-center'>
          <Spacer value={30} />
          {isLoading ? <Label size='h6' center>Loading ...</Label> : <Label size='h6' center>Data not available</Label>}
        </div>
      }
      </div>
      <Button onClick={()=>captureSnapshot('beta-amyloid')} >Capture Snapshot</Button>

    </div >
  );
};

PETSUVReport.defaultProps = {
};

PETSUVReport.displayName = 'PETSUVReport';
export default PETSUVReport;
