/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line no-unused-vars
// import { setIntl } from '../../../store/intl/index';

// import classnames from 'classnames';
import './language-switcher.scss';
import { setIntl } from '../../../store/intl/reducer';
import { selectLan } from '../../../store/intl';

export interface LanguageSwitcherProps {
  id: string;
  className?: string;
}

const LanguageSwitcher = (props: LanguageSwitcherProps): JSX.Element => {
  const { id, className } = props;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const currentLanguage = useSelector(selectLan || { code: 'en', label: 'English', flag: '🇺🇸' }); // Assuming that the language state is stored in the 'intl' slice
  const availableLanguages = [
    { code: 'en', label: 'English', flag: '🇺🇸' },
    { code: 'en-UK', label: 'English (UK)', flag: '🇬🇧' },
    { code: 'fr', label: 'French', flag: '🇫🇷' },
    { code: 'es', label: 'Spanish', flag: '🇪🇸' },
    { code: 'it', label: 'Italian', flag: '🇮🇹' },
    { code: 'he', label: 'Hebrew', flag: '🇮🇱' },
    { code: 'de', label: 'German', flag: '🇩🇪' },
    { code: 'nl', label: 'Dutch', flag: '🇳🇱' },
    { code: 'sv', label: 'Swedish', flag: '🇸🇪' },
    { code: 'ko', label: 'Korean', flag: '🇰🇷' },
    { code: 'ja', label: 'Japanese', flag: '🇯🇵' },
  ];
  
  useEffect(() => {
    const userLanguage = navigator.language.split('-')[0];
    const  matchingLanguage = availableLanguages.find((language) => language.code === userLanguage);
    
    if (matchingLanguage ) {
      dispatch(setIntl({ lan: matchingLanguage }));
    }
  }, []);

  const handleLanguageChange = (_language: { code: string; label: string; flag: string; }) => {
    dispatch(setIntl({ lan: _language }));
    setIsOpen(false);
  };

  return (
    <div key={`LanguageSwitcher_${id}`} className="language-switcher">
     <button
        className="language-toggle"
        onClick={() => setIsOpen(!isOpen)}
        >
        {currentLanguage?.flag}
      </button>
      {isOpen && (
        <ul className="language-list">
          {availableLanguages.map((language) => (
            <li key={language.code}>
              <button
                className="language-button"
                onClick={() => handleLanguageChange(language)}
                disabled={currentLanguage?.code === language.code}
              >
                <span>{language.flag}{'   '}{language.label}</span>                
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

LanguageSwitcher.defaultProps = {
  className: '',
};

LanguageSwitcher.displayName = 'LanguageSwitcher';
export default LanguageSwitcher;
