import React from 'react';
// import classnames from 'classnames';
import './insightec-target.scss';
import vertical from './vertical.png';
import h1 from './h1.png';
import h2 from './h2.png';
import verticalsvg from './vertical.svg';

export interface InsightecTargetProps  {
  title?: string | null, 
}

const InsightecTarget = (props: InsightecTargetProps) => {
  const { title } = props;
  return (
          <div className="insightec-target">
          InsightecTarget
          <img src={verticalsvg} alt="img" className='insightec-target__img'/>
          <img src={h1} alt="img" className='insightec-target__h1img'/>
          <img src={h2} alt="img" className='insightec-target__h2img'/>
          </div>
  );
};

InsightecTarget.defaultProps = {
  title : 'test',
};

InsightecTarget.displayName = 'InsightecTarget';


export default InsightecTarget;
