import React from 'react';
import { Badge, Text } from '../components/atoms';

const RenderStatusIcon = ({ status }: any): JSX.Element => {
  switch (status) {
    case 'cleared':
      return (
        <Badge label='cleared' color='green' />
      );
    case 'pending':
      return (
        <Badge label='pending' color='blue' />
      );
    case 'in progress':
      return (
        <Badge label='in progress' color='red' />
      );
    case 'finding':
      return (
        <Badge label='finding' color='red' />
      );
    default:
      return (
        <Badge label='unknown' color='gray' />
      );
  }
};

export default RenderStatusIcon;