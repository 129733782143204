import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BsPeople } from 'react-icons/bs';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FaClipboardList } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { IoStatsChart } from 'react-icons/io5';
import Pagination from '@mui/material/Pagination';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { IconButton, Label, Spacer } from '../../../components/atoms';
import { RegistryPatient, SearchBox } from '../../../components/molecules';
import { useGetPatientRegistryRequirementsQuery, registriesApi } from '../../../api/builders/registries.api';

export interface PageProps {
  registry: any;
}

interface LocationState {
  registry: any;
}

const CarePathwayTracker = () => {
  // const { registry } = props;

  const location = useLocation();
  const { registry } = location.state as LocationState;
  const navigate = useNavigate();
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [payload, setPayload] = React.useState<any>(null);
  const { data } = useGetPatientRegistryRequirementsQuery(payload !== null ? payload : skipToken);
  const { refetch } =
    registriesApi.endpoints.getPatientRegistryRequirements.useQuerySubscription(registry?.id ? registry.id : skipToken);
  const [patients, setPatients] = React.useState<any | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string>('');


  const handleChangeSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filteredPats = patients?.filter((pat: any) => (pat.mrn?.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  React.useEffect(() => {
    // const newBreadcrumb = {
    //   label: 'Tracker',
    //   path: location.pathname,
    //   state: { registry },
    // };
    // const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    // if (alreadyExists === false) {
    //   setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    // }
    const newBreadcrumb = [
      {
        label: registry?.name ?? 'Registry',
        path: '/private/registries/registry/overview',
        state: { registry },
      },
      {
        label: 'Tracker',
        path: location.pathname,
        state: { registry },
      },
    ];
    setBreadcrumbs(newBreadcrumb);
  }, [location.pathname]);


  React.useEffect(() => {
    if (registry) {
      const registryID = registry.id;
      setPayload({
        registryID,
        pageSize: 10,
        orderBy: 'recent',
      });
    }
  }, [registry]);

  const forward = () => {
    setPayload({
      ...payload,
      pageSize: 10,
      pageCurrent: data.pageCurrent + 1,
      orderBy: 'recent',
    });
  };

  const back = () => {
    setPayload({
      ...payload,
      pageSize: 10,
      pageCurrent: data.pageCurrent - 1,
      orderBy: 'recent',
    });
  };

  const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === data.pageCurrent) {
      return;
    }
    setPayload({
      ...payload,
      pageSize: 10,
      pageCurrent: newPage,
      orderBy: 'recent',
    });
  };

  const handleChangeTab = (newTab: string) => {
    navigate(`/private/registries/registry/${newTab}`, { state: { registry } });
  };

  React.useEffect(() => {
    if (data && Array.isArray(data.items) && data.items.length > 0) {
      // const pats = Array.isArray(patients) ? patients : [];
      // const curr = pats.concat(data.items);
      // setPatients(curr);
      setPatients(data.items);
    }
  }, [data]);


  if (!location?.state) {
    return (
      <div className='d-flex-row-center'>
        <Label size="h4" weight={600} color="primary">Error Loading Registry Details</Label>
      </div>
    );
  }
  return (
    <div className="registry">
      <div className="registry__header">
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('overview')}>
          <AiOutlineDashboard className='registry__icon' />
          <Label size='h5' uppercase>Overview</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('settings')}>
          <FiSettings className='registry__icon' />
          <Label size='h5' uppercase>Settings</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('patients')}>
          <BsPeople className='registry__icon' />
          <Label size='h5' uppercase>All Patients</Label>
        </button>
        <button type='button' className='registry__tab-active' onClick={() => handleChangeTab('tracker')}>
          <IoStatsChart className='registry__icon-active' />
          <Label size='h5' uppercase>Patient Tracker</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('conferences')}>
          <FaClipboardList className='registry__icon' />
          <Label size='h5' uppercase>Conferences</Label>
        </button>
      </div>
      <Spacer value={5} />
      <div className="care-pathway-tracker">
        <div className="care-pathway-tracker__header">
          <span className='d-flex'>
            <Label size="h3" weight={300}>
              Patient Care Pathway Tracker
            </Label>
            <IconButton icon='refresh' onClick={refetch} />
          </span>
          <SearchBox value={searchTerm} onChange={handleChangeSearch} />
        </div>
        <Spacer value={20} />

        <div className='care-pathway-tracker__table-container'>

          <table className="care-pathway-tracker__table">
            <thead>
              <tr>
                <th>
                  <Label size='h6' center uppercase>MRN</Label>
                </th>
                <th>
                  <Label size='h6' center uppercase>Current Path</Label>
                </th>
                <th>
                  <Label size='h6' center uppercase>last update</Label>
                </th>
                <th>
                  <Label size='h6' center uppercase>Care Pathway Tracker</Label>
                </th>
              </tr>
            </thead>
            <tbody>
              {(patients && Array.isArray(patients)) ?
                filteredPats.map((item: any) => (
                  <RegistryPatient
                    patient={item}
                    key={item.id}
                  />
                ))
                :
                <tr>
                  <td colSpan={4} >
                    <span className='care-pathway-tracker__empty'>
                      <Spacer value={30} />
                      <Spacer value={30} />
                      <Spacer value={30} />
                      <Spacer value={30} />
                      <Spacer value={30} />
                      <Spacer value={30} />
                      <Label size='h6'> Nothing to show</Label>
                      <Spacer value={30} />
                      <Spacer value={30} />
                      <Spacer value={30} />
                      <Spacer value={30} />
                      <Spacer value={30} />
                      <Spacer value={30} />

                    </span>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <Spacer value={20} />
        {data &&
          <div className='w-100 btn-row'>
            <Pagination count={data.pageTotal} page={data.pageCurrent} onChange={handleChange} />
          </div>
        }

      </div>
    </div>
  );
};

export default withAuthenticator(CarePathwayTracker);
