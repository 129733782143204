import React from 'react';
// import classnames from 'classnames';
import './link-patients-to-existing-cohort.scss';
import MenuItem from '@mui/material/MenuItem';
import { Tile, Dropdown, Spinner, Text, Spacer, Button } from '../../atoms';
import { useGetCohortsQuery } from '../../../api/builders/cohorts.api';

export interface LinkPatientsToExistingCohortProps {
  id: string,
  className?: string,
  onSubmit: (cohort: string) => void,
}

const LinkPatientsToExistingCohort = (props: LinkPatientsToExistingCohortProps): JSX.Element => {
  const { id, onSubmit } = props;
  const { data, isError, isFetching } = useGetCohortsQuery('');
  const [selected, setSelected] = React.useState<any | null>(null);

  const handleSelect = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelected(event.target.value);
  };

  const handleLink = () => {
    onSubmit(selected.id);
  };
  
  return (
    <div key={`LinkPatientsToExistingCohort_${id}`} className="card">
      {isFetching ?
        <Spinner />
        :
        <div>
          {data && data?.length > 0 &&
            <Dropdown
              name='Cohort'
              label='Cohort'
              value={selected}
              onChange={handleSelect}
            >
              {data.map((it: any) => <MenuItem value={it} >{it.cohortLabel} </MenuItem>)}
            </Dropdown>}
          <Spacer value={10} />
          <div className='btn-row'>
            <Button size='wide' onClick={handleLink}>Add</Button>
          </div>
          <Spacer value={10} />
        </div>
      }
    </div>
  );
};

LinkPatientsToExistingCohort.defaultProps = {
  className: '',
};

LinkPatientsToExistingCohort.displayName = 'LinkPatientsToExistingCohort';
export default LinkPatientsToExistingCohort;
