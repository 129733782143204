
const registryMenu = [
  {
    title: 'Registries',
    url: '/private/registries',
    private: true,
  },
];

export default registryMenu;

