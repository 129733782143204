/* eslint-disable */

import { Logger } from 'aws-amplify';

const FormulaParser = require('hot-formula-parser').Parser;
const parser = new FormulaParser();

const logger = new Logger('FormulaParserUtils');
// const FormulaParser = require('hot-formula-parser').Parser;

const computeScore = props => {
  const {surveyId, questions, scoreFormula} = props;
  let surveyscores = [];
  try {
    var questionsarray = [];
    if (!Array.isArray(questions)) {
      questionsarray = JSON.parse(questions);
    }
    if (typeof scoreFormula === 'string') {
      const _temp = JSON.parse(scoreFormula);
      let allscores = _temp;

      // let
      allscores.map(formula => {
        logger.info('--> formula', formula);
      });

      allscores = allscores.map(_score => {
        let score = null;
        //handling old format
        if (_score.hasOwnProperty('scores')) {
          score = _score.scores[0];
          score.label = _score?.type
        } else {
          score = _score;
        }

        let resultscore = -1;
        let formula = score.formula.replaceAll(/[\[\]]/g, '');
        formula = formula.replaceAll('Q', 'Q_');
        formula = formula.replaceAll('Q__', 'Q_');

        var parser = new FormulaParser();
        var totalparser = new FormulaParser();
        questionsarray.map(q => {
          if (q.answer.length === 0 ){
          } else {
            switch (q?.type) {
              case 'LIST_SINGLE':
                let max = q.options.reduce((prev, item) => {
                  let val = parseFloat(item.value);
                  prev = prev > val ? prev : val;
                  return prev;
                }, 0);
                parser.setVariable('Q_' + q.order, q?.answer[0].value);
                totalparser.setVariable('Q_' + q.order, max);
                break;
              default:
                let defmax = q.options.reduce((prev, item) => {
                  let val = parseFloat(item.value);
                  prev = prev > val ? prev : val;
                  return prev;
                }, 0);
                parser.setVariable('Q_' + q.order, q?.answer[0].value);
                totalparser.setVariable('Q_' + q.order, defmax);
            }
          }
        });
        let tmp = parser.parse(formula);
        let total = totalparser.parse(formula);
        if (tmp.error) {
          console.error('EEER', score, tmp, total);
          // throw tmp.error;
        } else {
          resultscore = {
            label: score.label,
            score: tmp?.result,
            totalScore: total?.result,
          };
          surveyscores.push(resultscore);
        }
        // let o = parser.parse('((1 + 5 ) / 10)*3')
      });
    }
    return surveyscores;
  } catch (e) {
    logger.error('ERROR IN FORMULA', e);
    return surveyscores;
  }
};

export default computeScore;