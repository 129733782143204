import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, Button, CohortUserItem, ShareCohort, PatientItem } from '../../../components';
import { useGetCohortInfoQuery, cohortsApis, useSendEmailMutation } from '../../../api/builders/cohorts.api';
import { IconButton } from '../../../components/atoms';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';

export interface Item {
  id: string,
  acl: string | null,
  description: string | null,
  shared: boolean,
  numberOfPatients: number,
  cohortLabel: string,
  createdAt: number
  type: string
}

interface LocationState {
  cohort: Item;
}

const Cohort = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cohort } = location.state as LocationState;
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetCohortInfoQuery({ id: cohort?.id ? cohort.id : skipToken, type: cohort?.type ? cohort.type : skipToken });
  const { refetch: refetchPats } =
    cohortsApis.endpoints.getCohortInfo.useQuerySubscription({ id: cohort?.id ? cohort.id : skipToken, type: cohort?.type ? cohort.type : skipToken });

  const [sendEmail] = useSendEmailMutation();
  const [shareModal, setShareModal] = React.useState(false);
  const [view, setView] = React.useState('participants');


  const handleAddPatients = () => {
    navigate(`/private/patients/${cohort.id}`);
  };


  const onSubmitShare = (payload: any) => {
    try {
      sendEmail(payload);
    } catch (e) {
      console.error('ERROR SENDING', e);
    }
  };

  const openShare = () => {
    setShareModal(true);
  };

  const closeShare = () => {
    setShareModal(false);
  };


  return (
    <div className="cohort" >
      <div className='cohort__header'>
        <Label size='h2' weight={200}>{cohort?.cohortLabel ?? 'Cohort'}</Label>
      </div>
      < div className="cohort__body" >
        <aside className='cohort__side-panel'>
          <div className='cohort__btn-wrapper'>
            <button className={view === 'participants' ? 'cohort__button-active' : 'cohort__button'} type='button' onClick={() => setView('participants')}> Participants</button>
            <button className={view === 'access' ? 'cohort__button-active' : 'cohort__button'} type='button' onClick={() => setView('access')}> Access</button>
            <button className={view === 'history' ? 'cohort__button-active' : 'cohort__button'} type='button' onClick={() => setView('history')}> Logs</button>
          </div>
        </aside>
        <div className='cohort__right-panel'>
          {view === 'participants' &&
            <div>
              <div className="study__heading d-flex-row _apart">
                <Label size="h4" weight={600} >Participants</Label>
                <div>
                  <IconButton icon="refresh" onClick={refetchPats} />
                  <IconButton icon="add" onClick={handleAddPatients} />
                </div>
              </div>
              <Grid container spacing={2}>
                <Grid xs={3}>
                  <Label center size='subtext'>MRN</Label>
                </Grid>
                <Grid xs={3}>
                  <Label center size='subtext'> Name</Label>
                </Grid>
                <Grid xs={2}>
                  <Label center size='subtext'> Age </Label>
                </Grid>
                <Grid xs={3}>
                  <Label center size='subtext'>Gender </Label>
                </Grid>
                <Grid xs={1}>
                  <Label center size='subtext'>Actions </Label>
                </Grid>
              </Grid>
              {Array.isArray(data) && data[0]?.patients && data[0].patients.map((item: any) => (
                <PatientItem
                  key={item.id}
                  id={item.id}
                  mrn={item.mrn}
                  firstname={item.firstname}
                  gender={item.PatientSex}
                  lastname={item.lastname}
                  checked={false}
                  editable={false}
                  handleCheck={() => console.info('hi')}
                  cohorts={[]}
                  dob={Number(item.PatientBirthDate)}
                  patient={item}
                  cohortId={cohort.id}
                  cohort={cohort.cohortLabel}
                />
              ))}
            </div>
          }

          {view === 'access' &&
            <div>
              <div className='study__heading d-flex-row _apart'>
                <Label size="h4" weight={600} >User Access</Label>
                <div>
                  <IconButton icon="refresh" onClick={refetchPats} />
                  <IconButton icon="add" onClick={openShare} />
                </div>
              </div>
              <Grid container spacing={2}>
                <Grid xs={4}>
                  <Label center size='subtext'>Email</Label>
                </Grid>
                <Grid xs={4}>
                  <Label center size='subtext'> Date Added </Label>
                </Grid>
                <Grid xs={4}>
                  <Label center size='subtext'>Actions</Label>
                </Grid>
              </Grid>
              {Array.isArray(data) && data[0]?.sharedWith && data[0].sharedWith.map((item: any) => (
                <CohortUserItem
                  email={item.email}
                  added={item.createdAt}
                />
              ))}
            </div>
          }

        </div>
      </div>

      <ShareCohort
        onOpen={shareModal}
        onClose={closeShare}
        addCohort={onSubmitShare}
        cohort={cohort}
      />
    </div>
  );
};

export default withAuthenticator(Cohort);