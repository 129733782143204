/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import './table-row.scss';
import { BiCircle } from 'react-icons/bi';
import { TableCell } from '@mui/material';
import { BsCheckCircle } from 'react-icons/bs';
import { HeaderProp } from '../TableHeader/TableHeader';

type Props = {
  onClick: (arg0: any) => any;
  columns: Array<HeaderProp>;
  data: any;
  key: string;
  noSelectors?: boolean;
  selected?: boolean;
};

const TableRow = (props: Props) => {
  const { data, columns, key, noSelectors, onClick, selected } = props;
  const [ isSelected, setIsSelected ] = useState(selected);
  
  const onRowClicked = (payload:any) => {
    onClick(payload);
  };

  return (
    <tr className="table-row">
      {!noSelectors &&
        <TableCell onClick={()=>setIsSelected((prev) => !prev)}>
          {isSelected ? <BsCheckCircle className='text-primary' size={16} /> : <BiCircle size={16} />}
        </TableCell>}
      {columns.map((column, j) => (
        <TableCell
          // eslint-disable-next-line react/no-array-index-key
          key={`row-cell-${key}-${j}`}
          onClick={onRowClicked}
        >
          {data[column.accessor]}
        </TableCell>
      ))}
    </tr>
  );
};

TableRow.defaultProps = {
  noSelectors: false,
  selected:false,
};

export default TableRow;
