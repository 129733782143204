import api from '../api';

export const conferencesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createConference: builder.mutation({
      query: (payload) => ({ url: '/conference/', method: 'POST', body: payload }),
      invalidatesTags: ['CONFERENCS'],
    }),
    updateConference: builder.mutation({
      query: ({ conferenceUID, payload }) => ({
        url: `/conference/${conferenceUID}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['CONFERENCE', 'CONFERENCS'],
    }),
    deleteConference: builder.mutation({
      query: ({ conferenceUID }) => ({ url: `/conference/${conferenceUID}`, method: 'DELETE' }),
      invalidatesTags: ['CONFERENCE', 'CONFERENCS'],
    }),
    getConferences: builder.query({
      query: (patientRegistryUID) => ({ url: `/conference/${patientRegistryUID}`, method: 'GET' }),
      providesTags: ['CONFERENCS'],
      transformResponse: (response: any) => response.items,
    }),
    getConferenceStatsById: builder.query({
      query: (id) => ({ url: `/conference/statsbyid/${id}`, method: 'GET' }),
      providesTags: ['CONFERENCE'],
      transformResponse: (response: any) => response.items,
    }),
    addPatientToConference: builder.mutation({
      query: (payload) => ({ url: '/patient/conference/', method: 'POST', body: payload }),
      invalidatesTags: ['CONFERENCEPATS', 'CONFERENCE'],
    }),
    getBlackholePatients: builder.query({
      query: (registryId) => ({ url: `/patientregistry/blackhole/${registryId}`, meathod: 'GET' }),
      providesTags: ['CONFERENCS'],
      transformResponse: (response: any) => response.items,
    }),
  }),
});

export const {
  usePrefetch,
  useCreateConferenceMutation,
  useUpdateConferenceMutation,
  useDeleteConferenceMutation,
  useGetConferencesQuery,
  useGetConferenceStatsByIdQuery,
  useAddPatientToConferenceMutation,
  useGetBlackholePatientsQuery,
} = conferencesApi;
