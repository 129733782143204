/* eslint-disable import/no-extraneous-dependencies */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          welcome: 'Welcome to <strong>Insightec</strong> Decentralized Data Management',
          logIn: 'Log In',
          logOut: 'Log Out',
          email: 'Email',
          password: 'Password',
          forgotPassword: 'Forgot Password',
          Trials: 'Trials',
          Registries: 'Registries',
          Insights: 'Insights',
          search: 'Search',
          Sites: 'Sites',
          'Imaging Analysis':'Imaging Analysis',
          'Surveys':'Surveys',
          'Documents':'Documents',
          'Radiology':'Radiology',
        },
      },
      fr: {
        translations: {
          welcome: 'Bienvenue dans la gestion décentralisée des données de <strong>Insightec</strong>',
          logIn: 'Se connecter',
          logOut: 'Se Déconnecter',
          email: 'Email',
          password: 'Mot de passe',
          forgotPassword: 'Mot de passe oublié',
          Trials: 'Etudes',
          Registries: 'Registres',
          Insights: 'Guides',
          search: 'Rechercher',
          'Imaging Analysis':'Analyses d\'images',
          'Surveys':'Questionnaires',
          'Documents':'Documents',
          'Radiology':'Radiologie',
        },
      },
      it: {
        translations: {
          welcome: 'Benvenuti nella gestione decentralizzata dei dati di <strong>Insightec</strong>',
          logIn: 'Accedi',
          email: 'Email',
          password: 'Password',
          forgotPassword: 'Password dimenticata',
        },
      },
      es: {
        translations: {
          welcome: 'Bienvenido/a a la gestión descentralizada de datos de <strong>Insightec</strong>',
          logIn: 'Iniciar sesión',
          email: 'Correo electrónico',
          password: 'Contraseña',
          forgotPassword: '¿Olvidaste tu contraseña?',
        },
      },
      nl: {
        translations: {
          welcome: 'Welkom bij het gedecentraliseerde gegevensbeheer van <strong>Insightec</strong>',
          logIn: 'Inloggen',
          email: 'E-mail',
          password: 'Wachtwoord',
          forgotPassword: 'Wachtwoord vergeten',
        },
      },
      de: {
        translations: {
          welcome: 'Willkommen bei der dezentralen Datenverwaltung von <strong>Insightec</strong>',
          logIn: 'Anmelden',
          email: 'E-Mail',
          password: 'Passwort',
          forgotPassword: 'Passwort vergessen',
        },
      },
      he: {
        translations: {
          welcome: 'ברוכים הבאים לניהול הנתונים הלא מרכזי של <strong>Insightec</strong>',
          logIn: 'התחברות',
          email: 'אימייל',
          password: 'סיסמה',
          forgotPassword: 'שכחת את הסיסמה',
        },
      },
      ja: {
        translations: {
          welcome: '<strong>Insightec</strong> の分散型データ管理へようこそ',
          logIn: 'ログイン',
          email: 'メールアドレス',
          password: 'パスワード',
          forgotPassword: 'パスワードをお忘れですか？',
        },
      },
      ko: {
        translations: {
          welcome: '인사이텍 탈중앙화된 데이터 관리에 오신 것을 환영합니다',
          logIn: '로그인',
          email: '이메일',
          password: '비밀번호',
          forgotPassword: '비밀번호를 잊으셨나요?',
        },
      },
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
