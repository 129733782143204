/* eslint-disable */
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiRefresh } from 'react-icons/bi';
import Grid from '@mui/material/Unstable_Grid2';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Label, Button, Spacer, Spinner, NewsItem } from '../../../components';
import { useGetActivitiesQuery, studyApi } from '../../../api/builders/study.api';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';


type RecentItemType = {
  id: string,
  participantId: string,
  type: string,
  siteId: number,
  backgroundUrl?: string,
  activities: Activity,
  secondaryId: string,
  drive_s3_path: string,
  updatedAt: number,
};

type Activity = {
  file: string | undefined,
  id: string | undefined,
  item: string | undefined,
  label: string | undefined,
  messages: string | undefined,
  order: number,
  createdAt: number | undefined,
  updatedAt: number | undefined,
  role: string | undefined,
  status: string,
  surveyId: string | undefined,
  type: string | undefined,
  userId: string | undefined,
  timePointId: string,
  visit: string,
};


type StudyItem = {
  label?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  summary?: string | undefined;
  acl?: string | undefined;
};


interface LocationState {
  study: StudyItem;
  acl: string,
}

const Recent = () => {
  const location = useLocation();
  const { study, acl } = location.state as LocationState;
  const navigate = useNavigate();
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);

  React.useEffect(() => {
    const newBreadcrumb = {
      label: 'Recent Activity',
      path: location.pathname,
      state: { study, acl },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);


  const { data, isFetching } = useGetActivitiesQuery({ studyId: study?.id ? study.id : skipToken });
  const { refetch: refetchActivites } =
    studyApi.endpoints.getActivities.useQuerySubscription({ studyId: study?.id ? study.id : skipToken });

  const isSmallDesktopWindow = useMediaQuery(
    { maxWidth: 950 }, undefined,
  );

  const onOpenSpecific = (_item: Activity, news: RecentItemType) => {
    const newsItem = {
      protocolItemId: _item.id,
      timePointId: _item.timePointId,
    };
    navigate('/private/studies/study/site/participant', { state: { participant: news, sitePath: news.drive_s3_path, activity: newsItem, acl } });
  };

  const sort = (items: RecentItemType[]) => {
    try {
      const copy = JSON.parse(JSON.stringify(items));
      const fitlered = copy.filter((it: RecentItemType) => it.activities !== null);
      fitlered.sort((a: RecentItemType, b: RecentItemType) => b.updatedAt - a.updatedAt);
      return fitlered;
    } catch {
      console.error('err sorting');
      return items;
    }
  };


  return (
    <div className="recent">
      <div className={isSmallDesktopWindow ? 'recent__header-sm' : 'recent__header'}>
        <Label size="h2" weight={300} >Recent Activity {Array.isArray(data) && `(${sort(data).length})`}
        </Label>
        <Spacer value={10} />
        <Button kind='ghost' icon={BiRefresh} onClick={refetchActivites} />
      </div>
      {isFetching ?
        <div className='super-centered'>
          <Spacer value={30} />
          <Spacer value={30} />
          <Spinner />
        </div>
        :
        <div className={isSmallDesktopWindow ? 'recent__body-sm' : 'recent__body'}>
          <div className='recent__theader' >
            <Grid container spacing={2}>
              <Grid xs={2}>
                <Label size='subtext'>Date</Label>
              </Grid>
              <Grid xs={2}>
                <Label size='subtext'>Participant</Label>
              </Grid>
              <Grid xs={2}>
                <Label size="subtext" >Visit</Label>
              </Grid>
              <Grid xs={3}>
                <Label size="subtext" >Item</Label>
              </Grid>
              <Grid xs={2}>
                <Label size="subtext" >Status</Label>
              </Grid>
              <Grid xs={1} >
                <div />
              </Grid>
            </Grid>
          </div>
          {Array.isArray(data) && sort(data).map((item: RecentItemType, index: number) => (
            <NewsItem news={item} key={item.updatedAt + index} onOpenSpecific={onOpenSpecific} acl='normal' />
          ))}
        </div>
      }
    </div>
  );
};

export default withAuthenticator(Recent);
