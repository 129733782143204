import React from 'react';
// import classnames from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import { AiFillEdit } from 'react-icons/ai';
import './met-protocol-status.scss';
import FlaggableDropdown from '../FlaggableDropdown/FlaggableDropdown';
import { Label, Button, Spacer } from '../../atoms';

export interface MetProtocolStatusProps {
  id: string | undefined,
  clinicalStatus?: string,
  corticosteroidUse?: string,
  handleChangeMetStats: (itemId: string, status: string, steroid: string) => void,
}
const Initial = {
  status: '',
  steroid: '',
};


const MetProtocolStatus = (props: MetProtocolStatusProps): JSX.Element => {
  const { id, clinicalStatus, corticosteroidUse, handleChangeMetStats } = props;
  const [values, setValues] = React.useState(Initial);
  const [edit, setEdit] = React.useState(false);


  React.useEffect(() => {
    if (clinicalStatus) {
      setValues((prev: any) => ({ ...prev, status: clinicalStatus }));
    }
  }, [clinicalStatus]);

  React.useEffect(() => {
    if (corticosteroidUse) {
      setValues((prev: any) => ({ ...prev, steroid: corticosteroidUse }));
    }
  }, [corticosteroidUse]);

  const handleSubmitChanges = () => {
    handleChangeMetStats(
      id ?? 'unknown',
      values.status,
      values.steroid,
    );
    setEdit(false);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  return (
    <div className='met-protocol-status'>
      <Spacer value={20} />
      <div className='met-protocol-status__heading'>
        <Label size='h4' weight={600}>Status:</Label>
        {!edit &&
          // <AiFillEdit className="met-manager__table-open" size={16} onClick={() => setEdit(true)} />
          <Button kind='ghost' icon={AiFillEdit} onClick={() => setEdit(true)} />
        }
      </div>
      <Spacer value={5} />

      {edit ?
        <div>
          <div>
            <FlaggableDropdown
              name='steroid'
              label='Corticosteroid Use'
              value={values.steroid}
              onChange={handleChangeInput}
            >
              <MenuItem value='stable'>Stable</MenuItem>
              <MenuItem value='Increased'>Increased</MenuItem>
              <MenuItem value='Decreased'>Decreased</MenuItem>
            </FlaggableDropdown>
          </div>
          <div>
            <FlaggableDropdown
              name='status'
              label='Status'
              value={values.status}
              onChange={handleChangeInput}
            >
              <MenuItem value='stable'>Stable</MenuItem>
              <MenuItem value='improved'>Improved</MenuItem>
              <MenuItem value='worse'>Worse</MenuItem>
            </FlaggableDropdown>
          </div>
          <Spacer value={20} />
          <div className='btn-row'>
            <Button kind='danger' onClick={() => setEdit(false)}>Cancel</Button>
            <Button onClick={handleSubmitChanges}>Save Changes</Button>
          </div>
        </div>
        :
        <div>
          <Label size="h5">Clinical Status: {clinicalStatus && clinicalStatus !== '' ? clinicalStatus : 'Unkown'}</Label>
          <Spacer value={5} />
          <Label size="h5">Corticosteroid Use: {corticosteroidUse && corticosteroidUse !== '' ? corticosteroidUse : 'Unkown'}</Label>
        </div>
      }

    </div>
  );
};

MetProtocolStatus.defaultProps = {
  // id: 'MetProtocolStatus',
  corticosteroidUse: 'unkown',
  clinicalStatus: 'unkown',
};

MetProtocolStatus.displayName = 'MetProtocolStatus';

export default MetProtocolStatus;
