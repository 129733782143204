/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
// import classnames from 'classnames';
import './crf-form-attachement.scss';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { Snapshot } from '../../../store/snapshots';

export interface CRFFormAttachementProps  {
  id: string,
  className?: string,
  notes:string;
  filled: boolean | false;
  file?: File;
  snapshot?: Snapshot;
  onDrop?: (arg0: File) => void;
}

const CRFFormAttachement = (props: CRFFormAttachementProps): JSX.Element => {
  const { id, className, notes, filled, file, snapshot, onDrop } = props;
  const [isDragOver, setIsDragOver] = useState(false);
  const [droppedImage, setDroppedImage] = useState<string | null>(null);
  const onFileDrop = (file: File) => {
    onDrop && onDrop(file);
  };
  
  useEffect(() => {
    if (file && file.type === 'image/png') {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const image = fileReader.result as string;
        setDroppedImage(image);
      };
      fileReader.readAsDataURL(file);
    }
  }, [file]);
  
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const { dataTransfer } = event;
    if (dataTransfer.items) {
      // Modern browsers support items
      for (let i = 0; i < dataTransfer.items.length; i++) {
        const item = dataTransfer.items[i];
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file && file.type === 'image/png') {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              const image = fileReader.result as string;
              // setDroppedImage(image);
            };
            fileReader.readAsDataURL(file);
            onFileDrop(file);
            return; // Stop processing other items
          }
        }
      }
    } else {
      // Legacy browsers support files
      for (let i = 0; i < dataTransfer.files.length; i++) {
        const file = dataTransfer.files[i];
        if (file.type === 'image/png') {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const image = fileReader.result as string;
            // setDroppedImage(image);
          };
          fileReader.readAsDataURL(file);
          onFileDrop(file);
          return; // Stop processing other files
        }
      }
    }

  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };
  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };
  return (
    <div className={`attachement ${filled && 'filled'} ${isDragOver && 'drag-over'} ${className}`}
    onDrop={handleDrop}
    onDragOver={handleDragOver}
    onDragLeave={handleDragLeave}
    >
   { droppedImage && (<img src={droppedImage} alt="Dropped Image" className="attachement-image" />)}
   { !droppedImage && (
    <div className='dicom-uploader-tagline-wrapper'>
      <AiOutlineCloudUpload size={40} />
    </div>)}
     {/* <div>{notes}</div> */}
      {/* <div>Attach Me</div>
      <div>{notes}</div> */}
    
    </div>
  );
};

CRFFormAttachement.defaultProps = {
  className: '',
};

CRFFormAttachement.displayName = 'CRFFormAttachement';
export default CRFFormAttachement;
