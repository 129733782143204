import React from 'react';
// import classnames from 'classnames';
import './icon-text-input.scss';
import { Label } from '../../atoms';

export interface IconTextInputProps {
  disabled?: boolean,
  invalid?: boolean,
  label: string | undefined,
  name?: string,
  value?: string | number,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: (event: React.KeyboardEvent) => void,
  id?: string,
  placeholder?: string,
  type?: string,
  autocomplete?: string,
  error?: string,
  className?: string,
  icon: any;
}

const IconTextInput = (props: IconTextInputProps): JSX.Element => {
  const { error, disabled, invalid, label, name, value, onChange, id, placeholder, type, autocomplete, className, onKeyDown, icon } = props;
  const [blur, setBlur] = React.useState(false);

  return (
    <div className='icon-text-input__wrapper'>
      {label && <Label className="input__label" size="h5" weight={600} color="primary" >{label}</Label>}
      <div className='icon-text-input'>
        <div
          className={`icon-text-input__input ${blur && 'icon-text-input__input--focused'}`}
        >
          <div className='icon-text-input__input-adornment'>
            {icon}
          </div>
          <input
            id={id}
            aria-label={id}
            onChange={onChange}
            className='icon-text-input__input-field'
            value={value}
            disabled={disabled}
            autoComplete={autocomplete}
            type={type}
            placeholder={placeholder}
            name={name}
            onKeyDown={onKeyDown}
            onFocus={() => setBlur(!blur)}
            onBlur={() => setBlur(false)}
          />
        </div>
      </div>
    </div>
  );
};

IconTextInput.defaultProps = {
  id: 'randomidhere',
  disabled: false,
  invalid: false,
  type: 'text',
  name: '',
  value: '',
  onChange: () => { },
  onKeyDown: () => { },
  placeholder: null,
  autocomplete: 'off',
  error: '',
  className: 'text-input',
};

IconTextInput.displayName = 'IconTextInput';
export default IconTextInput;
