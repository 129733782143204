/* eslint-disable  */
import React from 'react';
// import classnames from 'classnames';
import './treatment-plan.scss';
import { Label, Spacer, DatePicker, Spinner, Button } from '../../atoms';


export interface TreatmentPlanProps {
  id: string,
  className?: string,
  item: ProtocolType
  handleUpdateTreatment: (id: string, content: any, status: string) => void,
  itemStatus: string,
  isEdititng: boolean,
}

type ProtocolType = {
  file: string | undefined;
  id: string | undefined;
  item: string | undefined;
  label: string | undefined;
  messages: string | undefined;
  order: number;
  createdAt: number | undefined;
  updatedAt: number | undefined;
  role: string | undefined;
  status: string | undefined;
  surveyId: string | undefined;
  type: string | undefined;
  userId: string | undefined;
  content: string,
};

const TreatmentPlan = (props: TreatmentPlanProps): JSX.Element => {
  const { id, className, item, handleUpdateTreatment, itemStatus, isEdititng } = props;
  const [visits, setVisits] = React.useState<any>(null);

  console.log('visits', visits);


  React.useEffect(() => {
    if (item && item.content) {
      const tmp = JSON.parse(item.content);
      setVisits(tmp);
    }
  }, [item]);

  const sort = (items: any) => {
    try {
      const arrayForSort = [...items];
      let test = [];
      test = arrayForSort.sort((a, b) => b.createdAt - a.createdAt);
      return test;
    } catch {
      return items;
    }
  };

  const onChangeDate = (e: any, vi: number, ii: number) => {
    const tmp = [...visits];
    const formattedDate = new Date(e).toISOString().split('T')[0];
    tmp[vi].timepoints[ii].value = formattedDate;
    setVisits(tmp);

    // let _val = moment(e).format('MM/DD/YYYY')
  };

  const saveChanges = () => {
    handleUpdateTreatment(id, visits, itemStatus);
  };


  return (
    <div key={`TreatmentPlan_${id}`} className="card">
      {isEdititng ?
        <div className='h-100 w-100 super-centered'>
          <Spinner />
        </div>
        :
        <div>
          <div className="table-container">
            <table className="treatment-plan__table">
              <tbody>
                {Array.isArray(visits) &&
                  visits.length > 0 &&
                  visits.map((visitItem: any, visitIndex) => (
                    <tr key={`visitItem_${visitIndex}`}>
                      <th>
                        <Label size="h4">{visitItem.item}</Label>
                      </th>
                      {Array.isArray(visitItem.timepoints) &&
                        sort(visitItem.timepoints).map((itemTimepoint: any, itemIndex: number) => (
                          <td key={`itemTimepoint_${itemIndex}`}>
                            <DatePicker
                              key={itemTimepoint.id}
                              value={itemTimepoint.value}
                              label={itemTimepoint.item}
                              id={itemTimepoint.item}
                              onChange={(e) => onChangeDate(e, visitIndex, itemIndex)}
                            />
                          </td>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/* {Array.isArray(visits) && visits.length > 0 && visits.map((visitItem: any, visitIndex) => (
            <div className='treatment-plan__item'>
              <div className='treatment-plan__item-title'>
                <Label size='h4'>{visitItem.item}</Label>
              </div>
              {Array.isArray(visitItem.timepoints) && visitItem.timepoints.length > 0 && sort(visitItem.timepoints).map((itemTimepoint: any, itemIndex: number) => (
                <DatePicker
                  value={itemTimepoint.value}
                  label={itemTimepoint.item}
                  id={itemTimepoint.item}
                  onChange={(e) => onChangeDate(e, visitIndex, itemIndex)}
                />
              ))}
            </div>
          ))} */}
          <Spacer value={20} />
          <div className='btn-row'>
            <Button onClick={saveChanges}>
              Save Changes
            </Button>
          </div>
        </div>
      }
      <Spacer value={20} />
      <Spacer value={20} />

    </div>
  );
};

TreatmentPlan.defaultProps = {
  className: '',
};

TreatmentPlan.displayName = 'TreatmentPlan';
export default TreatmentPlan;
