import QueryString from 'qs';
import api from '../../../api/api';

export const sitesAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getSiteInfo: builder.query({
      query: (payload) => ({ url: `/site/statsbyid/${payload.siteId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['SITE'],
    }),
    createParticipant: builder.mutation({
      query: (payload) => ({ url: '/participant', method: 'POST', body: payload }),
      invalidatesTags: ['SITE'],
    }),
    editParticipant: builder.mutation({
      query: (payload) => ({ url: `/participant/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['SITE'],
    }),
  }), 
});

export const { usePrefetch, useGetSiteInfoQuery, useCreateParticipantMutation, useEditParticipantMutation } = sitesAPI;
