import QueryString from 'qs';
import api from '../api';

export const logsApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllLogs: builder.query({
      query: (payload) =>  ({ url: '/log', method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['LOGS'],
    }),
    getCohortLogs: builder.query({
      query: (payload) => ({ url: `/log/statsbyid/${payload}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['LOGS'],
    }),
    createLog: builder.mutation({
      query: (payload) => ({ url: '/log/', method: 'POST', body: payload }),
      invalidatesTags: ['PROTOCOLLOGS'],
    }),
  }),
});

export const {  
  usePrefetch,
  useGetAllLogsQuery,
  useGetCohortLogsQuery,
  useCreateLogMutation,
} = logsApis;
