import React, { useState, useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tile, Label, Spacer, DatePicker, Button, Modal } from '../../atoms';
import TextAreaInput from '../TextAreaInput/TextAreaInput';
import CRFForms from '../CRFForms/CRFForms';
import FormItem from '../FormItem/FormItem';
import PrintForm from './PrintForm';
import './care-item-factory.scss';
import { useGetSavedCRFSQuery, registriesApi } from '../../../api/builders/registries.api';
import DocumentsDrive from '../DocumentsDrive/DocumentsDrive';

export interface CRFManagerProps {
  className?: string,
  item: any,
  filePath: string,
  updateItem: (pl: any) => void,
  carePathwayId: string,
  readOnly: boolean,
}


const CRFManager = (props: CRFManagerProps): JSX.Element => {
  const { item, filePath, updateItem, carePathwayId, readOnly } = props;
  const [questions, setQuestions] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [payload, setPl] = useState<any>(null);
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetSavedCRFSQuery(payload !== null ? payload : skipToken);
  const { refetch } =
    registriesApi.endpoints.getSavedCRFS.useQuerySubscription(payload !== null ? payload : skipToken);

  useEffect(() => {
    if (item && item.crfQuestions && typeof item.crfQuestions === 'string') {
      const parsed = JSON.parse(item.crfQuestions);
      setQuestions(parsed);
    }
  }, [item]);

  useEffect(() => {
    const tmp = {
      patientcarepathwayId: carePathwayId,
      stepId: item.id,
    };
    setPl(tmp);
  }, [carePathwayId]);


  const handleViewAllAnswer = (ans: any, index: number) => {
    const updatedSurvey = [...questions];
    updatedSurvey[index].answer = ans;
    setQuestions(updatedSurvey);
  };

  const saveProgress = () => {
    try {
      const pl = {
        stepId: item.id,
        crfQuestions: JSON.stringify(questions),
      };
      updateItem(pl);
    } catch (e) {
      console.error('e', e);
    }
  };

  const getClass = (indx: any) => {
    let adtnClass = '';
    try {
      if (questions.length === 1) {
        adtnClass = 'span-two';
      } else {
        if (indx > 0 && indx < questions.length - 1) {
          if (
            (questions[indx - 1].type === 'Boolean' || questions[indx - 1].type === 'LIST_SINGLE' || questions[indx - 1].type === 'SECTIONTITLE') &&
            (questions[indx + 1].type === 'Boolean' || questions[indx + 1].type === 'LIST_SINGLE' || questions[indx + 1].type === 'SECTIONTITLE')
          ) {
            adtnClass = 'span-two';
          }
        }
        if (indx === questions.length - 1) {
          if (questions[indx - 1].type === 'Boolean' || questions[indx - 1].type === 'LIST_SINGLE' || questions[indx - 1].type === 'SECTIONTITLE') {
            adtnClass = 'span-two';
          }
        }
      }
    } catch (e) {
      console.error('e', e, indx);
    }
    return adtnClass;
  };


  return (
    <div>
      <div className='crf-wrapper' >
        {questions?.map((q: any, index: number) => (
          <FormItem
            // eslint-disable-next-line react/no-array-index-key
            key={`survey-question-${q.id}-${index}`}
            question={q}
            onAnswer={(it: any) => handleViewAllAnswer(it, index)}
            currentIndex={index}
            editable={!readOnly}
            noNumbers
            className={getClass(index)}
          />
        ))}
      </div>

      <Spacer value={20} />
      <div className='btn-row'>
        {!readOnly &&
          <Button onClick={saveProgress}>Save Progress</Button>}
        {!readOnly &&
          <Button onClick={() => setOpen(true)}>Sign and Complete</Button>}
        {/* REGISTRYTODO::: Connect Send to Epic with api */}
        {/* <Button >Send to Epic</Button> */}
      </div>


      {/* TODO:: FIX DOCUMENT DRIVE, ALL VERSION SHOULD TAKE IN DATA? */}
      {/* {(Array.isArray(data) && data.length > 0) &&
        <DocumentsDrive data={data} refetch={refetch} itemId={item.id} />
      } */}

      <Spacer value={20} />

      <Modal open={open} onClose={() => setOpen(false)} title={item?.stepLabel}>
        <div className='crf-wrapper' >
          <PrintForm
            data={questions}
            item={item}
            title={`${item.description} Form`}
            path={`${filePath}/step/${item.id}/documents/`}
            submitSurvey={saveProgress}
            carePathwayId={carePathwayId}
            renderItem={(q: any, index: number) => <FormItem
              // eslint-disable-next-line react/no-array-index-key
              key={`survey-question-${q.id}-${index}`}
              question={q}
              onAnswer={(it: any) => handleViewAllAnswer(it, index)}
              currentIndex={index}
              editable={false}
              noNumbers
            />}
          />

        </div>
      </Modal>
    </div>
  );
};

CRFManager.defaultProps = {
  className: '',
};

CRFManager.displayName = 'CareItemFactory';
export default CRFManager;
