import React, { useEffect, useState } from 'react';
import { ImAttachment } from 'react-icons/im';
import { Button, Label, Spacer, Alert, IconButton } from '../../atoms';
import './care-pathway-visit-attachement.scss';


export interface CarePathwayVisitAttachementProps {
  className?: string,
  currentStep: any,
  // attachement?: any,
  handleLink: (pl: any) => void,
}



const CarePathwayVisitAttachement = (props: CarePathwayVisitAttachementProps): JSX.Element => {
  const { className, handleLink, currentStep } = props;
  const [isDragOver, setIsDragOver] = useState(false);
  const [droppedItems, setDroppedItems] = useState<any | null>([]);

  const handleDrop = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    try {
      if (e.dataTransfer.items) {
        const item = JSON.parse(e.dataTransfer.getData('ehrData'));
        if (item &&  currentStep?.patientStatus !== 'COMPLETED' ) {
          // Check if the item already exists in droppedItems based on its ID
          const isItemAlreadyDropped = droppedItems.some((droppedItem: any) => droppedItem.id === item.id);

          // If the item is not already in the droppedItems array, add it to the array
          if (!isItemAlreadyDropped) {
            const newArr = [...droppedItems, item];
            setDroppedItems((prevDroppedItems: any) => [...prevDroppedItems, item]);

          }

        }
      }
    } catch (err) {
      console.error(err);
    }
  };


  const handleSave = () => {
    try {
      const pl = {
        stepId: currentStep.id,
        visits: JSON.stringify(droppedItems),
      };
      handleLink(pl);
    } catch (e) {
      console.error(e);
    }
  };


  useEffect(() => {
    if (currentStep?.visits && currentStep?.visits !== 'null') {
      const tmp = JSON.parse(currentStep.visits);
      console.log('x', tmp);
      setDroppedItems(tmp);
    }
  }, [currentStep]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };
  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleRm = (itemId: string) => {
    const newArr = droppedItems.filter((item: any) => item.id !== itemId);
    setDroppedItems(newArr);
    // const pl = {
    //   stepId: currentStep.id,
    //   visits: JSON.stringify(newArr),
    // };
    // console.log(pl);
    // handleLink(pl);
  };


  return (
    <div>
      <div
        className={`care-pathway-visit-attachement ${isDragOver && 'drag-over'} ${className}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {droppedItems.length > 0 ? (
          droppedItems.map((item: any) => (
            <>
              <div className='care-pathway-visit-attachement__item' key={item.id}>
                <div>
                  <Label uppercase size='h5' weight={600}>
                    {item?.visitLabel ?? ''}
                  </Label>
                  <Spacer value={10} />
                  <Label uppercase size='h6' weight={600}>
                    {item?.visitDate ?? ' '}{item?.visitTime ?? ''}
                  </Label>
                  <Spacer value={10} />
                  <Label uppercase size='h6' weight={400}>
                    {item.attendingDoctorFirstname ?? ''}, {item.attendingDoctorLastname ?? ''}
                  </Label>
                  <Spacer value={10} />
                  <Label uppercase size='h6' weight={400} color='primary'>
                    {item.status ?? ''}
                  </Label>
                </div>
                <IconButton icon='close' onClick={() => handleRm(item.id)} />
              </div>
            </>
          ))
        ) : (
          <div className='care-pathway-visit-attachement__empty'>
            <ImAttachment size={16} className='care-pathway-visit-attachement__icon' />
            <Spacer value={5} />
            <Label size='h4' weight={600} color='ghost'>
              Attach EHR Items
            </Label>
          </div>
        )}
      </div>

      {
        droppedItems?.length > 0 && currentStep?.patientStatus !== 'COMPLETED' &&
        <div>
          <Spacer value={20} />
          <Button onClick={handleSave}>Save Changes</Button>
        </div>
      }
    </div>
  );
};

CarePathwayVisitAttachement.defaultProps = {
  className: '',
};

CarePathwayVisitAttachement.displayName = 'CarePathwayVisitAttachement';
export default CarePathwayVisitAttachement;
