/* eslint-disable no-plusplus */
import React from 'react';
import './read-only-met-review.scss';
import { AiFillEdit } from 'react-icons/ai';
import { BsCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import Grid from '@mui/material/Unstable_Grid2';
import { Dropdown, Label, Spacer, Button, Modal, Spinner, Checkbox } from '../../atoms';
import { useGetparticipantTumorsQuery } from '../../../api/builders/met.api';


export interface ReadOnlyMetReviewProps {
  participant: string | undefined,
  itemId: string | undefined,
  acl: string,
  role: string,
  isEdititng: boolean,
  status: string,
  handleReviewItem: (item: string, status: string) => void,
}

export interface Met {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  isTreated: boolean,
  isTreatable: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  visit?: string,
  tumorassessments: MetHx[] | [],
}
export interface MetHx {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  tumorAssessmentId: string,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  protocolItemId: string,
  isTreated: boolean,
  isTreatable: boolean,
}

const getRoleTest = (role: string) => {
  switch (role) {
    case 'CLR1':
      return 'Reviewer A';
    case 'CLR2':
      return 'Reviewer B';
    case 'CLTR':
      return 'Tie-Breaker';
    default:
      return 'Reviewer';
  }
};

const ReadOnlyMetReview = (props: ReadOnlyMetReviewProps) => {
  const { participant, status, itemId, acl, isEdititng, handleReviewItem, role } = props;
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetparticipantTumorsQuery({ participantId: participant });

  const getDiameters = (arr: { diameterAxial: number, diameterCoronal: number, diameterSagittal: number }[]) => {
    const newArr = [];
    if (arr && arr.length > 1) {
      for (let i = 0; i < arr.length; i++) {
        newArr.push(arr[i].diameterCoronal);
        newArr.push(arr[i].diameterSagittal);
        newArr.push(arr[i].diameterAxial);
      }
    }
    return newArr;
  };

  const getMaxD = (item: Met) => {
    let max = 0;
    const arr = getDiameters(item.tumorassessments);
    if (arr.length > 0) {
      max = arr.reduce((a, b) => Math.max(a, b));
    }
    return max;
  };

  const calcSum = () => {
    let theMaxestOfTheMax = 0;
    const arrayOfMaxes: any[] = [];
    if (Array.isArray(data) && data.length > 0) {
      data.map((item: Met) => (arrayOfMaxes.push(getMaxD(item))));
    }
    if (arrayOfMaxes.length > 0) {
      theMaxestOfTheMax = arrayOfMaxes.reduce((acc, num) => acc + num, 0);
    }
    return <Label uppercase size='h6'>{theMaxestOfTheMax > 0 ? `Sum of Longest Diameters: ${theMaxestOfTheMax}` : ''} </Label>;
  };




  const renderRow = (item: Met, index: number) => {
    if (item.tumorassessments.length > 0) {
      const findMatch = item.tumorassessments.filter(it => it.protocolItemId === itemId);
      if (findMatch && findMatch.length > 0) {
        return (
          <tr className='met-manager__met-item-wrapper' key={index} role="button" tabIndex={-1} >
            < td className='met-manager__table-td'>
              {item.tumorLabel ? item.tumorLabel : <span>-</span>}
            </td>
            < td className='met-manager__table-td'>
              {item.lobe ? item.lobe : <span>-</span>}
            </td>
            < td className='met-manager__table-td'>
              {item.laterality ? item.laterality : <span>-</span>}
            </td>
            <td className='met-manager__coords-td'>
              <Label center size='subtext'>RL / AP / SI</Label>
              <span>
                {item.RLCoord ? item.RLCoord : <span>-</span>} / {' '}
                {item.APCoord ? item.APCoord : <span>-</span>} / {' '}
                {item.SICoord ? item.SICoord : <span>-</span>}
              </span>
            </td>
            <td className='met-manager__coords-td'>
              <Label center size='subtext'>Axial / Coronal / Sagittal</Label>
              <p className='d-flex-row-center m-0'>
                {findMatch[0].diameterAxial ? findMatch[0].diameterAxial : <span>-</span>}  /{' '}
                {findMatch[0].diameterCoronal ? findMatch[0].diameterCoronal : <span>-</span>} /{' '}
                {findMatch[0].diameterSagittal ? findMatch[0].diameterSagittal : <span>-</span>}
              </p>
            </td>
            < td className='met-manager__table-td'>
              {item.isTreated ? <span>Yes</span> : <span>No</span>}
            </td>
            < td className='met-manager__table-td'>
              {item.isTreatable ? <span>Yes</span> : <span>No</span>}
            </td>
            {/* < td className='met-manager__table-td'>
              {calcSum(findMatch[0].diameterAxial, findMatch[0].diameterCoronal,  findMatch[0].diameterSagittal)}
            </td> */}
            < td className='met-manager__table-td'>
              {item?.visit && item.visit === 'Screening and Baseline' ? <BsCheckCircleFill /> : <BsFillXCircleFill />}
            </td>
          </tr>
        );
      }
    }
    return (
      <tr className='met-manager__met-item-wrapper' key={index} role="button" tabIndex={-1} >
        < td className='met-manager__table-td'>
          {item.tumorLabel ? item.tumorLabel : <span>-</span>}
        </td>
        < td className='met-manager__table-td'>
          {item.lobe ? item.lobe : <span>-</span>}
        </td>
        < td className='met-manager__table-td'>
          {item.laterality ? item.laterality : <span>-</span>}
        </td>
        <td className='met-manager__coords-td'>
          <Label center size='subtext'>RL / AP / SI</Label>
          <span>
            {item.RLCoord ? item.RLCoord : <span>-</span>} / {' '}
            {item.APCoord ? item.APCoord : <span>-</span>} / {' '}
            {item.SICoord ? item.SICoord : <span>-</span>}
          </span>
        </td>
        <td className='met-manager__coords-td'>
          <Label center size='subtext'>Axial / Coronal / Sagittal</Label>
          <p className='d-flex-row-center'>
            <span>-</span>  / <span>-</span> / <span>-</span>
          </p>
        </td>
        < td className='met-manager__table-td'>
          {item.isTreated ? <span>Yes</span> : <span>No</span>}
        </td>
        < td className='met-manager__table-td'>
          {item.isTreatable ? <span>Yes</span> : <span>No</span>}
        </td>
        < td className='met-manager__table-td'>
          {item?.visit && item.visit === 'Screening and Baseline' ? <BsCheckCircleFill /> : <BsFillXCircleFill />}
        </td>
      </tr>
    );
  };

  const renderReviewerRow = (item: Met, index: number) => {
    if (item.tumorassessments.length > 0) {
      const findMatch = item.tumorassessments.filter(it => it.protocolItemId === itemId);
      if (findMatch && findMatch.length > 0) {
        return (
          <tr className='met-manager__met-item-wrapper' key={index} role="button" tabIndex={-1} >
            < td className='met-manager__table-td'>
              {item.tumorLabel ? item.tumorLabel : <span>-</span>}
            </td>
            < td className='met-manager__table-td'>
              {item.lobe ? item.lobe : <span>-</span>}
            </td>
            < td className='met-manager__table-td'>
              {item.laterality ? item.laterality : <span>-</span>}
            </td>
            <td className='met-manager__coords-td'>
              <Label center size='subtext'>RL / AP / SI</Label>
              <span>
                {item.RLCoord ? item.RLCoord : <span>-</span>} / {' '}
                {item.APCoord ? item.APCoord : <span>-</span>} / {' '}
                {item.SICoord ? item.SICoord : <span>-</span>}
              </span>
            </td>
            <td className='met-manager__coords-td'>
              <Label center size='subtext'>Axial / Coronal / Sagittal</Label>
              <p className='d-flex-row-center'>
                {findMatch[0].diameterAxial ? findMatch[0].diameterAxial : <span>-</span>}  /{' '}
                {findMatch[0].diameterCoronal ? findMatch[0].diameterCoronal : <span>-</span>} /{' '}
                {findMatch[0].diameterSagittal ? findMatch[0].diameterSagittal : <span>-</span>}
              </p>
            </td>
          </tr>
        );
      }
    }
    return (
      <tr className='met-manager__met-item-wrapper' key={index} role="button" tabIndex={-1} >
        < td className='met-manager__table-td'>
          {item.tumorLabel ? item.tumorLabel : <span>-</span>}
        </td>
        < td className='met-manager__table-td'>
          {item.lobe ? item.lobe : <span>-</span>}
        </td>
        < td className='met-manager__table-td'>
          {item.laterality ? item.laterality : <span>-</span>}
        </td>
        <td className='met-manager__coords-td'>
          <Label center size='subtext'>RL / AP / SI</Label>
          <span>
            {item.RLCoord ? item.RLCoord : <span>-</span>} / {' '}
            {item.APCoord ? item.APCoord : <span>-</span>} / {' '}
            {item.SICoord ? item.SICoord : <span>-</span>}
          </span>
        </td>
        <td>
          <Label center size='subtext'>Axial / Coronal / Sagittal</Label>
          <p className='d-flex-row-center'>
            <span>-</span>  / <span>-</span> / <span>-</span>
          </p>
        </td>
      </tr>
    );
  };

  const sort = (items: Met[]) => {
    try {
      const arrayForSort = [...items];
      let test = [];
      test = arrayForSort.sort((a, b) => a.tumorLabel.localeCompare(b.tumorLabel));
      return test;
    } catch {
      console.error('failed to sort MET data');
      return items;
    }
  };

  const handleReviewStatus = (id: any, newStatus: string) => {
    handleReviewItem(id, newStatus);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ReviewActions = ({ access, id }: any): JSX.Element => {
    switch (access) {
      case 'CORELAB':
      case 'admin':
        return (<div className='btn-row'>
          <Button onClick={() => handleReviewStatus(itemId, 'rejected')} kind='danger'>Reject</Button>
          <Button onClick={() => handleReviewStatus(itemId, 'approved')} >Approve</Button>
        </div>);
      default:
        return (
          <div />
        );
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderActions = ({ itemStatus, access, id }: any): JSX.Element => {
    switch (itemStatus) {
      case 'pending review':
        return (<ReviewActions access={access} itemId={id} />);
      default:
        return (
          <div />
        );
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Factory = (): JSX.Element => {
    switch (acl) {
      case 'CLR1':
      case 'CLR2':
      case 'CLTR':
        return (
          <table className='met-manager__table'>
            <thead className='met-manager__table-thead'>
              <th className='met-manager__table-th'>MET</th>
              <th className='met-manager__table-th'>Lobe</th>
              <th className='met-manager__table-th'>Laterality</th>
              <th className='met-manager__table-th'>Coordinates</th>
              <th className='met-manager__table-th'>Diameters</th>
            </thead>

            <tbody>
              {Array.isArray(data) && data.length > 0 && sort(data).map((item: Met, index) => (
                renderReviewerRow(item, index)
              ),
              )}
            </tbody>
          </table>
        );
        break;
      case 'admin':
        return (
          <>
            <Label uppercase size="h6">Report by: {getRoleTest(role)} </Label>
            <Spacer value={10} />
            <table className='met-manager__table'>
              <thead className='met-manager__table-thead'>
                <th className='met-manager__table-th'>MET</th>
                <th className='met-manager__table-th'>Lobe</th>
                <th className='met-manager__table-th'>Laterality</th>
                <th className='met-manager__table-th'>Coordinates</th>
                <th className='met-manager__table-th'>Diameters</th>
                <th className='met-manager__table-th'>Treated</th>
                <th className='met-manager__table-th'>Treatable</th>
                <th className='met-manager__table-th'>Added At Baseline</th>
              </thead>

              <tbody>
                {Array.isArray(data) && data.length > 0 && sort(data).map((item: Met, index) => (
                  renderRow(item, index)
                ),
                )}
              </tbody>
            </table>
          </>
        );
      default:
        return (
          <table className='met-manager__table'>
            <thead className='met-manager__table-thead'>
              <th className='met-manager__table-th'>MET</th>
              <th className='met-manager__table-th'>Lobe</th>
              <th className='met-manager__table-th'>Laterality</th>
              <th className='met-manager__table-th'>Coordinates</th>
              <th className='met-manager__table-th'>Diameters</th>
              <th className='met-manager__table-th'>Treated</th>
              <th className='met-manager__table-th'>Treatable</th>
              <th className='met-manager__table-th'>Added At Baseline</th>
            </thead>

            <tbody>
              {Array.isArray(data) && data.length > 0 && sort(data).map((item: Met, index) => (
                renderRow(item, index)
              ),
              )}
            </tbody>
          </table>
        );
    }
  };

  return (
    <div className="card">
      {/* <div> {calcSum()} </div>
      <Spacer value={10} /> */}
      <Factory />
      <Spacer value={20} />
      {isEdititng ? <div className='w-100 h-100 super-centered'> <Spinner /> </div> : <RenderActions itemStatus={status} access={acl} id={itemId} />}

    </div>
  );
};

ReadOnlyMetReview.defaultProps = {
};

ReadOnlyMetReview.displayName = 'ReadOnlyMetReview';


export default ReadOnlyMetReview;

