import React from 'react';
import FileListItem from '../FileListItem/FileListItem';
// import classnames from 'classnames';
import './files-list.scss';

export interface FileListProps {
  files: FileSystemEntry[]
}

const FilesList = React.memo(
  (props: React.PropsWithChildren<FileListProps>) => (
    <div>
      {props.files.map((file: FileSystemEntry) => (
        <FileListItem key={`${file.name}`} file={file} />
      ))}
    </div>
  ),
);

FilesList.displayName = 'FilesList';

export default FilesList;
