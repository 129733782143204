/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import './met-manager.scss';
import { Dropdown, Label, Spacer, Button, Modal, Spinner, Checkbox } from '../../atoms';
import MetItemDetails from '../MetItemDetails/MetItemDetails';
import MetReview from '../MetReview/MetReview';
import MetBaselineMRI from '../MetBaselineMRI/MetBaselineMRI';
import MetTable from '../MetTable/MetTable';
import MetProtocolStatus from '../MetProtocolStatus/MetProtocolStatus';
import { useGetparticipantTumorsQuery, useAddTumorAsMutation, useEditTumorAsMutation, useEditTumorMutation } from '../../../api/builders/met.api';
import MultiPanels from '../MultiPanels/MultiPanels';
import SmartViewer from '../SmartViewer/SmartViewer';
// eslint-disable-next-line import/no-cycle


export interface MetManagerProps {
  participant: string | undefined,
  itemId: string | undefined,
  visitId: string | undefined,
  collapse: () => void,
  expand: () => void,
  itemStatus: string | undefined,
  handleChangeStatus: (item: string, status: string) => void,
  acl: string,
  reviewMode: boolean,
  clinicalStatus: string,
  corticosteroidUse: string,
  toggleReviewMode: () => void,
  baselineMRI: any,
  handleChangeMetStats: (itemId: string, status: string, steroid: string) => void,
  role: string,
  info: any,
  path: string | undefined,
  protocolItem: any,
}

export interface FlagItem {
  user: string,
  userEmail: string,
  message: string,
  time: string,
  item: string,
}
export interface TypeError {
  status: number,
  data: { message: string },
}

export interface Met {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  isTreated: boolean,
  isTreatable: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  visit?: string,
  tumorassessments: MetHx[] | [],
}

export interface MetHx {
  tumorLabel: string,
  RLCoord: number,
  APCoord: number,
  SICoord: number,
  image: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  tumorAssessmentId: string,
  volume: number,
  longDiameter: number,
  sumDiameter: number,
  status: string,
  steroid: string,
  isTumor: boolean,
  notes: string,
  lobe: string,
  laterality: string,
  id: string,
  tumorId?: string | undefined,
  protocolItemId: string,
  isTreated: boolean,
  isTreatable: boolean,
}


export interface MetAs {
  tumorId: string,
  protocolItemId: string | undefined,
  isTumor: boolean,
  type: string,
  diameterAxial: number,
  diameterCoronal: number,
  diameterSagittal: number,
  volume: number,
  location: string,
  RLCoord?: number,
  APCoord?: number,
  SICoord?: number,
  notes: string,
  status: string,
  steroid?: string,
  isTreated: boolean,
  isTreatable: boolean,
}



const MetManager = (props: MetManagerProps) => {
  const { participant, itemId, collapse, expand, handleChangeStatus, acl, toggleReviewMode, reviewMode, corticosteroidUse, handleChangeMetStats, clinicalStatus, visitId, baselineMRI, role, protocolItem, info, path } = props;
  const [current, setCurrent] = React.useState<Met | null>(null);
  const [editModal, setEditModal] = React.useState(false);
  const [error, setError] = React.useState({ open: false, message: '' });
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetparticipantTumorsQuery({ participantId: participant });
  const [addTumorAs, { isLoading: isAddingTumor, isError: isErrorAddingTumor }] = useAddTumorAsMutation();
  const [editTumor, { isLoading: isEditingTumorAs, isError: isErrorEditingTumorAs }] = useEditTumorMutation();
  const [editTumorAs, { isLoading: isEditingTumor, isError: isErrorEditingTumor }] = useEditTumorAsMutation();


  const cancelReviewMode = () => {
    toggleReviewMode();
    expand();
  };


  const submit = () => {
    if (itemId) {
      handleChangeStatus(itemId, 'pending review');
    }
  };

  const handleReviewMode = () => {
    toggleReviewMode();
    collapse();
  };


  const onChangeItem = (item: Met, indx: number) => {
    setCurrent(item);
    setEditModal(true);
  };

  const closeEdit = () => {
    setCurrent(null);
    setEditModal(false);
  };

  const addTumorAssessmnt = async (payload: MetAs) => {
    try {
      const tumorPl = {
        id: current?.id,
        body: {
          isTreated: payload.isTreated,
          isTreatable: payload.isTreatable,
          RLCoord: payload.RLCoord,
          APCoord: payload.APCoord,
          SICoord: payload.SICoord,
          // volume: payload.volume,
        },
      };
      editTumor(tumorPl);
      // }
      if (current && current?.tumorassessments?.length > 0) {
        const findMatch = current?.tumorassessments.filter(it => it.protocolItemId === itemId);
        if (findMatch && findMatch.length > 0) {
          const editVals = {
            tumorId: payload.tumorId,
            protocolItemId: payload.protocolItemId,
            isTumor: payload.isTumor,
            diameterAxial: payload.diameterAxial.toString(),
            diameterCoronal: payload.diameterCoronal.toString(),
            diameterSagittal: payload.diameterSagittal.toString(),
            volume: payload.volume,
            RLCoord: payload.RLCoord,
            APCoord: payload.APCoord,
            SICoord: payload.SICoord,
            notes: payload.notes,
            steroid: payload.steroid ? payload.steroid : '',
            status: payload.status,
          };
          await editTumorAs({
            tumorassessmentId: findMatch[0].id,
            body: editVals,
          });
        } else {
          await addTumorAs(payload);
        }
      } else {
        await addTumorAs(payload);
      }
      closeEdit();
    } catch (e) {
      const err = e as TypeError;
      if (err?.status === 400) {
        setError({
          open: true,
          message: err.data.message,
        });
      }
    }
  };

  return (
    <div className="met-manager">
      <Spacer value={20} />
      {reviewMode ?
        <MetReview
          mets={data}
          itemId={itemId}
          cancelReviewMode={cancelReviewMode}
          onChangeItem={onChangeItem}
          submit={submit}
          role={role}
          info={info}
          path={path}
          protocolItem={protocolItem}
        />
        :
        <div className="met-manager">
          <MultiPanels className="met-manager__panels_wrapper"  id="met-panels-wrapper">
            <div className="met_panel_1">
              <MetBaselineMRI 
                visitId={visitId} 
                participant={participant} 
                baselineMRI={baselineMRI} 
                role={role} 
                />
              {/* <Spacer value={20} /> */}
              <MetTable
                itemId={itemId}
                data={data}
                participant={participant}
                acl={acl}
                onChangeItem={onChangeItem}
                visitId={visitId}
                role={role}
              />
              <Spacer value={20} />
              <Spacer value={20} />
              {(acl === 'admin' || acl === 'SITE COORDINATOR') &&
                <MetProtocolStatus
                  clinicalStatus={clinicalStatus}
                  corticosteroidUse={corticosteroidUse}
                  id={itemId}
                  handleChangeMetStats={handleChangeMetStats}
                />}
              <Spacer value={20} />
              <Spacer value={20} />
              <div className='btn-row'>
                {data && data.length > 0 && <Button onClick={handleReviewMode}>Review and Sign</Button>}
              </div>
            </div>
            <div className="met_panel_2">
              <SmartViewer id="metsmartv" visitId={visitId} item={itemId} />
            </div>
          </MultiPanels>
          {/* <MetBaselineMRI visitId={visitId} participant={participant} baselineMRI={baselineMRI} role={role} />
          <Spacer value={20} />
          <MetTable
            itemId={itemId}
            data={data}
            participant={participant}
            acl={acl}
            onChangeItem={onChangeItem}
            visitId={visitId}
            role={role}
          />
          <Spacer value={20} />
          <Spacer value={20} />
          {(acl === 'admin' || acl === 'SITE COORDINATOR') &&
            <MetProtocolStatus
              clinicalStatus={clinicalStatus}
              corticosteroidUse={corticosteroidUse}
              id={itemId}
              handleChangeMetStats={handleChangeMetStats}
            />}
          <Spacer value={20} />
          <Spacer value={20} />
          <div className='btn-row'>
            {data && data.length > 0 && <Button onClick={handleReviewMode}>Review and Sign</Button>}
          </div> */}

        </div>
      }

      <Modal open={editModal} onClose={closeEdit} title={`Edit ${current?.tumorLabel ? current.tumorLabel : 'MET'}`} >
        <div>
          {current != null ?
            <MetItemDetails visitId={visitId} role={role} acl={acl} met={current} protocolItemId={itemId} addTumorAssessmnt={addTumorAssessmnt} loading={isEditingTumor || isAddingTumor} error={error} />
            :
            <Label>Error Loading Met Details</Label>
          }
        </div>
      </Modal>

    </div >
  );
};

MetManager.defaultProps = {
  // key: '_key',
};

MetManager.displayName = 'MetManager';


export default MetManager;

