/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import { BsCheckCircle, BsChevronRight, BsChevronLeft, BsBoxArrowInUpRight, BsTrash } from 'react-icons/bs';
import { BiCircle, BiLockAlt, BiLockOpenAlt, BiRefresh } from 'react-icons/bi';
import { IoAddSharp } from 'react-icons/io5';

import TableHeader, { HeaderProp } from './TableHeader/TableHeader';
import TableRow from './TableRow/TableRow';
import TableCell from './TableCell/TableCell';
import './data-table.scss';
import Paginator from './Paginator/Paginator';
import { TableState } from './types';

const defaultSize = Math.floor(window.innerHeight / 70);
const defaultSizeSm = Math.floor(window.innerHeight / 80);
type Props = {
  title: string;
  data: Array<any>;
  columns: Array<HeaderProp>;
  noSelectors?: boolean;
  noPagination?: boolean;
  noHeader?: boolean;
  small: boolean | false;
};



const DataTable = (props: Props) => {
  const { title, columns, data, noSelectors, small } = props;

  const [ sliced, setSliced ] = useState(data);
  const [ selected, setSelected ] = useState([]);
  // const [
  //   {
  //     rowsPerPage,
  //     currentPage,
  //     selectedRows,
  //     allSelected,
  //     selectedCount,
  //     selectedColumn,
  //     sortDirection,
  //     toggleOnSelectedRowsChange,
  //   },
  //   dispatch,
  // ] = React.useReducer<React.Reducer<TableState<T>, Action<T>>>(tableReducer, {
  //   allSelected: false,
  //   selectedCount: 0,
  //   selectedRows: [],
  //   selectedColumn: defaultSortColumn,
  //   toggleOnSelectedRowsChange: false,
  //   sortDirection: defaultSortDirection,
  //   currentPage: paginationDefaultPage,
  //   rowsPerPage: paginationPerPage,
  //   selectedRowsFlag: false,
  //   contextMessage: defaultProps.contextMessage,
  // });


  const onRowClicked = (payload:any) => {
    // console.info('rowclicked', payload);
  };

  const onRowSelected = (payload:any) => {
    // console.info('row selected', payload);
  };

  return (
    <div className="data-table-container__tile">
      <div className="data-table">
        <thead>
          <TableHeader
            headers={columns}
            noSelectors={noSelectors}
            onSelectAll={() => {
              // console.info('select all not done');
            }}
            small={small}
            selected={selected}
            sliced={sliced}
          />
        </thead>
        <tbody>
          {sliced.length > 0 ? (
            sliced.map((row, i) => (
              <TableRow columns={columns} data={row} key={`row-${i}`} 
              onClick={onRowClicked} noSelectors={noSelectors}
              />
            ))
          ) : (
            <div>No data</div>
          )}
        </tbody>
      </div>
      <Paginator 
      onChangePage={()=>console.info('change page')}
      onChangeRowsPerPage={()=>console.info('change page')}
      rowCount={500}
      currentPage={2}
      rowsPerPage={25}
      />
    </div>
  );
};
DataTable.defaultProps = {
  noSelectors : false,
  noPagination : false,
  noHeader : false,
};
export default DataTable;
