import React, { useState, useEffect } from 'react';
import { GrFormAdd } from 'react-icons/gr';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiFillPlusCircle } from 'react-icons/ai';
import { BiRefresh } from 'react-icons/bi';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { Label, Spacer, Button, Modal, Alert } from '../../../components';
import { SearchBox, AuditListItem, TextAreaInput } from '../../../components/molecules';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { useGetAuditsQuery, useCreateAuditMutation } from '../../../api/builders/hives.api';

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean;
  message: string;
  kind: AlertType;
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const Audits = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [newAuditSummary, setNewAuditSummary] = useState<string>('');
  const [addModal, setAddModal] = useState(false);
  const { data: audits, isError, isLoading, isFetching } = useGetAuditsQuery('');
  const [createAudit, { isLoading: isAdding, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useCreateAuditMutation();
  const filteredAudits = audits?.filter((audit: any) => (audit.description?.toLowerCase().includes(searchTerm.toLowerCase()) || audit.status?.toLowerCase().includes(searchTerm.toLowerCase())),
  );
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const openAdd = () => {
    setAddModal(true);
  };

  const closeAdd = () => {
    setAddModal(false);
    setNewAuditSummary('');
  };

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  const handleClick = (_item: any) => {
    navigate('/private/audits/audit', { state: { audit: _item } });
  };


  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Adding New Audit: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
      closeAdd();
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding New Audit',
        kind: 'success',
      });
      closeAdd();
    }
  }, [isSuccessAdding]);

  // useEffect(() => {
  //   const newBreadcrumb = {
  //     label: 'Audit',
  //     path: location.pathname,
  //   };
  //   const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);


  //   if (alreadyExists === false) {
  //     setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
  //   }
  // }, [location.pathname]);


  const handleChangeSearch = (value: string) => {
    setSearchTerm(value);
  };

  const addAudit = () => {
    try {
      createAudit({
        description: newAuditSummary,
      });
    } catch (e) {
      console.error('add auit err');
    }
  };


  const handleChangeSummary = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewAuditSummary(event.target.value);
  };


  return (
    <div className="audits">
      <div className="audits__header">
        <span className="audits__header-title">
          <Label size="h2" weight={300}>
            Audits
          </Label>
          <button type="button" className="icon-btn" aria-label="refetch" >
            <BiRefresh />
          </button>
        </span>
        <Spacer value={30} />
        <Spacer value={30} />
        <Spacer value={30} />
        <Spacer value={30} />
        {/* <button type='button' className='studies__icon-btn' aria-label='refetch' onClick={openAdd}>
          <AiFillPlusCircle size={24} className='studies__icon-btn-icon' />
        </button> */}
        <SearchBox value={searchTerm}
          onChange={handleChangeSearch}
          placeholder='search' />
      </div>
      <div className="audits__body">
        {(Array.isArray(filteredAudits) && filteredAudits.length > 0) ?
          <div>
            {filteredAudits.map((item: any) => (
              <AuditListItem onClick={handleClick} item={item} />
            ))}
          </div>
          :
          <div className="audits__empty">
            <Label color="ghost" size="h3">
              No Previous Audits
            </Label>
          </div>
        }
      </div>
      {/* <div className="audits__body">
        {(Array.isArray(filteredAudits) && filteredAudits.length > 0) ?
          < table >
            <tr>
              <th><Label center size="subtext">Item</Label></th>
              <th><Label center size="subtext">Date</Label></th>
              <th><Label center size="subtext"># of Items Audted</Label></th>
            </tr>
            {filteredAudits.map((item: any) => (
              <tr>
                <td>{item.item ?? 'N/A'}</td>
                <td>{item.date ?? 'N/A'}</td>
                <td>{item.number ?? 'N/A'}</td>
              </tr>
            ))}
          </table>
          :
          <div className="audits__empty">
            <Label color="ghost" size="h3">
              No Previous Audits
            </Label>
          </div>
        }
      </div> */}
      <Modal title='Create Audit' open={addModal} onClose={closeAdd}>
        <div>
          <TextAreaInput name="summary" label="Description:" value={newAuditSummary} onChange={handleChangeSummary} />
          <Spacer value={30} />
          <div className='btn-row'>
            <Button loading={isAdding} onClick={addAudit}>
              Create Audit
            </Button>
          </div>
        </div>
      </Modal>

      <Alert severity={alert.kind} open={alert.open} onClose={resetAlert} message={alert.message} />
    </div >
  );
};

export default withAuthenticator(Audits);
