import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import './protocol-image-analysis.scss';
import { useGetAnalysisDeetsQuery } from '../../../api/builders/imageAnalysis.api';
import CareStep from '../CareStep/CareStep';


export interface ProtocolImageAnalysisProps {
  protocolItem: any,
  participant: any,
  readOnly?: boolean,
}

const ProtocolImageAnalysis = (props: ProtocolImageAnalysisProps): JSX.Element => {
  const { protocolItem, participant, readOnly } = props;
  const { data } = useGetAnalysisDeetsQuery(protocolItem?.id ? protocolItem.id : skipToken);

  return (
    <div className="card">
      {(data && data[0]) &&
        <CareStep careCase={data[0]} participant={participant} readO={readOnly} />
      }
    </div>
  );
};

ProtocolImageAnalysis.defaultProps = {
  readOnly: false,
};

ProtocolImageAnalysis.displayName = 'ProtocolImageAnalysis';
export default ProtocolImageAnalysis;
