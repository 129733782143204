/* eslint-disable no-alert */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { Button, Label, TextInput, Spacer, Tile, Logo } from '../../components';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [hasSendCode, setHasSendCode] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [state, setState] = React.useState({
    username: '',
    code: '',
    password: '',
    confirmPassword: '',
    status: '',
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };


  const sendCode = async (username: string) => {
    try {
      setIsSending(true);
      setError(false);
      setHasSendCode(false);

      await Auth.forgotPassword(username.toLowerCase());
      setHasSendCode(true);
    } catch (err) {
      setError(true);
      // setError(err.message);
    } finally {
      setIsSending(false);
    }
  };

  const onSubmit = async (values: { username: any; password: any; confirmPassword: any; code: any; }) => {
    const { username, password, confirmPassword, code } = values;

    try {
      setIsUpdating(true);
      setError(false);

      if (password !== confirmPassword) {
        alert('Confirm password is not equal to password');
        return;
      }

      await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password);
      navigate('/login');
    } catch (err) {
      setError(true);
      // setError(err.message);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="auth" >
      <Label size="h1" bold color="primary" weight={900} uppercase>Reset Password</Label>
      <Spacer value={10} />

      {hasSendCode ?
        <div>
          <div>
            <Tile className="auth_tile" >
              <div className="auth__body">
                <div className="auth__form">
                  <div className="auth-form__inputs_wrapper">
                    <TextInput
                      id="password"
                      type="password"
                      value={state.password}
                      autocomplete="off"
                      // autocomplete="current-password"
                      placeholder={t('password') || 'Password'}
                      label={t('password') || 'Password'}
                      onChange={onChange}
                    />
                    <Spacer value={10} />
                    <TextInput
                      id="confirmPassword"
                      type="password"
                      autocomplete="off"
                      value={state.confirmPassword}
                      placeholder={t('confirm new password') || 'Confirm new Password'}
                      label={t('confirm new password') || 'Confirm new Password'}
                      onChange={onChange}
                    />
                    <Spacer value={10} />
                    <TextInput
                      id="code"
                      value={state.code}
                      placeholder={t('code') || 'Code'}
                      label={t('code') || 'Code'}
                      onChange={onChange}
                      autocomplete="new-password"
                    />
                  </div>
                  <Spacer value={10} />

                  <div className="super-centered auth__btn-wrapper">
                    <Button loading={state.status === 'loading'} size="large" className="auth__btn-styles" onClick={() => onSubmit(state)} >
                      {t('Reset Password')}
                    </Button>
                  </div>
                </div>
              </div>
            </Tile>

          </div>
        </div> :
        <div>
          <Tile className="auth_tile" >
            <div className="auth__body">
              <div className="auth__form">
                <div className="auth-form__inputs_wrapper">
                  <TextInput
                    id="username"
                    type="Email"
                    autocomplete="Email"
                    value={state.username}
                    placeholder={t('email') || 'Email'}
                    label={t('email') || 'Email'}
                    onChange={onChange}
                  />
                </div>
                <Spacer value={10} />

                <div className="super-centered auth__btn-wrapper">
                  <Button loading={state.status === 'loading'} size="large" className="auth__btn-styles" onClick={() => sendCode(state.username)} >
                    {t('Get Code')}
                  </Button>
                </div>
              </div>
            </div>
          </Tile>

        </div>
      }

      <Spacer value={10} />
      {error && <Label color='danger'>
        {t('Password cannot be reset. Please contact your administrator.')}
        </Label>}
    </div>
  );
};
export default ForgotPassword;
