import React, { useState, useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AiFillPauseCircle, AiOutlineArrowRight, AiOutlinePlus } from 'react-icons/ai';
import { Label, Button, Spinner, Spacer, Modal, Alert, Tile } from '../../../components';
import { AuditListItem, TextAreaInput } from '../../../components/molecules';
import { useGetAuditsByStudyQuery, useCreateAuditMutation } from '../../../api/builders/hives.api';


type Props = {
  study: any,
};


const StudyItem = ({ study }: Props) => {
  const { data: audits, isLoading: isLoadingAudits, isFetching: isFetchingAudits } = useGetAuditsByStudyQuery(study?.id ?? skipToken);
  const [newAuditSummary, setNewAuditSummary] = useState<string>('');
  const [addModal, setAddModal] = useState(false);
  const [listModal, setListModal] = useState(false);
  const [createAudit, { isLoading: isAdding, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useCreateAuditMutation();


  const openAdd = () => {
    setAddModal(true);
  };

  const closeAdd = () => {
    setAddModal(false);
    setNewAuditSummary('');
  };


  const openList = () => {
    setListModal(true);
  };
  const closeList = () => {
    setListModal(false);
  };

  const addAudit = () => {
    try {
      createAudit({
        description: newAuditSummary,
        studyId: study?.id,
      });
      closeAdd();
    } catch (e) {
      console.error('add auit err');
    }
  };


  const handleChangeSummary = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewAuditSummary(event.target.value);
  };

  return (
    <>
      <Tile className='hive__study-item' >
        <div>
          <div>
            <div className='hive__body-title'>
              <Label size="h4" weight={600} >{study.title}</Label>
              <AiFillPauseCircle size={30} className='pause-btn' />
            </div>
            <div className='hive__study-item-body'>
              <Spacer value={20} />
              <div >
                <span className='d-flex-center'>
                  <Label size="h2" weight={600} >03/22</Label>
                  <Spacer value={10} />
                  <span className='d-flex-col'>
                    <Label size="subtext" weight={200} >last </Label>
                    <Label size="subtext" weight={200} > audit</Label>
                  </span>
                </span>
                <Spacer value={5} />
                {/* <Label size="h5" weight={200} >Previous <AiOutlineArrowRight />
                </Label> */}
                <Button kind='ghost' onClick={openList} >Previous <AiOutlineArrowRight /></Button>
              </div>
              <Spacer value={20} />
              <Spacer value={20} />
              <Spacer value={20} />

              <div >
                <span className='d-flex-center'>
                  <Label size="h2" weight={600} >4</Label>
                  <Spacer value={10} />
                  <span className='d-flex-col'>
                    <Label size="subtext" weight={200} >risk </Label>
                    <Label size="subtext" weight={200} >score</Label>
                  </span>
                </span>
                <Spacer value={5} />
                <Button kind='ghost' onClick={openAdd} >New Audit <AiOutlinePlus />
                </Button>
              </div>

              <Spacer value={20} />
              <Spacer value={20} />
              <Spacer value={20} />
              <div>
                <span className='d-flex-center'>
                  <Label size="h2" weight={600} >27%</Label>
                  <Spacer value={10} />
                  <span className='d-flex-col'>
                    <Label size="subtext" weight={200} >images </Label>
                    <Label size="subtext" weight={200} >audited</Label>
                  </span>
                </span>
                <Spacer value={10} />
              </div>
            </div>
          </div>
        </div>
      </Tile>

      <Modal title='Create Audit' open={addModal} onClose={closeAdd}>
        <div>
          <TextAreaInput name="summary" label="Description:" value={newAuditSummary} onChange={handleChangeSummary} />
          <Spacer value={30} />
          <div className='btn-row'>
            <Button loading={isAdding} onClick={addAudit}>
              Create Audit
            </Button>
          </div>
        </div>
      </Modal>

      <Modal title='Previous Audits' open={listModal} onClose={closeList}>
        <div>
          {audits?.length > 0 ?
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {audits?.map((audit: any) => (
                <AuditListItem onClick={() => console.info('click')} item={audit} />
              ))}
            </>
            :
            <div>
              <Label size="h4" color='ghost' weight={200} >No Previous Audits</Label>
            </div>
          }
        </div>
      </Modal>
    </>
  );
};


export default StudyItem;
