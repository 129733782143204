import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { Tile, Text, Label, Spacer, SheildIcon, Button } from '../../atoms';


import './audit-action-item.scss';

export interface AuditActionItemProps {
  className?: string,
  item: any,
  onClick: (item: any) => void,
  active?: boolean,
  getImgs: (pl: any) => void,
  readOnly: boolean,
}

const AuditActionItem = (props: AuditActionItemProps): JSX.Element => {
  const { item, onClick, active, getImgs, readOnly } = props;

  const handleGetMoreImgs = () => {
    try {
      const payload = {
        numberOfTypeScans: 3,
        seriesId: item.id,
      };
      getImgs(payload);
    } catch (e) {
      console.error('e', e);
    }
  };

  return (
    <div className="card" role='button' tabIndex={-1} onClick={() => onClick(item)}>
      <Tile className={active ? 'audit-action-item__active' : 'none'}>
        <div className='audit-action-item__body'>
          <div>
            <Label uppercase size="h5" weight={600} >
              {item?.auditStatus === 'audited' && <FaCheck color='green' size={10} />} {' '}
              {item.SeriesDescription}
            </Label>
            <Spacer value={5} />
            <Label size="subtext" weight={400} >{item?.auditStatus ?? 'Incomplete'}</Label>
            <Spacer value={10} />
            {!readOnly && <button onClick={handleGetMoreImgs} type='button' className='audit-action-item__btn'>Add 3 similar</button>}
          </div>
          {item?.riskLevel !== 'null' &&
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {item.riskLevel === 1 ? <SheildIcon type='good' /> : <SheildIcon type='bad' />}
            </>
          }
        </div>
      </Tile>
    </div>
  );
};

AuditActionItem.defaultProps = {
  className: '',
  active: false,
};

AuditActionItem.displayName = 'AuditActionItem';
export default AuditActionItem;
