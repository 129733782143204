import React from 'react';
// import classnames from 'classnames';
import './cohort-user-item.scss';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BsTrash } from 'react-icons/bs';
import { Tile, Spacer, Label, Button, Modal, Badge, Checkbox } from '../../atoms';

export interface CohortUserItemProps {
  email: string,
  added: number,
  readonly?: boolean,
}


const CohortUserItem = (props: CohortUserItemProps): JSX.Element => {
  const { email, added, readonly } = props;



  return (
    <div className='cohort-user-item'>
      <Tile>
        <div className='cohort-user-item__body'>
          <Grid container spacing={2}>
            <Grid xs={4} >
              <Label size="h5" center>{email}</Label>
            </Grid>
            <Grid xs={4} >
              <Label size="h5" center> {moment(added).format('L')} </Label>
            </Grid>
            <Grid xs={4} >
              {!readonly &&
                <div className='super-centered'>
                  <BsTrash />
                </div>
              }
            </Grid>
          </Grid>
        </div>
      </Tile>
    </div>
  );
};

CohortUserItem.defaultProps = {
  readonly: false,
};

CohortUserItem.displayName = 'CohortUserItem';
export default CohortUserItem;

