/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Pie, PieChart, Sector, ComposedChart, ResponsiveContainer } from 'recharts';
import Grid from '@mui/material/Unstable_Grid2';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { Label, Spacer, Tile, Text } from '../../../components';
import { useGetImgStatsQuery } from '../../../api/builders/study.api';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';

type StudyItem = {
  label?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  summary?: string | undefined;
  sponsor?: string | undefined;
  studyId?: string | undefined;
  acl?: string | undefined;
};


type Site = {
  siteLabel: string;
  region?: string;
  participants?: { name: string; site: string }[] | null;
  status: string;
};

type SiteItemProps = {
  item: Site;
};
const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#20caf9',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#20caf9',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#20caf9',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#20caf9',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check sx={{ fontSize: 30 }} className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}



const renderStatus = (status: string) => {
  const steps = [
    { label: 'Legal', completed: true },
    { label: 'Ethics', completed: true },
    { label: 'IT', completed: true },
    { label: 'Training', completed: false },
    { label: 'Pending Start', completed: false },
    { label: 'Recruiting', completed: false },
    { label: 'Closed', completed: false },
  ];

  const getActiveStep = () => {
    switch (status.toLowerCase()) {
      case 'training':
        return 3
      case 'pending start':
        return 4
      case 'recruiting':
        return 5
      case 'closed':
        return 6
      default:
        return 3
        break;
    }
  }

  return (
    <>
      <Stepper activeStep={getActiveStep()} alternativeLabel connector={<QontoConnector />}>
        {steps.map(({ label, completed }) => (
          <Step key={label} >
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};



const renderComplted = () => {

  const data = [
    { name: 'All Participants', value: 200 },
    { name: 'Completed', value: 0 },
  ];

  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`Completed: ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          ( 0% )
        </text>
      </g>
    );
  };

  return (
    <PieChart width={400} height={300} margin={{ left: 10, right: 10 }}>
      <Pie
        activeIndex={1}
        activeShape={renderActiveShape}
        data={data}
        cx={140}
        cy={150}
        innerRadius={60}
        outerRadius={80}
        fill="#20caf9"
        dataKey="value"
      />
    </PieChart>
  );
};


interface LocationState {
  study: any;
}

type ChartData = Record<string, number>;


const SiteItem: React.FC<SiteItemProps> = ({ item }) => (
  <Grid container spacing={5} className='study-dash__site-item'>
    <Grid sm={2} className='study-dash__center'>
      <Label center size='h4'>{item.siteLabel}</Label>
    </Grid>
    <Grid sm={2} className='study-dash__center'>
      <Label center size='h4'>{item.region?.toUpperCase()}</Label>
    </Grid>
    <Grid sm={8}>
      {renderStatus(item.status)}
    </Grid>
  </Grid>
);


const StudyDashboard = () => {
  const location = useLocation();
  const { study } = location.state as LocationState;
  const {
    data,
    isLoading: isLoadingData,
    isFetching: isFetchingData,
    isError: ErrorFetching,
  } = useGetImgStatsQuery(study?.id ?? skipToken);
  const { t, i18n } = useTranslation();
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);


  React.useEffect(() => {
    const newBreadcrumb = {
      label: 'Study Dashboard',
      path: location.pathname,
      state: { study },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);


  const formatChartData = (data: any) => {
    const chartData = Object.entries(data)
      .filter(([key]) => key !== "Total image count")
      .map(([name, value]) => ({ name, value }));

    return chartData;
  };

  const getTotalImageCount = (data: any) => {
    const totalImageCount = data["Total image count"];
    return totalImageCount;
  };


  const DashLayout = (): JSX.Element => {
    const a = '';
    return (
      <div className="parent">
        <div className="div1">
          <Tile className='h-100'>
            <div>
              <Label  size="h1" weight={600} color='secondary' >{study?.title}</Label>
              <Spacer value={20} />
              <Text >{study?.description}</Text>
            </div>
          </Tile>
        </div>
        <div className="div2">
          <Tile className='h-100'>
            <div className='h-100'>
              {Array.isArray(study?.sites) &&
                <div className='study-dash__info'>
                  <Label center size="h2" weight={800} color='secondary' >{study?.sites.length}{' '}</Label>
                  <Spacer value={10} />
                  <Label center size="h2" >{' '}Sites</Label>
                </div>
              }
            </div>
          </Tile>
        </div>
        <div className="div3">
          <Tile className='h-100'>
            <div className='h-100'>
              {Array.isArray(study?.sites) &&
                <div className='study-dash__info'>
                  <Label center size="h2" weight={800} color='secondary' >{study?.numofparticipants}{' '}</Label>
                  <Spacer value={10} />
                  <Label center size="h2" >{' '}Participants</Label>
                </div>
              }
            </div>
          </Tile>
        </div>
        <div className="div4">
          <Tile className='h-100'>
            <div className='h-100'>
              {Array.isArray(data) && data.length > 0 &&
                <div className='study-dash__info'>
                  {/* <Label center size="h2" weight={800} color='secondary' >{Number(study?.numofparticipants) * 3}{' '}</Label>
                  <Spacer value={10} />
                  <Label center size="h2" >{' '}Images Uploaded</Label> */}
                  <Label center size="h2" weight={800} color="secondary">
                    {getTotalImageCount(data[0])}{" "}
                  </Label>
                  <Spacer value={10} />
                  <Label center size="h2">
                    {" "}Total image count
                  </Label>
                </div>
              }
            </div>
          </Tile>
        </div>
        <div className="div5">
          <Tile className='h-100'>
            <>
              <Label  size="h2" >Participant Progress</Label>
              <div className='study-dash__graph'>
                {renderComplted()}
              </div>
            </>
          </Tile>
        </div>
        <div className="div6">
          <Tile className='h-100'>
            <>
              <Label  size="h2" >Sites Activity</Label>
              <Spacer value={5} />
              <div style={{ width: "100%", height: 300 }}>
                {study &&
                  // <BarChart width={500} height={300} data={study.sites} margin={{ bottom: 80 }} >
                  //   <XAxis dataKey="siteLabel" interval={0} angle={-45} textAnchor="end" />
                  //   <YAxis />
                  //   <Tooltip />
                  //   <Bar dataKey="numofparticipants" fill="#20caf9" />
                  // </BarChart>

                  <ResponsiveContainer>
                    <ComposedChart
                      layout="vertical"
                      width={600}
                      height={300}
                      data={study.sites}
                      margin={{
                        top: 10,
                        right: 20,
                        bottom: 10,
                        left: 20
                      }}
                    >
                      <XAxis type="number" />
                      <YAxis dataKey="siteLabel" type="category" width={100} />
                      <Tooltip />
                      <Bar dataKey="numofparticipants" label barSize={50} fill="#20caf9" />
                    </ComposedChart>
                  </ResponsiveContainer>
                }
              </div>
            </>
          </Tile>
        </div>
        <div className="div7">
          <Tile className='h-100'>
            <div className='h-100' >
              <Label  size="h2" >Image Statistics</Label>
              <Spacer value={20} />
              <div style={{ width: "100%", height: 300 }}>
                {Array.isArray(data) && data.length > 0 &&
                  <ResponsiveContainer>
                    <ComposedChart
                      layout="vertical"
                      width={600}
                      height={400}
                      data={formatChartData(data[0])}
                      margin={{
                        top: 10,
                        right: 20,
                        bottom: 10,
                        left: 20
                      }}
                    >
                      <XAxis type="number" />
                      <YAxis dataKey="name" type="category" width={200} />
                      <Tooltip />
                      <Bar dataKey="value" label barSize={50} fill="#20caf9" />
                    </ComposedChart>
                  </ResponsiveContainer>
                }
              </div>
            </div>
          </Tile>
        </div>
        <div className="div8">
          <Tile>
            {study?.sites &&
              <div className='h-100'>
                <Label  size="h2" >Detialed Site Tracker</Label>
                <Grid container spacing={2} className='study-dash__site-item'>
                  <Grid xs={2} style={{ paddingBottom: 0 }}>
                    <Label center size="subtext">{t('Label')}</Label>
                  </Grid>
                  <Grid xs={2} style={{ paddingBottom: 0 }}>
                    <Label center size="subtext">{t('Region')}</Label>
                  </Grid>
                  <Grid xs={8} style={{ paddingBottom: 0 }}>
                    <Label center size="subtext">{t('Progress')}</Label>
                  </Grid>
                </Grid>
                <Spacer value={10} />
                {Array.isArray(study?.sites) && study?.sites.length > 0 && study?.sites.map((item: Site, index: number) => (
                  <SiteItem key={index} item={item} />
                ))}
              </div>
            }
          </Tile >
        </div>

      </div>
    );
  };


  return (
    <div className="study-dash">
      <Spacer value={20} />
      <DashLayout />
      <Spacer value={30} />
    </div>
  );
};

export default withAuthenticator(StudyDashboard);


