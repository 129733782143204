import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import './protocol-file-loader.scss';
import { BsFillXCircleFill, BsFolderFill } from 'react-icons/bs';
import { useGetStudyFilesQuery } from '../../../pages/private/StudyDrive/api';
import { Label, Tile, Button } from '../../atoms';
import ProtocolFileDisplay from '../ProtocolFileDisplay/ProtocolFileDisplay';



export interface ProtocolFileLoaderProps {
  participant: ParticipantItem,
}

type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  type: string | undefined;
  id: string;
  consentStatus: string;
  group?: string,
  userProtocol: any,
};


const ProtocolFileLoader = (props: ProtocolFileLoaderProps) => {
  const { participant } = props;
  const [currentVisit, setCurrentVisit] = React.useState<any>(null);
  const [currentItem, setCurrentItem] = React.useState<any>(null);

  const handleSelectVisit = (visit: any) => {
    setCurrentVisit(visit);
  };

  const handleSelectItem = (newItem: any) => {
    setCurrentItem(newItem);
  };

  const clear = () => {
    setCurrentVisit(null);
    setCurrentItem(null);
  };
  const clearItem = () => {
    setCurrentItem(null);
  };

  React.useEffect(() => {
    clear();
  }, [participant]);

  return (
    <div className='protocol-file-loader'>
      <Label size='h3'>{participant?.participantId} Drive </Label>
      <div className='protocol-file-loader__breadcrumb-wrapper'>
        {currentVisit &&  <Button kind='ghost' onClick={clear}><BsFillXCircleFill className='protocol-file-loader__icon' /> {currentVisit.visit}</Button>}
        {currentItem &&  <Button kind='ghost' onClick={clearItem}> <BsFillXCircleFill className='protocol-file-loader__icon' /> {currentItem.item}</Button>}
      </div>
      {!currentVisit && !currentItem &&
        <div className="protocol-file-loader__list-wrapper">
          {participant.userProtocol && Array.isArray(participant.userProtocol.timePoints) && participant.userProtocol.timePoints.length > 0 && participant.userProtocol.timePoints.map((item: any) => (
            <div role='button' tabIndex={-1} aria-label="select participant" onClick={() => handleSelectVisit(item)}>
              <Tile className="protocol-file-loader__item" >
                <div>
                  <Label size='h4' color={currentVisit === item ? 'primary' : 'default'}>  {item.visit} </Label>
                </div>
              </Tile>
            </div>
          ))}
        </div>
      }
      <div className="protocol-file-loader__list-wrapper" >
        {currentVisit && !currentItem && Array.isArray(currentVisit.userProtocolItems) && currentVisit.userProtocolItems.map((item: any) => (
          <div role='button' tabIndex={-1} aria-label="select participant" onClick={() => handleSelectItem(item)}>
            <Tile className="protocol-file-loader__item" >
              <div>
                <Label size='h4' color={currentItem === item ? 'primary' : 'default'}> {item.item} </Label>
              </div>
            </Tile>
          </div>
        ))}
      </div>
      {currentItem &&
        <ProtocolFileDisplay protocolItem={currentItem} />
      }
    </div>
  );
};

ProtocolFileLoader.defaultProps = {
};

ProtocolFileLoader.displayName = 'ProtocolFileLoader';


export default ProtocolFileLoader;
