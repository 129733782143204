import React, { useMemo } from 'react';
import { IconContext } from 'react-icons';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';
import AppBar from '../AppBar/AppBar';

import './page-layout.scss';


type Props = {
  children?: JSX.Element,
  header?: boolean,
  footer?: boolean,
  logout: () => void,
  // width?: number,
  // navigation?: JSX.Element,
  token: string | undefined,
  navigation?: { title: string, url: string, private?: boolean, submenu? : {  title: string, url: string, }[]  }[],
  userMenuItems?: { title: string, url: string, private?: boolean }[],
};



const PageLayout = ({ children, header, footer, navigation, userMenuItems, token, logout }: Props) => {
  const iconContextValue = useMemo(() => ({ color: 'gray', className: 'global-class-name' }), []);
  return (
  <IconContext.Provider value={iconContextValue}>
    <div className='page-layout'>
      <AppBar navigation={navigation} userMenuItems={userMenuItems} token={token} logout={logout} />
      <div className='page-layout__body'>{children}</div> 
      {footer && <PageFooter className='page-layout_footer'/>} 
    </div>
  </IconContext.Provider>
  );
};

PageLayout.defaultProps = {
  children: <div>{' '}</div>,
  header: false,
  footer: false,
  navigation: [{ title: '', url: '' }],
  userMenuItems: [{ title: '', url: '' }],
};

export default PageLayout;
