import React from 'react';
import Lottie from 'lottie-react';
import classNames from 'classnames';
import groovyAnimation from './anim1.json';
import './custom-animation.scss';

export interface CustomAnimationProps  {
  className?: string;
}

const CustomAnimation = (props: CustomAnimationProps) => {
  const { className } = props;
  
  const classes = {
    'custom-animation': true,
    [`${className}`]: true,
  };
  return (
        <div key='custom_spin' className={classNames(classes)}>
          <div className='anim'>
            <Lottie animationData={groovyAnimation} loop={false} className='anim2'/>
          </div>
        </div>
  ); 
};

CustomAnimation.defaultProps = {
  className: '',
};

CustomAnimation.displayName = 'CustomAnimation';


export default CustomAnimation;
