import React from 'react';
// import classnames from 'classnames';
import './participant-tracker.scss';

export interface ParticipantTrackerProps {
  id: string,
  className?: string,
}

const ParticipantTracker = (props: ParticipantTrackerProps): JSX.Element => {
  const { id, className } = props;
  return (
    <div key={`ParticipantTracker_${id}`} className="card">
      ParticipantTracker
    </div>
  );
};

ParticipantTracker.defaultProps = {
  className: '',
};

ParticipantTracker.displayName = 'ParticipantTracker';
export default ParticipantTracker;
