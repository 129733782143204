import React from 'react';
// eslint-disable-next-line import/no-named-as-default
/* eslint-disable no-plusplus */
import { useParams } from 'react-router-dom';
import FlatList from 'flatlist-react';
import Grid from '@mui/material/Unstable_Grid2';
import { Label, Button, LinkPatientsToExistingCohort, Spinner, Modal, Spacer, PatientsTable } from '../../../components';
import { SimpleEMRSearch, AdvancedEMRSearch } from '../../../components/molecules';
// import { patients } from './testData';
import { useAdvancedSearchQuery, useLinkToCohortMutation } from '../../../api/builders/patient.api';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';



const Patients = () => {
  const { linkedCohort } = useParams();
  const [payload, setPayload] = React.useState({ pageSize: 10, type: 'mrn', orderBy: 'recent', pageCurrent: 1 });

  // const [{ pageSize, current: pageCurrent, query, type }, setPagination] = React.useState({ pageSize: 10, current: 1, query: '', type: 'mrn' });

  // const { data, isError, isFetching } = useSearchPatientsQuery({ pageSize, pageCurrent, query, type });
  const { data: allPats, isLoading: isLoadingData, isFetching: isFetchingData } = useAdvancedSearchQuery(payload);

  const [linkPats, { isLoading: isAdding, isError: isErrorAdding }] = useLinkToCohortMutation();
  const [checked, setChecked] = React.useState<any[]>([]);
  const [searching, setSearching] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [patients, setPatients] = React.useState<any | null>(null);



  React.useEffect(() => {
    if (allPats && Array.isArray(allPats.items)) {
      if (searching) {
        setPatients(allPats.items);
      } else if (allPats.pageCurrent > 1) {
        const pats = Array.isArray(patients) ? patients : [];
        const curr = pats.concat(allPats.items);
        setPatients(curr);
      } else {
        setPatients(allPats.items);
      }
    }
  }, [allPats]);

  const openModal = async () => {
    if (linkedCohort) {
      try {
        const pl = {
          patientIds: checked,
          cohortId: linkedCohort,
        };
        await linkPats(pl);
        setChecked([]);
      } catch (e) {
        console.error('Error', e);
      }
    } else {
      setModal(true);
    }
  };


  // const handleChecked = (cohort: string) => {
  //   const found = checked.includes(cohort);
  //   let newArr: string[] = [];
  //   if (found) {
  //     newArr = checked.filter((item) => item !== cohort);
  //   } else {
  //     newArr = checked.concat(cohort);
  //   }
  //   setChecked(newArr);
  // };



  const handleLink = (cohort: string) => {
    try {
      const pl = {
        patientIds: checked,
        cohortId: cohort,
      };
      linkPats(pl);
      setChecked([]);
      setModal(false);
    } catch (e) {
      console.error('Error', e);
    }
  };

  const onSubmitSearch = (newQuery: string) => {
    setPayload(prev => ({ ...prev, query: newQuery }));
    setSearching(true);
    if (newQuery === '') {
      setPatients(null);
      setSearching(false);
    }
  };

  const onFieldSearch = (newQuery: string) => {
    setPayload(prev => ({ ...prev, type: newQuery }));
    setSearching(true);
    if (newQuery === '') {
      setPatients(null);
      setSearching(false);
    }
  };



  const loadMore = () => {
    setPayload({
      ...payload,
      pageSize: 10,
      pageCurrent: allPats?.pageCurrent ? (allPats.pageCurrent + 1) : 0,
      orderBy: 'recent',
    });
  };


  return (
    <div className="patients">
      <SimpleEMRSearch onSubmitSearch={onSubmitSearch} onFieldSearch={onFieldSearch} />
      {/* <AdvancedEMRSearch onSubmitSearch={onSubmitSearch} onFieldSearch={onFieldSearch} /> */}

      {(isLoadingData || isFetchingData) ?
        <div className="patients__body super-centered">
          <Spacer value={30} />
          <Spacer value={30} />
          <Spacer value={30} />
          <Spinner />
        </div>
        :
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {Array.isArray(patients) && patients.length > 0 &&
            <div className="patients__body">
              <Button kind='secondary' loading={isAdding} onClick={openModal} disabled={checked.length <= 0}>Add to Cohort</Button>
              <Spacer value={10} />
              <PatientsTable
                patients={patients}
                loading={false}
                id={linkedCohort}
                isAdding={isAdding}
                onAdd={openModal}
                checked={checked}
                setChecked={setChecked}
              />
              <Spacer value={20} />
              <Button loading={isLoadingData || isFetchingData} disabled={allPats.pageCurrent >= allPats.pageTotal} onClick={loadMore}>Load More</Button>
            </div>
          }
        </>
      }



      <Modal open={modal} onClose={() => setModal(false)} title='Add to Exiting Cohort'>
        <LinkPatientsToExistingCohort id='LinkPatientsToExistingCohort' onSubmit={handleLink} />
      </Modal>
    </div>
  );
};

export default withAuthenticator(Patients);


