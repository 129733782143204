/* eslint-disable react/require-default-props */
import React from 'react';
// import classnames from 'classnames';
import './file-list-item.scss';
import { BsFileEarmarkRichtextFill } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import Grid from '@mui/material/Unstable_Grid2';
import { Text } from '../../atoms';

export interface FileListItemProps {
  file: FileSystemEntry,
  onDelete?: (item: FileSystemEntry) => void,
}

const FileListItem = (props: FileListItemProps) => {
  const { file, onDelete } = props;
  return (
    <div className="file-list-item--wrapper">
      <div className="file-list-item--header">
        <BsFileEarmarkRichtextFill />
        <Text>{file.name}</Text>{' '}
      </div>
      <div>
        {onDelete && <FaTrashAlt />} 
      </div>
    </div>
  );
};


FileListItem.displayName = 'FileListItem';


export default FileListItem;
