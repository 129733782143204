/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
// import classnames from 'classnames';
import './advanced-flat-list.scss';
import { BiRefresh } from 'react-icons/bi';
import SearchBox from '../SearchBox/SearchBox';
import { Label } from '../../atoms';

export interface AdvancedFlatListProps<T> {
  id: string;
  className?: string;
  data: T[] | [];
  renderItem: (item: T) => JSX.Element;
  title: string;
  searchFunction: (item: T, searchTerm: string) => boolean;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const AdvancedFlatList = <T,>(props: AdvancedFlatListProps<T>): JSX.Element => {
  const { id, className, data, renderItem, title, searchFunction } = props;

  // State to handle search input
  const [searchTerm, setSearchTerm] = useState('');
  const handleChangeSearch = (value: string) => {
    setSearchTerm(value);
  };
  // Filter the data based on search term
  const filteredData = data?.filter((item) => searchFunction(item, searchTerm));

  return (
    <div key={`AdvancedFlatList_${id}`} className={`advanced-flat-list ${className}`}>
       <div className="advanced-flat-list-header">
       <span className="header-title">
          <Label size="h3" weight={300}>
            {title}
          </Label>
          <button type="button" className="icon-btn" aria-label="refetch" >
            <BiRefresh />
          </button>
        </span>
        <SearchBox value={searchTerm}
          onChange={handleChangeSearch}
          placeholder='search' />
         </div>
         <div className="body">
         {filteredData?.map((item, index) => renderItem(item))}
         </div>

    </div>
  );
};

AdvancedFlatList.defaultProps = {
  className: '',
};

AdvancedFlatList.displayName = 'AdvancedFlatList';
export default AdvancedFlatList;
