import React from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLocation } from 'react-router-dom';
import { BsDownload, BsBoxArrowInUpRight } from 'react-icons/bs';
import moment from 'moment';
import { useGetFilesQuery, useDownloadPatSeriesMutation, useDownLoadFileMutation, useSendImgMutation } from '../../../api/builders/files.api';
import { Label, Text, Spacer, Alert } from '../../../components';
import './patient.scss';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';


interface LocationState {
  patient: any;
  secondary: boolean,
  cohort: string,
}

interface SerieInterface {
  id: string;
  SeriesInstanceUID: string;
}

interface StudyInterface {
  id: string;
  series: Array<SerieInterface> | null;
}

interface StudyFilesReducerStateInterface {
  tree: Array<StudyInterface> | [] | null;
  loading: boolean;
  loaded: boolean;
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean;
  message: string;
  kind: AlertType;
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const initstate = { tree: [], loading: false, loaded: false };
const inithistory: any[] | (() => any[]) = [];

const StudyFilesReducer = (state: StudyFilesReducerStateInterface, action: { type: string, payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case 'UPDATE':
      if (Array.isArray(payload)) {
        const studies = payload.reduce((q: any, it: any) => {
          let temp = q.find((o: { studyInstanceUID: any; }) => o.studyInstanceUID === it.StudyInstanceUID);
          if (!temp) {
            const study = { series: [], studyDescription: it.StudyDescription, studyInstanceUID: it.StudyInstanceUID, bucket: it.bucket, studyDate: it.StudyDate, id: it.id, updatedAt: it.updatedAt, createdAt: it.createdAt, studyTime: it.studyTime };
            q.push(study);
            temp = study;
          }
          if (Array.isArray(it.ScanSeries)) {
            temp.series = it.ScanSeries.reduce((qs: any, its: any) => {
              const seriestemp = qs.find((o: { id: any; SeriesInstanceUID: any; }) => o.SeriesInstanceUID === its.SeriesInstanceUID);
              if (!seriestemp) {
                qs.push(its);
              }
              return qs;
            }, temp.series);
          }
          return q;
        }, []);
        // eslint-disable-next-line no-case-declarations
        const tempstate = { ...state };
        tempstate.tree = studies;
        return tempstate;
      }
      return state;


      break;
    default:
      return state;
  }
};



const Patient = () => {
  const location = useLocation();
  const { patient, secondary, cohort } = location.state as LocationState;
  const [state, dispatch] = React.useReducer(StudyFilesReducer, initstate);
  const [downloadlink, setLink] = React.useState('');

  const [getFile, info] = useDownloadPatSeriesMutation();
  const [sendImg, { isLoading: isAdding, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useSendImgMutation();
  const [download, downloadfileinfo] = useDownLoadFileMutation();
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetFilesQuery({ patientId: patient?.id ? patient.id : skipToken });
  const [studies, setStudies] = React.useState<any>(null);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Sending File: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding File to Queue',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  React.useEffect(() => {
    dispatch({ type: 'UPDATE', payload: data });
  }, [data]);


  const onDownload = async (item: any) => {
    const downloadPl = {
      bucket: data[0].bucket,
      seriesId: item.id,
      archiveFilePath: `COLD/s3zip/${item?.SeriesDescription}.zip`,
      archiveFolderPath: item?.SeriesDescription,
      archiveFormat: 'zip',
    };
    const mydata = await getFile(downloadPl).unwrap();
    const newPath = `s3://${mydata.bucket}/${mydata.archiveFilePath}`;
    const downloadPayload = { files: [{ s3_path: newPath }] };
    const yourbrandnewfile = await download(downloadPayload).unwrap();
    setLink(yourbrandnewfile[0].signedUrl);
    const downloadobject = { label: item.SeriesDescription, link: yourbrandnewfile[0].signedUrl };
    const link = document.createElement('a');
    link.href = yourbrandnewfile[0].signedUrl;
    link.rel = 'noreferrer';
    document.body.appendChild(link);
    link.click();
  };

  const onSend = async (item: any, study: any) => {
    try {
      const sendPl = {
        s3_bucket: study.bucket,
        seriesId: item.id,
        secondaryId: patient.secondaryId,
        cohort,
        pid: patient.id,
      };
      sendImg(sendPl);
    } catch (e) {
      console.error('ON SEND ERROR', e);
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const Study = (studyProp: any): JSX.Element => {
    const { item } = studyProp;
    const { series } = item;

    // eslint-disable-next-line react/no-unstable-nested-components
    const Serie = (serieProp: any): JSX.Element => {
      const { item: serie, study } = serieProp;
      const { id: serieId, SeriesDescription, Modality, SpacingBetweenSlices, SliceThickness, PixelSpacing } = serie;
      return (
        <div
          className="image-series-display--serie-wrapper"
          id='123'
        >
          <div
            className="image-series-display--serie-header">
            <Label weight={600} size="h6">{' '}{Modality}{' - '}{SeriesDescription}</Label>
          </div>
          <div className="image-series-display--serie-info">
            <Label weight={600} size="h6">Space Between Slices: {SpacingBetweenSlices}</Label>
            <Label weight={600} size="h6">Slice Thickness: {SliceThickness}</Label>
            <Label weight={600} size="h6">Pixel Spacing: {PixelSpacing}</Label>
          </div>
          <button aria-label="Save" type='button' onClick={() => onDownload(serie)} className='icon-btn'><BsDownload /></button>
          <button aria-label="Save" type='button' onClick={() => onSend(serie, study)} className='icon-btn'><BsBoxArrowInUpRight /></button>
        </div>
      );
    };

    return (
      <div className="image-series-display--wrapper">
        <div className="image-series-display--header">
          {/* <BsFileEarmarkRichtextFill /> */}
          <Text>📂 {moment(item?.studyDate).format('LLL')}  -  {item.studyDescription}</Text>
        </div>
        <div className="image-series-display--series-wrapper">
          {series?.map((serie: any) => <Serie study={item} item={serie} key={serie.id} />)}
        </div>
      </div>
    );
  };


  return (
    <div className="patient">
      <Label size='h1' weight={200}>Patient {secondary ? patient?.secondaryId : patient?.mrn} Files</Label>
      <Spacer value={20} />
      {data && data?.length > 0 ?
        <div>
          {state.tree?.map((it: any) => <Study key={it.id} item={it} />)}
        </div>
        :
        <div >
          <Spacer value={20} />
          <Spacer value={20} />
          <Spacer value={20} />
          <Label size='h4'>Your files are decoded and getting organized. please come back later.</Label>
        </div>
      }
      <Alert severity={alert.kind} open={alert.open} onClose={resetAlert} message={alert.message} />
    </div>
  );
};

export default withAuthenticator(Patient);
