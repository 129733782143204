import QueryString from 'qs';
import api from '../api';


export const chatApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addChatRoom: builder.mutation({
      query: (payload) => ({ url: '/chatroom', method: 'POST', body: payload }),
      invalidatesTags: ['CHATS'],
    }),
    updateMessageStatus: builder.mutation({
      query: (payload) => ({ url: `/chatmessage/${payload.chatmessageId}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['CHATS'],
    }),
    addMessage: builder.mutation({
      query: (payload) => ({ url: '/chatmessage', method: 'POST', body: payload }),
      invalidatesTags: ['CHATS'],
    }),
    getChatRooms: builder.query({
      query: (payload) => ({ url: `/chatroom/statsbyid/${payload}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['CHATS'],
    }),

    getProtocolChat: builder.query({
      query: (payload) => ({ url: `/chatmessage/item/${payload}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['CHATS'],
    }),
  }),
});

export const {  
  usePrefetch,
  useAddChatRoomMutation, 
  useGetChatRoomsQuery, 
  useAddMessageMutation, 
  useUpdateMessageStatusMutation, 
  useGetProtocolChatQuery, 
} = chatApi;
