import React from 'react';
import moment from 'moment';
import { BsChevronCompactRight, BsCheckCircleFill } from 'react-icons/bs';
import { FaEllipsisH } from 'react-icons/fa';
import { TfiTime } from 'react-icons/tfi';
import { Label, Spacer, DatePicker, Modal, Button, Badge } from '../../atoms';
import './care-path-list-item.scss';

interface Step {
  completionDate: string;
  description: string;
  id: string;
  isFirst: boolean;
  isLast: boolean;
  label: string;
  nextSteps: { toStep: string; condition: string }[];
  order: number;
  patientStatus: string;
  relativeStartDay: string;
  relativeStartDayVariance: string;
  scheduledDate: string;
  stepLabel: string;
  type: string;
  minDate: number;
  maxDate: number;
  targetDate: number;
}

export interface CarePathListItemProps {
  step: Step,
  currentStep: Step,
  isActive: boolean,
  handleClick: (item: any) => void,
  showConnectingLine: boolean;
}

// function isStepClickable(activeStep: Step, stepToCheck: Step): boolean {
//   const isCompletedActiveStep = activeStep.patientStatus === 'COMPLETED';
//   const nextStepIds = activeStep.nextSteps.map(nextStep => nextStep.toStep);
//   return isCompletedActiveStep && nextStepIds.includes(stepToCheck.id);
// }

const CarePathListItem = (props: CarePathListItemProps): JSX.Element => {
  const { step, currentStep, isActive, handleClick, showConnectingLine } = props;

  const isCompleted = step.patientStatus === 'COMPLETED';
  const isCurrent = step.id === currentStep.id;


  // REGISTRYTODO::: add the custom chair time icon where relevant 
  const renderContent = () => {
    if (isCompleted) {
      return (
        <div className='care-path-list-item-completed'>
          <div className='care-path-list-item-completed__indicator'>
            <BsCheckCircleFill className='green-text' />
          </div>
          <div className='care-path-list-item-completed__card'>
            <Label uppercase size='h5' weight={600} color="success">
              {step.description}
            </Label>
            <Spacer value={5} />
            <Label uppercase size='h6' weight={400} color="ghost">
              Completd on: {step.completionDate}
            </Label>
          </div>
        </div>
      );
    } if (isCurrent || step.order === currentStep.order) {
      return (
        <div className='care-path-list-item-active'>
          <div className='care-path-list-item-active__indicator'>
            <span className='care-path-list-item-active__wrapper'>
              <FaEllipsisH size={12} className='care-path-list-item-active__icon' />
            </span>
          </div>
          <div>
            <Label uppercase size='h5' weight={600} color="primary">
              {step.description}
            </Label>
            <Spacer value={5} />
            <Label uppercase size='h6' weight={400} color='danger'>
              Due By: {step.scheduledDate !== 'null' ? moment(step.scheduledDate).format('L') : moment(step.maxDate).format('L')}
            </Label>
          </div>
        </div>
      );
    }
    return (
      <div className='care-path-list-item-disabled'>
        <div className='care-path-list-item-disabled__indicator'>
          <span className='care-path-list-item-disabled__wrapper'>
            <TfiTime size={35} className='care-path-list-item-disabled__icon' />
          </span>
        </div>
        <div>
          <Label uppercase size='h5' weight={600} color='ghost'>
            {step.description}
          </Label>
          <Spacer value={5} />
          <Label uppercase size='h6' weight={400} color='ghost'>
            {(step.scheduledDate !== 'null' && step.patientStatus.toUpperCase() === 'SCHEDULED') ? `Scheduled on ${moment(step.scheduledDate).format('L')}` : 'Not Scheduled'}
          </Label>
        </div>
      </div>
    );
  };


  return (
    <div className='care-path-list-item'>
      <div className='care-path-list-item__card' role='button' tabIndex={-1} onClick={() => handleClick(step)}>
        {renderContent()}
      </div>
      {isActive &&
        <div className='care-path-list-item__indicator'>
          <BsChevronCompactRight />
        </div>
      }
    </div>
  );
};

CarePathListItem.defaultProps = {
};

CarePathListItem.displayName = 'CarePathListItem';
export default CarePathListItem;
