import React from 'react';
// import classnames from 'classnames';
import './question-types.scss';

export interface NumberProps {
  id: string,
  className?: string,
}

const Number = (props: NumberProps): JSX.Element => {
  const { id, className } = props;
  return (
    <div key={`Number_${id}`} className="card">
      Number
    </div>
  );
};

Number.defaultProps = {
  className: '',
};

Number.displayName = 'Number';
export default Number;
