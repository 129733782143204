import React from 'react';
import classnames from 'classnames';
import LinearProgress from '@mui/material/LinearProgress';
import './progress-bar.scss';

export interface ProgressBarProps {
  min: number,
  max: number,
  value: any,
  kind?: string,
}

const ProgressBar = (props: ProgressBarProps): JSX.Element => {
  const { min, max, value, kind } = props;

  const classes = {
    [`progress-bar--${kind}`]: true,
  };

  const normalise = (val: any) => {
    const newVAL = ((val - min) * 100) / (min - max);
    return Math.abs(newVAL);
  };

  return (
    <div className={classnames(classes)} >
      <LinearProgress variant="determinate" value={normalise(value)} />
    </div>
  );
};

ProgressBar.defaultProps = {
  kind: 'primary',
};

ProgressBar.displayName = 'ProgressBar';
export default ProgressBar;
