
const patientMenu = [
  {
    title: 'HOME',
    url: '/private/mysurveys',
    private: true,
  },
];

export default patientMenu;

