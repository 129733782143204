import QueryString from 'qs';
import api from '../../../api/api';

export const participantApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getparticipantInfo: builder.query({
      query: (payload) => { 
        console.info('');
        return ({ url: `/participant/statsbyid/${payload.participantId}`, method: 'GET' });
      },
      transformResponse: (response: any) => response.items,
      providesTags: ['PROTOCOL'],
    }),
    
    getparticipantTumors: builder.query({
      query: (payload) => { 
        console.info('getparticipantTumors', payload);
        return ({ url: `/tumor/statsbyid/${payload.participantId}`, method: 'GET' });
      },
      transformResponse: (response: any) => response.items,
      providesTags: ['TUMORS'],
    }),
    getProtocolFiles: builder.query({
      query: (payload) => { 
        console.info('');
        return ({ url: `/files/${payload.protocolItemId}`, method: 'GET' });
      },
      transformResponse: (response: any) => response,
    }),
    getProtocolSeries: builder.query({
      query: (payload) => { 
        console.info('payload', payload);
        return ({ url: `/files/images/${payload}`, method: 'GET' });
      },
      transformResponse: (response: any) => response.items,
      providesTags: ['REQUIREMENT_IMAGES'],
    }),
    editProtocol: builder.mutation({
      query: (payload) => ({ url: `/participant/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['PROTOCOL'],
    }),
    editTumor: builder.mutation({
      query: (payload) => ({ url: `/tumor/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['TUMORS'],
    }),

    
    // getStudySites: builder.query({
    //   query: (payload) => { 
    //     console.info('test');
    //     return ({ url: `/site/${payload.studyId}`, method: 'GET' });
    //   },
    //   transformResponse: (response: any) => response.items,
    //   providesTags: ['SITES'],
    // }),
    // downloadFile: builder.mutation({
    //   query: (payload) => { 
    //     console.info('AT API>> payload', payload);
    //     return ({ url: '/files/s3zip/series/', meathod: 'POST', body: payload });
    //   },
    //   transformResponse: (response: any) => response,
    //   // providesTags: ['SITES'],
    // }),
    downloadSeries: builder.mutation({ 
      query: (payload) => ({ url: '/files/s3zip/series', method: 'POST', body: payload }),
    }),
    addMET: builder.mutation({
      query: (payload) => {
        console.info('addMET payload', payload);
        return ({ url: '/tumor', method: 'POST', body: payload });
      },
      invalidatesTags: ['TUMORS'],
    }),
    addTumorAs: builder.mutation({
      query: (payload) => ({ url: '/tumorassessment', method: 'POST', body: payload }),
      invalidatesTags: ['TUMORS'],
    }),
    addChatRoom: builder.mutation({
      query: (payload) => ({ url: '/chatroom', method: 'POST', body: payload }),
      invalidatesTags: ['CHATS'],
    }),
    updateMessageStatus: builder.mutation({
      query: (payload) => ({ url: `/chatmessage/${payload.chatmessageId}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['CHATS'],
    }),
    addMessage: builder.mutation({
      query: (payload) => ({ url: '/chatmessage', method: 'POST', body: payload }),
      invalidatesTags: ['CHATS'],
    }),
    getChatRooms: builder.query({
      query: (payload) => { 
        console.info('chatroom');
        return ({ url: `/chatroom/statsbyid/${payload}`, method: 'GET' });
      },
      transformResponse: (response: any) => response.items,
      providesTags: ['CHATS'],
    }),
    getAllVisitImages: builder.query({
      query: (payload) => { 
        console.info('chatroom');
        return ({ url: `/files/images/timepoint/${payload}`, method: 'GET' });
      },
      transformResponse: (response: any) => response.items,
      providesTags: ['REQUIREMENT_IMAGES'],
    }),
    getProtocolChat: builder.query({
      query: (payload) => { 
        console.info('chatroom');
        return ({ url: `/chatmessage/item/${payload}`, method: 'GET' });
      },
      transformResponse: (response: any) => response.items,
      providesTags: ['CHATS'],
    }),
    creatFileConnection: builder.mutation({
      query: (payload) => ({ url: '/files/images/connect/', method: 'POST', body: payload }),
      invalidatesTags: ['REQUIREMENT_IMAGES'],
    }),
    editTumorAs: builder.mutation({
      query: (payload) => ({ url: `/tumorassessment/${payload.tumorassessmentId}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['TUMORS'],
    }),
    removeTumor: builder.mutation({
      query: (id) => ({ url: `/tumor/${id}`, method: 'DELETE' }),
      invalidatesTags: ['TUMORS'],
    }),
  }), 
});

export const { usePrefetch, useGetparticipantInfoQuery, useGetparticipantTumorsQuery, useAddMETMutation, useAddTumorAsMutation, useEditTumorAsMutation, useRemoveTumorMutation, useEditProtocolMutation, useGetProtocolFilesQuery, useEditTumorMutation, useGetProtocolSeriesQuery, useDownloadSeriesMutation, useCreatFileConnectionMutation, useAddChatRoomMutation, useGetChatRoomsQuery, useAddMessageMutation, useUpdateMessageStatusMutation, useGetProtocolChatQuery, useGetAllVisitImagesQuery } = participantApi;
