import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { BiRefresh } from 'react-icons/bi';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import { Label, Alert, Spacer, Button, Modal, TextInput, SiteListItem, Dropdown, Spinner } from '../../../components';
import { AddUser, UserItem } from '../../../components/molecules';
import { useGetStudyUsersQuery, studyApi } from '../../../api/builders/study.api';
import { useEditSiteMutation } from './api';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';


type SiteItem = {
  id: string | undefined;
  name: string | undefined;
  region: string | undefined;
  siteId: string | undefined;
  siteLabel: string | undefined;
  status: string | undefined;
  prefix: string | undefined;
  drive_s3_path: string | undefined;
};

type StudyItem = {
  label?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  summary?: string | undefined;
  acl?: string | undefined;
};


interface LocationState {
  site: SiteItem;
  study: StudyItem;
}

const initialVals = {
  siteLabel: '',
  name: '',
  status: '',
  siteId: '',
  prefix: '',
};

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

const SiteSettings = () => {
  const location = useLocation();
  const { site, study } = location.state as LocationState;
  const [values, setValues] = React.useState(initialVals);
  const [activeTab, setActiveTab] = React.useState('details');
  const [edit, { isLoading: isEdititng, isSuccess, isError, error }] = useEditSiteMutation();
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetStudyUsersQuery({ studyId: study?.id ? study.id : skipToken });
  const { refetch: refetchUsers } =
    studyApi.endpoints.getStudyUsers.useQuerySubscription({ studyId: study?.id ? study.id : skipToken });
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  React.useEffect(() => {
    const newBreadcrumb = {
      label: 'Settings',
      path: location.pathname,
      state: { site },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (isError) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Editing Site: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isError]);

  React.useEffect(() => {
    if (isSuccess) {
      setAlert({
        open: true,
        message: 'Success Editing Site',
        kind: 'success',
      });
    }
  }, [isSuccess]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };

  const handleChageTab = (newT: string) => {
    setActiveTab(newT);
  };

  React.useEffect(() => {
    if (site) {
      setValues({
        siteId: site?.siteId ?? '',
        siteLabel: site?.siteLabel ?? '',
        name: site?.name ?? '',
        status: site?.status ?? '',
        prefix: site?.prefix ?? '',
      });
    }
  }, [site]);

  const handleChangeVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const submitChanges = () => {
    try {
      const payload = {
        id: site.id,
        body: {
          ...values,
        },
      };
      edit(payload);
    } catch (e) {
      console.error('err saving study edit', e);
    }
  };


  return (
    <div className="site-settings">
      <div className='study__header'>
        <Label size="h2" weight={300} >{site?.siteLabel} Settings</Label>
      </div>
      <div className='study__body'>
        <Grid container spacing={6}>
          <Grid xs={3}>
            <div role='button' onClick={() => handleChageTab('details')} tabIndex={-1} className={activeTab === 'details' ? 'study-settings__active-tab' : 'study-settings__tab'}>
              <Label size="h4" weight={600} color={activeTab === 'details' ? 'primary' : 'default'}>Details</Label>
            </div>
            {/* <div role='button' onClick={() => handleChageTab('users')} tabIndex={-1} className={activeTab === 'users' ? 'study-settings__active-tab' : 'study-settings__tab'}>
              <Label size="h4" weight={600} color={activeTab === 'users' ? 'primary' : 'default'} >Manage Users</Label>
            </div> */}
          </Grid>
          {activeTab === 'details' &&

            <Grid xs={9}>
              <TextInput
                name='siteLabel'
                label='Label'
                value={values.siteLabel}
                onChange={handleChangeVals}
              />
              <TextInput
                name='siteId'
                label='Site Id'
                value={values.siteId}
                onChange={handleChangeVals}
              />
              <TextInput
                name='prefix'
                label='Site Prefix'
                value={values.prefix}
                onChange={handleChangeVals}
              />
              <TextInput
                name='name'
                label="Description"
                value={values.name}
                onChange={handleChangeVals}
              />
              <Dropdown
                name='status'
                label='Status'
                value={values.status}
                onChange={handleChangeVals}
              >
                <MenuItem value="training">Training</MenuItem>
                <MenuItem value="pending start">Pending Start</MenuItem>
                <MenuItem value="recruiting">Recruiting</MenuItem>
                <MenuItem value="paused">Paused</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
              </Dropdown>

              <Spacer value={20} />

              <div className='btn-row'>
                <Button loading={isEdititng} onClick={submitChanges}>Save Changes</Button>
              </div>
            </Grid>
          }
          {activeTab === 'users' &&
            <Grid xs={9}>
              <div className='d-flex-row'>
                <div className='d-flex'>
                  {/* <Label size="h4" weight={300}>Manage Users</Label> */}
                  <Button kind='ghost' onClick={refetchUsers}> <BiRefresh size={20} /></Button>
                </div>
                <AddUser study={study} id='adduser' />
              </div>
              <Spacer value={10} />
              <div >
                {(isLoadingData || isFetchingData) ?
                  <div className='super-centered'>
                    <Spacer value={30} />
                    <Spacer value={30} />
                    <Spacer value={30} />
                    <Spinner />
                  </div>
                  :
                  <UserItem users={data} id='n/a' study={study} />
                }
              </div >
            </Grid>
          }
        </Grid>
      </div>
      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

export default withAuthenticator(SiteSettings);
