import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Grid from '@mui/material/Grid';
import * as Component from '../../../components';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { Label, Tile, Spacer, ProtocolItemFactory, ProtocolVisitItem, ProtocolVisitCriteriaItem, BreadcrumbWithMenu, Alert, Spinner, CareStep, ParticipantFiles, Tab, Tabs } from '../../../components';
import CustomProgress from '../../../components/atoms/CustomProgress/CustomProgress';
import { Activity, ParticipantItem } from '../Participant/Participant';

interface LocationState {
  participant: ParticipantItem,
  sitePath: string,
  activity?: Activity,
  acl: string,
  siteId: string,
}

const ParticipantAudit = () => {
  const somevar = 'ParticipantAudit';
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { participant, sitePath, activity, acl, siteId } = location.state as LocationState;
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const newBreadcrumb = {
      label: `${participant?.participantId ?? 'participant//'}./`,
      path: location.pathname,
      state: { participant, sitePath, activity, acl, siteId },
    };
    const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    if (alreadyExists === false) {
      setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    }
  }, [location.pathname]);

  return (
    <div className="participant-audit">
      {/* <div className='participant-audit__header'>
      </div> */}
      <div className='participant-audit__body'>
      participant-audit
      </div>
      <Spacer value={20} />
      {error && <Alert severity='error' open={error} onClose={() => setError(false)} message='Unauthorized to perform this action' />}
  
    </div>
  );
};

export default withAuthenticator(ParticipantAudit);
