
const imxMenu = [
  {
    title: 'My Data',
    url: '/private/shared/cohorts',
    private: true,
  },
];

export default imxMenu;
