import React from 'react';
// import classnames from 'classnames';
import './consent-manager.scss';
import MenuItem from '@mui/material/MenuItem';
import { Label, Modal, Dropdown, Button, Spacer } from '../../atoms';
import { useCreatePUTSignedUrlMutation } from '../../../api/api';

export interface ConsentManagerProps {
  participant: ParticipantItem,
  onOpen: boolean,
  onClose: () => void,
  binderPath: string,
  onUpdateConsent: (payload: { id: string, status: string }) => void
}

type ParticipantItem = {
  screeningId: string | undefined;
  participantId: string | undefined;
  type: string | undefined;
  id: string;
  consentStatus: string;
};

export interface File {
  name: string;
  size: number;
  type: string;
  extension?: string;
  content?: ArrayBuffer;
  arrayBuffer: any,
  slice: any,
  stream: any,
  text: any
}


const ConsentManager = (props: ConsentManagerProps) => {
  const { participant, onOpen, onClose, onUpdateConsent, binderPath } = props;
  const [status, setStatus] = React.useState(participant.consentStatus ? participant.consentStatus : 'Not Consented');
  const [file, setFile] = React.useState<File>();
  const [getPUTSignedUrl] = useCreatePUTSignedUrlMutation();



  // eslint-disable-next-line @typescript-eslint/no-shadow
  const myuploadToS3 = (signedUrl: string, file: File) =>
    fetch(signedUrl, { method: 'PUT', body: file, headers: { 'Content-Type': file.type } });

  const saveFileS3 = (url: string, _file: File | undefined, fileType: string) => {
    const myHeaders = { 'Content-Type': fileType };
    const response = fetch(url, {
      method: 'PUT',
      body: _file,
      // headers: myHeaders,
    });
  };

  const onCloseModal = () => {
    onClose();
  };


  const handleChangeStatus = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStatus(event.target.value);
  };


  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event?.target?.files?.[0]);
  };


  const uploadFile = async () => {
    const path = `${binderPath}participants/${participant.id}/`;
    const fileType = file?.type ? file?.type : 'application/pdf';
    const { signedUrl, backgroundId } = await getPUTSignedUrl({ s3_path: path, ContentType: fileType }).unwrap();
    await saveFileS3(signedUrl, file, fileType);
    return backgroundId;
  };

  const submitChanges = async () => {
    if (status === 'consented') {
      const uploadedFile = await uploadFile();
      if (typeof uploadedFile === 'string') {
        const pl = {
          id: participant.id,
          status,
          backgroundId: uploadedFile,
        };
        await onUpdateConsent(pl);
        onCloseModal();
      }
    } else {
      const pl = {
        id: participant.id,
        status,
      };
      onUpdateConsent(pl);
      onCloseModal();
    }
  };


  return (
    <Modal
      open={onOpen}
      onClose={onCloseModal}
      title="Update Consent"
      size="sm"
    >
      <div>
        <Dropdown
          name='status'
          label='Status'
          value={status}
          onChange={handleChangeStatus}
        >
          <MenuItem value="not consented">Not Consented</MenuItem>
          <MenuItem value="consented">Consented</MenuItem>
          <MenuItem value="revoked">Revoked</MenuItem>
        </Dropdown>
        {status === 'consented' &&
          <div>
            <Spacer value={10} />
            <Label className="input__label" size="h5" weight={600} color="primary" >Upload File (Optional)</Label>
            <input className='consent-manager__file-input' id='consentFileInput' type='file' onChange={handleOnChange} />
          </div>
        }
        <Spacer value={20} />
        <div className='btn-row'>
          <Button outline kind="danger" onClick={onCloseModal} >
            Cancel
          </Button>
          <Button onClick={submitChanges}>
            update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ConsentManager.defaultProps = {
  // key: '_key',
};

ConsentManager.displayName = 'ConsentManager';


export default ConsentManager;
