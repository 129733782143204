import React from 'react';
// import classnames from 'classnames';
import { BsFillFlagFill, BsFlag } from 'react-icons/bs';
import './flag.scss';
import { Modal } from '../../atoms';
import TextInput from '../TextInput/TextInput';

export interface FlagProps {
  addFlag: (payload: FlagItem) => void,
  item: string,
}

export interface FlagItem {
  user: string,
  userEmail: string,
  message: string,
  time: string,
  item: string,
}

const initial = {
  user: '',
  userEmail: '',
  message: '',
  time: '',
  item: '',
};

const Flag = (props: FlagProps) => {
  const { addFlag } = props;
  const [open, setOpen] = React.useState(false);
  const [newFlagValues, setNewFlagValues] = React.useState(initial);

  const handleChangenVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFlagValues({ ...newFlagValues, [event.target.id]: event.target.value });
  };

  return (
    <div>
      <div role='button' tabIndex={-1} onClick={() => setOpen(true)}>
        <BsFillFlagFill />
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title='Item Flags'
      >
        <div>
          <TextInput
            label="user"
            id="user"
            onChange={handleChangenVals}
            value={newFlagValues.user}
          />
          <TextInput
            label="userEmail"
            id="userEmail"
            onChange={handleChangenVals}
            value={newFlagValues.userEmail}
          />
          <TextInput
            label="message"
            id="message"
            onChange={handleChangenVals}
            value={newFlagValues.message}
          />
          <TextInput
            label="time"
            id="time"
            onChange={handleChangenVals}
            value={newFlagValues.time}
          />
        </div>
      </Modal>
    </div>
  );
};

Flag.defaultProps = {
  // key: '_key',
};

Flag.displayName = 'Flag';


export default Flag;
