/* eslint-disable react/require-default-props */
import React from 'react';
// import classnames from 'classnames';

import { FaSearch } from 'react-icons/fa';
import './search-box.scss';

type Props = {
  value?: string;
  onChange?: (value: string ) => void;
  onSubmit?: (value: string ) => void;
  placeholder?: string;
  className?: string;
};

const SearchBox = ({ value, onChange, onSubmit, placeholder, className }: Props) => {
  const [state, setState] = React.useState({
    value: value || '',
  });
  const [field, setField] = React.useState('mrn');
  const [blur, setBlur] = React.useState(false);

  React.useEffect(() => {
    if (typeof value === 'string') {
      setState({ value });
    }
  }, [value]);

  const handleChangeField = (val: any) => {
    setField(val.target.value);
    // if (onChangeField) {
    //   onChangeField(val.target.value);
    // }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { value } = e.target;
    setState({ value });
    if (onChange) onChange(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(state.value);
    }
  };
  const localclassname = `search-box ${className || ''}`;
  const showSubmitButton = onSubmit !== undefined;
  return (
    <div className={localclassname}>
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder={placeholder} value={state.value} onChange={handleChange} />
        {showSubmitButton && (
          <button type="submit" className="icon-btn">
            <FaSearch className="icon" color="#20caf9"/>
          </button>
        )}
      </form>
    </div>
  );
};
SearchBox.defaultProps = {
  value: '',
  onChange: () => null,
  onSubmit: undefined,
  placeholder: 'Enter text to search',
};

export default SearchBox;
