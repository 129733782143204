/* eslint-disable no-console */
import React, { useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsPeople } from 'react-icons/bs';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FaClipboardList } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { IoStatsChart } from 'react-icons/io5';
import moment from 'moment';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { AdvancedFlatList, ConferencesManager, ConferenceReadyPatient } from '../../../components/molecules';
import { Label, Tile, IconButton } from '../../../components/atoms';
import { useGetPatientsByPCPStatusQuery, registriesApi } from '../../../api/builders/registries.api';


interface LocationState {
  // state: {
  registry: any;
  // };
}

export interface PageProps {
  registry: any;
}



const Conferences = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { breadcrumbs, setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const { registry } = location?.state as LocationState;
  const { data: blackHole, isLoading: isLoadingHold, isFetching: isFetchingHole } = useGetPatientsByPCPStatusQuery({
    patientRegistryUID: registry ? registry.id : skipToken,
    status: 'Ready for Conference',
  });

  const { refetch } = registriesApi.endpoints.getPatientsByPCPStatus.useQuerySubscription({
    patientRegistryUID: registry ? registry.id : skipToken,
    status: 'Ready for Conference',
  });

  React.useEffect(() => {
    // const newBreadcrumb = {
    //   label: 'Conferences',
    //   path: location.pathname,
    //   state: { registry },
    // };
    // const alreadyExists = breadcrumbs.some((breadcrumb) => breadcrumb.path === newBreadcrumb.path);

    // if (alreadyExists === false) {
    //   setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
    // }
    const newBreadcrumb = [
      {
        label: registry?.name ?? 'Registry',
        path: '/private/registries/registry/overview',
        state: { registry },
      },
      {
        label: 'Conferences',
        path: location.pathname,
        state: { registry },
      },
    ];
    setBreadcrumbs(newBreadcrumb);
  }, [location.pathname]);


  const handleChangeTab = (newTab: string) => {
    navigate(`/private/registries/registry/${newTab}`, { state: { registry } });
  };

  if (!location?.state) {
    return (
      <div className='d-flex-row-center'>
        <Label size="h4" weight={600} color="primary">Error Loading Registry Details</Label>
      </div>
    );
  }
  return (
    <div className="registry">
      <div className="registry__header">
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('overview')}>
          <AiOutlineDashboard className='registry__icon' />
          <Label size='h5' uppercase>Overview</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('settings')}>
          <FiSettings className='registry__icon' />
          <Label size='h5' uppercase>Settings</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('patients')}>
          <BsPeople className='registry__icon' />
          <Label size='h5' uppercase>All Patients</Label>
        </button>
        <button type='button' className='registry__tab' onClick={() => handleChangeTab('tracker')}>
          <IoStatsChart className='registry__icon' />
          <Label size='h5' uppercase>Patient Tracker</Label>
        </button>
        <button type='button' className='registry__tab-active' onClick={() => handleChangeTab('conferences')}>
          <FaClipboardList className='registry__icon-active' />
          <Label size='h5' uppercase>Conferences</Label>
        </button>
      </div>
      <div className="conferences">
        <div className="conferences--body">
          <ConferencesManager
            className="conferences-mgr"
            id="conferences-manager"
            registry={registry}
          />
          <Tile className='ready'>
            <div>
              <span className='d-flex'>
                <Label size="h3" weight={300}>
                  Pending Patients
                </Label>
                <IconButton icon='refresh' onClick={refetch} />
              </span>
              {blackHole?.length > 0 && (
                <>
                  {blackHole.map((item: any) => (
                    <ConferenceReadyPatient
                      item={item}
                    />
                  ))}
                </>
              )}
            </div>
          </Tile>
        </div>
      </div>
    </div>
  );
};


export default withAuthenticator(Conferences);