import QueryString from 'qs';
import api from '../api';


export const studyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudyInfo: builder.query({
      query: (payload) => ({ url: `/study/statsbyid/${payload.studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['STUDY'],
    }),
    getStudySites: builder.query({
      query: (payload) => ({ url: `/site/${payload.studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['SITES'],
    }),
    getActivities: builder.query({
      query: (payload) => ({ url: `/participant/activities/${payload.studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['ACTIVITIES'],
    }),
    getImgStats: builder.query({
      query: (studyId) => ({ url: `/study/scanreportbyid/${studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['STUDYSTATS'],
    }),
    createSite: builder.mutation({
      query: (payload) => ({ url: '/site', method: 'POST', body: payload }),
      invalidatesTags: ['STUDY'],
    }),
    editStudy: builder.mutation({
      query: (payload) => ({ url: `/study/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['STUDIES', 'STUDY'],
    }),
    getStudyUsers: builder.query({
      query: (payload) => ({ url: `/user/study/${payload.studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['STUDYUSERS'],
    }),
    createUser: builder.mutation({
      query: (payload) => ({ url: '/user', method: 'POST', body: payload }),
      transformResponse: (response: any) => response,
      invalidatesTags: ['STUDYUSERS'],
    }),
    editUser: builder.mutation({
      query: (payload) => ({ url: `/user/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['STUDYUSERS'],
    }),
    searchPatients: builder.query({
      query: ({ query, type, pageCurrent, pageSize }) => `/patient/search?query=${query}&type=${type}&${QueryString.stringify({ pageCurrent, pageSize })}`,
      providesTags: ['PATIENTS'],
    }),
  }),
});

export const { usePrefetch, useSearchPatientsQuery, useGetStudyInfoQuery, useGetStudySitesQuery, useCreateSiteMutation, useGetActivitiesQuery, useEditStudyMutation, useGetStudyUsersQuery, useCreateUserMutation, useEditUserMutation, useGetImgStatsQuery } = studyApi;
