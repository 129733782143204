import React from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import ParticipantScans from '../../ParticipantScans/ParticipantScans';
import { Tile, Label, Text, Button, Spacer, Alert } from '../../../atoms';
import { useEditAnalysisMutation } from '../../../../api/builders/imageAnalysis.api';

export interface InputProps {
  careCase: any,
  participant: any,
  handleStartProcessing: (id: string) => any,
}

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const Inputs = (props: InputProps): JSX.Element => {
  const { careCase, handleStartProcessing, participant } = props;
  const [appContent, setAppContent] = React.useState<any | null>(null);
  const [isDragActive, setIsDragActive] = React.useState(false);
  const [handleEdit, { error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useEditAnalysisMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Saving Inputs ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Saving Inputs',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  React.useEffect(() => {
    if (careCase && careCase.userAppInput) {
      const tmp = JSON.parse(JSON.stringify(careCase.userAppInput));
      setAppContent(tmp);
    }
  }, [careCase]);




  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
    setIsDragActive(false);
  };

  // enables us to copy the file from our PC to the drop area
  const handleDragOver = (event: any) => {
    event.preventDefault();
    // event.dataTransfer.dropEffect === 'copy';
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
  };


  const handleDrop = async (e: any, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsDragActive(false);
      if (e.dataTransfer.items) {
        const newScan = JSON.parse(e.dataTransfer.getData('scan'));
        if (newScan) {
          setAppContent(() => {
            const newArray = [...appContent];
            const objectToUpdate = newArray[index];
            const updatedObject = {
              ...objectToUpdate,
              inputs: JSON.stringify(newScan),
            };
            newArray[index] = updatedObject; // replace the object in the array
            return newArray;
          });
        }
      }
      // let res = await onUpload(_files);
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemoveScan = (index: number) => {
    if (Array.isArray(appContent) && appContent.length > 0) {
      setAppContent(() => {
        const newArray = [...appContent];
        const objectToUpdate = newArray[index];
        const updatedObject = { ...objectToUpdate }; // create a new copy of the object
        updatedObject.inputs = '';
        newArray[index] = updatedObject; // replace the object in the array
        return newArray;
      });
    }
  };

  const handleSave = async () => {
    try {
      if (appContent.length > 0) {
        await appContent.forEach((i: any) => {
          if (i.inputs) {
            const parsed = JSON.parse(i.inputs);
            const editPl = {
              id: i.id,
              body: {
                status: careCase.status,
                inputs: i.inputs,
                dicom_s3: parsed?.ScanSeries?.coldKey ?? '',
                bucket: parsed?.bucket ?? '',
              },
            };
            handleEdit(editPl);
          } 
          // else {
          //   setAlert({
          //     open: true,
          //     message: 'Cannot save empty input, please add scan to save',
          //     kind: 'error',
          //   });
          // }
        });
      }
    } catch (e) {
      console.error('ERROR PROCESSING', e);
    }
  };

  const startProcessing = async () => {
    await handleSave();
    handleStartProcessing(careCase.id);
  };

  // const renderInput = (i: any, index: number) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const Droppers = (inputProps: { i: any, index: number, handleRemoveScan: unknown }): JSX.Element => {
    const { i, index } = inputProps;

    const parsed = JSON.parse(i);
    if (parsed) {
      return (
        <div className='care-step__scan-card'>
          <div className='care-step__scan-card__header'>
            <Label uppercase size="h5" weight={600} >
              {parsed.StudyDescription}
            </Label>
            <button onClick={() => handleRemoveScan(index)} type='button' className='care-step__btn' >
              <IoClose />
            </button>
          </div>
          <Label uppercase size="h6" >
            Study Date: {moment(parsed.StudyDate).format('l')}
          </Label>
          <Label uppercase size="h6" >
            {parsed.Modality && <div>Modality: {parsed.Modality}</div>}
          </Label>
        </div>
      );
    }
    return <div> {' '} </div>;
  };

  const MemodInputs = React.useMemo(() => Droppers, [careCase]);

  // const hasInputs = () => {
  //   if (appContent === null) return false;
  //   return appContent.every((item: any) => item.inputs.trim().length > 0);
  // };

  const hasInputs = () => {
    if (appContent === null) return false;
    return appContent.some((item: any) => {
      if (item.required) {
        return !!(item.inputs && item.inputs.trim().length > 0);
      }
      return false;
    });
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={7}>
          <div>
            {
              appContent && appContent.map((item: any, index: number) => (
                <div
                  onDrop={(event) => handleDrop(event, index)}
                  onDragOver={(event) => handleDragOver(event)}
                  onDragEnter={(event) => handleDragEnter(event)}
                  onDragLeave={(event) => handleDragLeave(event)}
                  className='care-step__dnd'
                >
                  <div className='h-100'>
                    {item.inputs !== '' ?
                      <div>
                        <Droppers i={item.inputs} index={index} handleRemoveScan={handleRemoveScan} />
                        {/* {renderInput(item.inputs, index)} */}
                      </div>
                      :
                      <div className='cloud'>
                        <AiOutlineCloudUpload size={40} />
                      </div>
                    }
                  </div>
                  <div className='care-step__dnd__bottom'>
                    <Text className='dicom-uploader-tagline'>
                      {item.scanType}
                    </Text>
                    {item.required &&
                      <Text className='dicom-uploader-tagline'>
                        (Required)
                      </Text>
                    }
                  </div>
                </div>
              ))
            }
            <Spacer value={15} />
            <div className='btn-row'>
              {/* {appContent && appContent[0]?.dicom_s3 ?
                <Button onClick={startProcessing}>Start Processing</Button>
                :
                <Button onClick={handleSave}>Save</Button>
              } */}
              <Button onClick={handleSave}>Save</Button>
              <Button disabled={!hasInputs()} onClick={startProcessing}>Start Processing</Button>
            </div>
          </div>
        </Grid>
        <Grid item sm={5}>
          <div>
            <ParticipantScans participant={participant} />
          </div>
        </Grid>
      </Grid>
      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div >
  );
};

Inputs.defaultProps = {
};

Inputs.displayName = 'Inputs';
export default Inputs;


