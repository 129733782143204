import clinicalMenu from './clinicalMenu';
import cohortMenu from './cohortMenu';
import imxMenu from './imxMenu';
import privacyMenu from './privacyMenu';
import trialsMenu from './trialsMenu';
import registryMenu from './registryMenu';
import patientMenu from './patientMenu';

const allMenu = [
  ...clinicalMenu,
  ...cohortMenu,
  ...imxMenu,
  ...privacyMenu,
  ...trialsMenu,
  ...registryMenu,
  ...patientMenu,
];

export default allMenu;
