import QueryString from 'qs';
import api from '../../../api/api';

export const studyStatusAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudyStatus: builder.query({
      query: (payload) => ({ url: `/study/requirementsbyid/${payload.id}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      // providesTags: ['SITE'],
    }),
    
  }), 
});

export const { useGetStudyStatusQuery } = studyStatusAPI;