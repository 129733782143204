import QueryString from 'qs';
import api from '../api';

export const studyConfigApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudyInfo: builder.query({
      query: (payload) =>  ({ url: `/study/statsbyid/${payload.studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['STUDY'],
    }),
    getStudySites: builder.query({
      query: (payload) =>  ({ url: `/site/${payload.studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['SITES'],
    }),
    getStudyUsers: builder.query({
      query: (payload) => ({ url: `/user/study/${payload.studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['STUDYUSERS'],
    }),
    createUser: builder.mutation({
      query: (payload) => ({ url: '/user', method: 'POST', body: payload }),
      transformResponse: (response: any) => response,
      invalidatesTags: ['STUDYUSERS'],
    }),
    editUser: builder.mutation({
      query: (payload) => ({ url: `/user/${payload.id}`, method: 'PUT', body: payload.body }),
      invalidatesTags: ['STUDYUSERS'],
    }),
  }),
});

export const { usePrefetch, useGetStudyInfoQuery, useGetStudySitesQuery, useCreateUserMutation, useGetStudyUsersQuery, useEditUserMutation } = studyConfigApi;
