import React, { useState, useEffect } from 'react';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { BiRefresh } from 'react-icons/bi';
import MenuItem from '@mui/material/MenuItem';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { Spacer, Label, Button, Spinner, Dropdown } from '../../atoms';
import DropAllFiles from '../DropAllFiles/DropAllFiles';
import ScanCard from '../ScanCard/ScanCard';
import FileCard from '../FileCard/FileCard';
import TextInput from '../TextInput/TextInput';
import { useGetAllParticipantScansQuery, useGetAllParticipantFilesQuery, participantFilesApi } from '../../../api/builders/participantFiles.api';
import './participant-files.scss';

export interface ParticipantFilesProps {
  participant: any,
  showScans: boolean,
  setShowScans: () => void,
  path: string,
}

const ParticipantFiles = (props: ParticipantFilesProps): JSX.Element => {
  const { participant, showScans, setShowScans, path } = props;
  const { data: images, isFetching: isLoadingScans } = useGetAllParticipantScansQuery(participant ?? skipToken);
  const { data: files, isFetching: isLoadingFiles } = useGetAllParticipantFilesQuery(participant ?? skipToken);
  const { refetch: refetchFiles } =
    participantFilesApi.endpoints.getAllParticipantFiles.useQuerySubscription(participant ?? skipToken);
  const { refetch: refetchScans } =
    participantFilesApi.endpoints.getAllParticipantScans.useQuerySubscription(participant ?? skipToken);
  const [view, setView] = useState('files');
  const [scans, setScans] = useState<any[]>([]);
  const [filteredScans, setFilteredScans] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [studyDateFilter, setStudyDateFilter] = useState<string>('');
  const [modalityFilter, setModalityFilter] = useState<string>('');

  useEffect(() => {
    // Fetch scans from API
    if (Array.isArray(images) && images.length > 0) {
      setScans(images);
    }
  }, [images]);

  const handleRefresh = () => {
    refetchFiles();
    refetchScans();
  };

  useEffect(() => {
    // Filter scans based on search term, study date filter, and modality filter
    const filtered = scans.filter((scan) =>
      scan.StudyDescription.toLowerCase().includes(searchTerm.toLowerCase()) &&
      scan.StudyDate.includes(studyDateFilter) &&
      (!modalityFilter || scan.Modality === modalityFilter),
    );
    setFilteredScans(filtered);
  }, [scans, searchTerm, studyDateFilter, modalityFilter]);

  const uniqueStudyDates = Array.from(new Set(scans.map((scan) => scan.StudyDate)));
  const uniqueModalities = Array.from(new Set(scans.map((scan) => scan.Modality).filter((modality) => modality)));


  const changeSize = () => {
    setShowScans();
  };

  return (
    <div className="participant-files">
      <div className='participant-files__header'>
        <span className='participant-files__title'>
          <Label size='h3' uppercase weight={600}>Participant Drive</Label>
          <button className='icon-btn' type='button' onClick={handleRefresh}>
            <BiRefresh />
          </button>
        </span>
        <button className='icon-btn' type='button' onClick={changeSize}>
          <MdArrowForwardIos />
        </button>
      </div>
      <Spacer value={10} />
      <div className='d-flex'>
        {view === 'files' &&
          <Button outline onClick={() => setView('upload')}>Upload Files</Button>
        }
        {view === 'upload' &&
          <Button outline onClick={() => setView('files')}>Show File List</Button>
        }
      </div>
      <Spacer value={10} />

      {(isLoadingScans || isLoadingFiles) ?
        <div className='spinner-wrapper'>
          <Spinner />
        </div>
        :
        <div>
          {view === 'files' &&
            <div className='participant-files__body'>
              <div className='participant__protocol-scroll-section-header'>
                <Label uppercase size="h6" color='primary'>
                  Images
                </Label>
              </div>
              <TextInput
                placeholder="Search by study description"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="participant-scans__search-row">
                <Dropdown
                  label="Study date"
                  name='studyDateFilter'
                  value={studyDateFilter}
                  onChange={(e) => setStudyDateFilter(e.target.value)}
                >
                  <MenuItem value=''>
                    Any
                  </MenuItem>
                  {uniqueStudyDates.length > 0 && uniqueStudyDates.map((date) => (
                    <MenuItem key={date} value={date}>
                      {moment(date).format('l')}
                    </MenuItem>
                  ))}
                </Dropdown>
                <Dropdown
                  label="Modality"
                  name='modality'
                  value={modalityFilter}
                  onChange={(e) => setModalityFilter(e.target.value)}
                >
                  <MenuItem value=''>
                    Any
                  </MenuItem>
                  {uniqueModalities.length > 0 && uniqueModalities.map((modality) => (
                    <MenuItem key={modality} value={modality}>
                      {modality}
                    </MenuItem>
                  ))}
                </Dropdown>
              </div>
              {filteredScans && filteredScans.map((item: any) => (
                <ScanCard scan={item} />
              ))}
              <Spacer value={15} />
              <div className='participant__protocol-scroll-section-header'>
                <Label uppercase size="h6" color='primary'>
                  Documents
                </Label>
              </div>
              {files && files.map((file: any) => (
                <FileCard file={file} draggable />
              ))}
            </div>
          }
          {view === 'upload' &&
            <div className='participant-files__body'>
              <DropAllFiles path={path} />
            </div>
          }
        </div>
      }
    </div>
  );
};

ParticipantFiles.defaultProps = {
};

ParticipantFiles.displayName = 'ParticipantFiles';
export default ParticipantFiles;
