import React from 'react';
import { Label } from '../../atoms';
import './file-card.scss';

export interface FileCardProps {
  file: any,
  draggable?: boolean,
}

const FileCard = (props: FileCardProps): JSX.Element => {
  const { file, draggable } = props;

  const dragStart = (event: any, item: any) => {
    try {
      event.dataTransfer.setData('items', JSON.stringify(item));
    } catch (e) {
      console.error(e);
    }
  };


  const getFileName = (fileName: string) => {
    try {
      const pathTmp = fileName.split('/');
      if (pathTmp[pathTmp.length - 1].length > 50) {
        return `${pathTmp[pathTmp.length - 1].substring(0, 49)} ...`;
      }
      return pathTmp[pathTmp.length - 1];
    } catch (e) {
      console.error(e);
      return 'File';
    }
  };


  return (
    <div
     className='file__item' 
     key={file.id}
     draggable={draggable}
     onDragStart={(e) => dragStart(e, file)}
     >
      <div className='file__btn' role='button' tabIndex={-1}>
        <Label uppercase size="h6">
          📂 {getFileName(file.hotKey)}
        </Label>
      </div>
    </div>
  );
};

FileCard.defaultProps = {
  draggable: false,
};

FileCard.displayName = 'FileCard';
export default FileCard;
