import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { BiRefresh } from 'react-icons/bi';
import { AiFillPlusCircle } from 'react-icons/ai';
import FlatList from 'flatlist-react';
import { BreadcrumbsContext } from '../../../utils/breadcrumbs/breadcrumbs-context';
import { Spinner, Label, Spacer, Alert, RegistryItem, Modal, TextInput, Button, SearchBox } from '../../../components';
import { TextAreaInput } from '../../../components/molecules';
import withAuthenticator from '../../../utils/authentication/withAuthenticator';
import { useGetPatRegistriesQuery, registriesApi, useCreateRegistryMutation, usePrefetch } from '../../../api/builders/registries.api';
import { IconButton } from '../../../components/atoms';

type StudiesTableProps = {
  title?: string | undefined;
  items: Array<any> | null;
};

type StudyItemProps = {
  label?: string | undefined;
  description?: string | undefined;
  status?: string | undefined;
  id?: string | undefined;
  title?: string | undefined;
  summary?: string | undefined;
};

const renderItem = (item: any, idx: React.Key | null | undefined) => (
  <RegistryItem key={idx} item={item} />
);
export interface TypeError {
  status: number,
  data: { message: string },
}

const initialForm = {
  name: '',
  description: '',
};

type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}


const Registries = () => {
  const [payload, setPayload] = React.useState<any>({
    pageSize: 10,
    orderBy: 'recent',
  });
  const [AddStudy, { isLoading: isAdding, error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useCreateRegistryMutation();
  const { setBreadcrumbs } = React.useContext(BreadcrumbsContext);
  const { data, isError, isLoading, isFetching } = useGetPatRegistriesQuery(payload);
  const { refetch: refetchParticipants } =
    registriesApi.endpoints.getPatRegistries.useQuerySubscription(payload);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [values, setValues] = React.useState(initialForm);
  const [studies, setStudies] = React.useState<any>(null);
  const [err, setErr] = React.useState({ open: false, message: '' });
  const prefetchNext = usePrefetch('getPatRegistries');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });

  const handleChangeSearch = (value: string) => {
    setSearchTerm(value);
  };

  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Adding New Registry: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Adding New Registry',
        kind: 'success',
      });
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  const loadMore = () => {
    setPayload({
      ...payload,
      pageSize: 10,
      pageCurrent: data.pageCurrent + 1,
      orderBy: 'recent',
    });
  };


  React.useEffect(() => {
    if (data && Array.isArray(data.items) && data.items.length > 0) {
      const items = Array.isArray(studies) ? studies : [];
      const curr = data.pageCurrent > 1 ? items.concat(data.items) : data.items;
      setStudies(curr);

      const PL = {
        pageCurrent: data.pageCurrent + 1,
        pageSize: 10,
        orderBy: 'recent',
      };
      prefetchNext(PL);
    }
  }, [data]);

  const filtered = studies?.filter((study: any) => (study.name?.toLowerCase().includes(searchTerm.toLowerCase()) || study.description?.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  const handleChangeSummary = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleChangeVals = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  React.useEffect(() => {
    setBreadcrumbs([]);
  }, []);

  const onCloseModal = () => {
    setIsOpen(false);
    setValues(initialForm);
    setErr({ open: false, message: '' });
  };

  const onOpenModal = () => {
    setIsOpen(true);
  };

  function validateFields(vals: { [key: string]: any }) {
    /* eslint-disable no-restricted-syntax */
    for (const field in vals) {
      if (!vals[field]) {
        setErr({
          open: true,
          message: `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
        });
        return true;
      }
    }
    return false;
  }


  const onAddStudy = async () => {
    try {
      const validate = validateFields(values);
      if (!validate) {
        const a = await AddStudy(values);
        onCloseModal();
      }
    } catch (onAddStudyErr) {
      console.error('error', onAddStudyErr);
    }
  };

  if (isLoading || isAdding || isFetching) {
    return (
      <div className="super-centered">
        <Spacer value={30} />
        <Spinner />
      </div>
    );
  }
  return (
    <div className="registries">

      <div className='registries__header'>
        <span className='registries__header-title'>
          <Label size="h2" weight={300}>
            Patient Registries
          </Label>
          <IconButton icon="refresh" onClick={refetchParticipants} />
        </span>

        <span className='d-flex'>
          <SearchBox value={searchTerm}
            onChange={handleChangeSearch}
            placeholder='Search' />
          <IconButton icon="add" onClick={onOpenModal} />
        </span>

      </div>


      {/* <div className='d-flex-row'>
        <div className='d-flex'>
          <Label size="h2" weight={300}>Patient Registries</Label>
          <button type='button' className='icon-btn' aria-label='refetch' onClick={refetchParticipants}>
            <BiRefresh />
          </button>
        </div>
        <div className='d-flex-row-center pr-12'>
          <button type='button' className='studies__icon-btn' aria-label='refetch' onClick={onOpenModal}>
            <AiFillPlusCircle size={24} className='studies__icon-btn-icon' />
          </button>
        </div>
      </div> */}


      <div className='registries__body'>
        <Spacer value={10} />
        {Array.isArray(filtered) && <FlatList list={filtered} renderItem={renderItem} renderWhenEmpty={() => <div className='h-100 w-100 super-centered' ><Label color="ghost" size="h2">Nothing to Show</Label></div>} />}
        {data?.pageCurrent < data?.pageTotal &&
          <Button onClick={loadMore}>Load More</Button>
        }
      </div>
      <Modal
        open={modalIsOpen}
        onClose={onCloseModal}
        title="Add New Trial"
        size='sm'
      >
        <div>
          <TextInput
            name='name'
            label='Title'
            value={values.name}
            onChange={handleChangeVals}
          />
          <TextAreaInput
            name='description'
            label='Summary'
            value={values.description}
            onChange={handleChangeSummary}
          />
          <Spacer value={20} />
          <div className='btn-row'>
            {err.open && <Label size='h6' color='danger'>{err.message}</Label>}
          </div>
          <Spacer value={20} />
          <div className='btn-row'>
            <Button onClick={onAddStudy}>Submit</Button>
          </div>
        </div>
      </Modal >
      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div >
  );
};

export default withAuthenticator(Registries);