import QueryString from 'qs';
import api from '../api';

export const patientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addPatients: builder.mutation({
      query: (payload) => ({ url: '/patient', method: 'POST', body: payload }),
      invalidatesTags: ['OPENCOHORT'],
    }),
    deletePatient: builder.mutation({
      query: (payload) => ({ url: `/${payload.id}`, method: 'DELETE', body: payload.body }),
      invalidatesTags: ['OPENCOHORT'],
    }),
    getAllPatients: builder.query({
      query: () => ({ url: '/patient', method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['PATIENTS'],
    }),
    linkToCohort: builder.mutation({
      query: (payload) => ({ url: '/patient/cohort', method: 'POST', body: payload }),
      invalidatesTags: ['OPENCOHORT'],
    }),
    searchPatients: builder.query({
      query: ({ query, type, pageCurrent, pageSize }) => `/patient/search?query=${query}&type=${type}&${QueryString.stringify({ pageCurrent, pageSize })}`,
      providesTags: ['PATIENTS'],
    }),
    advancedSearch: builder.query({
      query: ({ query, pageCurrent, pageSize }) => `/patient/search?${query}&${QueryString.stringify({ pageCurrent, pageSize })}`,
      providesTags: ['PATIENTS'],
    }),
  }),
});

export const {  
  usePrefetch,
  useAddPatientsMutation,
  useDeletePatientMutation,
  useGetAllPatientsQuery,
  useLinkToCohortMutation, 
  useSearchPatientsQuery,
  useAdvancedSearchQuery,
} = patientApi;
