import QueryString from 'qs';
import api from '../../../api/api';

export const testsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudyFiles: builder.query({
      query: ({ id, order_by, pageCurrent, pageSize }) => `/files/${id}?${QueryString.stringify({ order_by, pageCurrent, pageSize })}`,
      transformResponse: (response: any ) => response,
      // providesTags: ['STUDIES'],
    }),
    getAllPats: builder.query({
      query: ({ siteId, order_by, pageCurrent, pageSize }) => ({ url: `participant/${siteId}?${QueryString.stringify({ order_by, pageCurrent, pageSize })}`, method: 'GET' }),
      transformResponse: (response: any ) => response,
    }),
    getStudySites: builder.query({
      query: (payload) => ({ url: `/site/${payload.studyId}`, method: 'GET' }),
      transformResponse: (response: any) => response.items,
      providesTags: ['SITES'],
    }),
    downLoadFile: builder.mutation({ query: (s3_path) => ({ url: '/files/drive/getobject/', method: 'POST', body: s3_path }) }),
    createZip: builder.mutation({ query: (payload) => ({ url: '/files/s3zip/', method: 'POST', body: payload }) }),
  }),
});

export const { useGetStudyFilesQuery, useGetAllPatsQuery, useGetStudySitesQuery, useDownLoadFileMutation, useCreateZipMutation } = testsApi;
