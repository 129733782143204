import React from 'react';
import classnames from 'classnames';
import './spacer.scss';

type Props = {
  value?: 5 | 10 | 15 | 20 | 30,
};

const Spacer = ({ value }: Props) => {

  const classes = {
    'spacer': true,
    [`spacer--${value}`]: true,
  };

  return (
    <div className={classnames(classes)} />
  );
};

Spacer.defaultProps = {
  value: 10,
};

export default Spacer;