/* eslint-disable  */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import * as cornerstone3D from '@cornerstonejs/core';
import * as cornerstone3DTools from '@cornerstonejs/tools';
import { cornerstoneStreamingImageVolumeLoader } from '@cornerstonejs/streaming-image-volume-loader';


// import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

import { ToolGroupManager } from '@cornerstonejs/tools';

// import classnames from 'classnames';
import './dicom-viewer3-d.scss';
import { useGetColdFileArrayQuery } from '../../../api/builders/participantFiles.api';
import LoadingWrapper from '../LoadingWrapper/LoadingWrapper';
import { ViewportType } from '@cornerstonejs/core/dist/esm/enums';
import { IStackViewport, PublicViewportInput } from '@cornerstonejs/core/dist/esm/types';
import { registerUnknownVolumeLoader, registerVolumeLoader } from '@cornerstonejs/core/dist/esm/loaders/volumeLoader';
import { registerImageLoader, registerUnknownImageLoader } from '@cornerstonejs/core/dist/esm/loaders/imageLoader';

cornerstoneWADOImageLoader.external.cornerstone = cornerstone3D;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

// cornerstoneTools.external.cornerstone = cornerstone;
const renderingEngineId = 'myRenderingEngine';
const volumeName = 'CT_VOLUME_ID'; // Id of the volume less loader prefix
const volumeLoaderScheme = 'cornerstoneStreamingImageVolume'; // Loader id which defines which volume loader to use
const volumeId = `${volumeLoaderScheme}:${volumeName}`; // VolumeId with loader id + volume id



var wadoconfig = {
  // maxWebWorkers: navigator.hardwareConcurrency || 1,
  // startWebWorkersOnDemand: true,
  useWebWorkers: true,
  decodeConfig: {
    convertFloatPixelDataToInt: false,
  },
};

cornerstoneWADOImageLoader.configure(wadoconfig);
var config = {
  maxWebWorkers: navigator.hardwareConcurrency || 1,
  startWebWorkersOnDemand: false,
  taskConfiguration: {
    decodeTask: {
      initializeCodecsOnStartup: true,
      strict: false,
    },
  },
};

cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
cornerstone3D.init();
cornerstone3DTools.init();
// cornerstone3DStreaming.cornerstoneStreamingImageVolumeLoader 

registerUnknownVolumeLoader(cornerstoneStreamingImageVolumeLoader as unknown as cornerstone3D.Types.VolumeLoaderFn);
registerVolumeLoader(
  'cornerstoneStreamingImageVolume',
  cornerstoneStreamingImageVolumeLoader as unknown as cornerstone3D.Types.VolumeLoaderFn
);

registerUnknownImageLoader(cornerstoneWADOImageLoader);


interface IStack {
  currentImageIdIndex: number;
  imageIds: string[];
}

const initStack: IStack = {
  currentImageIdIndex: 0,
  imageIds: []
};

export interface DicomViewer3DProps  {
  id: string | undefined,
  className?: string,
  viewerId: string;
  series: any;
  prefix?: string;
}
const serializeSerieId = (st: any) => {
  const variable = 'filler';
  return st.replace(/\./g, '_');
};

const DicomViewer3D = (props: DicomViewer3DProps): JSX.Element => {
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const renderingEngineRef = useRef<cornerstone3D.RenderingEngine | null>(null);

  const { id, series, viewerId, prefix, className } = props;
  const [currentStack, setCurrentStack] = useState(initStack);
  const {
    data,
    isLoading: isLoadingData,
    isFetching: isFetchingData,
    isError
  } = useGetColdFileArrayQuery( series ? {id: serializeSerieId(series.id), job:series?.registrationJobId, aligned:series?.alignment} : skipToken );
  
  useEffect(() => {
    if (viewerRef.current) {
      const renderingEngine = new cornerstone3D.RenderingEngine();
      renderingEngineRef.current = renderingEngine;
      // Access the element here once it is rendered
      const element = viewerRef.current;
      // API1: set Viewports
      renderingEngine.setViewports([
        {
          viewportId: "CTAxial",
          type: ViewportType.ORTHOGRAPHIC,
          element,
          defaultOptions: {
            orientation: cornerstone3D.Enums.OrientationAxis.AXIAL,
          },
        },
      ]);
      // API2: Enable Element
      renderingEngine.enableElement({
        viewportId: "CTAxial",
        type: ViewportType.ORTHOGRAPHIC,
        element,
        defaultOptions: {
          orientation: cornerstone3D.Enums.OrientationAxis.AXIAL,
        },
      });
    
    } else {
      console.error('viewerRef not avialable');
    }
}, []);

  const callback = (arg: any) => {
    console.info('callback', arg);
  };
  const load = async (stack: IStack) => {
    if (renderingEngineRef.current){
      const renderingEngine = renderingEngineRef.current;
      const viewport = renderingEngine.getViewport('CTAxial') ;
      // console.log('==viewport==', viewport);
      if (viewport){
        // await viewport?.setStack(stack.imageIds);
        // console.log('==>ctVolume>', 'before');

        // const ctVolume = await cornerstone3D.volumeLoader.createAndCacheVolume(
        const ctVolume = await cornerstone3D.volumeLoader.createAndCacheVolume(
          volumeId,
          { imageIds: stack.imageIds}
        )
        // console.log('==>ctVolume>', ctVolume);

        ctVolume.load(callback)
      }
    } else {
      console.error('no rendering engine');
    }
  };

  useEffect(() => {
    
    if (currentStack.imageIds.length>0){
      load(currentStack);
    }

  }, [currentStack]);

  useEffect(() => {
    if (data && data.Keys) {
      let imageIds = data.Keys.map((file: any) => `wadouri:${file}`);
      setCurrentStack({currentImageIdIndex:60, imageIds});
    }
  }, [data]);

  
  return (
    <div key={`DicomViewer3D_${id}`} className={`dicom-viewer--wrapper ${className}`}>
      <LoadingWrapper error={isError ? 'Error fetching data' : undefined} id='dicom3d-w' isLoading={isLoadingData || isFetchingData } className="dicom-viewer3-d_loading-wrapper ">
          <div id={viewerId} ref={viewerRef} className='viewer3d'>
            DicomViewer3D Ref
          </div>
        {/* {JSON.stringify(data.length)} */}
      </LoadingWrapper>
    </div>
  ); 
};

DicomViewer3D.defaultProps = {
  id: 'dicomviewer3d',
  className: '',
};

DicomViewer3D.displayName = 'DicomViewer3D';
export default DicomViewer3D;
