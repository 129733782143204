/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
import React from 'react';

import { useTranslation } from 'react-i18next';
import './protocol-visit-criteria-item.scss';
import { useMediaQuery } from 'react-responsive';
import { BsFillFlagFill } from 'react-icons/bs';
import { Label, Modal, Button, Spacer, Alert } from '../../atoms';
import { useCreatFileConnectionMutation } from '../../../api/builders/participantFiles.api';
import { IProtocolItemType } from '../ProtocolItem/ProtocolItem';


type AlertType = 'success' | 'error' | 'info' | 'warning';

type Alerto = {
  open: boolean,
  message: string,
  kind: AlertType,
};

interface ApiError {
  status: number;
  data: {
    message: string;
  };
}

type Props = {
  item: IProtocolItemType | undefined,
  active: boolean,
  index: number,
  onChangeItem: (item: IProtocolItemType, index: number) => void,
  // isUploading?: boolean,
  isCondensed: boolean | null;
};

// type ProtocolItemType = {
//   file: string | undefined,
//   id: string | undefined,
//   item: string | undefined,
//   label: string | undefined,
//   messages: string | undefined,
//   order: number,
//   createdAt: number | undefined,
//   updatedAt: number | undefined,
//   role: string | undefined,
//   status: string,
//   surveyId: string | undefined,
//   type: string | undefined,
//   userId: string | undefined,
//   deviation: boolean | null;
//   alignmentReference?: boolean | null;
// };

const ProtocolVisitCriteriaItem = (props: Props) => {
  const { active, item, index, onChangeItem, isCondensed = false } = props;
  // const { alignmentReference } = item;
  const [isDragActive, setIsDragActive] = React.useState(false);
  const [draggedFile, setDraggedFile] = React.useState<any>(null);
  const [modal, setModal] = React.useState(false);
  const [warnModal, setWarnModal] = React.useState(false);
  const [cannotDropAlert, setDropAlert] = React.useState(false);
  const [createConnection, { error, isError: isErrorAdding, isSuccess: isSuccessAdding }] = useCreatFileConnectionMutation();
  const [alert, setAlert] = React.useState<Alerto>({
    open: false,
    message: '',
    kind: 'success',
  });


  React.useEffect(() => {
    if (isErrorAdding) {
      const apiError = error as ApiError;
      setAlert({
        open: true,
        message: `Error Creating Link: ${apiError?.data?.message && apiError.data?.message}`,
        kind: 'error',
      });
      setDraggedFile(null);
    }
  }, [isErrorAdding]);

  React.useEffect(() => {
    if (isSuccessAdding) {
      setAlert({
        open: true,
        message: 'Success Linking Image',
        kind: 'success',
      });
      setDraggedFile(null);
    }
  }, [isSuccessAdding]);

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'success',
    });
  };


  const { t, i18n } = useTranslation();
  const getColor = (status: string) => {
    switch (status) {
      case 'incomplete':
        return 'gray-text';
      case 'completed':
        return 'green-text';
      case 'accepted':
        return 'green-text';
      case 'approved':
        return 'green-text';
      case 'pending review':
        return 'blue-text';
      case 'pending':
        return 'blue-text';
      case 'missing':
        return 'orange-text';
      case 'rejected':
        return 'red-text';
      default:
        return '';
    }
  };

  const handleMediaQueryChange = (matches: any) => {
    if (matches) {
      // window.alert("Trigger a window's alert at viewport of 1244px");
    } else {
      // do something if false
    }
  };
  const isSmallDesktopWindow = useMediaQuery(
    { maxWidth: 1150 }, undefined, handleMediaQueryChange,
  );

  const handleCloseAlert = () => {
    setDropAlert(false);
  };

  const handleCloseModal = () => {
    setModal(false);
    setDraggedFile(null);
  };

  const handleDragEnter = (event: any) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
    setIsDragActive(false);
  };

  // enables us to copy the file from our PC to the drop area
  const handleDragOver = (event: any) => {
    event.preventDefault();
    // event.dataTransfer.dropEffect === 'copy';
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragActive(true);
    }
  };

  const handleConfirm = () => {
    let id: string;
    if (draggedFile.ScanSeries && draggedFile.ScanSeries.length > 0) {
      id = draggedFile.ScanSeries[0].id;
    } else {
      id = draggedFile.id;
    }
    const payload = {
      seriesId: id,
      protocolItemId: item?.id,
    };
    createConnection(payload);
    setModal(false);
  };


  const handleDrop = async (e: any) => {
    if (active || (item?.status !== 'not started' && item?.status !== 'incomplete' && item?.status !== 'missing' && item?.status !== 'rejected') || item?.type !== 'radiology') {
      setDropAlert(true);
      setIsDragActive(false);
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsDragActive(false);
      if (e.dataTransfer.items) {
        const data = JSON.parse(e.dataTransfer.getData('items'));
        if (data) {
          setDraggedFile(data);
          setModal(true);
        }
      }
      // let res = await onUpload(_files);
    } catch (err) {
      console.error(err);
    }
  };
  const handleCloseWarnModal = () => {
    setWarnModal(false);
  };

  const handleChange = () => {
    if (!isDragActive && !draggedFile && item) {
      onChangeItem(item, index);
      // handleCloseWarnModal();
    }
  };

  const getClassname = () => {
    if (active) {
      return 'protocol-item protocol-item__active';
    }
    if (isDragActive) {
      return 'protocol-item protocol-item__dragging';
    }
    return 'protocol-item';
  };

  const renderFileName = () => {
    if (draggedFile) {
      if (draggedFile.Modality && draggedFile.SeriesDescription) {
        return draggedFile.Modality && draggedFile.SeriesDescription;
      }
      if (draggedFile?.ScanSeries[0].SeriesDescription) {
        return draggedFile.ScanSeries[0].SeriesDescription;
      }
    }
    return 'N/A';
  };


  return (
    <div>
      {item &&
        <div
          role='button'
          tabIndex={index}
          key={item.id}
          onClick={handleChange}
          className={getClassname()}
          onDrop={(event) => handleDrop(event)}
          onDragOver={(event) => handleDragOver(event)}
          onDragEnter={(event) => handleDragEnter(event)}
          onDragLeave={(event) => handleDragLeave(event)}
        >
          <div >
            
            {/* <Label size='h8'>req id {props?.item?.id}</Label> */}

            <div className={isSmallDesktopWindow ? 'protocol-item__grid-stacked' : 'protocol-item__grid'} >
              <div className='protocol-item__status'>
              { !isCondensed &&  <Label size='subtext' >Type: </Label> }
                <Label size='subtext' weight={600} >{t(item.type!)}</Label>
              </div>
              
              {!isCondensed && item.alignmentReference === true &&
              <div className='protocol-item__ref'><Label size='subtext' weight={600} >Alignement reference</Label>
              </div>}
              <div className={isSmallDesktopWindow ? 'protocol-item__status' : 'protocol-item__status flex-end'}>
              {!isCondensed && <Label size='subtext'  >Status: </Label>}
                <Label size='subtext' weight={600} className={getColor(item.status!)}>
                  {t(item.status!)}
                </Label>
              </div>
            </div>
            <div className={isSmallDesktopWindow ? 'protocol-item__grid-small' : 'protocol-item__grid'} >
              {/* <div className={isSmallDesktopWindow ? 'protocol-item__grid-stacked' : 'protocol-item__grid'} > */}
              <Label bold={active} uppercase
                color={active ? 'primary' : 'default'}
                size='h6' weight={600}>
                {item.item}
              </Label>
              {item.status === 'rejected' && <BsFillFlagFill size={10} className='protocol-item__flag' />}
            </div>

          </div>
          <Modal open={modal} onClose={handleCloseModal} title='Move File' size='sm'>
            <div>
              <Label size="h4">
                Would you like to move {renderFileName()} to {item.item}?
              </Label>
              <Spacer value={20} />
              <div className='btn-row'>
                <Button kind='danger' onClick={handleCloseModal}>Cancel</Button>
                <Button onClick={handleConfirm}>Confirm</Button>
              </div>
            </div>
          </Modal>
          <Modal open={warnModal} onClose={handleCloseWarnModal} title='WARNING' size='sm'>
            <div>
              <Label size="h4" >
                Your upload is not complete! If you leave this item now, any files that are still in progress of uploading may be lost. Are you sure you want to leave?
              </Label>
              <Spacer value={20} />
              <div className='btn-row'>
                <Button kind='danger' onClick={handleCloseWarnModal}>Cancel</Button>
                <Button onClick={handleChange}>Confirm</Button>
              </div>
            </div>
          </Modal>
          <Alert onClose={handleCloseAlert} severity="error" open={cannotDropAlert} message='Cannot Move Files to this Item' />
        </div>
      }

      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

ProtocolVisitCriteriaItem.defaultProps = {
  // isUploading: false,
};

export default ProtocolVisitCriteriaItem;
