import React from 'react';
import { styled } from '@mui/material/styles';
import './switch.scss';
import Switch from '@mui/material/Switch';
import { Label } from '../../atoms';

type Props = {
  checked?: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  label?: string
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#094ea5',
    '&:hover': {
      backgroundColor: (theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#094ea5',
  },
}));

const StyledSwitch = (props: Props) => {
  const { checked, onChange, label } = props;
  return (
    <div className='switch'>
      <GreenSwitch
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      {label && <Label className='input__label' size='h5' weight={600} color='primary' >{label}</Label>}
    </div>
  );
};

StyledSwitch.defaultProps = {
  checked: false,
  label: 'Check',
};

export default StyledSwitch;
