import React, { useState, useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tile, Spacer, Label, Button, Modal, Badge, Checkbox } from '../../atoms';
import FormItem from '../FormItem/FormItem';
import PrintForm from './PrintForm';
import { useGetSavedCRFSQuery, registriesApi, useAdvancePatientStepMutation } from '../../../api/builders/registries.api';
import DocumentsDrive from '../DocumentsDrive/DocumentsDrive';
import './conference-crf.scss';

export interface ConferenceCRFProps {
  className?: string,
  patient: any,
  readOnly?: boolean,
}

const ConferenceCRF = (props: ConferenceCRFProps): JSX.Element => {
  const { patient, readOnly } = props;
  const [questions, setQuestions] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [payload, setPl] = useState<any>(null);
  const { data, isLoading: isLoadingData, isFetching: isFetchingData } = useGetSavedCRFSQuery(payload !== null ? payload : skipToken);
  const { refetch } =
    registriesApi.endpoints.getSavedCRFS.useQuerySubscription(payload !== null ? payload : skipToken);
  const [edit, { isLoading: isEdititng, isSuccess, isError, error }] = useAdvancePatientStepMutation();


  useEffect(() => {
    if (patient && patient?.steps?.crfQuestions && typeof patient.steps.crfQuestions === 'string') {
      const parsed = JSON.parse(patient.steps.crfQuestions);
      setQuestions(parsed);
    }
  }, [patient]);

  useEffect(() => {
    const tmp = {
      patientcarepathwayId: patient?.patientcarepathwayId,
      stepId: patient?.steps?.id,
    };
    setPl(tmp);
  }, [patient]);

  const handleStatusControl = async () => {
    try {
      const pl = {
        patientcarepathwayId: patient?.patientcarepathwayId,
        body: {
          stepId: patient?.steps?.id,
          status: 'COMPLETED',
        },
      };
      console.log('pl', pl);
      await edit(pl);
      setOpen(false);
    } catch (e) {
      console.error('e', e);
    }
  };


  const saveProgress = () => {
    try {
      const pl = {
        patientcarepathwayId: patient?.patientcarepathwayId,
        body: {
          stepId: patient.steps.id,
          crfQuestions: JSON.stringify(questions),
        },
      };
      edit(pl);
    } catch (e) {
      console.error('e', e);
    }
  };


  const handleViewAllAnswer = (ans: any, index: number) => {
    const updatedSurvey = [...questions];
    updatedSurvey[index].answer = ans;
    setQuestions(updatedSurvey);
  };

  const getClass = (indx: any) => {
    let adtnClass = '';
    try {
      if (questions.length === 1) {
        adtnClass = 'span-two';
      } else {
        if (indx > 0 && indx < questions.length - 1) {
          if (
            (questions[indx - 1].type === 'Boolean' || questions[indx - 1].type === 'LIST_SINGLE' || questions[indx - 1].type === 'SECTIONTITLE') &&
            (questions[indx + 1].type === 'Boolean' || questions[indx + 1].type === 'LIST_SINGLE' || questions[indx + 1].type === 'SECTIONTITLE')
          ) {
            adtnClass = 'span-two';
          }
        }
        if (indx === questions.length - 1) {
          if (questions[indx - 1].type === 'Boolean' || questions[indx - 1].type === 'LIST_SINGLE' || questions[indx - 1].type === 'SECTIONTITLE') {
            adtnClass = 'span-two';
          }
        }
      }
    } catch (e) {
      console.error('e', e, indx);
    }
    return adtnClass;
  };



  return (
    <div className='conference-crf__body'>
      <div>
        <Label uppercase size='h5' weight={600} color="primary">
          {patient.firstname} {patient.lastname}
        </Label>
        <Spacer value={5} />
        <Label uppercase size='h5' weight={300}>
          {patient?.steps?.description}
        </Label>
        {patient?.steps?.description &&
          <div className='conference-crf__body'>
            <div>
              <div className='crf-wrapper' >
                {questions?.map((q: any, index: number) => (
                  <FormItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`survey-question-${q.id}-${index}`}
                    question={q}
                    onAnswer={(it: any) => handleViewAllAnswer(it, index)}
                    currentIndex={index}
                    editable
                    noNumbers
                    className={getClass(index)}
                  />
                ))}
              </div>
              <Spacer value={20} />
              {patient?.steps?.patientStatus !== 'COMPLETED' &&
                <div className='btn-row'>
                  <Button onClick={saveProgress}>Save Progress</Button>
                  <Button onClick={() => setOpen(true)}>Sign and Complete</Button>
                </div>}
            </div>
          </div>
        }
      </div>

      <div>
        {/* TODO:: FIX DOC DRIVE COMPONENT */}
        {/* {(Array.isArray(data) && data.length > 0) &&
          <DocumentsDrive data={data} refetch={refetch} itemId={patient.steps.id} />
        } */}
        <Spacer value={20} />
      </div>

      <Modal open={open} onClose={() => setOpen(false)} title={patient?.steps?.stepLabel}>
        <div className='crf-wrapper' >
          {/* REGISTRYTODO:::  change the renderItem function, honestly looks busted, should show table or something ? */}
          <PrintForm
            data={questions}
            item={patient?.steps}
            title={`${patient?.steps?.description} Form`}
            path={`s3://upstream-prod-files-fs/PatientCarePathway/${patient?.patientcarepathwayId}/step/${patient?.steps?.id}/documents/`}
            submitSurvey={handleStatusControl}
            carePathwayId={patient?.patientcarepathwayId}
            renderItem={(q: any, index: number) => <FormItem
              // eslint-disable-next-line react/no-array-index-key
              key={`survey-question-${q.id}-${index}`}
              question={q}
              onAnswer={(it: any) => handleViewAllAnswer(it, index)}
              currentIndex={index}
              editable={false}
              noNumbers
            />}
          />

        </div>
      </Modal>
    </div>
  );
};

ConferenceCRF.defaultProps = {
  className: '',
  readOnly: false,
};

ConferenceCRF.displayName = 'ConferenceCRF';
export default ConferenceCRF;

