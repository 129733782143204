import React, { useEffect } from 'react';
/* eslint-disable */
import { useLocation, Link } from 'react-router-dom';

interface BreadcrumbLink {
  link: string;
  name: string;
}


export interface BreadcrumbProps {
  crumbs: BreadcrumbLink[],
}

const Breadcrumb = (props: BreadcrumbProps): JSX.Element => {
  const { crumbs } = props
  // const location = useLocation();
  // const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbLink[]>([]);

  // useEffect(() => {
  //   const pathname = location.pathname;
  //   const paths = pathname.split('/').filter(x => x);
  //   const path2 = pathname.split('/').filter(x => x !== 'private');
  //   const breadcrumbLinks: BreadcrumbLink[] = paths.map((path, index) => {
  //     const link = `/${paths.slice(0, index + 1).join('/')}`;
  //     return { link, name: path };
  //   });
  //   setBreadcrumbs(breadcrumbLinks);
  // }, [location.pathname]);


  return (
    <div className="card">
      {crumbs.map((breadcrumb, index) => (
        <div key={index} >
          {index === crumbs.length - 1 ? (
            breadcrumb.name
          ) : (
            <span> <Link to={breadcrumb.link}>{breadcrumb.name}</Link> {' '}/</span>
          )}
        </div>
      ))}
    </div>
  );
};

Breadcrumb.defaultProps = {
};

Breadcrumb.displayName = 'Breadcrumb';
export default Breadcrumb;
